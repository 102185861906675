import { types } from "./actions";

const initialState = {
  isLoadingList: false,
  error: null,
  list: null,
  listjlo: null,
  isLoading: false,
};

const strategies = {
  [types.GET_USERS_LIST_REQUEST]: getUsersListRequest,
  [types.GET_USERS_LIST_SUCCESS]: getUsersListSuccess,
  [types.GET_USERS_LIST_SUCCESSJLO]: getUsersListSuccessjlo,
  [types.GET_USERS_LIST_ERROR]: getUsersListError,
  [types.ACTIVATE_USER_REQUEST]: activateUserRequest,
  [types.ACTIVATE_USER_SUCCESS]: activateUserSuccess,
  [types.ACTIVATE_USER_ERROR]: activateUserError,
  [types.DEACTIVATE_USER_REQUEST]: deactivateUserRequest,
  [types.DEACTIVATE_USER_SUCCESS]: deactivateUserSuccess,
  [types.DEACTIVATE_USER_ERROR]: deactivateUserError,

  [types.PAID_TO_FREE_USER_REQUEST]: paidtofreeUserRequest,
  [types.PAID_TO_FREE_USER_SUCCESS]: paidtofreeUserSuccess,
  [types.PAID_TO_FREE_USER_ERROR]: paidtofreeUserError,
  [types.FREE_TO_PAID_USER_REQUEST]: freetopaidUserRequest,
  [types.FREE_TO_PAID_USER_SUCCESS]: freetopaidUserSuccess,
  [types.FREE_TO_PAID_USER_ERROR]: freetopaidUserError,
  __default__: (state) => state,
};

export default function reducer(state = initialState, action) {
  const transformer = strategies[action.type]
    ? strategies[action.type]
    : strategies.__default__;
  return transformer(state, action);
}

function getUsersListRequest(state) {
  return { ...state, isLoadingList: true, error: null };
}

function getUsersListSuccess(state, action) {
  return {
    ...state,
    isLoadingList: false,
    error: null,
    list: action.payload,
  };
}

function getUsersListSuccessjlo(state, action) {
  return {
    ...state,
    isLoadingList: false,
    error: null,
    listjlo: action.payload,
  };
}

function getUsersListError(state, action) {
  return { ...state, isLoadingList: false, error: action.payload };
}

function activateUserRequest(state) {
  return { ...state, isLoading: true, error: null };
}

function activateUserSuccess(state) {
  return { ...state, isLoading: false, error: null };
}

function activateUserError(state, action) {
  return { ...state, isLoading: false, error: action.payload };
}

function deactivateUserRequest(state) {
  return { ...state, isLoading: true, error: null };
}

function deactivateUserSuccess(state) {
  return { ...state, isLoading: false, error: null };
}

function deactivateUserError(state, action) {
  return { ...state, isLoading: false, error: action.payload };
}

function paidtofreeUserRequest(state) {
  return { ...state, isLoading: true, error: null };
}

function paidtofreeUserSuccess(state) {
  return { ...state, isLoading: false, error: null };
}

function paidtofreeUserError(state, action) {
  return { ...state, isLoading: false, error: action.payload };
}

function freetopaidUserRequest(state) {
  return { ...state, isLoading: true, error: null };
}

function freetopaidUserSuccess(state) {
  return { ...state, isLoading: false, error: null };
}

function freetopaidUserError(state, action) {
  return { ...state, isLoading: false, error: action.payload };
}
