import "../css/App.css";

import Main from "./main/Main";
import React, { Component } from "react";

import "./Appx.scss";
class App extends Component {
  componentDidMount() {}

  render() {
    return (
      <div className="App">
        <Main />
      </div>
    );
  }
}

export default App;
