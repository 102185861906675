import PropTypes from "prop-types";
import React from "react";
import "./Login.scss";

const LoginForm = ({
  loggingIn,
  onClick,
  onChange,
  onKeyPress,
  errors,
  user,
  loginButton,
}) => {
  return (
    <div className="card-body container-fluid d-flex justify-items-center align-items-center">
      <div className="row mx-auto">
        <div className="col-md-6 left hidden-sm">
          <div className="text pl-4">
            <p>
              Sign In to your <span>account</span>
            </p>
          </div>
        </div>
        <div className="col-md-6 right d-flex">
          <div className="login pt-md-5">
            <div className="card-body-heading pb-3">
              <img
                src={require("../../assets/images/logo_black.png")}
                style={{ width: 80 }}
                alt=""
              />
              <h3 className="pt-4">Welcome,</h3>
              <p>Sign in to your account</p>
            </div>

            <form onSubmit={onClick}>
              <div className="form-group">
                <input
                  className="form-control"
                  value={user ? user.email : ""}
                  onChange={onChange}
                  type="email"
                  name="email"
                  placeholder="example@mail.com"
                />
                <div className="errox-box text-left">
                  <p className="error">{errors.summary}</p>
                </div>
              </div>
              <div className="form-group position-relative">
                <input
                  className="form-control"
                  type="password"
                  name="password"
                  value={user ? user.password : ""}
                  placeholder="Password"
                  onChange={onChange}
                />
                {}
                <div className="errox-box text-left">{}</div>
              </div>
              <div className="form-group shadow-lg mb-5 bg-white rounded">
                {}
                {loggingIn && (
                  <button
                    className="btn btn-primary btn-lg btn-block p-3"
                    disabled={loggingIn}
                  >
                    <span
                      className="spinner-border"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                )}
                {!loggingIn && (
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg btn-block p-3"
                  >
                    SIGN IN
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

LoginForm.propTypes = {
  onClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  loginButton: PropTypes.object.isRequired,
};

export default LoginForm;
