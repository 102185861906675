import { types } from "./actions";
import { call, put, takeLatest, take } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { db, getUserToken } from "../../app/utilities/firebase";
import axios from "axios";
import { config } from "../../app/utilities/firebase";

export function* LicenceWatcherSaga() {
  yield takeLatest(types.GET_LICENCE_REQUESTS, workerSaga);
}

function* workerSaga() {
  try {
    const channel = new eventChannel((emitter) => {
      let listener;
      db.collection("license_requests").onSnapshot(async () => {
        let requests = [];
        const snap = await db.collection("license_requests").get();
        requests = snap.docs.map((d) => d.data());

        emitter(
          requests.sort((a, b) => {
            let nameOrder = a.firstName.localeCompare(b.firstName);

            let statusOrder =
              (a.status === "pending" && b.status === "pending") ||
              (a.status !== "pending" && b.status !== "pending")
                ? nameOrder
                : a.status !== "pending"
                ? 1
                : -1;
            return statusOrder;
          })
        );
      });

      return () => {
        listener.off();
      };
    });

    while (true) {
      const data = yield take(channel);
      yield put({ type: types.GET_LICENCE_REQUESTS_SUCCESS, payload: data });
    }
  } catch (error) {
    yield put({ type: types.GET_LICENCE_REQUESTS_ERROR, payload: error });
  }
}

export function* AddLicenceWatcherSaga() {
  yield takeLatest(types.ADD_LICENCE_REQUEST, addLicenseRequestWorkerSaga);
}

function* addLicenseRequestWorkerSaga(action) {
  try {
    const userRef = yield call(checkEmail, action.payload.email);
    console.log("user ref", userRef);
    if (userRef.size === 0) {
      yield call(AddLicenseRequests, action.payload);
      yield put({ type: types.ADD_LICENCE_REQUEST_SUCCESS });
    } else {
      yield put({
        type: types.ADD_LICENCE_REQUEST_ERROR,
        payload: { message: "User with the email already exists" },
      });
    }
  } catch (error) {
    yield put({
      type: types.ADD_LICENCE_REQUEST_ERROR,
      payload: error,
    });
  }
}
async function checkEmail(email) {
  const userRef = db.collection("users").where("email", "==", email).get();
  return userRef;
}
async function AddLicenseRequests(data) {
  console.log(data);

  let docRef = db.collection("license_requests").doc();
  const doc_id = docRef.id;
  data = { ...data, doc_id };
  docRef.set(data);
}

export function* DeleteLicenceWatcherSaga() {
  yield takeLatest(types.DELETE_LICENCE_REQUEST, SetFreePeriodWorkerSaga);
}

export function* SetFreePeriodWatcherSaga() {
  yield takeLatest(types.SET_FREE_PERIOD_REQUEST, SetFreePeriodWorkerSaga);
}

function* SetFreePeriodWorkerSaga(action) {
  try {
    yield call(SetFreePeriod, action.payload);
    yield put({ type: types.SET_FREE_PERIOD_SUCCESS });
  } catch (error) {
    yield put({
      type: types.SET_FREE_PERIOD_ERROR,
      payload: error,
    });
  }
}

async function SetFreePeriod(data) {
  if (!data.uid) {
    return await axios.post(`${config.apiUrl}/approverequest`, data, {
      headers: {
        Authorization: await getUserToken(),
      },
    });
  }
  return await axios.post(`${config.apiUrl}/updaterequest`, data, {
    headers: {
      Authorization: await getUserToken(),
    },
  });
}
