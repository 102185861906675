import { types } from "./actions";
import { call, put, takeLatest } from "redux-saga/effects";
import { signOut } from "../../app/utilities/firebase";

export function* watcherSagaLogout() {
  yield takeLatest(types.REQUEST_LOGOUT, workerSagaLogout);
}

function requestLogout() {
  return signOut();
}

function* workerSagaLogout() {
  try {
    yield call(requestLogout);
    localStorage.clear();
    yield put({ type: types.LOGOUT_SUCCESS });
  } catch (error) {
    yield put({ type: types.LOGOUT_ERROR, payload: error });
  }
}
