export const types = {
  GET_LICENCE_REQUESTS: "[Dashboard] Get license request list",
  GET_LICENCE_REQUESTS_SUCCESS: "[Dashboard] Get license request list Success",
  GET_LICENCE_REQUESTS_ERROR: "[Dashboard] Get license request list Error",
  LOGOUT_SUCCESS: "[AUTH] Logout Success",
  ADD_LICENCE_REQUEST: "[DASHBOARD] Send add license request",
  ADD_LICENCE_REQUEST_SUCCESS: "[DASHBOARD] Add license request success",
  ADD_LICENCE_REQUEST_ERROR: "[DASHBOARD] Add license request error",
  DELETE_LICENCE_REQUEST: "[DASHBOARD] Send delete license request",
  SET_FREE_PERIOD_REQUEST: "[DASHBOARD] Send set free period request",
  SET_FREE_PERIOD_SUCCESS:
    "[DASHBOARD] Send set free period or delete license request success",
  SET_FREE_PERIOD_ERROR:
    "[DASHBOARD] Send set free period or delete license request error",
};

export const LoadAllLicenceRequests = () => {
  return {
    type: types.GET_LICENCE_REQUESTS,
  };
};
export const LoadAllLicenceRequestsSuccess = (list) => {
  return {
    type: types.GET_LICENCE_REQUESTS_SUCCESS,
    payload: list,
  };
};
export const LoadAllLicenceRequestsError = (error) => {
  return {
    type: types.GET_LICENCE_REQUESTS_ERROR,
    payload: error,
  };
};

export const AddLicenseRequest = (LicenseRequest) => {
  return {
    type: types.ADD_LICENCE_REQUEST,
    payload: LicenseRequest,
  };
};

export const AddLicenseRequestSuccess = () => {
  return {
    type: types.ADD_LICENCE_REQUEST_SUCCESS,
  };
};

export const AddLicenseRequestError = (error) => {
  return {
    type: types.ADD_LICENCE_REQUEST_ERROR,
    payload: error,
  };
};

export const DeleteLicenseRequest = (id) => {
  return {
    type: types.DELETE_LICENCE_REQUEST,
    payload: id,
  };
};
export const DeleteLicenseRequestSuccess = () => ({
  type: types.DELETE_LICENCE_REQUEST_SUCCESS,
});

export const DeleteLicenseRequestError = (error) => {
  return {
    type: types.DELETE_LICENCE_REQUEST_ERROR,
    payload: error,
  };
};

export const SetFreePeriodRequest = (data) => {
  return {
    type: types.SET_FREE_PERIOD_REQUEST,
    payload: data,
  };
};

export const SetFreePeriodSuccess = () => ({
  type: types.SET_FREE_PERIOD_SUCCESS,
});

export const SetFreePeriodError = (error) => {
  return {
    type: types.SET_FREE_PERIOD_ERROR,
    payload: error,
  };
};
