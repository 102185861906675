import { types } from "./actions";

const initialState = {
  isLoadingList: false,
  error: null,
  list: null,
  isLoading: false,
  emailList: null,
  mmsList: null,
  anniverserySMSList: null,
  anniverseryEMAILList: null,
  anniverseryMMSList: null,
  isLoadingBirthdaySMS: false,
  isLoadingBirthdayEmail: false,
  isLoadingBirthdayMMS: false,
  isLoadingAnniverserySMS: false,
  isLoadingAnniverseryEmail: false,
  isLoadingAnniverseryMMS: false,
  isLoadingDELETEBirthdaySMS: false,
  postcloseSMSList: null,
  postcloseMMSList: null,
  PostcloseEmailList: null,
  isLoadingPostCloseSMS: false,
  isLoadingPostCloseEMAIL: false,
  isLoadingPostCloseMMS: false,
  removeImageLoading: false,
  removeImageSucess: false,
  removeImageError: null,
  setNurtureDateLoading: false,
  setNurtureDateSucess: false,
  setNurtureDateError: null,
  getNurtureDateLoading: false,
  getNurtureDateSucess: false,
  getNurtureDateError: null,
  nurtureDates: null,
};

const strategies = {
  [types.GET_AIASSISTANT_LIST_REQUEST]: getAiListRequest,
  [types.GET_AIASSISTANT_LIST_SUCCESS]: getAiListSuccess,
  [types.GET_AIASSISTANT_LIST_ERROR]: getAiListError,
  [types.ADD_AIASSISTANT_REQUEST]: addAiRequest,
  [types.ADD_AIASSISTANT_SUCCESS]: addAiSuccess,
  [types.ADD_AIASSISTANT_ERROR]: addAiError,
  [types.DELETE_AIASSISTANT_REQUEST]: deleteAiRequest,
  [types.DELETE_AIASSISTANT_SUCCESS]: deleteAiSeccess,
  [types.DELETE_AIASSISTANT_ERROR]: deleteAiError,
  [types.ADD_AIASSISTANT_SELECT_REQUEST]: addSelectAiRequest,
  [types.ADD_AIASSISTANT_SELECT_SUCCESS]: addselectAiSuccess,
  [types.ADD_AIASSISTANT_SELECT_ERROR]: addselectAiError,

  [types.GET_AIASSISTANT_EMAIL_REQUEST]: getAiEmailtRequest,
  [types.GET_AIASSISTANT_EMAIL_SUCCESS]: getAiEmailtSuccess,
  [types.GET_AIASSISTANT_EMAIL_ERROR]: getAiEmailtError,
  [types.ADD_AIASSISTANT_EMAIL_REQUEST]: addAiEmailRequest,
  [types.ADD_AIASSISTANT_EMAIL_SUCCESS]: addAiEmailSuccess,
  [types.ADD_AIASSISTANT_EMAIL_ERROR]: addAiEmailError,
  [types.DELETE_AIASSISTANT_EMAIL_REQUEST]: deleteAiEmailRequest,
  [types.DELETE_AIASSISTANT_EMAIL_SUCCESS]: deleteAiEmailSuccess,
  [types.DELETE_AIASSISTANT_EMAIL_ERROR]: deleteAiEmailError,
  [types.ADD_AIASSISTANT_EMAIL_SELECT_REQUEST]: addAiEmailSelectRequest,
  [types.ADD_AIASSISTANT_EMAIL_SELECT_SUCCESS]: addAiEmailSelectSuccess,
  [types.ADD_AIASSISTANT_EMAIL_SELECT_ERROR]: addAiEmailSelectError,

  [types.GET_AIASSISTANT_MMS_REQUEST]: getAiMMStRequest,
  [types.GET_AIASSISTANT_MMS_SUCCESS]: getAiMMStSuccess,
  [types.GET_AIASSISTANT_MMS_ERROR]: getAiMMStError,
  [types.ADD_AIASSISTANT_MMS_REQUEST]: addAiMMSRequest,
  [types.ADD_AIASSISTANT_MMS_SUCCESS]: addAiMMSSuccess,
  [types.ADD_AIASSISTANT_MMS_ERROR]: addAiMMSError,
  [types.DELETE_AIASSISTANT_MMS_REQUEST]: deleteAiMMSRequest,
  [types.DELETE_AIASSISTANT_MMS_SUCCESS]: deleteAiMMSSuccess,
  [types.DELETE_AIASSISTANT_MMS_ERROR]: deleteAiMMSError,
  [types.ADD_AIASSISTANT_MMS_SELECT_REQUEST]: addAiMMSSelectRequest,
  [types.ADD_AIASSISTANT_MMS_SELECT_SUCCESS]: addAiMMSSelectSuccess,
  [types.ADD_AIASSISTANT_MMS_SELECT_ERROR]: addAiMMSSelectError,

  [types.REMOVE_AIASSISTANT_MMS_IMAGE_REQUEST]: removeMMSImage,
  [types.REMOVE_AIASSISTANT_MMS_IMAGE_SUCCESS]: removeMMSImageSuccess,
  [types.REMOVE_AIASSISTANT_MMS_IMAGE_ERROR]: removeMMSImageError,

  [types.GET_ANNIVERSERY_AIASSISTANT_LIST_REQUEST]: getAnniverseryAiListRequest,
  [types.GET_ANNIVERSERY_AIASSISTANT_LIST_SUCCESS]: getAnniverseryAiListSuccess,
  [types.GET_ANNIVERSERY_AIASSISTANT_LIST_ERROR]: getAnniverseryAiListError,
  [types.ADD_ANNIVERSERY_AIASSISTANT_REQUEST]: addAnniverseryAiRequest,
  [types.ADD_ANNIVERSERY_AIASSISTANT_SUCCESS]: addAnniverseryAiSuccess,
  [types.ADD_ANNIVERSERY_AIASSISTANT_ERROR]: addAnniverseryAiError,
  [types.DELETE_ANNIVERSERY_AIASSISTANT_REQUEST]: deleteAnniverseryAiRequest,
  [types.DELETE_ANNIVERSERY_AIASSISTANT_SUCCESS]: deleteAnniverseryAiSeccess,
  [types.DELETE_ANNIVERSERY_AIASSISTANT_ERROR]: deleteAnniverseryAiError,
  [types.ADD_ANNIVERSERY_AIASSISTANT_SELECT_REQUEST]:
    addSelectAnniverseryAiRequest,
  [types.ADD_ANNIVERSERY_AIASSISTANT_SELECT_SUCCESS]:
    addselectAnniverseryAiSuccess,
  [types.ADD_ANNIVERSERY_AIASSISTANT_SELECT_ERROR]: addselectAnniverseryAiError,

  [types.GET_ANNIVERSERY_AIASSISTANT_EMAIL_REQUEST]:
    getAnniverseryAiEmailtRequest,
  [types.GET_ANNIVERSERY_AIASSISTANT_EMAIL_SUCCESS]:
    getAnniverseryAiEmailtSuccess,
  [types.GET_ANNIVERSERY_AIASSISTANT_EMAIL_ERROR]: getAnniverseryAiEmailtError,
  [types.ADD_ANNIVERSERY_AIASSISTANT_EMAIL_REQUEST]:
    addAnniverseryAiEmailRequest,
  [types.ADD_ANNIVERSERY_AIASSISTANT_EMAIL_SUCCESS]:
    addAnniverseryAiEmailSuccess,
  [types.ADD_ANNIVERSERY_AIASSISTANT_EMAIL_ERROR]: addAnniverseryAiEmailError,
  [types.DELETE_ANNIVERSERY_AIASSISTANT_EMAIL_REQUEST]:
    deleteAnniverseryAiEmailRequest,
  [types.DELETE_ANNIVERSERY_AIASSISTANT_EMAIL_SUCCESS]:
    deleteAnniverseryAiEmailSuccess,
  [types.DELETE_ANNIVERSERY_AIASSISTANT_EMAIL_ERROR]:
    deleteAnniverseryAiEmailError,
  [types.ADD_ANNIVERSERY_AIASSISTANT_EMAIL_SELECT_REQUEST]:
    addAnniverseryAiEmailSelectRequest,
  [types.ADD_ANNIVERSERY_AIASSISTANT_EMAIL_SELECT_SUCCESS]:
    addAnniverseryAiEmailSelectSuccess,
  [types.ADD_ANNIVERSERY_AIASSISTANT_EMAIL_SELECT_ERROR]:
    addAnniverseryAiEmailSelectError,

  [types.GET_ANNIVERSERY_AIASSISTANT_MMS_REQUEST]: getAnniverseryAiMMStRequest,
  [types.GET_ANNIVERSERY_AIASSISTANT_MMS_SUCCESS]: getAnniverseryAiMMStSuccess,
  [types.GET_ANNIVERSERY_AIASSISTANT_MMS_ERROR]: getAnniverseryAiMMStError,
  [types.ADD_ANNIVERSERY_AIASSISTANT_MMS_REQUEST]: addAnniverseryAiMMSRequest,
  [types.ADD_ANNIVERSERY_AIASSISTANT_MMS_SUCCESS]: addAnniverseryAiMMSSuccess,
  [types.ADD_ANNIVERSERY_AIASSISTANT_MMS_ERROR]: addAnniverseryAiMMSError,
  [types.DELETE_ANNIVERSERY_AIASSISTANT_MMS_REQUEST]:
    deleteAnniverseryAiMMSRequest,
  [types.DELETE_ANNIVERSERY_AIASSISTANT_MMS_SUCCESS]:
    deleteAnniverseryAiMMSSuccess,
  [types.DELETE_ANNIVERSERY_AIASSISTANT_MMS_ERROR]: deleteAnniverseryAiMMSError,
  [types.ADD_ANNIVERSERY_AIASSISTANT_MMS_SELECT_REQUEST]:
    addAnniverseryAiMMSSelectRequest,
  [types.ADD_ANNIVERSERY_AIASSISTANT_MMS_SELECT_SUCCESS]:
    addAnniverseryAiMMSSelectSuccess,
  [types.ADD_ANNIVERSERY_AIASSISTANT_MMS_SELECT_ERROR]:
    addAnniverseryAiMMSSelectError,

  [types.GET_POSTCLOSENURTURE_LIST_REQUEST]: getPOSTCLOSENURTUREListRequest,
  [types.GET_POSTCLOSENURTURE_LIST_SUCCESS]: getPOSTCLOSENURTUREListSuccess,
  [types.GET_POSTCLOSENURTURE_LIST_ERROR]: getPOSTCLOSENURTUREListError,
  [types.ADD_POSTCLOSENURTURE_REQUEST]: addPOSTCLOSENURTURERequest,
  [types.ADD_POSTCLOSENURTURE_SUCCESS]: addPOSTCLOSENURTURESuccess,
  [types.ADD_POSTCLOSENURTURE_ERROR]: addPOSTCLOSENURTUREError,
  [types.DELETE_POSTCLOSENURTURE_REQUEST]: deletePOSTCLOSENURTURERequest,
  [types.DELETE_POSTCLOSENURTURE_SUCCESS]: deletePOSTCLOSENURTURESeccess,
  [types.DELETE_POSTCLOSENURTURE_ERROR]: deletePOSTCLOSENURTUREError,
  [types.ADD_POSTCLOSENURTURE_SELECT_REQUEST]: addSelectPOSTCLOSENURTURERequest,
  [types.ADD_POSTCLOSENURTURE_SELECT_SUCCESS]: addselectPOSTCLOSENURTURESuccess,
  [types.ADD_POSTCLOSENURTURE_SELECT_ERROR]: addselectPOSTCLOSENURTUREError,

  [types.GET_POSTCLOSENURTURE_EMAIL_REQUEST]: getPOSTCLOSENURTUREEmailtRequest,
  [types.GET_POSTCLOSENURTURE_EMAIL_SUCCESS]: getPOSTCLOSENURTUREEmailtSuccess,
  [types.GET_POSTCLOSENURTURE_EMAIL_ERROR]: getPOSTCLOSENURTUREEmailtError,
  [types.ADD_POSTCLOSENURTURE_EMAIL_REQUEST]: addPOSTCLOSENURTUREEmailRequest,
  [types.ADD_POSTCLOSENURTURE_EMAIL_SUCCESS]: addPOSTCLOSENURTUREEmailSuccess,
  [types.ADD_POSTCLOSENURTURE_EMAIL_ERROR]: addPOSTCLOSENURTUREEmailError,
  [types.DELETE_POSTCLOSENURTURE_EMAIL_REQUEST]:
    deletePOSTCLOSENURTUREEmailRequest,
  [types.DELETE_POSTCLOSENURTURE_EMAIL_SUCCESS]:
    deletePOSTCLOSENURTUREEmailSuccess,
  [types.DELETE_POSTCLOSENURTURE_EMAIL_ERROR]: deletePOSTCLOSENURTUREEmailError,
  [types.ADD_POSTCLOSENURTURE_EMAIL_SELECT_REQUEST]:
    addPOSTCLOSENURTUREEmailSelectRequest,
  [types.ADD_POSTCLOSENURTURE_EMAIL_SELECT_SUCCESS]:
    addPOSTCLOSENURTUREEmailSelectSuccess,
  [types.ADD_POSTCLOSENURTURE_EMAIL_SELECT_ERROR]:
    addPOSTCLOSENURTUREEmailSelectError,

  [types.GET_POSTCLOSENURTURE_MMS_REQUEST]: getPOSTCLOSENURTUREMMStRequest,
  [types.GET_POSTCLOSENURTURE_MMS_SUCCESS]: getPOSTCLOSENURTUREMMStSuccess,
  [types.GET_POSTCLOSENURTURE_MMS_ERROR]: getPOSTCLOSENURTUREMMStError,
  [types.ADD_POSTCLOSENURTURE_MMS_REQUEST]: addPOSTCLOSENURTUREMMSRequest,
  [types.ADD_POSTCLOSENURTURE_MMS_SUCCESS]: addPOSTCLOSENURTUREMMSSuccess,
  [types.ADD_POSTCLOSENURTURE_MMS_ERROR]: addPOSTCLOSENURTUREMMSError,
  [types.DELETE_POSTCLOSENURTURE_MMS_REQUEST]: deletePOSTCLOSENURTUREMMSRequest,
  [types.DELETE_POSTCLOSENURTURE_MMS_SUCCESS]: deletePOSTCLOSENURTUREMMSSuccess,
  [types.DELETE_POSTCLOSENURTURE_MMS_ERROR]: deletePOSTCLOSENURTUREMMSError,
  [types.ADD_POSTCLOSENURTURE_MMS_SELECT_REQUEST]:
    addPOSTCLOSENURTUREMMSSelectRequest,
  [types.ADD_POSTCLOSENURTURE_MMS_SELECT_SUCCESS]:
    addPOSTCLOSENURTUREMMSSelectSuccess,
  [types.ADD_POSTCLOSENURTURE_MMS_SELECT_ERROR]:
    addPOSTCLOSENURTUREMMSSelectError,

  [types.SET_POSTCLOSENURTURE_DATE]: setPostcloseDate,
  [types.SET_POSTCLOSENURTURE_DATE_SUCCESS]: setPostCloseDateSuccess,
  [types.SET_POSTCLOSENURTURE_DATE_ERROR]: setPostCloseDateError,
  [types.GET_POSTCLOSENURTURE_DATE]: getPostcloseDate,
  [types.GET_POSTCLOSENURTURE_DATE_SUCCESS]: getPostCloseDateSuccess,
  [types.GET_POSTCLOSENURTURE_DATE_ERROR]: getPostCloseDateError,

  __default__: (state) => state,
};

export default function reducer(state = initialState, action) {
  const transformer = strategies[action.type]
    ? strategies[action.type]
    : strategies.__default__;
  return transformer(state, action);
}

function getAiListRequest(state) {
  return { ...state, isLoadingBirthdaySMS: true, error: null };
}

function getAiListSuccess(state, action) {
  return {
    ...state,
    isLoadingBirthdaySMS: false,
    error: null,
    list: action.payload,
  };
}
function getAiListError(state, action) {
  return { ...state, isLoadingBirthdaySMS: false, error: action.payload };
}

function addAiRequest(state) {
  return { ...state, isLoadingBirthdaySMS: true, error: null };
}
function addAiSuccess(state, action) {
  return {
    ...state,
    isLoadingBirthdaySMS: false,
    error: null,
  };
}
function addAiError(state, action) {
  return { ...state, isLoadingBirthdaySMS: false, error: action.payload };
}

function deleteAiRequest(state) {
  return { ...state, isLoadingDELETEBirthdaySMS: true, error: null };
}
function deleteAiSeccess(state, action) {
  return {
    ...state,
    isLoadingDELETEBirthdaySMS: false,
    error: null,
  };
}
function deleteAiError(state, action) {
  return { ...state, isLoadingDELETEBirthdaySMS: false, error: action.payload };
}

function addSelectAiRequest(state) {
  return { ...state, isLoadingBirthdaySMS: true, error: null };
}
function addselectAiSuccess(state, action) {
  return {
    ...state,
    isLoadingBirthdaySMS: false,
    error: null,
    list: action.payload,
  };
}
function addselectAiError(state, action) {
  return { ...state, isLoadingBirthdaySMS: false, error: action.payload };
}

function getAiEmailtRequest(state) {
  return { ...state, isLoadingBirthdayEmail: true, error: null };
}

function getAiEmailtSuccess(state, action) {
  return {
    ...state,
    isLoadingBirthdayEmail: false,
    error: null,
    emailList: action.payload,
  };
}
function getAiEmailtError(state, action) {
  return { ...state, isLoadingBirthdayEmail: false, error: action.payload };
}
function addAiEmailRequest(state) {
  return { ...state, isLoadingBirthdayEmail: true, error: null };
}
function addAiEmailSuccess(state, action) {
  return {
    ...state,
    isLoadingBirthdayEmail: false,
    error: null,
  };
}
function addAiEmailError(state, action) {
  return { ...state, isLoadingBirthdayEmail: false, error: action.payload };
}
function deleteAiEmailRequest(state) {
  return { ...state, isLoadingBirthdayEmail: true, error: null };
}
function deleteAiEmailSuccess(state, action) {
  return {
    ...state,
    isLoadingBirthdayEmail: false,
    error: null,
    emailList: action.payload,
  };
}
function deleteAiEmailError(state, action) {
  return { ...state, isLoadingBirthdayEmail: false, error: action.payload };
}
function addAiEmailSelectRequest(state) {
  return { ...state, isLoadingBirthdayEmail: true, error: null };
}
function addAiEmailSelectSuccess(state, action) {
  return {
    ...state,
    isLoadingBirthdayEmail: false,
    error: null,
    emailList: action.payload,
  };
}
function addAiEmailSelectError(state, action) {
  return { ...state, isLoadingBirthdayEmail: false, error: action.payload };
}

function getAiMMStRequest(state) {
  return { ...state, isLoadingBirthdayMMS: true, error: null };
}

function getAiMMStSuccess(state, action) {
  return {
    ...state,
    isLoadingBirthdayMMS: false,
    error: null,
    mmsList: action.payload,
  };
}
function getAiMMStError(state, action) {
  return { ...state, isLoadingBirthdayMMS: false, error: action.payload };
}
function addAiMMSRequest(state) {
  return { ...state, isLoadingBirthdayMMS: true, error: null };
}
function addAiMMSSuccess(state, action) {
  return {
    ...state,
    isLoadingBirthdayMMS: false,
    error: null,
  };
}
function addAiMMSError(state, action) {
  return { ...state, isLoadingBirthdayMMS: false, error: action.payload };
}
function deleteAiMMSRequest(state) {
  return { ...state, isLoadingBirthdayMMS: true, error: null };
}
function deleteAiMMSSuccess(state, action) {
  return {
    ...state,
    isLoadingBirthdayMMS: false,
    error: null,
    mmsList: action.payload,
  };
}
function deleteAiMMSError(state, action) {
  return { ...state, isLoadingBirthdayMMS: false, error: action.payload };
}
function addAiMMSSelectRequest(state) {
  return { ...state, isLoadingBirthdayMMS: true, error: null };
}
function addAiMMSSelectSuccess(state, action) {
  return {
    ...state,
    isLoadingBirthdayMMS: false,
    error: null,
    mmsList: action.payload,
  };
}
function addAiMMSSelectError(state, action) {
  return { ...state, isLoadingBirthdayMMS: false, error: action.payload };
}

function getAnniverseryAiListRequest(state) {
  return { ...state, isLoadingAnniverserySMS: true, error: null };
}

function getAnniverseryAiListSuccess(state, action) {
  return {
    ...state,
    isLoadingAnniverserySMS: false,
    error: null,
    anniverserySMSList: action.payload,
  };
}
function getAnniverseryAiListError(state, action) {
  return { ...state, isLoadingAnniverserySMS: false, error: action.payload };
}

function addAnniverseryAiRequest(state) {
  return { ...state, isLoadingAnniverserySMS: true, error: null };
}
function addAnniverseryAiSuccess(state, action) {
  return {
    ...state,
    isLoadingAnniverserySMS: false,
    error: null,
  };
}
function addAnniverseryAiError(state, action) {
  return { ...state, isLoadingAnniverserySMS: false, error: action.payload };
}

function deleteAnniverseryAiRequest(state) {
  return { ...state, isLoadingAnniverserySMS: true, error: null };
}
function deleteAnniverseryAiSeccess(state, action) {
  return {
    ...state,
    isLoadingAnniverserySMS: false,
    error: null,
    anniverserySMSList: action.payload,
  };
}
function deleteAnniverseryAiError(state, action) {
  return { ...state, isLoadingAnniverserySMS: false, error: action.payload };
}

function addSelectAnniverseryAiRequest(state) {
  return { ...state, isLoadingAnniverserySMS: true, error: null };
}
function addselectAnniverseryAiSuccess(state, action) {
  return {
    ...state,
    isLoadingAnniverserySMS: false,
    error: null,
    anniverserySMSList: action.payload,
  };
}
function addselectAnniverseryAiError(state, action) {
  return { ...state, isLoadingAnniverserySMS: false, error: action.payload };
}

function getAnniverseryAiEmailtRequest(state) {
  return { ...state, isLoadingAnniverseryEmail: true, error: null };
}

function getAnniverseryAiEmailtSuccess(state, action) {
  return {
    ...state,
    isLoadingAnniverseryEmail: false,
    error: null,
    anniverseryEMAILList: action.payload,
  };
}
function getAnniverseryAiEmailtError(state, action) {
  return { ...state, isLoadingAnniverseryEmail: false, error: action.payload };
}
function addAnniverseryAiEmailRequest(state) {
  return { ...state, isLoadingAnniverseryEmail: true, error: null };
}
function addAnniverseryAiEmailSuccess(state, action) {
  return {
    ...state,
    isLoadingAnniverseryEmail: false,
    error: null,
  };
}
function addAnniverseryAiEmailError(state, action) {
  return { ...state, isLoadingAnniverseryEmail: false, error: action.payload };
}
function deleteAnniverseryAiEmailRequest(state) {
  return { ...state, isLoadingAnniverseryEmail: true, error: null };
}
function deleteAnniverseryAiEmailSuccess(state, action) {
  return {
    ...state,
    isLoadingAnniverseryEmail: false,
    error: null,
    anniverseryEMAILList: action.payload,
  };
}
function deleteAnniverseryAiEmailError(state, action) {
  return { ...state, isLoadingAnniverseryEmail: false, error: action.payload };
}
function addAnniverseryAiEmailSelectRequest(state) {
  return { ...state, isLoadingAnniverseryEmail: true, error: null };
}
function addAnniverseryAiEmailSelectSuccess(state, action) {
  return {
    ...state,
    isLoadingAnniverseryEmail: false,
    error: null,
    anniverseryEMAILList: action.payload,
  };
}
function addAnniverseryAiEmailSelectError(state, action) {
  return { ...state, isLoadingAnniverseryEmail: false, error: action.payload };
}

function getAnniverseryAiMMStRequest(state) {
  return { ...state, isLoadingAnniverseryMMS: true, error: null };
}

function getAnniverseryAiMMStSuccess(state, action) {
  return {
    ...state,
    isLoadingAnniverseryMMS: false,
    error: null,
    anniverseryMMSList: action.payload,
  };
}
function getAnniverseryAiMMStError(state, action) {
  return { ...state, isLoadingAnniverseryMMS: false, error: action.payload };
}
function addAnniverseryAiMMSRequest(state) {
  return { ...state, isLoadingAnniverseryMMS: true, error: null };
}
function addAnniverseryAiMMSSuccess(state, action) {
  return {
    ...state,
    isLoadingAnniverseryMMS: false,
    error: null,
  };
}
function addAnniverseryAiMMSError(state, action) {
  return { ...state, isLoadingAnniverseryMMS: false, error: action.payload };
}
function deleteAnniverseryAiMMSRequest(state) {
  return { ...state, isLoadingAnniverseryMMS: true, error: null };
}
function deleteAnniverseryAiMMSSuccess(state, action) {
  return {
    ...state,
    isLoadingAnniverseryMMS: false,
    error: null,
    anniverseryMMSList: action.payload,
  };
}
function deleteAnniverseryAiMMSError(state, action) {
  return { ...state, isLoadingAnniverseryMMS: false, error: action.payload };
}
function addAnniverseryAiMMSSelectRequest(state) {
  return { ...state, isLoadingAnniverseryMMS: true, error: null };
}
function addAnniverseryAiMMSSelectSuccess(state, action) {
  return {
    ...state,
    isLoadingAnniverseryMMS: false,
    error: null,
    anniverseryMMSList: action.payload,
  };
}
function addAnniverseryAiMMSSelectError(state, action) {
  return { ...state, isLoadingAnniverseryMMS: false, error: action.payload };
}

function getPOSTCLOSENURTUREListRequest(state) {
  return { ...state, isLoadingPostCloseSMS: true, error: null };
}

function getPOSTCLOSENURTUREListSuccess(state, action) {
  return {
    ...state,
    isLoadingPostCloseSMS: false,
    error: null,
    postcloseSMSList: action.payload,
  };
}
function getPOSTCLOSENURTUREListError(state, action) {
  return { ...state, isLoadingPostCloseSMS: false, error: action.payload };
}

function addPOSTCLOSENURTURERequest(state) {
  return { ...state, isLoadingPostCloseSMS: true, error: null };
}
function addPOSTCLOSENURTURESuccess(state, action) {
  return {
    ...state,
    isLoadingPostCloseSMS: false,
    error: null,
  };
}
function addPOSTCLOSENURTUREError(state, action) {
  return { ...state, isLoadingPostCloseSMS: false, error: action.payload };
}

function deletePOSTCLOSENURTURERequest(state) {
  return { ...state, isLoadingPostCloseSMS: true, error: null };
}
function deletePOSTCLOSENURTURESeccess(state, action) {
  return {
    ...state,
    isLoadingPostCloseSMS: false,
    error: null,
  };
}
function deletePOSTCLOSENURTUREError(state, action) {
  return { ...state, isLoadingPostCloseSMS: false, error: action.payload };
}

function addSelectPOSTCLOSENURTURERequest(state) {
  return { ...state, isLoadingPostCloseSMS: true, error: null };
}
function addselectPOSTCLOSENURTURESuccess(state, action) {
  return {
    ...state,
    isLoadingPostCloseSMS: false,
    error: null,
    postcloseSMSList: action.payload,
  };
}
function addselectPOSTCLOSENURTUREError(state, action) {
  return { ...state, isLoadingPostCloseSMS: false, error: action.payload };
}

function getPOSTCLOSENURTUREEmailtRequest(state) {
  return { ...state, isLoadingPostCloseEMAIL: true, error: null };
}

function getPOSTCLOSENURTUREEmailtSuccess(state, action) {
  return {
    ...state,
    isLoadingPostCloseEMAIL: false,
    error: null,
    PostcloseEmailList: action.payload,
  };
}
function getPOSTCLOSENURTUREEmailtError(state, action) {
  return { ...state, isLoadingPostCloseEMAIL: false, error: action.payload };
}
function addPOSTCLOSENURTUREEmailRequest(state) {
  return { ...state, isLoadingPostCloseEMAIL: true, error: null };
}
function addPOSTCLOSENURTUREEmailSuccess(state, action) {
  return {
    ...state,
    isLoadingPostCloseEMAIL: false,
    error: null,
  };
}
function addPOSTCLOSENURTUREEmailError(state, action) {
  return { ...state, isLoadingPostCloseEMAIL: false, error: action.payload };
}
function deletePOSTCLOSENURTUREEmailRequest(state) {
  return { ...state, isLoadingPostCloseEMAIL: true, error: null };
}
function deletePOSTCLOSENURTUREEmailSuccess(state, action) {
  return {
    ...state,
    isLoadingPostCloseEMAIL: false,
    error: null,
    PostcloseEmailList: action.payload,
  };
}
function deletePOSTCLOSENURTUREEmailError(state, action) {
  return { ...state, isLoadingPostCloseEMAIL: false, error: action.payload };
}
function addPOSTCLOSENURTUREEmailSelectRequest(state) {
  return { ...state, isLoadingPostCloseEMAIL: true, error: null };
}
function addPOSTCLOSENURTUREEmailSelectSuccess(state, action) {
  return {
    ...state,
    isLoadingPostCloseEMAIL: false,
    error: null,
    PostcloseEmailList: action.payload,
  };
}
function addPOSTCLOSENURTUREEmailSelectError(state, action) {
  return { ...state, isLoadingPostCloseEMAIL: false, error: action.payload };
}

function getPOSTCLOSENURTUREMMStRequest(state) {
  return { ...state, isLoadingPostCloseMMS: true, error: null };
}

function getPOSTCLOSENURTUREMMStSuccess(state, action) {
  return {
    ...state,
    isLoadingPostCloseMMS: false,
    error: null,
    postcloseMMSList: action.payload,
  };
}
function getPOSTCLOSENURTUREMMStError(state, action) {
  return { ...state, isLoadingPostCloseMMS: false, error: action.payload };
}
function addPOSTCLOSENURTUREMMSRequest(state) {
  return { ...state, isLoadingPostCloseMMS: true, error: null };
}
function addPOSTCLOSENURTUREMMSSuccess(state, action) {
  return {
    ...state,
    isLoadingPostCloseMMS: false,
    error: null,
  };
}
function addPOSTCLOSENURTUREMMSError(state, action) {
  return { ...state, isLoadingPostCloseMMS: false, error: action.payload };
}
function deletePOSTCLOSENURTUREMMSRequest(state) {
  return { ...state, isLoadingPostCloseMMS: true, error: null };
}
function deletePOSTCLOSENURTUREMMSSuccess(state, action) {
  return {
    ...state,
    isLoadingPostCloseMMS: false,
    error: null,
    postcloseMMSList: action.payload,
  };
}
function deletePOSTCLOSENURTUREMMSError(state, action) {
  return { ...state, isLoadingPostCloseMMS: false, error: action.payload };
}
function addPOSTCLOSENURTUREMMSSelectRequest(state) {
  return { ...state, isLoadingPostCloseMMS: true, error: null };
}
function addPOSTCLOSENURTUREMMSSelectSuccess(state, action) {
  return {
    ...state,
    isLoadingPostCloseMMS: false,
    error: null,
    postcloseMMSList: action.payload,
  };
}
function addPOSTCLOSENURTUREMMSSelectError(state, action) {
  return { ...state, isLoadingPostCloseMMS: false, error: action.payload };
}

function removeMMSImage(state) {
  return { ...state, removeImageLoading: true, removeImageError: null };
}
function removeMMSImageSuccess(state) {
  return { ...state, removeImageLoading: false, removeImageError: null };
}
function removeMMSImageError(state, action) {
  return {
    ...state,
    removeImageLoading: false,
    removeImageError: action.payload,
  };
}

function setPostcloseDate(state) {
  return {
    ...state,
    setNurtureDateLoading: true,
    setNurtureDateSucess: false,
    setNurtureDateError: null,
  };
}
function setPostCloseDateSuccess(state) {
  return {
    ...state,
    setNurtureDateLoading: false,
    setNurtureDateSucess: true,
    setNurtureDateError: null,
  };
}
function setPostCloseDateError(state, action) {
  return {
    ...state,
    setNurtureDateLoading: false,
    setNurtureDateSucess: false,
    setNurtureDateError: action.payload,
  };
}
function getPostcloseDate(state) {
  return {
    ...state,
    getNurtureDateLoading: true,
    getNurtureDateSucess: false,
    getNurtureDateError: null,
  };
}
function getPostCloseDateSuccess(state, action) {
  return {
    ...state,
    nurtureDates: action.payload,
    getNurtureDateLoading: false,
    getNurtureDateSucess: true,
    getNurtureDateError: null,
  };
}
function getPostCloseDateError(state, action) {
  return {
    ...state,
    getNurtureDateLoading: false,
    getNurtureDateSucess: false,
    getNurtureDateError: action.payload,
  };
}
