import app from "firebase";
import "firebase/auth";
import "firebase/firestore";
import { UserType } from "../../constants/commonEnums";

const dev = {
  env: "dev",
  apiKey: "AIzaSyDQ-SkvT5U35cBlICyX3adGhVzmneJ2pkg",
  authDomain: "dailycrm-c9949.firebaseapp.com",
  databaseURL: "https://dailycrm-c9949.firebaseio.com",
  projectId: "dailycrm-c9949",
  storageBucket: "dailycrm-c9949.appspot.com",
  messagingSenderId: "726161968570",
  appId: "1:726161968570:web:aaca3bf110de282c",
  apiUrl:
    process.env.REACT_APP_STAGE === "emulator"
      ? `http://${
          process.env.REACT_APP_CF_BASE_URL || "localhost"
        }:5001/dailycrm-c9949/us-central1`
      : "https://us-central1-dailycrm-c9949.cloudfunctions.net",
};

const devMongo = {
  mongoUrl:
    process.env.REACT_APP_STAGE === "emulator"
      ? `http://${
          process.env.REACT_APP_MONGO_BACKEND_URL || "localhost"
        }:8080/api`
      : "https://devapi.dailyai.io/api",
};

const staging = {
  env: "staging",
  apiKey: "AIzaSyDCbbtsBNAL8d0Tha69vjidhE_qYUwcq3M",
  authDomain: "dailyai-staging.firebaseapp.com",
  projectId: "dailyai-staging",
  storageBucket: "dailyai-staging.appspot.com",
  messagingSenderId: "395079008917",
  appId: "1:395079008917:web:356788f2bc72cd2dce919c",
  measurementId: "G-JB8519DJSW",
  apiUrl: "https://us-central1-dailyai-staging.cloudfunctions.net",
};

const stagingMongo = {
  mongoUrl: "https://stagingapi.dailyai.io/api",
};

const prod = {
  env: "prod",
  apiKey: "AIzaSyBNLupca37eBS9jYz58K6xgI9Pbgas3HWw",
  authDomain: "dailyai-live.firebaseapp.com",
  databaseURL: "https://dailyai-live.firebaseio.com",
  apiUrl: "https://us-central1-dailyai-live.cloudfunctions.net",
  projectId: "dailyai-live",
  storageBucket: "dailyai-live.appspot.com",
  messagingSenderId: "636198002120",
  appId: "1:636198002120:web:4298fbf3c1eedae68cf4fd",
  measurementId: "G-469ET8RTL6",
};

const prodMongo = {
  mongoUrl: "https://api.dailyai.io/api",
};

console.log("react env", process.env);

let config = dev;
let mongo = devMongo;

switch (process.env.REACT_APP_STAGE) {
  case "prod":
    mongo = prodMongo;
    config = prod;
    break;
  case "staging":
    mongo = stagingMongo;
    config = staging;
    break;
  default:
    mongo = devMongo;
    config = dev;
    break;
}

app.initializeApp(config);

const auth = app.auth();
const db = app.firestore();
const storage = app.storage();

if (process.env.REACT_APP_STAGE === "emulator") {
  console.log("emulator");
  const emulatorhost = process.env.REACT_APP_CF_BASE_URL || "localhost";
  auth.useEmulator(`http://${emulatorhost}:9099`);
  db.useEmulator(emulatorhost, 8081);
  storage.useEmulator(emulatorhost, 9199);
}

const storageRef = storage.ref();

const doSignInWithEmailAndPassword = async (email, password) => {
  try {
    await app.auth().setPersistence(app.auth.Auth.Persistence.LOCAL);
    const user = await auth.signInWithEmailAndPassword(email, password);

    const userType = await checkAuth(user);
    if (userType !== UserType.INTERNAL_ADMIN) {
      signOut();
      return false;
    } else {
      getUserToken();
      return user;
    }
  } catch (e) {
    console.info("The user entered is not an internal admin.  Logging out.");
    return false;
  }
};

const checkAuth = async (user) => {
  const userDoc = await db.doc(`users/${user.user.uid}`).get();
  const userType = userDoc.data().userType;
  return userType;
};

const signOut = () => {
  return auth.signOut();
};

const getUserData = async () => {
  let users = await db.collection(`users`).get();
  return users.docs;
};
export const getUserToken = async () =>
  `Bearer ${await auth.currentUser.getIdToken(true)}`;

export {
  doSignInWithEmailAndPassword,
  checkAuth,
  signOut,
  getUserData,
  db,
  auth,
  storageRef,
  storage,
  config,
  mongo
};
