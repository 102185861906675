import React from "react";
import "./SetFreePeriods.scss";

const backArrow = () => (
  <svg width="20px" height="20px" viewBox="0 0 50 80" id="backArrowSvg">
    <polyline
      fill="none"
      stroke="#bebebe"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
      points="
	45.63,75.8 0.375,38.087 45.63,0.375 "
    />
  </svg>
);

const number = new RegExp(/^[1-9][0-9]*$/);
export default class SetFreePeriod extends React.Component {
  state = {
    selectDuration: "DAYS",
    number: "",
    error: "",
  };
  selectDayClick(period) {
    this.setState({ selectDuration: period });
  }
  setNumber({ target }) {
    let error = this.state.error;
    error = target.value && number.test(target.value) ? "" : "Invalid input";
    this.setState({
      number: target.value,
      error,
    });
  }
  setFreePeriodSubmit(event) {
    event.preventDefault();
    this.props.planSelect(
      this.props.planType,
      this.state.number,
      this.state.selectDuration
    );
  }
  setNumber = this.setNumber.bind(this);
  setFreePeriodSubmit = this.setFreePeriodSubmit.bind(this);

  render() {
    return (
      <div className="setFreePeriodsection mt-5 pl-4">
        <div className="setFreePeriodHeadSection">
          <h5 className="setFreePeriodHead font-hn-bold">Set Free Period</h5>
        </div>
        <div className="setFreePeriodFormSection pt-3">
          <form
            onSubmit={this.setFreePeriodSubmit}
            className=" justify-content-around align-items-center "
          >
            <ul className="list-inline">
              <li className="list-inline-item">
                <input
                  id="setFreePeriodInput"
                  className="form-control"
                  type="text"
                  onChange={this.setNumber}
                  value={this.state.number}
                />
              </li>
              <li className="list-inline-item">
                <div id="setFreedropdown" className="dropdown">
                  <a
                    className="btn  dropdown-toggle"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {this.state.selectDuration}
                  </a>
                  <div
                    id="dropdownMenu"
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <button
                      type="button"
                      className="dropdown-item font-hn-medium"
                      onClick={() => this.selectDayClick("DAYS")}
                    >
                      DAYS
                    </button>
                    <button
                      type="button"
                      className="dropdown-item font-hn-medium"
                      onClick={() => this.selectDayClick("WEEKS")}
                    >
                      WEEKS
                    </button>
                    <button
                      type="button"
                      className="dropdown-item font-hn-medium"
                      onClick={() => this.selectDayClick("MONTHS")}
                    >
                      MONTHS
                    </button>
                    <button
                      type="button"
                      className="dropdown-item font-hn-medium"
                      onClick={() => this.selectDayClick("YEARS")}
                    >
                      YEARS
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <div className="mt-5 pt-3">
              {}
              {!this.props.isLoading ? (
                <button
                  disabled={this.state.error || !this.state.number}
                  className="btn setfreePeriodBtn mr-5 font-hn-medium"
                  type="submit"
                >
                  SAVE
                </button>
              ) : (
                <button className="btn setfreePeriodBtn mr-5" disabled>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only">Loading...</span>
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    );
  }
}
