import { types } from "./actions";
const initialState = {
  isListLoading: false,
  BankDataRequest: [],
  BankDataApproved: [],
  BankDataRejected: [],
  error: null,
  totalcountRequest: null,
  totalcountApproved: null,
  totalcountRejected: null,
  isLoadingreject: false,
  isLoadingapprove: false,
};

const strategies = {
  [types.GET_BANK_REQUEST]: getBankDataRequest,
  [types.GET_BANK_REQUEST_SUCCESS]: getBankDataSuccessRequest,
  [types.GET_BANK_REQUEST_ERROR]: getBankDataErrorRequest,
  [types.GET_BANK_APPROVED]: getBankDataApproved,
  [types.GET_BANK_APPROVED_SUCCESS]: getBankDataSuccessApproved,
  [types.GET_BANK_APPROVED_ERROR]: getBankDataErrorApproved,

  [types.GET_BANK_REJECTED]: getBankDataRejected,
  [types.GET_BANK_REJECTED_SUCCESS]: getBankDataSuccessRejected,
  [types.GET_BANK_REJECTED_ERROR]: getBankDataErrorRejected,
  [types.REJECT_REQUEST]: reject,
  [types.REJECT_REQUEST_SUCCESS]: rejectSuccess,
  [types.REJECT_REQUEST_ERROR]: rejectError,
  [types.APPROVE_REQUEST]: approve,
  [types.APPROVE_REQUEST_SUCCESS]: approveSuccess,
  [types.APPROVE_REQUEST_ERROR]: approveError,

  __default__: (state) => ({ ...state, error: null }),
};

export default function reducer(state = initialState, action) {
  const transformer = strategies[action.type]
    ? strategies[action.type]
    : strategies.__default__;
  return transformer(state, action);
}

function getBankDataRequest(state, action) {
  return {
    ...state,
    isListLoading: true,
  };
}

function getBankDataSuccessRequest(state, action) {
  return {
    ...state,
    isListLoading: false,
    BankDataRequest: action.payload.data.data,
    totalcountRequest: action.payload.data.count,
  };
}

function getBankDataErrorRequest(state, action) {
  return {
    ...state,
    isListLoading: false,
    error: action,
  };
}

function getBankDataApproved(state, action) {
  return {
    ...state,
    isListLoading: true,
  };
}

function getBankDataSuccessApproved(state, action) {
  return {
    ...state,
    isListLoading: false,
    BankDataApproved: action.payload.data.data,
    totalcountApproved: action.payload.data.count,
  };
}

function getBankDataErrorApproved(state, action) {
  return {
    ...state,
    isListLoading: false,
    error: action,
  };
}

function getBankDataRejected(state, action) {
  return {
    ...state,
    isListLoading: true,
  };
}

function getBankDataSuccessRejected(state, action) {
  return {
    ...state,
    isListLoading: false,
    BankDataRejected: action.payload.data.data,
    totalcountRejected: action.payload.data.count,
  };
}

function getBankDataErrorRejected(state, action) {
  return {
    ...state,
    isListLoading: false,
    error: action,
  };
}

function reject(state, action) {
  return {
    ...state,
    isLoadingreject: true,
  };
}

function rejectSuccess(state, action) {
  return {
    ...state,
    isLoadingreject: false,
  };
}

function rejectError(state, action) {
  return {
    ...state,
    isLoadingreject: false,
  };
}

function approve(state, action) {
  return {
    ...state,
    isLoadingapprove: true,
  };
}

function approveSuccess(state, action) {
  return {
    ...state,
    isLoadingapprove: false,
  };
}

function approveError(state, action) {
  return {
    ...state,
    isLoadingapprove: false,
  };
}
