import React from "react";
import "./RejectedFreeAccount.scss";
import SelectPlan from "../../Modal/SelectPlan";
export default class RejectedFreeAccount extends React.Component {
  constructor(props) {
    super(props);
    this.extendsHandeler = this.extendsHandeler.bind(this);
    this.planSelect = this.planSelect.bind(this);
  }
  state = {
    userData: {},
    showSelectPlan: false,
  };
  extendsHandeler(item, index) {
    this.setState({
      showSelectPlan: !this.state.showSelectPlan,
      userData: item,
    });
  }

  planSelect(planType, planValue, planPeriod) {
    this.props.planSelect(
      planType,
      planValue,
      planPeriod,
      this.state.userData.doc_id
    );
  }
  render() {
    return (
      <div className="table-layout mt-3">
        <table className="table" cellSpacing="0">
          <thead className="table-header">
            <tr id="ap-fa-tableHeadRow">
              <th className="font-hn-medium ap-fa-table-headText">UserInfo</th>
              <th className="font-hn-medium ap-fa-table-headText pl-0">
                Phone
              </th>
              <th className="font-hn-medium ap-fa-table-headText pl-0">Bank</th>
              <th className="font-hn-medium ap-fa-table-headText pl-0">
                Requested
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.data.map((list) => {
              return (
                <tr id="ap-fa-tableBodyRow" key={list.doc_id}>
                  <td className="font-hn-medium ap-fa-tableText pt-4">
                    <div className="tableText font-hn-bold">
                      {list.firstName} {list.lastName}
                    </div>
                    <div className="ap-fa-tableEmailText font-hn-medium">
                      {list.email}
                    </div>
                  </td>
                  <td className="font-hn-medium ap-fa-tableText pt-4 pl-0">
                    {list.phoneNumber}
                  </td>
                  <td className="font-hn-medium ap-fa-tableText pt-4 pl-0">
                    {list.bankName}
                  </td>
                  <td className="font-hn-medium ap-fa-tableText pt-4 pl-0">
                    {list.noOfLicense}
                  </td>
                  {}
                </tr>
              );
            })}
            {this.state.showSelectPlan ? (
              <SelectPlan
                planSelect={this.planSelect}
                plan={"firstFew"}
                isLoading={this.props.isLoading}
                shown={this.state.showSelectPlan}
                userData={this.state.userData}
                onClose={this.extendsHandeler}
                notify={this.props.notify}
              />
            ) : null}
          </tbody>
        </table>
      </div>
    );
  }
}
