import React from "react";
import "./Discount.scss";
import * as dashboardActions from "../../store/dashboard/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class Discount extends React.Component {
  state = {
    number: 0,
  };
  setNumber({ target }) {
    this.setState({
      number: target.value,
    });
  }
  setFreePeriodSubmit(event) {
    event.preventDefault();
  }
  setNumber = this.setNumber.bind(this);
  setFreePeriodSubmit = this.setFreePeriodSubmit.bind(this);
  render() {
    return (
      <div className="discountsection mt-5 pl-4">
        <div className="discountHeadSection">
          <h5 className="discountHead">Discount</h5>
        </div>
        <div className="discountFormSection pt-3">
          <form
            onSubmit={this.setFreePeriodSubmit}
            className=" justify-content-around align-items-center "
          >
            <ul className="list-inline">
              <li className="list-inline-item">
                <input
                  id="discountInput"
                  className="form-control"
                  type="number"
                  onChange={this.setNumber}
                  value={this.state.number}
                />
              </li>
              <li className="list-inline-item">
                <div id="discountdropdown" className="dropdown">
                  <a aria-expanded="false">License</a>
                </div>
              </li>
            </ul>
            <div className="mt-5 pt-3">
              <button
                className="btn discountBACKBtn"
                onClick={this.props.backButton}
                type="button"
              >
                BACK
              </button>
              <button className="btn discountBtn mr-5" type="submit">
                SAVE
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dashboard: state.dashboard,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(dashboardActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Discount);
