export const UserType = {
  SLO: "SLO",
  JLO: "JLO",
  MO: "MO",
  SUPER_ADMIN: "Super Admin",
  TEAM_LEADER: "TeamLeader",
  ADMIN: "admin",
  MANAGER: "manager",
  INTERNAL_ADMIN: "superAdmin",
  NONE: "",
};

export const UserDesignation = {
  SENIOR_LOAN_OFFICER: "Senior Loan Officer",
  JUNIOR_LOAN_OFFICER: "Junior Loan Officer",
  LOAN_OFFICER_ASSISTANT: "Loan Officer Assistant",
  LOAN_PROCESSOR: "Loan Processor",
  SALES_MANAGER: "Sales Manager",
  BUSINESS_DEVELOPMENT_MANAGER: "Business Development Manager",
  ADMINISTRATIVE_ASSISTANT: "Administrative Assistant",
  MARKETING_MANAGER: "Marketing Manager",
  LOAN_ASSIGNEE: "Loan Assignee",
};
