import React from "react";
import "./EncompassDetails.scss";
import EncompassSingleView from "./EncompassSingleView";

import { connect } from "react-redux";
import EncompassEmpty from "./EncompassEmpty";
class EncompassDetails extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.changeText = this.changeText.bind(this);
  }
  state = {
    showDetails: false,
    userData: {},
    search: "",
    activeIndex: null,
    data: {},
  };
  handleClick(event, index) {
    this.setState({ userData: event });
    this.setState({ showDetails: true });
    this.setState({ activeIndex: index });
  }
  changeText({ target }) {
    this.setState({ search: target.value });
  }

  render() {
    let data = {};
    return (
      <div className="row">
        <div className="col-8">
          <div className="bg-white partner-layout w-set task-list-layout d-flex flex-column pt-5 px-3">
            <div className="d-flex  pb-2 flex-column flex-md-row">
              <div className="pr-4 pt-1 d-flex align-items-baseline font-hn-medium">
                <h4 className="en-de-head-Text">Subscription</h4>
              </div>

              <div className="d-flex en-de-searchView">
                <div id="en-de-searchstyle" className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Seach Name"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    onChange={this.changeText}
                    value={this.state.search}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text" id="basic-addon2">
                      <img
                        src={require("../../assets/images/searchIcon.svg")}
                        alt="search"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="en-de-table-layout mt-3">
              <div className="d-flex align-items-center justify-content-center text-center">
                <p></p>
              </div>
              <table className="table en-de-table-striped table-hover">
                <thead className="en-de-table-header">
                  <tr id="en-de-tableHeadRow">
                    <th id="zzzz" className="en-de-HeadText font-hn-medium">
                      Loan Officer
                      <img
                        src={require("../../assets/images/arrow_upward.svg")}
                        alt="arrow"
                      />
                      <img
                        src={require("../../assets/images/arrow_downward.svg")}
                        alt="arrow"
                      />
                    </th>
                    <th className="en-de-HeadText font-hn-medium">
                      Phone Number
                    </th>
                    <th className="en-de-HeadText font-hn-medium">
                      Encompass Status
                    </th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {this.props.encompassDetails.list &&
                  this.props.encompassDetails.list.length > 0 ? (
                    this.props.encompassDetails.list
                      .filter((item) => {
                        data = { ...item };
                        return (
                          !this.state.search ||
                          item.firstName
                            .toLowerCase()
                            .indexOf(this.state.search.toLowerCase()) > -1
                        );
                      })
                      .map((item, index) => {
                        let customActive =
                          this.state.activeIndex === index
                            ? "visible-bg-set"
                            : "";
                        let svgBack =
                          this.state.activeIndex === index
                            ? "svgBack"
                            : "ADMIN_INTEGRATION";
                        return (
                          <tr
                            key={index}
                            className="list-tr pb-2 pt-2 "
                            id="en-de-tableBodyRow"
                          >
                            <td className="font-12  outline-none  data-align pt-4">
                              <div className="en-de-tableText font-hn-medium">
                                {item.firstName} {item.lastName}
                              </div>
                            </td>
                            <td className="font-12  outline-none data-align pt-4">
                              <div className="en-de-tableText font-hn-medium">
                                {item.phoneNo}
                              </div>
                            </td>
                            <td className="font-12  text-capitalize data-align outline-none pt-4 d-flex justify-content-between ">
                              <div className="en-de-tableText font-hn-medium">
                                {item.encompassDetails.account_status}
                              </div>
                              <div
                                className="en-de-svgDiv cursor-pointer"
                                onClick={() => this.handleClick(item, index)}
                              >
                                <svg
                                  viewBox="0 0 16 10"
                                  id={customActive}
                                  className="p-1"
                                >
                                  <g
                                    id="ADMIN"
                                    stroke="none"
                                    strokeWidth="1"
                                    fillRule="evenodd"
                                  >
                                    <g
                                      id={svgBack}
                                      transform="translate(-1363.000000, -350.000000)"
                                      fillRule="nonzero"
                                    >
                                      <path
                                        d="M1371.14428,351.73747 C1369.26073,351.750659 1367.74443,353.22538 1367.75632,355.032522 C1367.76821,356.839664 1369.3038,358.295895 1371.18738,358.28631 C1373.07095,358.276629 1374.59028,356.804774 1374.58212,354.997613 C1374.57815,354.127505 1374.21341,353.294682 1373.5684,352.683009 C1372.9234,352.071337 1372.05117,351.73112 1371.14428,351.73747 Z M1371.14428,357.331742 C1369.81418,357.318588 1368.74494,356.27712 1368.75129,355.00093 C1368.7577,353.724739 1369.8373,352.69316 1371.16747,352.692247 C1372.49765,352.691336 1373.57878,353.721433 1373.58709,354.997613 C1373.58975,355.619106 1373.33292,356.21575 1372.87394,356.654318 C1372.41495,357.092885 1371.79204,357.336852 1371.14428,357.331742 Z M1378.87065,354.77327 C1377.19403,351.799523 1374.25373,350 1371,350 C1367.74627,350 1364.801,351.799523 1363.1393,354.77327 L1363,354.997613 L1363.12935,355.22673 C1364.80597,358.200477 1367.74627,360 1371,360 C1374.25373,360 1377.199,358.224344 1378.87065,355.22673 L1379,354.997613 L1378.87065,354.77327 Z M1371,359.02148 C1368.199,359.02148 1365.62687,357.522673 1364.13433,354.997613 C1365.62687,352.472554 1368.199,350.973747 1371,350.973747 C1373.801,350.973747 1376.34328,352.477327 1377.8607,354.997613 C1376.34328,357.522673 1373.79602,359.02148 1371,359.02148 Z"
                                        id="Shape-Copy-4"
                                      ></path>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                  ) : (
                    <tr
                      id="preventTRBorder"
                      className="d-flex justify-content-center align-items-center font-16 mt-5"
                    >
                      <td id="preventTdBorder">Nothing to Show</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {this.state.showDetails ? (
          <EncompassSingleView data={this.state.userData} subscription={data} />
        ) : (
          <EncompassEmpty encompassDetails={this.props.encompassDetails} />
        )}

        {}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    encompassDetails: state.encompassDetails,
  };
}

export default connect(mapStateToProps)(EncompassDetails);
