import { types } from "./actions";

const initialState = {
  isListLoading: false,
  NexusBankDataRequest: [],
  NexusBankDataApproved: [],
  NexusBankDataRejected: [],
  error: null,
  nexusList: null,
  isLoading: false,
  nexusBankJsonList: [],
  nexusBankJsonRequest: false,
  nexusBankJsonSuccess: false,
  nexusBankJsonError: null,
  totalcountRequest: null,
  totalcountApproved: null,
  totalcountRejected: null,
  isLoadingreject: false,
  isLoadingapprove: false,
};

const strategies = {
  [types.ADD_NEXUS_BANK_REQUEST]: addNexusBankRequest,
  [types.ADD_NEXUS_BANK_SUCCESS]: addNexusBankSuccess,
  [types.ADD_NEXUS_BANK_ERROR]: addNexusBankError,
  [types.LOAD_NEXUS_BANK_INTEGRATION_LIST_REQUEST]:
    loadNexusIntegrationListRequest,
  [types.LOAD_NEXUS_BANK_INTEGRATION_LIST_SUCCESS]:
    loadNexusIntegrationListSuccess,
  [types.LOAD_NEXUS_BANK_INTEGRATION_LIST_ERROR]: loadNexusIntegrationListError,
  [types.DELETE_NEXUS_BANK_INTEGRATION_REQUEST]: deleteNexusIntegrationRequest,
  [types.DELETE_NEXUS_BANK_INTEGRATION_SUCCESS]: deleteNexusIntegrationSuccess,
  [types.DELETE_BANK_INTEGRATION_ERROR]: deleteNexusIntegrationError,

  [types.GET_NEXUS_BANK_JSON_REQUEST]: getNexusBankJSONRequest,
  [types.GET_NEXUS_BANK_JSON_SUCCESS]: getNexusBankJsonSuccess,
  [types.GET_NEXUS_BANK_JSON_ERROR]: getNexusBankJsonError,

  [types.GET_NEXUS_BANK_USERS_REQUEST]: getNexusBankDataRequest,
  [types.GET_NEXUS_BANK_REQUEST_SUCCESS]: getNexusBankDataSuccessRequest,
  [types.GET_NEXUS_BANK_REQUEST_ERROR]: getNexusBankDataErrorRequest,
  [types.GET_NEXUS_BANK_APPROVED]: getNexusBankDataApproved,
  [types.GET_NEXUS_BANK_APPROVED_SUCCESS]: getNexusBankDataSuccessApproved,
  [types.GET_NEXUS_BANK_APPROVED_ERROR]: getNexusBankDataErrorApproved,

  [types.GET_NEXUS_BANK_REJECTED]: getNexusBankDataRejected,
  [types.GET_NEXUS_BANK_REJECTED_SUCCESS]: getNexusBankDataSuccessRejected,
  [types.GET_NEXUS_BANK_REJECTED_ERROR]: getNexusBankDataErrorRejected,
  [types.NEXUS_BANK_REJECTED]: reject,
  [types.NEXUS_BANK_REJECTED_SUCCESS]: rejectSuccess,
  [types.NEXUS_BANK_REJECTED_ERROR]: rejectError,
  [types.APPROVE_NEXUS_REQUEST]: approve,
  [types.APPROVE_NEXUS_REQUEST_SUCCESS]: approveSuccess,
  [types.APPROVE_NEXUS_REQUEST_ERROR]: approveError,

  __default__: (state) => ({ ...state, error: null }),
};

export default function reducer(state = initialState, action) {
  const transformer = strategies[action.type]
    ? strategies[action.type]
    : strategies.__default__;
  return transformer(state, action);
}

function addNexusBankRequest(state) {
  return { ...state, isLoading: true, error: null };
}

function addNexusBankSuccess(state) {
  return { ...state, isLoading: false, error: null };
}

function addNexusBankError(state, action) {
  return { ...state, isLoading: false, error: action.payload };
}
function getNexusBankJSONRequest(state, action) {
  return {
    ...state,
    nexusBankJsonRequest: true,
    nexusBankJsonSuccess: false,
    nexusBankJsonError: null,
  };
}
function getNexusBankJsonSuccess(state, action) {
  return {
    ...state,
    nexusbankJsonList: action.payload.data.data,
    nexusBankJsonRequest: false,
    nexusBankJsonSuccess: true,
    nexusBankJsonError: null,
  };
}
function getNexusBankJsonError(state, action) {
  return {
    ...state,
    nexusBankJsonRequest: false,
    nexusBankJsonSuccess: false,
    nexusBankJsonError: action,
  };
}
function loadNexusIntegrationListRequest(state) {
  return { ...state, isLoading: true, error: null };
}

function loadNexusIntegrationListSuccess(state, action) {
  return { ...state, isLoading: false, error: null, nexusList: action.payload };
}

function loadNexusIntegrationListError(state, action) {
  return { ...state, isLoading: false, error: action.payload };
}

function deleteNexusIntegrationRequest(state) {
  return { ...state, isLoading: true, error: null };
}

function deleteNexusIntegrationSuccess(state) {
  return { ...state, isLoading: false, error: null };
}

function deleteNexusIntegrationError(state, action) {
  return { ...state, isLoading: false, error: action.payload };
}

function getNexusBankDataRequest(state, action) {
  return {
    ...state,
    isListLoading: true,
  };
}

function getNexusBankDataSuccessRequest(state, action) {
  const array = action.payload.data.data;
  return {
    ...state,
    isListLoading: false,
    NexusBankDataRequest: array.filter((obj) => obj.status === "Awaiting"),
    totalcountRequest: array.length,
  };
}

function getNexusBankDataErrorRequest(state, action) {
  return {
    ...state,
    isListLoading: false,
    error: action,
  };
}

function getNexusBankDataApproved(state, action) {
  return {
    ...state,
    isListLoading: true,
  };
}

function getNexusBankDataSuccessApproved(state, action) {
  const array = action.payload.data.data;
  return {
    ...state,
    isListLoading: false,
    NexusBankDataApproved: array.filter((obj) => obj.status === "Approved"),
    totalcountApproved: array.length,
  };
}

function getNexusBankDataErrorApproved(state, action) {
  return {
    ...state,
    isListLoading: false,
    error: action,
  };
}

function getNexusBankDataRejected(state, action) {
  return {
    ...state,
    isListLoading: true,
  };
}

function getNexusBankDataSuccessRejected(state, action) {
  const array = action.payload.data.data;
  return {
    ...state,
    isListLoading: false,
    NexusBankDataRejected: array.filter((obj) => obj.status === "Rejected"),
    totalcountRejected: array.length,
  };
}

function getNexusBankDataErrorRejected(state, action) {
  return {
    ...state,
    isListLoading: false,
    error: action,
  };
}

function reject(state, action) {
  return {
    ...state,
    isLoadingreject: true,
  };
}

function rejectSuccess(state, action) {
  return {
    ...state,
    isLoadingreject: false,
  };
}

function rejectError(state, action) {
  return {
    ...state,
    isLoadingreject: false,
  };
}

function approve(state, action) {
  return {
    ...state,
    isLoadingapprove: true,
  };
}

function approveSuccess(state, action) {
  return {
    ...state,
    isLoadingapprove: false,
  };
}

function approveError(state, action) {
  return {
    ...state,
    isLoadingapprove: false,
  };
}
