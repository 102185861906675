import React, { Suspense } from "react";
const PackagesView = React.lazy(() => import("./packagesView/PackagesView"));
export default class PackageContainer extends React.Component {
  render() {
    return (
      <Suspense
        fallback={
          <h4
            style={{
              color: "black",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Loading...
          </h4>
        }
      >
        <PackagesView />
      </Suspense>
    );
  }
}
