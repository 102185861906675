import React from "react";
import "./LicenseStatus.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as licenceStatusActions from "../../store/licenseStatus/actions";
import * as dashboardActions from "../../store/dashboard/actions";
import ApprovedFreeAccount from "./ApprovedFreeAccount/ApprovedFreeAccount";
import RejectedFreeAccount from "./RejectedFreeAccount/RejectedFreeAccount";
import ApprovedFreePeriod from "./ApprovedFreePeriod/ApprovedFreePeriod";
import RejectFreePeriod from "./RejectFreePeriod/RejectFreePeriod";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
class LicenseStatus extends React.Component {
  constructor(props) {
    super(props);
    this.approveHandler = this.approveHandler.bind(this);
    this.rejectHandeler = this.rejectHandeler.bind(this);
    this.onChange = this.onChange.bind(this);
    this.planSelect = this.planSelect.bind(this);
    this.stop = this.stop.bind(this);
  }
  componentDidMount() {}
  state = {
    currentPage: "approve",
    currentStatus: "freePeriod",
    bgColor1: "#EFF5FC",
    bgColor2: "#ffff",
    isChecked: false,
    activeLableperiod: "#4880ff",
    activeLableaccount: "#d0cccd",
  };
  stop(uid, planType) {
    if (planType === "firstFew") {
      this.props.stopLicenceRequest({ uid, planType, planValue: 0 });
    }
    if (planType === "freePeriod") {
      this.props.stopLicenceRequest({ uid, planType, planValue: "now" });
    }
  }

  approveHandler() {
    this.setState({ currentPage: "approve" });
    this.setState({ bgColor1: "#EFF5FC" });
    this.setState({ bgColor2: "#ffff" });
  }
  rejectHandeler() {
    this.setState({ currentPage: "reject" });
    this.setState({ bgColor1: "#ffff" });
    this.setState({ bgColor2: "#EFF5FC" });
  }
  onChange(event) {
    if (this.state.isChecked) {
      this.setState({ currentStatus: "freePeriod" });
      this.setState({ activeLableperiod: "#4880ff" });
      this.setState({ activeLableaccount: "#d0cccd" });
    } else {
      this.setState({ currentStatus: "freeAccount" });
      this.setState({ activeLableperiod: "#d0cccd" });
      this.setState({ activeLableaccount: "#4880ff" });
    }
    this.setState({ isChecked: !this.state.isChecked });
  }

  planSelect(planType, planValue, planPeriod, id, uid) {
    if (planType === "firstFew") {
      this.props.SetFreePeriodRequest({
        planType,
        planValue,
        id,
        uid,
      });
    } else {
      this.props.SetFreePeriodRequest({
        planType,
        planValue,
        planPeriod,
        id,
        uid,
      });
    }
  }

  notify(text) {
    toast(text, {
      position: "bottom-right",
    });
  }
  render() {
    return (
      <section
        id="licenseStatusId"
        className="project-tab bg-white partner-layout w-set task-list-layout d-flex flex-column pt-5 px-3"
      >
        <div className="d-flex justify-content-between flex-column flex-md-row li-st-div">
          <div className="d-flex align-items-baseline font-hn-medium">
            <button
              className="btn font-lato-bold"
              onClick={this.approveHandler}
              style={{ backgroundColor: this.state.bgColor1 }}
            >
              APPROVED
            </button>
            <button
              className="btn font-lato-bold "
              onClick={this.rejectHandeler}
              style={{ backgroundColor: this.state.bgColor2 }}
            >
              REJECTED
            </button>
          </div>

          <div className="custom-control custom-switch ">
            <label
              style={{ color: this.state.activeLableperiod }}
              className="labelfreePlan alignment cursor-pointer"
              htmlFor="customSwitch1"
            >
              FREE PERIOD
            </label>
            <input
              defaultChecked={this.state.isChecked}
              type="checkbox"
              className="custom-control-input cursor-pointer"
              id="customSwitch1"
              onChange={this.onChange}
            />
            <label
              style={{ color: this.state.activeLableaccount }}
              className="custom-control-label labelfreePlan cursor-pointer"
              htmlFor="customSwitch1"
            >
              FREE ACCOUNT
            </label>
          </div>
        </div>
        {this.state.currentPage === "approve" &&
          this.state.currentStatus === "freePeriod" && (
            <ApprovedFreePeriod
              stop={this.stop}
              notify={this.notify}
              isLoading={this.props.dashboard.isLoading}
              planSelect={this.planSelect}
              error={this.props.licenceStatus.error}
              data={
                this.props.dashboard.list
                  ? this.props.dashboard.list.filter(
                      (item) =>
                        item.status === "accepted" &&
                        item.planType === "freePeriod"
                    )
                  : []
              }
            />
          )}{" "}
        {this.state.currentPage === "reject" &&
          this.state.currentStatus === "freePeriod" && (
            <RejectFreePeriod
              data={
                this.props.dashboard.list
                  ? this.props.dashboard.list.filter(
                      (item) =>
                        item.status === "rejected" &&
                        item.planSelect === "freePeriod"
                    )
                  : []
              }
            />
          )}{" "}
        {this.state.currentPage === "reject" &&
          this.state.currentStatus === "freeAccount" && (
            <RejectedFreeAccount
              data={
                this.props.dashboard.list
                  ? this.props.dashboard.list.filter(
                      (item) =>
                        item.status === "rejected" &&
                        item.planSelect === "freeAccount"
                    )
                  : []
              }
            />
          )}{" "}
        {this.state.currentPage === "approve" &&
          this.state.currentStatus === "freeAccount" && (
            <ApprovedFreeAccount
              stop={this.stop}
              notify={this.notify}
              isLoading={this.props.dashboard.isLoading}
              planSelect={this.planSelect}
              error={this.props.dashboard.error}
              data={
                this.props.dashboard.list
                  ? this.props.dashboard.list.filter(
                      (item) =>
                        item.status === "accepted" &&
                        item.planType === "firstFew"
                    )
                  : []
              }
            />
          )}
        }
        <ToastContainer />
      </section>
    );
  }
}
function mapStateToProps(state) {
  return {
    dashboard: state.dashboard,
    licenceStatus: state.licenceStatus,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...dashboardActions, ...licenceStatusActions },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(LicenseStatus);
