import { types } from "./actions";

const initialState = {
  isLoadingList: true,
  error: null,
  list: null,
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_LICENCE_REQUESTS:
      return { ...state, isLoadingList: true, error: null };
    case types.GET_LICENCE_REQUESTS_SUCCESS:
      return { ...state, isLoadingList: false, list: action.payload };
    case types.GET_LICENCE_REQUESTS_ERROR:
      return {
        ...state,
        isLoadingList: false,
        list: null,
        error: action.payload,
      };
    case types.ADD_LICENCE_REQUEST: {
      return { ...state, isLoading: true, error: null };
    }
    case types.ADD_LICENCE_REQUEST_SUCCESS:
      return { ...state, isLoading: false };
    case types.ADD_LICENCE_REQUEST_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case types.DELETE_LICENCE_REQUEST: {
      return { ...state, error: null, isLoading: true };
    }
    case types.SET_FREE_PERIOD_REQUEST: {
      return { ...state, isLoading: true, error: null };
    }
    case types.SET_FREE_PERIOD_SUCCESS: {
      return { ...state, error: null, isLoading: false };
    }
    case types.SET_FREE_PERIOD_ERROR: {
      return { ...state, error: action.payload, isLoading: false };
    }
    case types.LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
}
