export const types = {
  ADD_NEXUS_BANK_REQUEST: "[NEXUS BANK INTEGRATION] Add bank request",
  ADD_NEXUS_BANK_SUCCESS: "[NEXUS BANK INTEGRATION] Add bank success",
  ADD_NEXUS_BANK_ERROR: "[NEXUS BANK INTEGRATION] Add bank error",
  LOAD_NEXUS_BANK_INTEGRATION_LIST_REQUEST:
    "[NEXUS BANK INTEGRATION] Load all bank integration list request",
  LOAD_NEXUS_BANK_INTEGRATION_LIST_SUCCESS:
    "[NEXUS BANK INTEGRATION] Load all bank integration list success",
  LOAD_NEXUS_BANK_INTEGRATION_LIST_ERROR:
    "[NEXUS BANK INTEGRATION] Load all bank integration list error",
  DELETE_NEXUS_BANK_INTEGRATION_REQUEST:
    "[NEXUS BANK INTEGRATION] Delete bank integration request",
  DELETE_NEXUS_BANK_INTEGRATION_SUCCESS:
    "[NEXUS BANK INTEGRATION] Delete bank integration success",
  DELETE_BANK_INTEGRATION_ERROR:
    "[NEXUS BANK INTEGRATION] Delete bank integration error",
  GET_NEXUS_BANK_JSON_REQUEST: "[NEXUS BANK INTEGRATION] Get bank Json request",
  GET_NEXUS_BANK_JSON_SUCCESS: "[NEXUS BANK INTEGRATION] Get bank Json success",
  GET_NEXUS_BANK_JSON_ERROR: "[NEXUS BANK INTEGRATION] Get bank Json error",

  GET_NEXUS_BANK_USERS_REQUEST: "[NEXUS PAGE] GET BANK DATA REQUEST ",
  GET_NEXUS_BANK_REQUEST_SUCCESS: "[NEXUS PAGE] GET BANK DATA REQUEST SUCCESS ",
  GET_NEXUS_BANK_REQUEST_ERROR: "[NEXUS PAGE] GET BANK DATA REQUEST ERROR",
  GET_NEXUS_BANK_APPROVED: "[NEXUS PAGE] GET BANK DATA APPROVED ",
  GET_NEXUS_BANK_APPROVED_SUCCESS:
    "[NEXUS PAGE] GET BANK DATA APPROVED SUCCESS ",
  GET_NEXUS_BANK_APPROVED_ERROR: "[NEXUS PAGE] GET BANK DATA APPROVED ERROR",

  GET_NEXUS_BANK_REJECTED: "[NEXUS PAGE] GET BANK DATA REJECTED ",
  GET_NEXUS_BANK_REJECTED_SUCCESS:
    "[NEXUS PAGE] GET BANK DATA REJECTED SUCCESS ",
  GET_NEXUS_BANK_REJECTED_ERROR: "[NEXUS PAGE] GET BANK DATA REJECTED ERROR",
  NEXUS_BANK_REJECTED: "[NEXUS PAGE] REJECT REQUEST",
  NEXUS_BANK_REJECTED_SUCCESS: "[NEXUS PAGE] REJECT REQUEST SUCCESS",
  NEXUS_BANK_REJECTED_ERROR: "[NEXUS PAGE] REJECT REQUEST ERROR",
  APPROVE_NEXUS_REQUEST: "[NEXUS PAGE] APPROVE REQUEST",
  APPROVE_NEXUS_REQUEST_SUCCESS: "[NEXUS PAGE] APPROVE REQUEST SUCCESS",
  APPROVE_NEXUS_REQUEST_ERROR: "[NEXUS PAGE] APPROVE REQUEST ERROR",
};

export const addNexusBankRequest = (data) => {
  return {
    type: types.ADD_NEXUS_BANK_REQUEST,
    payload: data,
  };
};

export const addNexusBankSuccess = () => {
  return { type: types.ADD_NEXUS_BANK_SUCCESS };
};

export const addNexusBankError = (error) => {
  return {
    type: types.ADD_NEXUS_BANK_ERROR,
    payload: error,
  };
};
export const getNexusBankJsonRequest = (bankId) => {
  return {
    type: types.GET_NEXUS_BANK_JSON_REQUEST,
    payload: bankId,
  };
};
export const getNexusBankJsonSuccess = (data) => {
  return {
    type: types.GET_NEXUS_BANK_JSON_SUCCESS,
    payload: data,
  };
};
export const getNexusBankJsonError = (error) => {
  return {
    type: types.GET_NEXUS_BANK_JSON_ERROR,
    payload: error,
  };
};

export const loadIntegrationNexusBankListRequest = () => {
  return {
    type: types.LOAD_NEXUS_BANK_INTEGRATION_LIST_REQUEST,
  };
};

export const loadIntegrationNexusBankListSuccess = (list) => {
  return {
    type: types.LOAD_NEXUS_BANK_INTEGRATION_LIST_SUCCESS,
    payload: list,
  };
};

export const loadIntegrationNexusBankListError = (error) => {
  return {
    type: types.LOAD_NEXUS_BANK_INTEGRATION_LIST_ERROR,
    payload: error,
  };
};

export const deleteNexusBankIntegrationRequest = (id) => {
  return {
    type: types.DELETE_NEXUS_BANK_INTEGRATION_REQUEST,
    payload: id,
  };
};

export const deleteNexusBankIntegrationSuccess = () => {
  return {
    type: types.DELETE_NEXUS_BANK_INTEGRATION_SUCCESS,
  };
};

export const deleteNexusBankIntegrationError = (error) => {
  return {
    type: types.DELETE_BANK_INTEGRATION_ERROR,
    payload: error,
  };
};

export const getNexusBankDataRequest = (data) => {
  return {
    type: types.GET_NEXUS_BANK_USERS_REQUEST,
    data: data,
  };
};

export const getNexusBankDataApproved = (data) => {
  return {
    type: types.GET_NEXUS_BANK_APPROVED,
    data: data,
  };
};

export const getNexusBankDataRejected = (data) => {
  return {
    type: types.GET_NEXUS_BANK_REJECTED,
    data: data,
  };
};

export const onreject = (id, uid, data) => {
  const payload = {
    uid: data.uid,
    bankId: data.bankId,
    loanOfficerGuid: data.loanOfficerGuid,
    bankName: data.bankName,
    approvalFlag: false,
  };
  return {
    type: types.NEXUS_BANK_REJECTED,
    payload: payload,
    uid: uid,
  };
};

export const onApprove = (data) => {
  const payload = {
    uid: data.uid,
    bankId: data.bankId,
    loanOfficerGuid: data.loanOfficerGuid,
    bankName: data.bankName,
    approvalFlag: true,
  };
  return {
    type: types.APPROVE_NEXUS_REQUEST,
    payload: payload,
  };
};
