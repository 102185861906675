import React from "react";
import Modal from "react-responsive-modal";
import "./loandetailspopup.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import JsonPopUp from "./popup/jsonPopUp";
import ConfirmationDefault from "./confirmation/confirmation";
import * as bankIntegrationActions from "../../../store/bankIntegration/actions";
const defaultData = {
  borrower_name: "applications[0].borrower.fullName",
  rate: "requestedInterestRatePercent",
  term: "loanAmortizationTermMonths",
  loan_purpose: "property.loanPurposeType",
  loan_to_value: "ltv",
  appraised_value: "propertyAppraisedValueAmount",
  loan_number: "loanNumber",
  lock_date: "loanSubmission.lockDate",
  lock_expiration_date: "loanSubmission.lockExpiresDate",
  closing_date: "loanProductData.loanScheduledClosingDate",
  funded_date: "milestoneFundedDate",
  loan_amount: "borrowerRequestedLoanAmount",
  loan_type: "mortgageType",
  down_payment: "downPayment.amount",
  loan_status: "milestoneCurrentName",
  loan_program: "loanProgramName",
  loan_source: "loanSource",
  created_date: "loanCreatedDateUtc",
  created_new_date: "loanCreatedDateUtc",
  credit_score: "applications[0].borrower.hmdaCreditScoreForDecisionMaking",
  credit_score_co_borr:
    "applications[0].coborrower.hmdaCreditScoreForDecisionMaking",
  milestone: "milestoneCurrentName",
  milestone_orginal: "virtualFields.CoreMilestone",
};
class FieldMaping extends React.Component {
  constructor(props) {
    super(props);
    this.props.getBankJsonRequest(props.bankId);
  }
  state = {
    loanIdJsonOpen: false,
    confirmationOpen: false,
    setDefault: [],
    currentSelected: null,
    currentSelectedPath: null,
    currentSetItem: null,
    borrower_name: "",
    rate: "",
    term: "",
    loan_purpose: "",
    loan_to_value: "",
    appraised_value: "",
    loan_number: "",
    lock_date: "",
    lock_expiration_date: "",
    closing_date: "",
    funded_date: "",
    loan_amount: "",
    loan_type: "",
    down_payment: "",
    loan_status: "",
    loan_program: "",
    loan_source: "",
    created_date: "",
    created_new_date: "",
    credit_score: "",
    credit_score_co_borr: "",
    milestone: "",
    milestone_orginal: "",
    borrower_nameSet: false,
    rateSet: false,
    termSet: false,
    loan_purposeSet: false,
    loan_to_valueSet: false,
    appraised_valueSet: false,
    loan_numberSet: false,
    lock_dateSet: false,
    lock_expiration_dateSet: false,
    closing_dateSet: false,
    funded_dateSet: false,
    loan_amountSet: false,
    loan_typeSet: false,
    down_paymentSet: false,
    loan_statusSet: false,
    loan_programSet: false,
    loan_sourceSet: false,
    created_dateSet: false,
    created_new_dateSet: false,
    credit_scoreSet: false,
    credit_score_co_borrSet: false,
    milestoneSet: false,
    milestone_orginalSet: false,

    borrower_namePath: this.props.bankDetails.loanFieldMaping
      ? this.props.bankDetails.loanFieldMaping.borrower_name
      : "",
    ratePath: this.props.bankDetails.loanFieldMaping
      ? this.props.bankDetails.loanFieldMaping.rate
      : "",
    termPath: this.props.bankDetails.loanFieldMaping
      ? this.props.bankDetails.loanFieldMaping.term
      : "",
    loan_purposePath: this.props.bankDetails.loanFieldMaping
      ? this.props.bankDetails.loanFieldMaping.loan_purpose
      : "",
    loan_to_valuePath: this.props.bankDetails.loanFieldMaping
      ? this.props.bankDetails.loanFieldMaping.loan_to_value
      : "",
    appraised_valuePath: this.props.bankDetails.loanFieldMaping
      ? this.props.bankDetails.loanFieldMaping.appraised_value
      : "",
    loan_numberPath: this.props.bankDetails.loanFieldMaping
      ? this.props.bankDetails.loanFieldMaping.loan_number
      : "",
    lock_datePath: this.props.bankDetails.loanFieldMaping
      ? this.props.bankDetails.loanFieldMaping.lock_date
      : "",
    lock_expiration_datePath: this.props.bankDetails.loanFieldMaping
      ? this.props.bankDetails.loanFieldMaping.lock_expiration_date
      : "",
    closing_datePath: this.props.bankDetails.loanFieldMaping
      ? this.props.bankDetails.loanFieldMaping.closing_date
      : "",
    funded_datePath: this.props.bankDetails.loanFieldMaping
      ? this.props.bankDetails.loanFieldMaping.funded_date
      : "",
    loan_amountPath: this.props.bankDetails.loanFieldMaping
      ? this.props.bankDetails.loanFieldMaping.loan_amount
      : "",
    loan_typePath: this.props.bankDetails.loanFieldMaping
      ? this.props.bankDetails.loanFieldMaping.loan_type
      : "",
    down_paymentPath: this.props.bankDetails.loanFieldMaping
      ? this.props.bankDetails.loanFieldMaping.down_payment
      : "",
    loan_statusPath: this.props.bankDetails.loanFieldMaping
      ? this.props.bankDetails.loanFieldMaping.loan_status
      : "",
    loan_programPath: this.props.bankDetails.loanFieldMaping
      ? this.props.bankDetails.loanFieldMaping.loan_program
      : "",
    loan_sourcePath: this.props.bankDetails.loanFieldMaping
      ? this.props.bankDetails.loanFieldMaping.loan_source
      : "",
    created_datePath: this.props.bankDetails.loanFieldMaping
      ? this.props.bankDetails.loanFieldMaping.created_date
      : "",
    created_new_datePath: this.props.bankDetails.loanFieldMaping
      ? this.props.bankDetails.loanFieldMaping.created_new_date
      : "",
    credit_scorePath: this.props.bankDetails.loanFieldMaping
      ? this.props.bankDetails.loanFieldMaping.credit_score
      : "",
    credit_score_co_borrPath: this.props.bankDetails.loanFieldMaping
      ? this.props.bankDetails.loanFieldMaping.credit_score_co_borr
      : "",
    milestonePath: this.props.bankDetails.loanFieldMaping
      ? this.props.bankDetails.loanFieldMaping.milestone
      : "",
    milestone_orginalPath: this.props.bankDetails.loanFieldMaping
      ? this.props.bankDetails.loanFieldMaping.milestone_orginal
      : "",
  };
  componentDidMount() {
    let details = [];
    details = this.props.bankDetails.loanFieldMaping
      ? this.props.bankDetails.loanFieldMaping
      : null;
    if (details) {
      if (details.borrower_name) {
        let data = this.setObjectName(details.borrower_name);
        this.setState({ borrower_name: data, borrower_nameSet: true });
      }
      if (details.rate) {
        let data = this.setObjectName(details.rate);
        this.setState({ rate: data, rateSet: true });
      }
      if (details.term) {
        let data = this.setObjectName(details.term);
        this.setState({ term: data, termSet: true });
      }
      if (details.loan_purpose) {
        let data = this.setObjectName(details.loan_purpose);
        this.setState({ loan_purpose: data, loan_purposeSet: true });
      }
      if (details.loan_to_value) {
        let data = this.setObjectName(details.loan_to_value);
        this.setState({ loan_to_value: data, loan_to_valueSet: true });
      }
      if (details.appraised_value) {
        let data = this.setObjectName(details.appraised_value);
        this.setState({ appraised_value: data, appraised_valueSet: true });
      }
      if (details.loan_number) {
        let data = this.setObjectName(details.loan_number);
        this.setState({ loan_number: data, loan_numberSet: true });
      }
      if (details.lock_date) {
        let data = this.setObjectName(details.lock_date);
        this.setState({ lock_date: data, lock_dateSet: true });
      }
      if (details.lock_expiration_date) {
        let data = this.setObjectName(details.lock_expiration_date);
        this.setState({
          lock_expiration_date: data,
          lock_expiration_dateSet: true,
        });
      }
      if (details.closing_date) {
        let data = this.setObjectName(details.closing_date);
        this.setState({ closing_date: data, closing_dateSet: true });
      }
      if (details.funded_date) {
        let data = this.setObjectName(details.funded_date);
        this.setState({ funded_date: data, funded_dateSet: true });
      }
      if (details.loan_amount) {
        let data = this.setObjectName(details.loan_amount);
        this.setState({ loan_amount: data, loan_amountSet: true });
      }
      if (details.loan_type) {
        let data = this.setObjectName(details.loan_type);
        this.setState({ loan_type: data, loan_typeSet: true });
      }
      if (details.down_payment) {
        let data = this.setObjectName(details.down_payment);
        this.setState({ down_payment: data, down_paymentSet: true });
      }
      if (details.loan_status) {
        let data = this.setObjectName(details.loan_status);
        this.setState({ loan_status: data, loan_statusSet: true });
      }
      if (details.loan_program) {
        let data = this.setObjectName(details.loan_program);
        this.setState({ loan_program: data, loan_programSet: true });
      }
      if (details.loan_source) {
        let data = this.setObjectName(details.loan_source);
        this.setState({ loan_source: data, loan_sourceSet: true });
      }
      if (details.created_new_date) {
        let data = this.setObjectName(details.created_new_date);
        this.setState({ created_new_date: data, created_new_dateSet: true });
      }
      if (details.created_date) {
        let data = this.setObjectName(details.created_date);
        this.setState({ created_date: data, created_dateSet: true });
      }
      if (details.credit_score) {
        let data = this.setObjectName(details.credit_score);
        this.setState({ credit_score: data, credit_scoreSet: true });
      }
      if (details.credit_score_co_borr) {
        let data = this.setObjectName(details.credit_score_co_borr);
        this.setState({
          credit_score_co_borr: data,
          credit_score_co_borrSet: true,
        });
      }
      if (details.milestone) {
        let data = this.setObjectName(details.milestone);
        this.setState({ milestone: data, milestoneSet: true });
      }
      if (details.milestone_orginal) {
        let data = this.setObjectName(details.milestone_orginal);
        this.setState({ milestone_orginal: data, milestone_orginalSet: true });
      }
    }
  }
  setObjectName = (arg) => {
    let reverse = arg.split("").reverse().join("");
    let removeFromDot = reverse.substring(0, reverse.indexOf("."));
    if (removeFromDot === "") {
      return arg;
    }
    let result = removeFromDot.split("").reverse().join("");
    return result;
  };
  _btnClick = (event, path, setItem) => {
    this.setState({
      loanIdJsonOpen: true,
      currentSelected: event,
      currentSelectedPath: path,
      currentSetItem: setItem,
    });
  };
  closeDetails = () => {
    this.setState({ loanIdJsonOpen: !this.state.loanIdJsonOpen });
  };
  closeConfirm = () => {
    this.setState({ confirmationOpen: null });
  };
  selectedField = (value, item, path) => {
    this.setState({ [item]: value });
    this.setState({ [this.state.currentSelectedPath]: path });
    this.setState({ [this.state.currentSetItem]: true });
  };
  onCancel = () => {};
  onSubmit = () => {
    this.setState({ setDefault: [] });
    const {
      borrower_namePath,
      ratePath,
      termPath,
      loan_purposePath,
      loan_to_valuePath,
      appraised_valuePath,
      loan_numberPath,
      lock_datePath,
      lock_expiration_datePath,
      closing_datePath,
      funded_datePath,
      loan_amountPath,
      loan_typePath,
      down_paymentPath,
      loan_statusPath,
      loan_programPath,
      loan_sourcePath,
      created_datePath,
      created_new_datePath,
      credit_scorePath,
      credit_score_co_borrPath,
      milestonePath,
      milestone_orginalPath,
    } = this.state;
    const tempArray = [
      { borrower_name: borrower_namePath },
      { rate: ratePath },
      { term: termPath },
      { loan_purpose: loan_purposePath },
      { loan_to_value: loan_to_valuePath },
      { appraised_value: appraised_valuePath },
      { loan_number: loan_numberPath },
      { lock_date: lock_datePath },
      { lock_expiration_date: lock_expiration_datePath },
      { closing_date: closing_datePath },
      { funded_date: funded_datePath },
      { loan_amount: loan_amountPath },
      { loan_type: loan_typePath },
      { down_payment: down_paymentPath },
      { loan_status: loan_statusPath },
      { loan_program: loan_programPath },
      { loan_source: loan_sourcePath },
      { created_date: created_datePath },
      { created_new_date: created_new_datePath },
      { credit_score: credit_scorePath },
      { credit_score_co_borr: credit_score_co_borrPath },
      { milestone: milestonePath },
      { milestone_orginal: milestone_orginalPath },
    ];
    let loanFieldMaping = {};
    let validationFlag = false;
    let setToDefault = [];
    tempArray.forEach((item) => {
      if (Object.values(item).toString() !== "") {
        loanFieldMaping[Object.keys(item).toString()] =
          Object.values(item).toString();
        validationFlag = true;
      } else {
        setToDefault.push(Object.keys(item).toString());
        this.setState((prevState) => ({
          setDefault: [...prevState.setDefault, Object.keys(item).toString()],
        }));
      }
    });
    if (validationFlag && !setToDefault.length) {
      this.props.setloanFieldMaping(this.props.bankId, loanFieldMaping);
      this.props.onClose();
    } else {
      this.setState({ confirmationOpen: true });
    }
  };
  confirmed = () => {
    this.closeConfirm();
    this.state.setDefault.map((item) => {
      // eslint-disable-next-line no-lone-blocks
      {
        item === "borrower_name" &&
          this.setState({ borrower_namePath: defaultData.borrower_name });
      }
      {
        item === "rate" && this.setState({ ratePath: defaultData.rate });
      }
      {
        item === "term" && this.setState({ termPath: defaultData.term });
      }
      {
        item === "loan_purpose" &&
          this.setState({ loan_purposePath: defaultData.loan_purpose });
      }
      {
        item === "appraised_value" &&
          this.setState({ appraised_valuePath: defaultData.appraised_value });
      }
      {
        item === "loan_number" &&
          this.setState({ loan_numberPath: defaultData.loan_number });
      }
      {
        item === "lock_date" &&
          this.setState({ lock_datePath: defaultData.lock_date });
      }
      {
        item === "lock_expiration_date" &&
          this.setState({
            lock_expiration_datePath: defaultData.lock_expiration_date,
          });
      }
      {
        item === "closing_date" &&
          this.setState({ closing_datePath: defaultData.closing_date });
      }
      {
        item === "funded_date" &&
          this.setState({ funded_datePath: defaultData.funded_date });
      }
      {
        item === "loan_amount" &&
          this.setState({ loan_amountPath: defaultData.loan_amount });
      }
      {
        item === "loan_type" &&
          this.setState({ loan_typePath: defaultData.loan_type });
      }
      {
        item === "down_payment" &&
          this.setState({ down_paymentPath: defaultData.down_payment });
      }
      {
        item === "loan_status" &&
          this.setState({ loan_statusPath: defaultData.loan_status });
      }
      {
        item === "loan_program" &&
          this.setState({ loan_programPath: defaultData.loan_program });
      }
      {
        item === "loan_source" &&
          this.setState({ loan_sourcePath: defaultData.loan_source });
      }
      {
        item === "created_date" &&
          this.setState({ created_datePath: defaultData.created_date });
      }
      {
        item === "created_new_date" &&
          this.setState({ created_new_datePath: defaultData.created_new_date });
      }
      {
        item === "credit_score" &&
          this.setState({ credit_scorePath: defaultData.credit_score });
      }
      {
        item === "credit_score_co_borr" &&
          this.setState({
            credit_score_co_borrPath: defaultData.credit_score_co_borr,
          });
      }
      {
        item === "milestone" &&
          this.setState({ milestonePath: defaultData.milestone });
      }
      {
        item === "milestone_orginal" &&
          this.setState({
            milestone_orginalPath: defaultData.milestone_orginal,
          });
      }
      {
        item === "loan_to_value" &&
          this.setState({ loan_to_valuePath: defaultData.loan_to_value });
      }
    });
    setTimeout(() => {
      this.onSubmit();
    }, 500);
  };
  render() {
    const {
      confirmationOpen,
      setDefault,
      loanIdJsonOpen,
      currentSelected,
      borrower_name,
      rate,
      term,
      loan_purpose,
      loan_to_value,
      appraised_value,
      loan_number,
      lock_date,
      lock_expiration_date,
      closing_date,
      funded_date,
      loan_amount,
      loan_type,
      down_payment,
      loan_status,
      loan_program,
      loan_source,
      created_date,
      created_new_date,
      credit_score,
      credit_score_co_borr,
      milestone,
      milestone_orginal,
      borrower_nameSet,
      rateSet,
      termSet,
      loan_purposeSet,
      loan_to_valueSet,
      appraised_valueSet,
      loan_numberSet,
      lock_dateSet,
      lock_expiration_dateSet,
      closing_dateSet,
      funded_dateSet,
      loan_amountSet,
      loan_typeSet,
      down_paymentSet,
      loan_statusSet,
      loan_programSet,
      loan_sourceSet,
      created_dateSet,
      created_new_dateSet,
      credit_scoreSet,
      credit_score_co_borrSet,
      milestoneSet,
      milestone_orginalSet,
    } = this.state;
    const { bankId } = this.props;
    return (
      <Modal open={this.props.shown} onClose={this.props.onClose} center>
        <div className="loandetailModal p-3">
          <div className="mt-4">
            <h5 className="font-hn-medium font-18"> MAP CUSTOM LOS FIELDS</h5>
          </div>
          <div className="row mt-4 mb-2  mx-0">
            <div className="col-md-7">
              <span className="font-14 font-lato-bold">FIELD NAME</span>
            </div>
            <div className="col-md-5 px-0">
              <span className="font-14 font-lato-bold">MAPPING</span>
            </div>
          </div>
          <div className="modal-scroll">
            <div className="row my-2 mx-0">
              <div className="col-md-7">
                <span className="font-14 font-lato-regular border-div p-2">
                  Borrower Name
                </span>
              </div>
              {borrower_nameSet ? (
                <div
                  id="chips"
                  className="col-md-3 px-0 d-flex align-items-center"
                >
                  <span className="font-12 truncate">{borrower_name}</span>
                  <span className="chipsClose-box">
                    <img
                      src={require("../../../assets/images/close.svg")}
                      alt=""
                      onClick={() =>
                        this.setState({
                          borrower_nameSet: false,
                          borrower_name: "",
                          borrower_namePath: "",
                        })
                      }
                    />
                  </span>
                </div>
              ) : (
                <div className="col-md-3 px-0">
                  <input
                    value={borrower_name}
                    className="form-control font-14 truncate h-100"
                    type="text"
                    readOnly
                    placeholder="Map from Json"
                  />
                </div>
              )}
              <div className="col-md-2 pr-1 pl-1">
                <input
                  value="Map Field"
                  className="btn jsonBtn h-100 font-lato-regular form-control font-14"
                  onClick={() =>
                    this._btnClick(
                      "borrower_name",
                      "borrower_namePath",
                      "borrower_nameSet"
                    )
                  }
                  type="button"
                />
              </div>
            </div>
            <div className="row my-2 mx-0">
              <div className="col-md-7">
                <span className="font-14 font-lato-regular border-div p-2">
                  Rate
                </span>
              </div>
              {rateSet ? (
                <div
                  id="chips"
                  className="col-md-3 px-0 d-flex align-items-center"
                >
                  <span className="font-12 truncate">{rate}</span>
                  <span className="chipsClose-box">
                    <img
                      src={require("../../../assets/images/close.svg")}
                      alt=""
                      onClick={() =>
                        this.setState({
                          rateSet: false,
                          rate: "",
                          ratePath: "",
                        })
                      }
                    />
                  </span>
                </div>
              ) : (
                <div className="col-md-3 px-0">
                  <input
                    value={rate}
                    className="form-control font-14 truncate h-100"
                    type="text"
                    readOnly
                    placeholder="Map from Json"
                  />
                </div>
              )}
              <div className="col-md-2 pr-1 pl-1">
                <input
                  value="Map Field"
                  className="btn jsonBtn h-100 font-lato-regular form-control"
                  onClick={() => this._btnClick("rate", "ratePath", "rateSet")}
                  type="button"
                />
              </div>
            </div>

            <div className="row my-2 mx-0">
              <div className="col-md-7">
                <span className="font-14 font-lato-regular border-div p-2">
                  Loan Purpose
                </span>
              </div>
              {loan_purposeSet ? (
                <div
                  id="chips"
                  className="col-md-3 px-0 d-flex align-items-center"
                >
                  <span className="font-12 truncate">{loan_purpose}</span>
                  <span className="chipsClose-box">
                    <img
                      src={require("../../../assets/images/close.svg")}
                      alt=""
                      onClick={() =>
                        this.setState({
                          loan_purposeSet: false,
                          loan_purpose: "",
                          loan_purposePath: "",
                        })
                      }
                    />
                  </span>
                </div>
              ) : (
                <div className="col-md-3 px-0">
                  <input
                    value={loan_purpose}
                    className="form-control font-14 truncate h-100"
                    type="text"
                    readOnly
                    placeholder="Map from Json"
                  />
                </div>
              )}
              <div className="col-md-2 pr-1 pl-1">
                <input
                  value="Map Field"
                  className="btn jsonBtn h-100 font-lato-regular form-control"
                  onClick={() =>
                    this._btnClick(
                      "loan_purpose",
                      "loan_purposePath",
                      "loan_purposeSet"
                    )
                  }
                  type="button"
                />
              </div>
            </div>
            <div className="row my-2 mx-0">
              <div className="col-md-7">
                <span className="font-14 font-lato-regular border-div p-2">
                  Loan to Value Percentage
                </span>
              </div>
              {loan_to_valueSet ? (
                <div
                  id="chips"
                  className="col-md-3 px-0 d-flex align-items-center"
                >
                  <span className="font-12 truncate">{loan_to_value}</span>
                  <span className="chipsClose-box">
                    <img
                      src={require("../../../assets/images/close.svg")}
                      alt=""
                      onClick={() =>
                        this.setState({
                          loan_to_valueSet: false,
                          loan_to_value: "",
                          loan_to_valuePath: "",
                        })
                      }
                    />
                  </span>
                </div>
              ) : (
                <div className="col-md-3 px-0">
                  <input
                    value={loan_to_value}
                    className="form-control font-14 truncate h-100"
                    type="text"
                    readOnly
                    placeholder="Map from Json"
                  />
                </div>
              )}
              <div className="col-md-2 pr-1 pl-1">
                <input
                  value="Map Field"
                  className="btn jsonBtn h-100 font-lato-regular form-control"
                  onClick={() =>
                    this._btnClick(
                      "loan_to_value",
                      "loan_to_valuePath",
                      "loan_to_valueSet"
                    )
                  }
                  type="button"
                />
              </div>
            </div>
            <div className="row my-2 mx-0">
              <div className="col-md-7">
                <span className="font-14 font-lato-regular border-div p-2">
                  Appraised Value
                </span>
              </div>
              {appraised_valueSet ? (
                <div
                  id="chips"
                  className="col-md-3 px-0 d-flex align-items-center"
                >
                  <span className="font-12 truncate">{appraised_value}</span>
                  <span className="chipsClose-box">
                    <img
                      src={require("../../../assets/images/close.svg")}
                      alt=""
                      onClick={() =>
                        this.setState({
                          appraised_valueSet: false,
                          appraised_value: "",
                          appraised_valuePath: "",
                        })
                      }
                    />
                  </span>
                </div>
              ) : (
                <div className="col-md-3 px-0">
                  <input
                    value={appraised_value}
                    className="form-control font-14 truncate h-100"
                    type="text"
                    readOnly
                    placeholder="Map from Json"
                  />
                </div>
              )}
              <div className="col-md-2 pr-1 pl-1">
                <input
                  value="Map Field"
                  className="btn jsonBtn h-100 font-lato-regular form-control"
                  onClick={() =>
                    this._btnClick(
                      "appraised_value",
                      "appraised_valuePath",
                      "appraised_valueSet"
                    )
                  }
                  type="button"
                />
              </div>
            </div>
            <div className="row my-2 mx-0">
              <div className="col-md-7">
                <span className="font-14 font-lato-regular border-div p-2">
                  Term (Months)
                </span>
              </div>
              {termSet ? (
                <div
                  id="chips"
                  className="col-md-3 px-0 d-flex align-items-center"
                >
                  <span className="font-12 truncate">{term}</span>
                  <span className="chipsClose-box">
                    <img
                      src={require("../../../assets/images/close.svg")}
                      alt=""
                      onClick={() =>
                        this.setState({
                          termSet: false,
                          term: "",
                          termPath: "",
                        })
                      }
                    />
                  </span>
                </div>
              ) : (
                <div className="col-md-3 px-0">
                  <input
                    value={term}
                    className="form-control font-14 truncate h-100"
                    type="text"
                    readOnly
                    placeholder="Map from Json"
                  />
                </div>
              )}
              <div className="col-md-2 pr-1 pl-1">
                <input
                  value="Map Field"
                  className="btn jsonBtn h-100 font-lato-regular form-control"
                  onClick={() => this._btnClick("term", "termPath", "termSet")}
                  type="button"
                />
              </div>
            </div>
            <div className="row my-2 mx-0">
              <div className="col-md-7">
                <span className="font-14 font-lato-regular border-div p-2">
                  Loan Number
                </span>
              </div>
              {loan_numberSet ? (
                <div
                  id="chips"
                  className="col-md-3 px-0 d-flex align-items-center"
                >
                  <span className="font-12 truncate">{loan_number}</span>
                  <span className="chipsClose-box">
                    <img
                      src={require("../../../assets/images/close.svg")}
                      alt=""
                      onClick={() =>
                        this.setState({
                          loan_numberSet: false,
                          loan_number: "",
                          loan_numberPath: "",
                        })
                      }
                    />
                  </span>
                </div>
              ) : (
                <div className="col-md-3 px-0">
                  <input
                    value={loan_number}
                    className="form-control font-14 truncate h-100"
                    type="text"
                    readOnly
                    placeholder="Map from Json"
                  />
                </div>
              )}
              <div className="col-md-2 pr-1 pl-1">
                <input
                  value="Map Field"
                  className="btn jsonBtn h-100 font-lato-regular form-control"
                  onClick={() =>
                    this._btnClick(
                      "loan_number",
                      "loan_numberPath",
                      "loan_numberSet"
                    )
                  }
                  type="button"
                />
              </div>
            </div>
            <div className="row my-2 mx-0">
              <div className="col-md-7">
                <span className="font-14 font-lato-regular border-div p-2">
                  Lock Date
                </span>
              </div>
              {lock_dateSet ? (
                <div
                  id="chips"
                  className="col-md-3 px-0 d-flex align-items-center"
                >
                  <span className="font-12 truncate">{lock_date}</span>
                  <span className="chipsClose-box">
                    <img
                      src={require("../../../assets/images/close.svg")}
                      alt=""
                      onClick={() =>
                        this.setState({
                          lock_dateSet: false,
                          lock_date: "",
                          lock_datePath: "",
                        })
                      }
                    />
                  </span>
                </div>
              ) : (
                <div className="col-md-3 px-0">
                  <input
                    value={lock_date}
                    className="form-control font-14 truncate h-100"
                    type="text"
                    readOnly
                    placeholder="Map from Json"
                  />
                </div>
              )}
              <div className="col-md-2 pr-1 pl-1">
                <input
                  value="Map Field"
                  className="btn jsonBtn h-100 font-lato-regular form-control"
                  onClick={() =>
                    this._btnClick("lock_date", "lock_datePath", "lock_dateSet")
                  }
                  type="button"
                />
              </div>
            </div>
            <div className="row my-2 mx-0">
              <div className="col-md-7">
                <span className="font-14 font-lato-regular border-div p-2">
                  Lock Expiration Date
                </span>
              </div>
              {lock_expiration_dateSet ? (
                <div
                  id="chips"
                  className="col-md-3 px-0 d-flex align-items-center"
                >
                  <span className="font-12 truncate">
                    {lock_expiration_date}
                  </span>
                  <span className="chipsClose-box">
                    <img
                      src={require("../../../assets/images/close.svg")}
                      alt=""
                      onClick={() =>
                        this.setState({
                          lock_expiration_dateSet: false,
                          lock_expiration_date: "",
                          lock_expiration_datePath: "",
                        })
                      }
                    />
                  </span>
                </div>
              ) : (
                <div className="col-md-3 px-0">
                  <input
                    value={lock_expiration_date}
                    className="form-control font-14 truncate h-100"
                    type="text"
                    readOnly
                    placeholder="Map from Json"
                  />
                </div>
              )}
              <div className="col-md-2 pr-1 pl-1">
                <input
                  value="Map Field"
                  className="btn jsonBtn h-100 font-lato-regular form-control"
                  onClick={() =>
                    this._btnClick(
                      "lock_expiration_date",
                      "lock_expiration_datePath",
                      "lock_expiration_dateSet"
                    )
                  }
                  type="button"
                />
              </div>
            </div>
            <div className="row my-2 mx-0">
              <div className="col-md-7">
                <span className="font-14 font-lato-regular border-div p-2">
                  Funded Date
                </span>
              </div>
              {funded_dateSet ? (
                <div
                  id="chips"
                  className="col-md-3 px-0 d-flex align-items-center"
                >
                  <span className="font-12 truncate">{funded_date}</span>
                  <span className="chipsClose-box">
                    <img
                      src={require("../../../assets/images/close.svg")}
                      alt=""
                      onClick={() =>
                        this.setState({
                          funded_dateSet: false,
                          funded_date: "",
                          funded_datePath: "",
                        })
                      }
                    />
                  </span>
                </div>
              ) : (
                <div className="col-md-3 px-0">
                  <input
                    value={funded_date}
                    className="form-control font-14 truncate h-100"
                    type="text"
                    readOnly
                    placeholder="Map from Json"
                  />
                </div>
              )}
              <div className="col-md-2 pr-1 pl-1">
                <input
                  value="Map Field"
                  className="btn jsonBtn h-100 font-lato-regular form-control"
                  onClick={() =>
                    this._btnClick(
                      "funded_date",
                      "funded_datePath",
                      "funded_dateSet"
                    )
                  }
                  type="button"
                />
              </div>
            </div>
            <div className="row my-2 mx-0">
              <div className="col-md-7">
                <span className="font-14 font-lato-regular border-div p-2">
                  Closing Date
                </span>
              </div>
              {closing_dateSet ? (
                <div
                  id="chips"
                  className="col-md-3 px-0 d-flex align-items-center"
                >
                  <span className="font-12 truncate">{closing_date}</span>
                  <span className="chipsClose-box">
                    <img
                      src={require("../../../assets/images/close.svg")}
                      alt=""
                      onClick={() =>
                        this.setState({
                          closing_dateSet: false,
                          closing_date: "",
                          closing_datePath: "",
                        })
                      }
                    />
                  </span>
                </div>
              ) : (
                <div className="col-md-3 px-0">
                  <input
                    value={closing_date}
                    className="form-control font-14 truncate h-100"
                    type="text"
                    readOnly
                    placeholder="Map from Json"
                  />
                </div>
              )}
              <div className="col-md-2 pr-1 pl-1">
                <input
                  value="Map Field"
                  className="btn jsonBtn h-100 font-lato-regular form-control"
                  onClick={() =>
                    this._btnClick(
                      "closing_date",
                      "closing_datePath",
                      "closing_dateSet"
                    )
                  }
                  type="button"
                />
              </div>
            </div>
            <div className="row my-2 mx-0">
              <div className="col-md-7">
                <span className="font-14 font-lato-regular border-div p-2">
                  Loan Amount
                </span>
              </div>
              {loan_amountSet ? (
                <div
                  id="chips"
                  className="col-md-3 px-0 d-flex align-items-center"
                >
                  <span className="font-12 truncate">{loan_amount}</span>
                  <span className="chipsClose-box">
                    <img
                      src={require("../../../assets/images/close.svg")}
                      alt=""
                      onClick={() =>
                        this.setState({
                          loan_amountSet: false,
                          loan_amount: "",
                          loan_amountPath: "",
                        })
                      }
                    />
                  </span>
                </div>
              ) : (
                <div className="col-md-3 px-0">
                  <input
                    value={loan_amount}
                    className="form-control font-14 truncate h-100"
                    type="text"
                    readOnly
                    placeholder="Map from Json"
                  />
                </div>
              )}
              <div className="col-md-2 pr-1 pl-1">
                <input
                  value="Map Field"
                  className="btn jsonBtn h-100 font-lato-regular form-control"
                  onClick={() =>
                    this._btnClick(
                      "loan_amount",
                      "loan_amountPath",
                      "loan_amountSet"
                    )
                  }
                  type="button"
                />
              </div>
            </div>
            <div className="row my-2 mx-0">
              <div className="col-md-7">
                <span className="font-14 font-lato-regular border-div p-2">
                  Loan Type
                </span>
              </div>
              {loan_typeSet ? (
                <div
                  id="chips"
                  className="col-md-3 px-0 d-flex align-items-center"
                >
                  <span className="font-12 truncate">{loan_type}</span>
                  <span className="chipsClose-box">
                    <img
                      src={require("../../../assets/images/close.svg")}
                      alt=""
                      onClick={() =>
                        this.setState({
                          loan_typeSet: false,
                          loan_type: "",
                          loan_typePath: "",
                        })
                      }
                    />
                  </span>
                </div>
              ) : (
                <div className="col-md-3 px-0">
                  <input
                    value={loan_type}
                    className="form-control font-14 truncate h-100"
                    type="text"
                    readOnly
                    placeholder="Map from Json"
                  />
                </div>
              )}
              <div className="col-md-2 pr-1 pl-1">
                <input
                  value="Map Field"
                  className="btn jsonBtn h-100 font-lato-regular form-control"
                  onClick={() =>
                    this._btnClick("loan_type", "loan_typePath", "loan_typeSet")
                  }
                  type="button"
                />
              </div>
            </div>
            <div className="row my-2 mx-0">
              <div className="col-md-7">
                <span className="font-14 font-lato-regular border-div p-2">
                  Down Payment
                </span>
              </div>
              {down_paymentSet ? (
                <div
                  id="chips"
                  className="col-md-3 px-0 d-flex align-items-center"
                >
                  <span className="font-12 truncate">{down_payment}</span>
                  <span className="chipsClose-box">
                    <img
                      src={require("../../../assets/images/close.svg")}
                      alt=""
                      onClick={() =>
                        this.setState({
                          down_paymentSet: false,
                          down_payment: "",
                          down_paymentPath: "",
                        })
                      }
                    />
                  </span>
                </div>
              ) : (
                <div className="col-md-3 px-0">
                  <input
                    value={down_payment}
                    className="form-control font-14 truncate h-100"
                    type="text"
                    readOnly
                    placeholder="Map from Json"
                  />
                </div>
              )}
              <div className="col-md-2 pr-1 pl-1">
                <input
                  value="Map Field"
                  className="btn jsonBtn h-100 font-lato-regular form-control"
                  onClick={() =>
                    this._btnClick(
                      "down_payment",
                      "down_paymentPath",
                      "down_paymentSet"
                    )
                  }
                  type="button"
                />
              </div>
            </div>
            <div className="row my-2 mx-0">
              <div className="col-md-7">
                <span className="font-14 font-lato-regular border-div p-2">
                  Loan Status
                </span>
              </div>
              {loan_statusSet ? (
                <div
                  id="chips"
                  className="col-md-3 px-0 d-flex align-items-center"
                >
                  <span className="font-12 truncate">{loan_status}</span>
                  <span className="chipsClose-box">
                    <img
                      src={require("../../../assets/images/close.svg")}
                      alt=""
                      onClick={() =>
                        this.setState({
                          loan_statusSet: false,
                          loan_status: "",
                          loan_statusPath: "",
                        })
                      }
                    />
                  </span>
                </div>
              ) : (
                <div className="col-md-3 px-0">
                  <input
                    value={loan_status}
                    className="form-control font-14 truncate h-100"
                    type="text"
                    readOnly
                    placeholder="Map from Json"
                  />
                </div>
              )}
              <div className="col-md-2 pr-1 pl-1">
                <input
                  value="Map Field"
                  className="btn jsonBtn h-100 font-lato-regular form-control"
                  onClick={() =>
                    this._btnClick(
                      "loan_status",
                      "loan_statusPath",
                      "loan_statusSet"
                    )
                  }
                  type="button"
                />
              </div>
            </div>
            <div className="row my-2 mx-0">
              <div className="col-md-7">
                <span className="font-14 font-lato-regular border-div p-2">
                  Loan Program
                </span>
              </div>
              {loan_programSet ? (
                <div
                  id="chips"
                  className="col-md-3 px-0 d-flex align-items-center"
                >
                  <span className="font-12 truncate">{loan_program}</span>
                  <span className="chipsClose-box">
                    <img
                      src={require("../../../assets/images/close.svg")}
                      alt=""
                      onClick={() =>
                        this.setState({
                          loan_programSet: false,
                          loan_program: "",
                          loan_programPath: "",
                        })
                      }
                    />
                  </span>
                </div>
              ) : (
                <div className="col-md-3 px-0">
                  <input
                    value={loan_program}
                    className="form-control font-14 truncate h-100"
                    type="text"
                    readOnly
                    placeholder="Map from Json"
                  />
                </div>
              )}
              <div className="col-md-2 pr-1 pl-1">
                <input
                  value="Map Field"
                  className="btn jsonBtn h-100 font-lato-regular form-control"
                  onClick={() =>
                    this._btnClick(
                      "loan_program",
                      "loan_programPath",
                      "loan_programSet"
                    )
                  }
                  type="button"
                />
              </div>
            </div>
            <div className="row my-2 mx-0">
              <div className="col-md-7">
                <span className="font-14 font-lato-regular border-div p-2">
                  Loan Source
                </span>
              </div>
              {loan_sourceSet ? (
                <div
                  id="chips"
                  className="col-md-3 px-0 d-flex align-items-center"
                >
                  <span className="font-12 truncate">{loan_source}</span>
                  <span className="chipsClose-box">
                    <img
                      src={require("../../../assets/images/close.svg")}
                      alt=""
                      onClick={() =>
                        this.setState({
                          loan_sourceSet: false,
                          loan_source: "",
                          loan_sourcePath: "",
                        })
                      }
                    />
                  </span>
                </div>
              ) : (
                <div className="col-md-3 px-0">
                  <input
                    value={loan_source}
                    className="form-control font-14 truncate h-100"
                    type="text"
                    readOnly
                    placeholder="Map from Json"
                  />
                </div>
              )}
              <div className="col-md-2 pr-1 pl-1">
                <input
                  value="Map Field"
                  className="btn jsonBtn h-100 font-lato-regular form-control"
                  onClick={() =>
                    this._btnClick(
                      "loan_source",
                      "loan_sourcePath",
                      "loan_sourceSet"
                    )
                  }
                  type="button"
                />
              </div>
            </div>
            <div className="row my-2 mx-0">
              <div className="col-md-7">
                <span className="font-14 font-lato-regular border-div p-2">
                  Created Date
                </span>
              </div>
              {created_dateSet ? (
                <div
                  id="chips"
                  className="col-md-3 px-0 d-flex align-items-center"
                >
                  <span className="font-12 truncate">{created_date}</span>
                  <span className="chipsClose-box">
                    <img
                      src={require("../../../assets/images/close.svg")}
                      alt=""
                      onClick={() =>
                        this.setState({
                          created_dateSet: false,
                          created_date: "",
                          created_datePath: "",
                        })
                      }
                    />
                  </span>
                </div>
              ) : (
                <div className="col-md-3 px-0">
                  <input
                    value={created_date}
                    className="form-control font-14 truncate h-100"
                    type="text"
                    readOnly
                    placeholder="Map from Json"
                  />
                </div>
              )}
              <div className="col-md-2 pr-1 pl-1">
                <input
                  value="Map Field"
                  className="btn jsonBtn h-100 font-lato-regular form-control"
                  onClick={() =>
                    this._btnClick(
                      "created_date",
                      "created_datePath",
                      "created_dateSet"
                    )
                  }
                  type="button"
                />
              </div>
            </div>
            <div className="row my-2 mx-0">
              <div className="col-md-7">
                <span className="font-14 font-lato-regular border-div p-2">
                  Created New Date
                </span>
              </div>
              {created_new_dateSet ? (
                <div
                  id="chips"
                  className="col-md-3 px-0 d-flex align-items-center"
                >
                  <span className="font-12 truncate">{created_new_date}</span>
                  <span className="chipsClose-box">
                    <img
                      src={require("../../../assets/images/close.svg")}
                      alt=""
                      onClick={() =>
                        this.setState({
                          created_new_dateSet: false,
                          created_new_date: "",
                          created_new_datePath: "",
                        })
                      }
                    />
                  </span>
                </div>
              ) : (
                <div className="col-md-3 px-0">
                  <input
                    value={created_new_date}
                    className="form-control font-14 truncate h-100"
                    type="text"
                    readOnly
                    placeholder="Map from Json"
                  />
                </div>
              )}
              <div className="col-md-2 pr-1 pl-1">
                <input
                  value="Map Field"
                  className="btn jsonBtn h-100 font-lato-regular form-control"
                  onClick={() =>
                    this._btnClick(
                      "created_new_date",
                      "created_new_datePath",
                      "created_new_dateSet"
                    )
                  }
                  type="button"
                />
              </div>
            </div>
            <div className="row my-2 mx-0">
              <div className="col-md-7">
                <span className="font-14 font-lato-regular border-div p-2">
                  Credit Store
                </span>
              </div>
              {credit_scoreSet ? (
                <div
                  id="chips"
                  className="col-md-3 px-0 d-flex align-items-center"
                >
                  <span className="font-12 truncate">{credit_score}</span>
                  <span className="chipsClose-box">
                    <img
                      src={require("../../../assets/images/close.svg")}
                      alt=""
                      onClick={() =>
                        this.setState({
                          credit_scoreSet: false,
                          credit_score: "",
                          credit_scorePath: "",
                        })
                      }
                    />
                  </span>
                </div>
              ) : (
                <div className="col-md-3 px-0">
                  <input
                    value={credit_score}
                    className="form-control font-14 truncate h-100"
                    type="text"
                    readOnly
                    placeholder="Map from Json"
                  />
                </div>
              )}
              <div className="col-md-2 pr-1 pl-1">
                <input
                  value="Map Field"
                  className="btn jsonBtn h-100 font-lato-regular form-control"
                  onClick={() =>
                    this._btnClick(
                      "credit_score",
                      "credit_scorePath",
                      "credit_scoreSet"
                    )
                  }
                  type="button"
                />
              </div>
            </div>
            <div className="row my-2 mx-0">
              <div className="col-md-7">
                <span className="font-14 font-lato-regular border-div p-2">
                  Credit Score Co borrower
                </span>
              </div>
              {credit_score_co_borrSet ? (
                <div
                  id="chips"
                  className="col-md-3 px-0 d-flex align-items-center"
                >
                  <span className="font-12 truncate">
                    {credit_score_co_borr}
                  </span>
                  <span className="chipsClose-box">
                    <img
                      src={require("../../../assets/images/close.svg")}
                      alt=""
                      onClick={() =>
                        this.setState({
                          credit_score_co_borrSet: false,
                          credit_score_co_borr: "",
                          credit_score_co_borrPath: "",
                        })
                      }
                    />
                  </span>
                </div>
              ) : (
                <div className="col-md-3 px-0">
                  <input
                    value={credit_score_co_borr}
                    className="form-control font-14 truncate h-100"
                    type="text"
                    readOnly
                    placeholder="Map from Json"
                  />
                </div>
              )}
              <div className="col-md-2 pr-1 pl-1">
                <input
                  value="Map Field"
                  className="btn jsonBtn h-100 font-lato-regular form-control"
                  onClick={() =>
                    this._btnClick(
                      "credit_score_co_borr",
                      "credit_score_co_borrPath",
                      "credit_score_co_borrSet"
                    )
                  }
                  type="button"
                />
              </div>
            </div>
            <div className="row my-2 mx-0">
              <div className="col-md-7">
                <span className="font-14 font-lato-regular border-div p-2">
                  Milestone/Status
                </span>
              </div>
              {milestoneSet ? (
                <div
                  id="chips"
                  className="col-md-3 px-0 d-flex align-items-center"
                >
                  <span className="font-12 truncate">{milestone}</span>
                  <span className="chipsClose-box">
                    <img
                      src={require("../../../assets/images/close.svg")}
                      alt=""
                      onClick={() =>
                        this.setState({
                          milestoneSet: false,
                          milestone: "",
                          milestonePath: "",
                        })
                      }
                    />
                  </span>
                </div>
              ) : (
                <div className="col-md-3 px-0">
                  <input
                    value={milestone}
                    className="form-control font-14 truncate h-100"
                    type="text"
                    readOnly
                    placeholder="Map from Json"
                  />
                </div>
              )}
              <div className="col-md-2 pr-1 pl-1">
                <input
                  value="Map Field"
                  className="btn jsonBtn h-100 font-lato-regular form-control"
                  onClick={() =>
                    this._btnClick("milestone", "milestonePath", "milestoneSet")
                  }
                  type="button"
                />
              </div>
            </div>
            <div className="row my-2 mx-0">
              <div className="col-md-7">
                <span className="font-14 font-lato-regular border-div p-2">
                  Milestone Orginal
                </span>
              </div>
              {milestone_orginalSet ? (
                <div
                  id="chips"
                  className="col-md-3 px-0 d-flex align-items-center"
                >
                  <span className="font-12 truncate">{milestone_orginal}</span>
                  <span className="chipsClose-box">
                    <img
                      src={require("../../../assets/images/close.svg")}
                      alt=""
                      onClick={() =>
                        this.setState({
                          milestone_orginalSet: false,
                          milestone_orginal: "",
                          milestone_orginalPath: "",
                        })
                      }
                    />
                  </span>
                </div>
              ) : (
                <div className="col-md-3 px-0">
                  <input
                    value={milestone_orginal}
                    className="form-control font-14 truncate h-100"
                    type="text"
                    readOnly
                    placeholder="Map from Json"
                  />
                </div>
              )}
              <div className="col-md-2 pr-1 pl-1">
                <input
                  value="Map Field"
                  className="btn jsonBtn h-100 font-lato-regular form-control"
                  onClick={() =>
                    this._btnClick(
                      "milestone_orginal",
                      "milestone_orginalPath",
                      "milestone_orginalSet"
                    )
                  }
                  type="button"
                />
              </div>
            </div>
            <div className="d-flex flex-end py-4">
              <button
                className="btn btn-normal font-lato-regular mr-3"
                onClick={() => this.props.onClose()}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary font-lato-regular"
                onClick={() => this.onSubmit()}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        {loanIdJsonOpen ? (
          <JsonPopUp
            shown={loanIdJsonOpen}
            onClose={this.closeDetails}
            currentSelected={currentSelected}
            bankId={bankId}
            selectField={this.selectedField}
          />
        ) : null}
        {confirmationOpen ? (
          <ConfirmationDefault
            open={confirmationOpen}
            onClose={this.closeConfirm}
            defaultItem={setDefault}
            submit={this.confirmed}
          />
        ) : null}
      </Modal>
    );
  }
}
function mapStateToProps(state) {
  return {
    getListLoading: state.bankIntegration.bankJsonSuccess,
    jsonList: state.bankIntegration.bankJsonList,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(bankIntegrationActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldMaping);
