import React, { Suspense } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./AiAnniversryView.scss";
import * as aiListActions from "../../../store/aiassistant/actions";
import ConfirmationModalGlobal from "../../Modal/ConfirmationModalGlobal";

const AiAnniversrySMS = React.lazy(() =>
  import("./AiAnniversrySMS/AiAnniversrySMS")
);
const AiAnniversryMMS = React.lazy(() =>
  import("./AiAnniversryMMS/AiAnniversryMMS")
);
const AiAnniversryEmail = React.lazy(() =>
  import("./AiAnniversryEmail/AiAnniversryEmail")
);
class AiAnniversryView extends React.Component {
  constructor(props) {
    super(props);
    this.smsHandeler = this.smsHandeler.bind(this);
    this.mmsHandeler = this.mmsHandeler.bind(this);
    this.emailHandeler = this.emailHandeler.bind(this);
    this.addNewTemplate = this.addNewTemplate.bind(this);
    this.saveAndUpdate = this.saveAndUpdate.bind(this);
    this.checkboxValidation = this.checkboxValidation.bind(this);
    this.deleteNewTemplate = this.deleteNewTemplate.bind(this);
  }
  state = {
    bgColorActive: "#EFF5FC",
    bgColorInActive: "#ffff",
    smsActive: true,
    mmsActive: false,
    emailActive: false,
    useCount: 1,
    addNewSmsTemplate: false,
    saveAndUpdateTemplate: false,
    addNewEmailTemplate: false,
    addNewMMSTemplate: false,
    smsTemplateUse: 0,
    mmsTemplateUse: 0,
    emailTemplateUse: 0,
    smsTemplateOn: false,
    mmsTemplateOn: false,
    emailTemplateOn: false,
    smsMoreTemplateError: false,
    mmsMoreTemplateError: false,
    emailMoreTemplateError: false,
    countAnniverserySMS: 0,
    countAnniverseryMMS: 0,
    countAnniverseryEmail: 0,
    deleteNewSmsTemplate: false,
    deleteNewEmailTemplate: false,
    deleteNewMMSTemplate: false,
    popUpShow: false,
    saveAndUpdateItemFromChild: "",
  };
  smsHandeler() {
    this.setState({ smsActive: true });
    this.setState({ mmsActive: false });
    this.setState({ emailActive: false });
    this.setState({ useCount: 1 });
  }
  mmsHandeler() {
    this.setState({ mmsActive: true });
    this.setState({ smsActive: false });
    this.setState({ emailActive: false });
    this.setState({ useCount: 3 });
  }
  emailHandeler() {
    this.setState({ emailActive: true });
    this.setState({ smsActive: false });
    this.setState({ mmsActive: false });
    this.setState({ useCount: 2 });
  }
  addNewTemplate() {
    if (this.state.useCount === 1) {
      this.setState({ addNewSmsTemplate: !this.state.addNewSmsTemplate });
    } else if (this.state.useCount === 2) {
      this.setState({ addNewEmailTemplate: !this.state.addNewEmailTemplate });
    } else if (this.state.useCount === 3) {
      this.setState({ addNewMMSTemplate: !this.state.addNewMMSTemplate });
    }
  }
  deleteNewTemplate(fromChild) {
    if (this.state.useCount === 1) {
      this.setState({ deleteNewSmsTemplate: !this.state.deleteNewSmsTemplate });
    } else if (this.state.useCount === 2) {
      this.setState({
        deleteNewEmailTemplate: !this.state.deleteNewEmailTemplate,
      });
    } else if (this.state.useCount === 3) {
      this.setState({ deleteNewMMSTemplate: !this.state.deleteNewMMSTemplate });
    }
  }

  saveAndUpdate(fromChild) {
    this.setState({ saveAndUpdateTemplate: !this.state.saveAndUpdateTemplate });
    if (fromChild !== "error") {
      this.setState({ popUpShow: !this.state.popUpShow });
      if (this.state.useCount === 1 && !this.state.smsMoreTemplateError) {
        this.setState({ saveAndUpdateItemFromChild: fromChild });
      } else if (
        this.state.useCount === 2 &&
        !this.state.emailMoreTemplateError
      ) {
        this.setState({ saveAndUpdateItemFromChild: fromChild });
      } else if (
        this.state.useCount === 3 &&
        !this.state.mmsMoreTemplateError
      ) {
        this.setState({ saveAndUpdateItemFromChild: fromChild });
      }
    }
  }
  rejectConfirm = () => {
    this.setState({ popUpShow: !this.state.popUpShow });
  };
  confirmHandler = () => {
    if (this.state.useCount === 1) {
      this.props.selectedANNIVERSERYAiAssistanSMSTemplate(
        this.state.saveAndUpdateItemFromChild
      );
    } else if (this.state.useCount === 2) {
      this.props.selectedANNIVERSERYAiAssistanEMAILTemplate(
        this.state.saveAndUpdateItemFromChild
      );
    } else if (this.state.useCount === 3) {
      this.props.selectedANNIVERSERYAiAssistanMMSTemplate(
        this.state.saveAndUpdateItemFromChild
      );
    }
    this.setState({ saveAndUpdateItemFromChild: "" });
  };
  async checkboxValidation(value) {
    if (this.state.useCount === 1) {
      if (!this.state.smsTemplateOn) {
        this.setState({
          countAnniverserySMS: (await this.props.aiList.anniverserySMSList)
            ? this.props.aiList.anniverserySMSList.filter(
                (item) => item.selected === true
              ).length
            : 0,
        });
      }
      let count = this.state.countAnniverserySMS + value;
      // if (count > 3) {
      //     this.setState({ smsMoreTemplateError: true })
      // } else {
      //     this.setState({ smsMoreTemplateError: false })
      // }
      this.setState({ smsTemplateUse: count });
      this.setState({ smsTemplateOn: true });
    } else if (this.state.useCount === 2) {
      if (!this.state.emailTemplateOn) {
        this.setState({
          countAnniverseryEmail: (await this.props.aiList.anniverseryEMAILList)
            ? this.props.aiList.anniverseryEMAILList.filter(
                (item) => item.selected === true
              ).length
            : 0,
        });
      }
      let count = this.state.countAnniverseryEmail + value;
      // if (count > 3) {
      //     this.setState({ emailMoreTemplateError: true })
      // } else {
      //     this.setState({ emailMoreTemplateError: false })
      // }
      this.setState({ emailTemplateUse: count, emailTemplateOn: true });
    } else if (this.state.useCount === 3) {
      if (!this.state.mmsTemplateOn) {
        this.setState({
          countAnniverseryMMS: (await this.props.aiList.anniverseryMMSList)
            ? this.props.aiList.anniverseryMMSList.filter(
                (item) => item.selected === true
              ).length
            : 0,
        });
      }
      let count = this.state.countAnniverseryMMS + value;
      // if (count > 3) {
      //     this.setState({ mmsMoreTemplateError: true })
      // } else {
      //     this.setState({ mmsMoreTemplateError: false })
      // }
      this.setState({ mmsTemplateUse: count, mmsTemplateOn: true });
    }
  }
  render() {
    let countAnniverseryEmail = this.props.aiList.anniverseryEMAILList
      ? this.props.aiList.anniverseryEMAILList.filter(
          (item) => item.selected === true
        ).length
      : 0;
    let countAnniverseryMMS = this.props.aiList.anniverseryMMSList
      ? this.props.aiList.anniverseryMMSList.filter(
          (item) => item.selected === true
        ).length
      : 0;
    let countAnniverserySMS = this.props.aiList.anniverserySMSList
      ? this.props.aiList.anniverserySMSList.filter(
          (item) => item.selected === true
        ).length
      : 0;

    let countSmsTotalCount = this.props.aiList.anniverserySMSList
      ? this.props.aiList.anniverserySMSList.filter((item) => item).length
      : 0;
    let countEmailTotalCount = this.props.aiList.anniverseryEMAILList
      ? this.props.aiList.anniverseryEMAILList.filter((item) => item).length
      : 0;
    let countMMSTotalCount = this.props.aiList.anniverseryMMSList
      ? this.props.aiList.anniverseryMMSList.filter((item) => item).length
      : 0;
    return (
      <div className="">
        <section
          id="aianniverseryView"
          className="py-4 project-tab bg-white partner-layout w-set task-list-layout d-flex flex-column "
        >
          <div className="d-flex mx-3">
            <div className="ai-template-set d-flex">
              {/* <div className="">
                                <span className="template-head">Templates</span>
                            </div> */}
              <div className="d-flex ai-template-set-btn pl-2">
                <div className="d-flex mr-2">
                  <button
                    className="btn font-lato-bold d-flex"
                    onClick={this.addNewTemplate}
                  >
                    <span className="imgSpan mr-1">
                      <img
                        src={require("../../../assets/images/add_circle.svg")}
                        alt="add"
                      />
                    </span>
                    Add Templates
                  </button>
                </div>

                <button
                  className="btn font-lato-bold d-flex"
                  onClick={(e) => this.saveAndUpdate("error")}
                >
                  <span className="imgSpan mr-1">
                    <img
                      src={require("../../../assets/images/save.svg")}
                      alt="save"
                    />
                  </span>
                  Save & Update Templates
                </button>
              </div>
            </div>
            <div className="a-template-selection d-flex ml-auto">
              <div className="d-flex">
                {this.state.useCount === 1 ? (
                  <div className="d-flex">
                    <span className="use-Head font-12 m-auto pr-3 d-flex">
                      Use{" "}
                      {this.state.smsTemplateOn ? (
                        <span className="pl-1">
                          {this.state.smsTemplateUse}
                        </span>
                      ) : (
                        <span className="pl-1">{countAnniverserySMS}</span>
                      )}
                      /{countSmsTotalCount}
                    </span>
                    {this.state.smsMoreTemplateError ? (
                      <small className="error"> maximum 3 templates</small>
                    ) : null}
                  </div>
                ) : this.state.useCount === 2 ? (
                  <div className="d-flex">
                    <span className="use-Head font-12 m-auto pr-3 d-flex">
                      Use{" "}
                      {this.state.emailTemplateOn ? (
                        <span className="pl-1">
                          {this.state.emailTemplateUse}
                        </span>
                      ) : (
                        <span className="pl-1">{countAnniverseryEmail}</span>
                      )}
                      /{countEmailTotalCount}
                    </span>
                    {this.state.emailMoreTemplateError ? (
                      <small className="error"> maximum 3 templates</small>
                    ) : null}
                  </div>
                ) : (
                  <div className="d-flex">
                    <span className="use-Head font-12 m-auto pr-3 d-flex">
                      Use{" "}
                      {this.state.mmsTemplateOn ? (
                        <span className="pl-1">
                          {this.state.mmsTemplateUse}
                        </span>
                      ) : (
                        <span className="pl-1">{countAnniverseryMMS}</span>
                      )}
                      /{countMMSTotalCount}
                    </span>
                    {this.state.mmsMoreTemplateError ? (
                      <small className="error"> maximum 3 templates</small>
                    ) : null}
                  </div>
                )}
                <button
                  type="button"
                  style={{
                    backgroundColor: this.state.smsActive
                      ? this.state.bgColorActive
                      : this.state.bgColorInActive,
                  }}
                  onClick={this.smsHandeler}
                  className="btn font-lato-bold"
                >
                  SMS TEMPLATES
                </button>
                <button
                  type="button"
                  style={{
                    backgroundColor: this.state.emailActive
                      ? this.state.bgColorActive
                      : this.state.bgColorInActive,
                  }}
                  onClick={this.emailHandeler}
                  className="btn font-lato-bold"
                >
                  EMAIL TEMPLATES
                </button>
                <button
                  type="button"
                  style={{
                    backgroundColor: this.state.mmsActive
                      ? this.state.bgColorActive
                      : this.state.bgColorInActive,
                  }}
                  onClick={this.mmsHandeler}
                  className="btn font-lato-bold"
                >
                  MMS TEMPLATES
                </button>
              </div>
            </div>
          </div>
        </section>
        <div>
          {this.state.smsActive ? (
            <Suspense
              fallback={
                <h2
                  style={{
                    color: "black",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Loading....
                </h2>
              }
            >
              <AiAnniversrySMS
                parentStateChange={this.addNewTemplate}
                addNewSmsTemplate={this.state.addNewSmsTemplate}
                saveAndUpdateparent={this.saveAndUpdate}
                saveAndUpdateTemplate={this.state.saveAndUpdateTemplate}
                parentCheckchange={this.checkboxValidation}
                parentNewArrayDelete={this.deleteNewTemplate}
                deleteNewSmsTemplate={this.state.deleteNewSmsTemplate}
              />
            </Suspense>
          ) : this.state.emailActive ? (
            <Suspense
              fallback={
                <h2
                  style={{
                    color: "black",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Loading....
                </h2>
              }
            >
              <AiAnniversryEmail
                parentStateChange={this.addNewTemplate}
                addNewEmailTemplate={this.state.addNewEmailTemplate}
                saveAndUpdateparent={this.saveAndUpdate}
                saveAndUpdateTemplate={this.state.saveAndUpdateTemplate}
                parentCheckchange={this.checkboxValidation}
                parentNewArrayDelete={this.deleteNewTemplate}
                deleteNewEmailTemplate={this.state.deleteNewEmailTemplate}
              />
            </Suspense>
          ) : (
            <Suspense
              fallback={
                <h2
                  style={{
                    color: "black",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Loading....
                </h2>
              }
            >
              <AiAnniversryMMS
                parentStateChange={this.addNewTemplate}
                addNewMMSTemplate={this.state.addNewMMSTemplate}
                saveAndUpdateparent={this.saveAndUpdate}
                saveAndUpdateTemplate={this.state.saveAndUpdateTemplate}
                parentCheckchange={this.checkboxValidation}
                parentNewArrayDelete={this.deleteNewTemplate}
                deleteNewMMSTemplate={this.state.deleteNewMMSTemplate}
              />
            </Suspense>
          )}
        </div>
        {this.state.popUpShow ? (
          <ConfirmationModalGlobal
            show={this.state.popUpShow}
            onClose={this.rejectConfirm}
            message="Are you sure you want to use the selected templates for auto nurturing?"
            confirmHandler={this.confirmHandler}
            isLoading={this.props.isLoading}
          />
        ) : null}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    aiList: state.aiAssistant,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(aiListActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AiAnniversryView);
