import React, { Suspense } from "react";
const EnterpriseView = React.lazy(() =>
  import("./EnterpriseView/EnterpriseView")
);
export default class EnterpriseContainer extends React.Component {
  render() {
    return (
      <Suspense
        fallback={
          <h4
            style={{
              color: "black",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Loading...
          </h4>
        }
      >
        <EnterpriseView />
      </Suspense>
    );
  }
}
