import React from "react";
import AddBankIntegration from "../Modal/AddBankIntegration";
import { connect } from "react-redux";
import "./BankIntegration.scss";
import { bindActionCreators } from "redux";
import * as bankIntegrationActions from "../../store/bankIntegration/actions";
import YesOrNo from "react-yes-or-no";
import * as CryptoJS from "crypto-js";
import FieldMaping from "./FieldMap/fieldMap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const visibleIcon = () => (
  <svg
    width="16px"
    height="10px"
    viewBox="0 0 16 10"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="ADMIN" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="ADMIN_INTEGRATION"
        transform="translate(-1363.000000, -350.000000)"
        fill="#8E8E8E"
        fillRule="nonzero"
      >
        <path
          d="M1371.14428,351.73747 C1369.26073,351.750659 1367.74443,353.22538 1367.75632,355.032522 C1367.76821,356.839664 1369.3038,358.295895 1371.18738,358.28631 C1373.07095,358.276629 1374.59028,356.804774 1374.58212,354.997613 C1374.57815,354.127505 1374.21341,353.294682 1373.5684,352.683009 C1372.9234,352.071337 1372.05117,351.73112 1371.14428,351.73747 Z M1371.14428,357.331742 C1369.81418,357.318588 1368.74494,356.27712 1368.75129,355.00093 C1368.7577,353.724739 1369.8373,352.69316 1371.16747,352.692247 C1372.49765,352.691336 1373.57878,353.721433 1373.58709,354.997613 C1373.58975,355.619106 1373.33292,356.21575 1372.87394,356.654318 C1372.41495,357.092885 1371.79204,357.336852 1371.14428,357.331742 Z M1378.87065,354.77327 C1377.19403,351.799523 1374.25373,350 1371,350 C1367.74627,350 1364.801,351.799523 1363.1393,354.77327 L1363,354.997613 L1363.12935,355.22673 C1364.80597,358.200477 1367.74627,360 1371,360 C1374.25373,360 1377.199,358.224344 1378.87065,355.22673 L1379,354.997613 L1378.87065,354.77327 Z M1371,359.02148 C1368.199,359.02148 1365.62687,357.522673 1364.13433,354.997613 C1365.62687,352.472554 1368.199,350.973747 1371,350.973747 C1373.801,350.973747 1376.34328,352.477327 1377.8607,354.997613 C1376.34328,357.522673 1373.79602,359.02148 1371,359.02148 Z"
          id="Shape-Copy-4"
        ></path>
      </g>
    </g>
  </svg>
);
const HideIcon = () => (
  <svg
    width="18px"
    height="12px"
    viewBox="0 0 33 28"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="eye-hide-solid"
        transform="translate(-2.000000, -4.000000)"
        fill="#8E8E8E"
        fillRule="nonzero"
      >
        <path
          d="M4.87,5.78 L9.33,10.24 C6.51091908,12.0551122 4.20690139,14.5657683 2.64,17.53 L2.38,18 L2.64,18.48 C6.01,24.71 11.92,28.48 18.46,28.48 C21.0126582,28.4794845 23.5321553,27.9017436 25.83,26.79 L30.83,31.79 L32.58,30.29 L6.58,4.29 L4.87,5.78 Z M13.17,14.08 C11.2382875,16.8042364 11.552648,20.5244341 13.914107,22.885893 C16.2755659,25.247352 19.9957636,25.5617125 22.72,23.63 L24.32,25.23 C22.4677809,26.0217359 20.4743389,26.43 18.46,26.43 C12.83,26.43 7.71,23.29 4.66,18 C6.12370762,15.4082183 8.23172427,13.2382012 10.78,11.7 L13.17,14.08 Z M18.47,7.53 C25.01,7.53 30.92,11.3 34.29,17.53 L34.29,17.53 L34.58,18 L34.32,18.48 C33.0653647,20.790192 31.3660743,22.8293405 29.32,24.48 L29.32,24.48 L27.9,23.06 C29.6679436,21.6535847 31.151156,19.9226046 32.27,17.96 C29.22,12.67 24.1,9.53 18.47,9.53 C17.2451997,9.53565308 16.0258515,9.69353012 14.84,10 L14.84,10 L13.23,8.38 C14.9206626,7.82108598 16.6893519,7.53418027 18.47,7.53 Z M18.37,11.17 C22.1469885,11.17 25.2134826,14.2230476 25.2300417,18 C25.2328738,18.8194115 25.0871851,19.6325574 24.8,20.4 L24.8,20.4 L16,11.6 C16.7580606,11.3165696 17.5606868,11.1709454 18.37,11.17 Z"
          id="Shape"
        ></path>
      </g>
    </g>
  </svg>
);

class BankIntegration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bankPopUp: false,
      deletePopUp: false,
      visible: [],
      clientSearch: "",
      instanceSearch: "",
      decryptedUserData: [{}],
      userData: {},
      openDeatails: false,
      currentBank: null,
      currentBankId: null,
    };
    this.addBank = this.addBank.bind(this);
    this.viewHandler = this.viewHandler.bind(this);
    this.reject = this.reject.bind(this);
  }

  reject = () =>
    this.props.deleteBankIntegrationRequest(this.state.userData.bankId);

  viewHandler = (item, index) => {
    this.state.visible[index] = true;
    this.setState((prev) => ({
      visible: prev.visible.map((val, i) => (!val && i === index ? true : val)),
    }));

    const bytes = CryptoJS.AES.decrypt(item.ciphertext, item.topSecret);
    const decryptedUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    this.state.decryptedUserData[index] = decryptedUserData;
    this.setState((prev) => ({
      decryptedUserData: prev.decryptedUserData.map((val, i) =>
        !val && i === index ? decryptedUserData : val
      ),
    }));
  };
  hideHandler = (item, index) => {
    this.state.visible[index] = false;
    this.setState((prev) => ({
      visible: prev.visible.map((val, i) =>
        !val && i === index ? false : val
      ),
    }));
  };

  addBankHandler = () => {
    this.setState({ bankPopUp: !this.state.bankPopUp });
    this.props.defaultAction();
  };
  deleteHandeler = (item, index) => {
    this.setState({ deletePopUp: !this.state.deletePopUp, userData: item });
  };

  addBank = (data) => {
    this.props.addBankRequest(data);
  };

  openMaping = (item) => {
    this.setState({ currentBank: item, currentBankId: item.bankId });
    setTimeout(() => {
      this.setState({ openDeatails: true });
    }, 200);
  };
  closeDetails = () => {
    this.setState({ openDeatails: !this.state.openDeatails });
  };
  render() {
    const { openDeatails, currentBank, currentBankId } = this.state;
    let filteredData = this.props.bankIntegration.list
      ? this.props.bankIntegration.list
          .filter(
            (item) =>
              !this.state.clientSearch ||
              item.clientId
                .toLowerCase()
                .indexOf(this.state.clientSearch.toLowerCase()) > -1
          )
          .filter(
            (item) =>
              !this.state.instanceSearch ||
              item.instanceId
                .toLowerCase()
                .indexOf(this.state.instanceSearch.toLowerCase()) > -1
          )
      : [];
    return (
      <div className="bg-white partner-layout w-set bankIntegration d-flex flex-column pt-5 px-3">
        <div className="d-flex justify-content-between pb-2 flex-column flex-md-row">
          <div className="d-flex align-items-baseline font-hn-medium">
            <h4 className="bank-in-headText">Bank Integration</h4>
          </div>
          <div className="right-btns d-flex">
            <button
              type="button"
              className="btn btn-primary shadow addBankBtn font-hn-medium"
              onClick={this.addBankHandler}
            >
              ADD BANK
            </button>
            {this.state.bankPopUp ? (
              <AddBankIntegration
                shown={this.state.bankPopUp}
                onClose={this.addBankHandler}
                addBank={this.addBank}
                isLoading={this.props.bankIntegration.isLoading}
                error={
                  this.props.bankIntegration.error
                    ? this.props.bankIntegration.error.response.data
                    : null
                }
              />
            ) : null}
          </div>
        </div>
        <div className="table-layout mt-3">
          <table className="table table-striped table-hover">
            <thead className="table-header">
              <tr id="encompassHeadRow">
                <th className="list-inline">
                  <div className="list-inline-item bank-in-HeadText font-hn-medium">
                    Client ID
                  </div>
                </th>
                <th className="list-inline ">
                  <div className="list-inline-item bank-in-HeadText font-hn-medium">
                    Instance ID
                  </div>
                </th>
                <th className="bank-in-HeadText p-19 font-hn-medium">Bank</th>
                <th className="bank-in-HeadText bank-in-actionTh p-19 font-hn-medium">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="table-body overflow-hidden">
              {filteredData.length > 0 ? (
                filteredData.map((item, index) => (
                  <tr
                    key={index}
                    className="list-tr   pointer pb-2 pt-2 "
                    id=""
                  >
                    <td className=" outline-none data-align pt-4">
                      <div className="bank-in-bodyText font-hn-medium">
                        {this.state.visible[index]
                          ? this.state.decryptedUserData[index].clientId
                          : "xxxxxxxxxxxxxxxx"}
                      </div>
                    </td>
                    <td className=" outline-none data-align pt-4">
                      <div className="bank-in-bodyText font-hn-medium">
                        {this.state.visible[index]
                          ? this.state.decryptedUserData[index].instanceID
                          : "xxxxxxxxxxxxxxxx"}
                      </div>
                    </td>
                    <td className=" outline-none data-align pt-4">
                      <div className="bank-in-bodyText font-hn-medium">
                        {item.bankName}
                      </div>
                    </td>
                    <td className=" outline-none data-align pt-3 bank-in-actionTh ">
                      <div className="d-flex">
                        {item.subscription_id ? (
                          <div className="row alignItemCenter">
                            <div className="col-md-4 d-flex justify-content-start">
                              <button
                                className="btn bank-in-rejBtn font-11 font-hn-medium"
                                onClick={() => this.deleteHandeler(item, index)}
                              >
                                DELETE
                              </button>
                            </div>
                            <div className="col-md-4 d-flex justify-content-center pr-0">
                              <button
                                className="btn activeBtn font-hn-medium font-11"
                                onClick={() => this.openMaping(item)}
                              >
                                MAP LOS
                              </button>
                            </div>
                            <div className="col-md-4 d-flex justify-content-end">
                              {this.state.visible[index] ? (
                                <div
                                  onClick={() => this.hideHandler(item, index)}
                                >
                                  {visibleIcon()}
                                </div>
                              ) : (
                                <div
                                  onClick={() => this.viewHandler(item, index)}
                                >
                                  {HideIcon()}
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="row alignItemCenter">
                            <div className="col-md-4 d-flex justify-content-start font-12 font-hn-medium">
                              <button id="bank-in-rejeBtn" className="pl-0">
                                Rejected
                              </button>
                            </div>
                            <div className="col-md-4 d-flex justify-content-center pr-0">
                              <button
                                className="btn activeBtn font-hn-medium font-11"
                                onClick={() => this.openMaping(item)}
                              >
                                MAP LOS
                              </button>
                            </div>
                            <div className="col-md-4 d-flex justify-content-end">
                              {this.state.visible[index] ? (
                                <div
                                  onClick={() => this.hideHandler(item, index)}
                                >
                                  {visibleIcon()}
                                </div>
                              ) : (
                                <div
                                  onClick={() => this.viewHandler(item, index)}
                                >
                                  {HideIcon()}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </td>
                    {openDeatails ? (
                      <FieldMaping
                        shown={openDeatails}
                        onClose={this.closeDetails}
                        bankId={currentBankId}
                        bankDetails={currentBank}
                      />
                    ) : null}
                  </tr>
                ))
              ) : (
                <tr
                  id="preventTRBorder"
                  className="d-flex justify-content-center align-items-center font-16 mt-5"
                >
                  <td id="preventTdBorder">Nothing to Show</td>
                </tr>
              )}
            </tbody>
          </table>
          {this.state.deletePopUp ? (
            <YesOrNo
              show={this.state.deletePopUp}
              onClose={this.deleteHandeler}
              message="Are you sure you want to delete?"
              confirmText="Yes"
              cancelText="No"
              confirmHandler={this.reject}
              isLoading={this.props.bankIntegration.isLoading}
              notify={this.notify}
              notificationText="DELETED"
            />
          ) : null}
        </div>
        <ToastContainer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    bankIntegration: state.bankIntegration,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(bankIntegrationActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BankIntegration);
