export const types = {
  GET_PACKAGES_REQUEST: "[PACKAGE PAGE] Get packages request",
  GET_PACKAGES_SUCCESS: "[PACKAGE PAGE] Get packages success",
  GET_PACKAGES_ERROR: "[PACKAGE PAGE] Get packages error",

  ADD_PACKAGE_REQUEST: "[PACKAGE PAGE] Add package request",
  ADD_PACKAGE_SUCCESS: "[PACKAGE PAGE] Add package success",
  ADD_PACKAGE_ERROE: "[PACKAGE PAGE] Add package error",

  UPDATE_PACKAGE_REQUEST: "[PACKAGE PAGE] UPDATE package request",
  UPDATE_PACKAGE_SUCCESS: "[PACKAGE PAGE] UPDATE package success",
  UPDATE_PACKAGE_ERROE: "[PACKAGE PAGE] UPDATE package error",

  DELETE_PACKAGE_REQUEST: "[PACKAGE PAGE] DELETE package request",
  DELETE_PACKAGE_SUCCESS: "[PACKAGE PAGE] DELETE package success",
  DELETE_PACKAGE_ERROE: "[PACKAGE PAGE] DELETE package error",
};

export const getPackages = (uId) => {
  return {
    type: types.GET_PACKAGES_REQUEST,
    uId: uId,
  };
};
export const getPackagesSuccess = (data) => {
  return {
    type: types.GET_PACKAGES_SUCCESS,
    payload: data,
  };
};
export const getPackagesError = (error) => {
  return {
    type: types.GET_PACKAGES_ERROR,
    payload: error,
  };
};
export const addPackageRequest = (pack) => {
  return {
    type: types.ADD_PACKAGE_REQUEST,
    pack: pack,
  };
};
export const addPackageSuccess = (data) => {
  return {
    type: types.ADD_PACKAGE_SUCCESS,
    payload: data,
  };
};
export const addPackageError = (error) => {
  return {
    type: types.ADD_PACKAGE_ERROE,
    payload: error,
  };
};

export const updatePackageRequest = (pack, package_id) => {
  return {
    type: types.UPDATE_PACKAGE_REQUEST,
    pack: pack,
    package_id: package_id,
  };
};
export const updatePackageSuccess = (data) => {
  return {
    type: types.UPDATE_PACKAGE_SUCCESS,
    payload: data,
  };
};
export const updatePackageError = (error) => {
  return {
    type: types.UPDATE_PACKAGE_ERROE,
    payload: error,
  };
};

export const deletePackageRequest = (package_id) => {
  return {
    type: types.DELETE_PACKAGE_REQUEST,
    package_id: package_id,
  };
};
export const deletePackageSuccess = (data) => {
  return {
    type: types.DELETE_PACKAGE_SUCCESS,
    payload: data,
  };
};
export const deletePackageError = (error) => {
  return {
    type: types.DELETE_PACKAGE_ERROE,
    payload: error,
  };
};
