import React from "react";
import "./EncompassEmpty.scss";
export default class EncompassEmpty extends React.Component {
  render() {
    return (
      <div className="col-4 en-de-clientView" style={{ color: "#000" }}>
        <div className="bg-white partner-layout w-set task-list-layout d-flex flex-column pt-5 px-3">
          <div className="my-5 py-5">
            {this.props.encompassDetails.list ? (
              <>
                <div className="d-flex justify-content-center align-items-center mt-5 pt-5">
                  <div className="en-emptyText">Click on</div>
                  <svg
                    className="ml-2"
                    width="16.47px"
                    height="9.66px"
                    viewBox="0 0 16 10"
                  >
                    <g
                      id="ADMIN"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <g
                        id="ADMIN_INTEGRATION"
                        transform="translate(-1363.000000, -350.000000)"
                        fill="#8E8E8E"
                        fillRule="nonzero"
                      >
                        <path
                          d="M1371.14428,351.73747 C1369.26073,351.750659 1367.74443,353.22538 1367.75632,355.032522 C1367.76821,356.839664 1369.3038,358.295895 1371.18738,358.28631 C1373.07095,358.276629 1374.59028,356.804774 1374.58212,354.997613 C1374.57815,354.127505 1374.21341,353.294682 1373.5684,352.683009 C1372.9234,352.071337 1372.05117,351.73112 1371.14428,351.73747 Z M1371.14428,357.331742 C1369.81418,357.318588 1368.74494,356.27712 1368.75129,355.00093 C1368.7577,353.724739 1369.8373,352.69316 1371.16747,352.692247 C1372.49765,352.691336 1373.57878,353.721433 1373.58709,354.997613 C1373.58975,355.619106 1373.33292,356.21575 1372.87394,356.654318 C1372.41495,357.092885 1371.79204,357.336852 1371.14428,357.331742 Z M1378.87065,354.77327 C1377.19403,351.799523 1374.25373,350 1371,350 C1367.74627,350 1364.801,351.799523 1363.1393,354.77327 L1363,354.997613 L1363.12935,355.22673 C1364.80597,358.200477 1367.74627,360 1371,360 C1374.25373,360 1377.199,358.224344 1378.87065,355.22673 L1379,354.997613 L1378.87065,354.77327 Z M1371,359.02148 C1368.199,359.02148 1365.62687,357.522673 1364.13433,354.997613 C1365.62687,352.472554 1368.199,350.973747 1371,350.973747 C1373.801,350.973747 1376.34328,352.477327 1377.8607,354.997613 C1376.34328,357.522673 1373.79602,359.02148 1371,359.02148 Z"
                          id="Shape-Copy-4"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </div>
                <div className="d-flex justify-content-center mb-5 pb-5 en-emptyText">
                  to view user details
                </div>
              </>
            ) : (
              <div className="d-flex justify-content-center align-items-center mt-5 pt-5">
                <div className="d-flex justify-content-center mb-5 pb-5 en-emptyText">
                  Nothing to show
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
