import { types } from "./actions";
import { call, put, takeLatest, take } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { db, config } from "../../app/utilities/firebase";
import axios from "axios";
import { getUserToken } from "../../app/utilities/firebase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function sleep(duration) {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(), duration);
  });
}
let paramsApproved, paramsRequest, paramsRejected;
export function* addNexusBankWatcherSaga() {
  yield takeLatest(types.ADD_NEXUS_BANK_REQUEST, addNexusBankWorkerSaga);
}

function* addNexusBankWorkerSaga(action) {
  try {
    yield call(addNexusBank, action.payload);
    yield put({ type: types.ADD_NEXUS_BANK_SUCCESS });
  } catch (error) {
    yield put({
      type: types.ADD_NEXUS_BANK_ERROR,
      payload: error,
    });
  }
}

async function addNexusBank(data) {
  return await axios.post(
    `${config.apiUrl}/AddSimpleNexusBank`,
    {
      ...data,
    },

    {
      headers: {
        Authorization: await getUserToken(),
      },
    }
  );
}

export function* nexusBankJSONWatcherSaga() {
  yield takeLatest(types.GET_NEXUS_BANK_JSON_REQUEST, getNexusBankJsonRequest);
}
function* getNexusBankJsonRequest(bankId) {
  try {
    const response = yield call(jsonNexusBankData, bankId.payload);
    if (response)
      yield put({
        type: types.GET_NEXUS_BANK_JSON_SUCCESS,
        payload: response,
      });
  } catch (error) {
    yield put({
      type: types.GET_NEXUS_BANK_JSON_ERROR,
      payload: error,
    });
  }
}

const jsonNexusBankData = async (bankId) => {
  return await axios.post(
    `${config.apiUrl}/GetSimpleNexusBanks`,
    {
      bankId: bankId,
    },
    {
      headers: {
        Authorization: await getUserToken(),
      },
    }
  );
};

export function* loadIntegrationNexusBankWatcherSaga() {
  yield takeLatest(
    types.LOAD_NEXUS_BANK_INTEGRATION_LIST_REQUEST,
    loadIntegrationNexusBankWorkerSaga
  );
}

function* loadIntegrationNexusBankWorkerSaga() {
  try {
    const channel = new eventChannel((emitter) => {
      let listener;
      db.collection("simple-nexus-banks").onSnapshot(async () => {
        const requests = [];
        const snap = await db.collection("simple-nexus-banks").get();
        snap.forEach(function (doc) {
          requests.push(doc.data());
        });
        emitter(
          requests.sort((a, b) => {
            if (a.bankName.toLowerCase() < b.bankName.toLowerCase()) {
              return 1;
            }
            if (a.bankName.toLowerCase() > b.bankName.toLowerCase()) {
              return -1;
            }
            return 0;
          })
        );
      });
      return () => {
        listener.off();
      };
    });
    while (true) {
      const data = yield take(channel);
      yield put({
        type: types.LOAD_NEXUS_BANK_INTEGRATION_LIST_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    yield put({
      type: types.LOAD_NEXUS_BANK_INTEGRATION_LIST_ERROR,
      payload: error,
    });
  }
}

export function* deleteNexusIntegrationWatcherSaga() {
  yield takeLatest(
    types.DELETE_NEXUS_BANK_INTEGRATION_REQUEST,
    deleteNexusIntegrationWorkerSaga
  );
}

function* deleteNexusIntegrationWorkerSaga(action) {
  try {
    yield call(deleteNexusIntegration, action.payload);
    yield put({
      type: types.DELETE_NEXUS_BANK_INTEGRATION_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: types.DELETE_BANK_INTEGRATION_ERROR,
      payload: error,
    });
  }
}

async function deleteNexusIntegration(id) {
  return await axios.post(
    `${config.apiUrl}/DeleteSimpleNexusBanks`,
    {
      bankId: id,
    },

    {
      headers: {
        Authorization: await getUserToken(),
      },
    }
  );
}

export function* NexusBankWatcherSaga() {
  yield takeLatest(types.GET_NEXUS_BANK_USERS_REQUEST, NexusBankWorkerSaga);
}

function* NexusBankWorkerSaga(action) {
  try {
    paramsRequest = action.data;
    const response = yield call(getSimpleNexusBank, action.data);
    if (response) {
      yield put({
        type: types.GET_NEXUS_BANK_REQUEST_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_NEXUS_BANK_REQUEST_ERROR,
      payload: error,
    });
  }
}

const getSimpleNexusBank = async (data) => {
  return await axios.post(`${config.apiUrl}/GetSimpleNexusRequests`, data, {
    headers: {
      Authorization: await getUserToken(),
    },
  });
};

export function* NexusBankApprovedWatcherSaga() {
  yield takeLatest(types.GET_NEXUS_BANK_APPROVED, NexusBankApprovedWorkerSaga);
}

function* NexusBankApprovedWorkerSaga(action) {
  paramsApproved = action.data;
  try {
    const response = yield call(getSimpleNexusBank, action.data);
    if (response)
      yield put({
        type: types.GET_NEXUS_BANK_APPROVED_SUCCESS,
        payload: response,
      });
  } catch (error) {
    yield put({
      type: types.GET_NEXUS_BANK_APPROVED_ERROR,
      payload: error,
    });
  }
}

export function* NexusBankGetRejectDataWatcherSaga() {
  yield takeLatest(types.GET_NEXUS_BANK_REJECTED, NexusBankRejectedWorkerSaga);
}

function* NexusBankRejectedWorkerSaga(action) {
  paramsRejected = action.data;
  try {
    const response = yield call(getSimpleNexusBank, action.data);
    if (response)
      yield put({
        type: types.GET_NEXUS_BANK_REJECTED_SUCCESS,
        payload: response,
      });
  } catch (error) {
    yield put({
      type: types.GET_NEXUS_BANK_REJECTED_ERROR,
      payload: error,
    });
  }
}

export function* NexusRejectWatcherSaga() {
  yield takeLatest(types.NEXUS_BANK_REJECTED, NexusRejectWorkerSaga);
}

function* NexusRejectWorkerSaga(action) {
  try {
    let response = yield call(rejectNexusIntegration, action.payload);
    if (response) {
      toast.success("Simple Nexus Request Rejected", {
        position: "bottom-right",
        autoClose: 2000,
      });
    }
    yield put({ type: types.NEXUS_BANK_REJECTED_SUCCESS, payload: "Rejected" });
    try {
      yield sleep(3000);
      const response = yield call(getSimpleNexusBank, paramsApproved);
      const response2 = yield call(getSimpleNexusBank, paramsRequest);
      const response3 = yield call(getSimpleNexusBank, paramsRejected);
      if (response)
        yield put({
          type: types.GET_NEXUS_BANK_APPROVED_SUCCESS,
          payload: response,
        });
      if (response2)
        yield put({
          type: types.GET_NEXUS_BANK_REQUEST_SUCCESS,
          payload: response2,
        });
      if (response3)
        yield put({
          type: types.GET_NEXUS_BANK_REJECTED_SUCCESS,
          payload: response3,
        });
    } catch (error) {
      yield put({
        type: types.GET_NEXUS_BANK_REQUEST_ERROR,
        payload: error,
      });
    }
  } catch (error) {
    yield put({ type: types.NEXUS_BANK_REJECTED_ERROR, payload: "Failed" });
    toast.error(error, {
      position: "bottom-right",
      autoClose: 2000,
    });
  }
}

async function rejectNexusIntegration(data) {
  return await axios.post(`${config.apiUrl}/UpdateSimpleNexusRequests`, data, {
    headers: {
      Authorization: await getUserToken(),
    },
  });
}

export function* NexusApproveWatcherSaga() {
  yield takeLatest(types.APPROVE_NEXUS_REQUEST, NexusApproveWorkerSaga);
}

function* NexusApproveWorkerSaga(action) {
  try {
    let response = yield call(approveNexusIntegration, action.payload);
    if (response) {
      toast.success("Simple Nexus Request Approved", {
        position: "bottom-right",
        autoClose: 2000,
      });
    }
    yield put({ type: types.APPROVE_NEXUS_REQUEST_SUCCESS });
    try {
      yield sleep(3000);
      const response = yield call(getSimpleNexusBank, paramsApproved);
      const response2 = yield call(getSimpleNexusBank, paramsRequest);
      const response3 = yield call(getSimpleNexusBank, paramsRejected);
      if (response)
        yield put({
          type: types.GET_NEXUS_BANK_APPROVED_SUCCESS,
          payload: response,
        });
      if (response2)
        yield put({
          type: types.GET_NEXUS_BANK_REQUEST_SUCCESS,
          payload: response2,
        });
      if (response3)
        yield put({
          type: types.GET_NEXUS_BANK_REJECTED_SUCCESS,
          payload: response3,
        });
    } catch (error) {
      yield put({
        type: types.GET_NEXUS_BANK_APPROVED_ERROR,
        payload: error,
      });
    }
  } catch (error) {
    yield put({
      type: types.APPROVE_NEXUS_REQUEST_ERROR,
      payload: error,
    });
  }
}

async function approveNexusIntegration(data) {
  return await axios.post(`${config.apiUrl}/UpdateSimpleNexusRequests`, data, {
    headers: {
      Authorization: await getUserToken(),
    },
  });
}
