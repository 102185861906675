export const types = {
  LOAD_ALL_BROKERS_REQUEST:
    "[BROKER INTEGRATION] Load all brokers list request",
  LOAD_ALL_BROKERS_SUCCESS:
    "[BROKER INTEGRATION] Load all brokers list success",
  LOAD_ALL_BROKERS_ERROR: "[BROKER INTEGRATION] Load all brokers list error",
};

export const loadAllBrokersRequest = () => {
  return {
    type: types.LOAD_ALL_BROKERS_REQUEST,
  };
};

export const loadAllBrokersSuccess = (list) => {
  return {
    type: types.LOAD_ALL_BROKERS_SUCCESS,
    payload: list,
  };
};

export const loadAllBrokersError = (error) => ({
  type: types.LOAD_ALL_BROKERS_ERROR,
  payload: error,
});
