import { types } from "./actions";
import { call, put, takeLatest, take } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { db, getUserToken } from "../../app/utilities/firebase";
import axios from "axios";
import { config } from "../../app/utilities/firebase";

export function* stopLicenceRequestWatcher() {
  yield takeLatest(types.STOP_LICENCE_REQUEST, stopLicenceRequestWorker);
}

function* stopLicenceRequestWorker(action) {
  try {
    const response = yield call(stopLicence, action.payload);
    if (response)
      yield put({
        type: types.STOP_LICENCE_SUCCESS,
      });
  } catch (error) {
    yield put({
      type: types.STOP_LICENCE_ERROR,
      payload: error,
    });
  }
}

const stopLicence = async (data) =>
  await axios.post(
    `${config.apiUrl}/deleterequest`,
    {
      ...data,
    },
    {
      headers: {
        Authorization: await getUserToken(),
      },
    }
  );
