export const types = {
  GET_AIASSISTANT_LIST_REQUEST: "[AI ASSISTANT] Load all request",
  GET_AIASSISTANT_LIST_SUCCESS: "[AI ASSISTANT] Load all success",
  GET_AIASSISTANT_LIST_ERROR: "[AI ASSISTANT] Load all  error",
  ADD_AIASSISTANT_REQUEST: "[AI ASSISTANT] send add sms Template",
  ADD_AIASSISTANT_SUCCESS: "[AI ASSISTANT] sucess add sms template",
  ADD_AIASSISTANT_ERROR: "[AI ASSISTANT]error add sms template",
  DELETE_AIASSISTANT_REQUEST: "[AI ASSISTANT] Send delete sms Template",
  DELETE_AIASSISTANT_SUCCESS: "[AI ASSISTANT] sucess delete sms Template",
  DELETE_AIASSISTANT_ERROR: "[AI ASSISTANT] error delete sms template",
  ADD_AIASSISTANT_SELECT_REQUEST: "[AI ASSISTANT] Send selected template",
  ADD_AIASSISTANT_SELECT_SUCCESS: "[AI ASSISTANT] sucess Selected sms template",
  ADD_AIASSISTANT_SELECT_ERROR: "[AI ASSISTANT]error Selected sms template",

  GET_AIASSISTANT_EMAIL_REQUEST: "[AI ASSISTANT] Load EMAIL all request",
  GET_AIASSISTANT_EMAIL_SUCCESS: "[AI ASSISTANT] Load EMAIL all success",
  GET_AIASSISTANT_EMAIL_ERROR: "[AI ASSISTANT] Load EMAIL all  error",
  ADD_AIASSISTANT_EMAIL_REQUEST: "[AI ASSISTANT] send add email Template",
  ADD_AIASSISTANT_EMAIL_SUCCESS: "[AI ASSISTANT] sucess add email template",
  ADD_AIASSISTANT_EMAIL_ERROR: "[AI ASSISTANT]error add email template",
  DELETE_AIASSISTANT_EMAIL_REQUEST: "[AI ASSISTANT] Send delete email Template",
  DELETE_AIASSISTANT_EMAIL_SUCCESS:
    "[AI ASSISTANT] sucess delete email Template",
  DELETE_AIASSISTANT_EMAIL_ERROR: "[AI ASSISTANT] error delete email template",
  ADD_AIASSISTANT_EMAIL_SELECT_REQUEST:
    "[AI ASSISTANT] Send selected email template",
  ADD_AIASSISTANT_EMAIL_SELECT_SUCCESS:
    "[AI ASSISTANT] sucess Selected email template",
  ADD_AIASSISTANT_EMAIL_SELECT_ERROR:
    "[AI ASSISTANT]error Selected email template",

  GET_AIASSISTANT_MMS_REQUEST: "[AI ASSISTANT] Load MMS all request",
  GET_AIASSISTANT_MMS_SUCCESS: "[AI ASSISTANT] Load MMS all success",
  GET_AIASSISTANT_MMS_ERROR: "[AI ASSISTANT] Load MMS all  error",
  ADD_AIASSISTANT_MMS_REQUEST: "[AI ASSISTANT] send add MMS Template",
  ADD_AIASSISTANT_MMS_SUCCESS: "[AI ASSISTANT] sucess add MMS template",
  ADD_AIASSISTANT_MMS_ERROR: "[AI ASSISTANT]error add MMS template",
  DELETE_AIASSISTANT_MMS_REQUEST: "[AI ASSISTANT] Send delete MMS Template",
  DELETE_AIASSISTANT_MMS_SUCCESS: "[AI ASSISTANT] sucess delete MMS Template",
  DELETE_AIASSISTANT_MMS_ERROR: "[AI ASSISTANT] error delete MMS template",
  ADD_AIASSISTANT_MMS_SELECT_REQUEST:
    "[AI ASSISTANT] Send selected MMS template",
  ADD_AIASSISTANT_MMS_SELECT_SUCCESS:
    "[AI ASSISTANT] sucess Selected MMS template",
  ADD_AIASSISTANT_MMS_SELECT_ERROR:
    "[AI ASSISTANT]error Selected email template",

  REMOVE_AIASSISTANT_MMS_IMAGE_REQUEST:
    "[AI ASSISTANT]request mms remove image template",
  REMOVE_AIASSISTANT_MMS_IMAGE_SUCCESS:
    "[AI ASSISTANT]success mms remove image",
  REMOVE_AIASSISTANT_MMS_IMAGE_ERROR: "[AI ASSISTANT]error mms remove image",

  GET_ANNIVERSERY_AIASSISTANT_LIST_REQUEST:
    "[AI ASSISTANT ANNIVERSERY] Load all request",
  GET_ANNIVERSERY_AIASSISTANT_LIST_SUCCESS:
    "[AI ASSISTANT ANNIVERSERY] Load all success",
  GET_ANNIVERSERY_AIASSISTANT_LIST_ERROR:
    "[AI ASSISTANT ANNIVERSERY] Load all  error",
  ADD_ANNIVERSERY_AIASSISTANT_REQUEST:
    "[AI ASSISTANT ANNIVERSERY] send add sms Template",
  ADD_ANNIVERSERY_AIASSISTANT_SUCCESS:
    "[AI ASSISTANT ANNIVERSERY] sucess add sms template",
  ADD_ANNIVERSERY_AIASSISTANT_ERROR:
    "[AI ASSISTANT ANNIVERSERY]error add sms template",
  DELETE_ANNIVERSERY_AIASSISTANT_REQUEST:
    "[AI ASSISTANT ANNIVERSERY] Send delete sms Template",
  DELETE_ANNIVERSERY_AIASSISTANT_SUCCESS:
    "[AI ASSISTANT ANNIVERSERY] sucess delete sms Template",
  DELETE_ANNIVERSERY_AIASSISTANT_ERROR:
    "[AI ASSISTANT ANNIVERSERY] error delete sms template",
  ADD_ANNIVERSERY_AIASSISTANT_SELECT_REQUEST:
    "[AI ASSISTANT ANNIVERSERY] Send selected template",
  ADD_ANNIVERSERY_AIASSISTANT_SELECT_SUCCESS:
    "[AI ASSISTANT ANNIVERSERY] sucess Selected sms template",
  ADD_ANNIVERSERY_AIASSISTANT_SELECT_ERROR:
    "[AI ASSISTANT ANNIVERSERY]error Selected sms template",

  GET_ANNIVERSERY_AIASSISTANT_EMAIL_REQUEST:
    "[AI ASSISTANT ANNIVERSERY] Load EMAIL all request",
  GET_ANNIVERSERY_AIASSISTANT_EMAIL_SUCCESS:
    "[AI ASSISTANT ANNIVERSERY] Load EMAIL all success",
  GET_ANNIVERSERY_AIASSISTANT_EMAIL_ERROR:
    "[AI ASSISTANT ANNIVERSERY] Load EMAIL all  error",
  ADD_ANNIVERSERY_AIASSISTANT_EMAIL_REQUEST:
    "[AI ASSISTANT ANNIVERSERY] send add email Template",
  ADD_ANNIVERSERY_AIASSISTANT_EMAIL_SUCCESS:
    "[AI ASSISTANT ANNIVERSERY] sucess add email template",
  ADD_ANNIVERSERY_AIASSISTANT_EMAIL_ERROR:
    "[AI ASSISTANT ANNIVERSERY]error add email template",
  DELETE_ANNIVERSERY_AIASSISTANT_EMAIL_REQUEST:
    "[AI ASSISTANT ANNIVERSERY] Send delete email Template",
  DELETE_ANNIVERSERY_AIASSISTANT_EMAIL_SUCCESS:
    "[AI ASSISTANT ANNIVERSERY] sucess delete email Template",
  DELETE_ANNIVERSERY_AIASSISTANT_EMAIL_ERROR:
    "[AI ASSISTANT ANNIVERSERY] error delete email template",
  ADD_ANNIVERSERY_AIASSISTANT_EMAIL_SELECT_REQUEST:
    "[AI ASSISTANT ANNIVERSERY] Send selected email template",
  ADD_ANNIVERSERY_AIASSISTANT_EMAIL_SELECT_SUCCESS:
    "[AI ASSISTANT ANNIVERSERY] sucess Selected email template",
  ADD_ANNIVERSERY_AIASSISTANT_EMAIL_SELECT_ERROR:
    "[AI ASSISTANT ANNIVERSERY]error Selected email template",

  GET_ANNIVERSERY_AIASSISTANT_MMS_REQUEST:
    "[AI ASSISTANT ANNIVERSERY] Load MMS all request",
  GET_ANNIVERSERY_AIASSISTANT_MMS_SUCCESS:
    "[AI ASSISTANT ANNIVERSERY] Load MMS all success",
  GET_ANNIVERSERY_AIASSISTANT_MMS_ERROR:
    "[AI ASSISTANT ANNIVERSERY] Load MMS all  error",
  ADD_ANNIVERSERY_AIASSISTANT_MMS_REQUEST:
    "[AI ASSISTANT ANNIVERSERY] send add MMS Template",
  ADD_ANNIVERSERY_AIASSISTANT_MMS_SUCCESS:
    "[AI ASSISTANT ANNIVERSERY] sucess add MMS template",
  ADD_ANNIVERSERY_AIASSISTANT_MMS_ERROR:
    "[AI ASSISTANT ANNIVERSERY]error add MMS template",
  DELETE_ANNIVERSERY_AIASSISTANT_MMS_REQUEST:
    "[AI ASSISTANT ANNIVERSERY] Send delete MMS Template",
  DELETE_ANNIVERSERY_AIASSISTANT_MMS_SUCCESS:
    "[AI ASSISTANT ANNIVERSERY] sucess delete MMS Template",
  DELETE_ANNIVERSERY_AIASSISTANT_MMS_ERROR:
    "[AI ASSISTANT ANNIVERSERY] error delete MMS template",
  ADD_ANNIVERSERY_AIASSISTANT_MMS_SELECT_REQUEST:
    "[AI ASSISTANT ANNIVERSERY] Send selected MMS template",
  ADD_ANNIVERSERY_AIASSISTANT_MMS_SELECT_SUCCESS:
    "[AI ASSISTANT ANNIVERSERY] sucess Selected MMS template",
  ADD_ANNIVERSERY_AIASSISTANT_MMS_SELECT_ERROR:
    "[AI ASSISTANT ANNIVERSERY]error Selected email template",

  GET_POSTCLOSENURTURE_LIST_REQUEST:
    "[AI ASSISTANT POSTCLOSENURTURE] Load all request",
  GET_POSTCLOSENURTURE_LIST_SUCCESS:
    "[AI ASSISTANT POSTCLOSENURTURE] Load all success",
  GET_POSTCLOSENURTURE_LIST_ERROR:
    "[AI ASSISTANT POSTCLOSENURTURE] Load all  error",
  ADD_POSTCLOSENURTURE_REQUEST:
    "[AI ASSISTANT POSTCLOSENURTURE] send add sms Template",
  ADD_POSTCLOSENURTURE_SUCCESS:
    "[AI ASSISTANT POSTCLOSENURTURE] sucess add sms template",
  ADD_POSTCLOSENURTURE_ERROR:
    "[AI ASSISTANT POSTCLOSENURTURE]error add sms template",
  DELETE_POSTCLOSENURTURE_REQUEST:
    "[AI ASSISTANT POSTCLOSENURTURE] Send delete sms Template",
  DELETE_POSTCLOSENURTURE_SUCCESS:
    "[AI ASSISTANT POSTCLOSENURTURE] sucess delete sms Template",
  DELETE_POSTCLOSENURTURE_ERROR:
    "[AI ASSISTANT POSTCLOSENURTURE] error delete sms template",
  ADD_POSTCLOSENURTURE_SELECT_REQUEST:
    "[AI ASSISTANT POSTCLOSENURTURE] Send selected template",
  ADD_POSTCLOSENURTURE_SELECT_SUCCESS:
    "[AI ASSISTANT POSTCLOSENURTURE] sucess Selected sms template",
  ADD_POSTCLOSENURTURE_SELECT_ERROR:
    "[AI ASSISTANT POSTCLOSENURTURE]error Selected sms template",

  GET_POSTCLOSENURTURE_EMAIL_REQUEST:
    "[AI ASSISTANT POSTCLOSENURTURE] Load EMAIL all request",
  GET_POSTCLOSENURTURE_EMAIL_SUCCESS:
    "[AI ASSISTANT POSTCLOSENURTURE] Load EMAIL all success",
  GET_POSTCLOSENURTURE_EMAIL_ERROR:
    "[AI ASSISTANT POSTCLOSENURTURE] Load EMAIL all  error",
  ADD_POSTCLOSENURTURE_EMAIL_REQUEST:
    "[AI ASSISTANT POSTCLOSENURTURE] send add email Template",
  ADD_POSTCLOSENURTURE_EMAIL_SUCCESS:
    "[AI ASSISTANT POSTCLOSENURTURE] sucess add email template",
  ADD_POSTCLOSENURTURE_EMAIL_ERROR:
    "[AI ASSISTANT POSTCLOSENURTURE]error add email template",
  DELETE_POSTCLOSENURTURE_EMAIL_REQUEST:
    "[AI ASSISTANT POSTCLOSENURTURE] Send delete email Template",
  DELETE_POSTCLOSENURTURE_EMAIL_SUCCESS:
    "[AI ASSISTANT POSTCLOSENURTURE] sucess delete email Template",
  DELETE_POSTCLOSENURTURE_EMAIL_ERROR:
    "[AI ASSISTANT POSTCLOSENURTURE] error delete email template",
  ADD_POSTCLOSENURTURE_EMAIL_SELECT_REQUEST:
    "[AI ASSISTANT POSTCLOSENURTURE] Send selected email template",
  ADD_POSTCLOSENURTURE_EMAIL_SELECT_SUCCESS:
    "[AI ASSISTANT POSTCLOSENURTURE] sucess Selected email template",
  ADD_POSTCLOSENURTURE_EMAIL_SELECT_ERROR:
    "[AI ASSISTANT POSTCLOSENURTURE]error Selected email template",

  GET_POSTCLOSENURTURE_MMS_REQUEST:
    "[AI ASSISTANT POSTCLOSENURTURE] Load MMS all request",
  GET_POSTCLOSENURTURE_MMS_SUCCESS:
    "[AI ASSISTANT POSTCLOSENURTURE] Load MMS all success",
  GET_POSTCLOSENURTURE_MMS_ERROR:
    "[AI ASSISTANT POSTCLOSENURTURE] Load MMS all  error",
  ADD_POSTCLOSENURTURE_MMS_REQUEST:
    "[AI ASSISTANT POSTCLOSENURTURE] send add MMS Template",
  ADD_POSTCLOSENURTURE_MMS_SUCCESS:
    "[AI ASSISTANT POSTCLOSENURTURE] sucess add MMS template",
  ADD_POSTCLOSENURTURE_MMS_ERROR:
    "[AI ASSISTANT POSTCLOSENURTURE]error add MMS template",
  DELETE_POSTCLOSENURTURE_MMS_REQUEST:
    "[AI ASSISTANT POSTCLOSENURTURE] Send delete MMS Template",
  DELETE_POSTCLOSENURTURE_MMS_SUCCESS:
    "[AI ASSISTANT POSTCLOSENURTURE] sucess delete MMS Template",
  DELETE_POSTCLOSENURTURE_MMS_ERROR:
    "[AI ASSISTANT POSTCLOSENURTURE] error delete MMS template",
  ADD_POSTCLOSENURTURE_MMS_SELECT_REQUEST:
    "[AI ASSISTANT POSTCLOSENURTURE] Send selected MMS template",
  ADD_POSTCLOSENURTURE_MMS_SELECT_SUCCESS:
    "[AI ASSISTANT POSTCLOSENURTURE] sucess Selected MMS template",
  ADD_POSTCLOSENURTURE_MMS_SELECT_ERROR:
    "[AI ASSISTANT POSTCLOSENURTURE]error Selected email template",

  SET_POSTCLOSENURTURE_DATE: "[AI ASSISTANT POSTCLOSENURTURE] Send Set dates",
  SET_POSTCLOSENURTURE_DATE_SUCCESS:
    "[AI ASSISTANT POSTCLOSENURTURE] Success Set dates",
  SET_POSTCLOSENURTURE_DATE_ERROR:
    "[AI ASSISTANT POSTCLOSENURTURE] error Set dates",
  GET_POSTCLOSENURTURE_DATE: "[AI ASSISTANT POSTCLOSENURTURE] Get Set dates",
  GET_POSTCLOSENURTURE_DATE_SUCCESS:
    "[AI ASSISTANT POSTCLOSENURTURE] Success GET dates",
  GET_POSTCLOSENURTURE_DATE_ERROR:
    "[AI ASSISTANT POSTCLOSENURTURE] error GET dates",
};

export const LoadAllAiassistantRequest = (uid) => {
  return {
    type: types.GET_AIASSISTANT_LIST_REQUEST,
    payload: uid,
  };
};
export const LoadAllAiassistantSuccess = (list) => {
  return {
    type: types.GET_AIASSISTANT_LIST_SUCCESS,
    payload: list,
  };
};

export const LoadAllAiassistantError = (error) => {
  return {
    type: types.GET_AIASSISTANT_LIST_ERROR,
    payload: error,
  };
};
export const addAiAssistanSMSTemplate = (smstemplate) => {
  return {
    type: types.ADD_AIASSISTANT_REQUEST,
    payload: smstemplate,
  };
};
export const addAiAssistantSMSSuccess = (data) => {
  return {
    type: types.ADD_AIASSISTANT_SUCCESS,
    payload: data,
  };
};
export const addAiAssistanSMSError = (data) => {
  return {
    type: types.ADD_AIASSISTANT_ERROR,
    payload: data,
  };
};

export const deleteAiAssistanSMSTemplate = (smstemplate) => {
  return {
    type: types.DELETE_AIASSISTANT_REQUEST,
    payload: smstemplate,
  };
};
export const deleteAiAssistantSMSSuccess = (data) => {
  return {
    type: types.DELETE_AIASSISTANT_SUCCESS,
    payload: data,
  };
};
export const deleteAiAssistanSMSError = (data) => {
  return {
    type: types.DELETE_AIASSISTANT_ERROR,
    payload: data,
  };
};

export const selectedAiAssistanSMSTemplate = (smstemplate) => {
  return {
    type: types.ADD_AIASSISTANT_SELECT_REQUEST,
    payload: smstemplate,
  };
};
export const selectedAiAssistantSMSSuccess = (data) => {
  return {
    type: types.ADD_AIASSISTANT_SELECT_SUCCESS,
    payload: data,
  };
};
export const selectedAiAssistanSMSError = (data) => {
  return {
    type: types.ADD_AIASSISTANT_SELECT_ERROR,
    payload: data,
  };
};

export const LoadAllAiassistantEmailRequest = (uid) => {
  return {
    type: types.GET_AIASSISTANT_EMAIL_REQUEST,
    payload: uid,
  };
};
export const LoadAllAiassistantEmailSuccess = (emaillist) => {
  return {
    type: types.GET_AIASSISTANT_EMAIL_SUCCESS,
    payload: emaillist,
  };
};
export const LoadAllAiassistantEmailError = (error) => {
  return {
    type: types.GET_AIASSISTANT_EMAIL_ERROR,
    payload: error,
  };
};
export const addAiAssistanEMAILTemplate = (smstemplate) => {
  return {
    type: types.ADD_AIASSISTANT_EMAIL_REQUEST,
    payload: smstemplate,
  };
};
export const addAiAssistantEMAILSuccess = (data) => {
  return {
    type: types.ADD_AIASSISTANT_EMAIL_SUCCESS,
    payload: data,
  };
};
export const addAiAssistanEMAILError = (data) => {
  return {
    type: types.ADD_AIASSISTANT_EMAIL_ERROR,
    payload: data,
  };
};

export const deleteAiAssistanEMAILTemplate = (smstemplate) => {
  return {
    type: types.DELETE_AIASSISTANT_EMAIL_REQUEST,
    payload: smstemplate,
  };
};
export const deleteAiAssistantEMAILSuccess = (data) => {
  return {
    type: types.DELETE_AIASSISTANT_EMAIL_SUCCESS,
    payload: data,
  };
};
export const deleteAiAssistanEMAILError = (data) => {
  return {
    type: types.DELETE_AIASSISTANT_EMAIL_ERROR,
    payload: data,
  };
};
export const selectedAiAssistanEMAILTemplate = (emailtemplate) => {
  return {
    type: types.ADD_AIASSISTANT_EMAIL_SELECT_REQUEST,
    payload: emailtemplate,
  };
};
export const selectedAiAssistantEMAILSuccess = (data) => {
  return {
    type: types.ADD_AIASSISTANT_EMAIL_SELECT_SUCCESS,
    payload: data,
  };
};
export const selectedAiAssistanEMAILError = (data) => {
  return {
    type: types.ADD_AIASSISTANT_EMAIL_SELECT_ERROR,
    payload: data,
  };
};

export const LoadAllAiassistantMMSRequest = (uid) => {
  return {
    type: types.GET_AIASSISTANT_MMS_REQUEST,
    payload: uid,
  };
};
export const LoadAllAiassistantMMSSuccess = (mmslist) => {
  return {
    type: types.GET_AIASSISTANT_MMS_SUCCESS,
    payload: mmslist,
  };
};
export const LoadAllAiassistantMMSError = (error) => {
  return {
    type: types.GET_AIASSISTANT_MMS_ERROR,
    payload: error,
  };
};
export const addAiAssistanMMSTemplate = (mmstemplate) => {
  return {
    type: types.ADD_AIASSISTANT_MMS_REQUEST,
    payload: mmstemplate,
  };
};
export const addAiAssistantMMSSuccess = (data) => {
  return {
    type: types.ADD_AIASSISTANT_MMS_SUCCESS,
    payload: data,
  };
};
export const addAiAssistanMMSError = (data) => {
  return {
    type: types.ADD_AIASSISTANT_MMS_ERROR,
    payload: data,
  };
};

export const deleteAiAssistanMMSTemplate = (smstemplate) => {
  return {
    type: types.DELETE_AIASSISTANT_MMS_REQUEST,
    payload: smstemplate,
  };
};
export const deleteAiAssistantMMSSuccess = (data) => {
  return {
    type: types.DELETE_AIASSISTANT_MMS_SUCCESS,
    payload: data,
  };
};
export const deleteAiAssistanMMSError = (data) => {
  return {
    type: types.DELETE_AIASSISTANT_MMS_ERROR,
    payload: data,
  };
};
export const selectedAiAssistanMMSTemplate = (smstemplate) => {
  return {
    type: types.ADD_AIASSISTANT_MMS_SELECT_REQUEST,
    payload: smstemplate,
  };
};
export const selectedAiAssistantMMSSuccess = (data) => {
  return {
    type: types.ADD_AIASSISTANT_MMS_SELECT_SUCCESS,
    payload: data,
  };
};
export const selectedAiAssistanMMSError = (data) => {
  return {
    type: types.ADD_AIASSISTANT_MMS_SELECT_ERROR,
    payload: data,
  };
};

export const LoadAllANNIVERSERYAiassistantRequest = (uid) => {
  return {
    type: types.GET_ANNIVERSERY_AIASSISTANT_LIST_REQUEST,
    payload: uid,
  };
};
export const LoadAllANNIVERSERYAiassistantSuccess = (list) => {
  return {
    type: types.GET_ANNIVERSERY_AIASSISTANT_LIST_SUCCESS,
    payload: list,
  };
};

export const LoadAllANNIVERSERYAiassistantError = (error) => {
  return {
    type: types.GET_ANNIVERSERY_AIASSISTANT_LIST_ERROR,
    payload: error,
  };
};
export const addANNIVERSERYAiAssistanSMSTemplate = (smstemplate) => {
  return {
    type: types.ADD_ANNIVERSERY_AIASSISTANT_REQUEST,
    payload: smstemplate,
  };
};
export const addANNIVERSERYAiAssistantSMSSuccess = (data) => {
  return {
    type: types.ADD_ANNIVERSERY_AIASSISTANT_SUCCESS,
    payload: data,
  };
};
export const addANNIVERSERYAiAssistanSMSError = (data) => {
  return {
    type: types.ADD_ANNIVERSERY_AIASSISTANT_ERROR,
    payload: data,
  };
};

export const deleteANNIVERSERYAiAssistanSMSTemplate = (smstemplate) => {
  return {
    type: types.DELETE_ANNIVERSERY_AIASSISTANT_REQUEST,
    payload: smstemplate,
  };
};
export const deleteANNIVERSERYAiAssistantSMSSuccess = (data) => {
  return {
    type: types.DELETE_ANNIVERSERY_AIASSISTANT_SUCCESS,
    payload: data,
  };
};
export const deleteANNIVERSERYAiAssistanSMSError = (data) => {
  return {
    type: types.DELETE_ANNIVERSERY_AIASSISTANT_ERROR,
    payload: data,
  };
};

export const selectedANNIVERSERYAiAssistanSMSTemplate = (smstemplate) => {
  return {
    type: types.ADD_ANNIVERSERY_AIASSISTANT_SELECT_REQUEST,
    payload: smstemplate,
  };
};
export const selectedANNIVERSERYAiAssistantSMSSuccess = (data) => {
  return {
    type: types.ADD_ANNIVERSERY_AIASSISTANT_SELECT_SUCCESS,
    payload: data,
  };
};
export const selectedANNIVERSERYAiAssistanSMSError = (data) => {
  return {
    type: types.ADD_ANNIVERSERY_AIASSISTANT_SELECT_ERROR,
    payload: data,
  };
};

export const LoadAllANNIVERSERYAiassistantEmailRequest = (uid) => {
  return {
    type: types.GET_ANNIVERSERY_AIASSISTANT_EMAIL_REQUEST,
    payload: uid,
  };
};
export const LoadAllANNIVERSERYAiassistantEmailSuccess = (emaillist) => {
  return {
    type: types.GET_ANNIVERSERY_AIASSISTANT_EMAIL_SUCCESS,
    payload: emaillist,
  };
};
export const LoadAllANNIVERSERYAiassistantEmailError = (error) => {
  return {
    type: types.GET_ANNIVERSERY_AIASSISTANT_EMAIL_ERROR,
    payload: error,
  };
};
export const addANNIVERSERYAiAssistanEMAILTemplate = (smstemplate) => {
  return {
    type: types.ADD_ANNIVERSERY_AIASSISTANT_EMAIL_REQUEST,
    payload: smstemplate,
  };
};
export const addANNIVERSERYAiAssistantEMAILSuccess = (data) => {
  return {
    type: types.ADD_ANNIVERSERY_AIASSISTANT_EMAIL_SUCCESS,
    payload: data,
  };
};
export const addANNIVERSERYAiAssistanEMAILError = (data) => {
  return {
    type: types.ADD_ANNIVERSERY_AIASSISTANT_EMAIL_ERROR,
    payload: data,
  };
};

export const deleteANNIVERSERYAiAssistanEMAILTemplate = (smstemplate) => {
  return {
    type: types.DELETE_ANNIVERSERY_AIASSISTANT_EMAIL_REQUEST,
    payload: smstemplate,
  };
};
export const deleteANNIVERSERYAiAssistantEMAILSuccess = (data) => {
  return {
    type: types.DELETE_ANNIVERSERY_AIASSISTANT_EMAIL_SUCCESS,
    payload: data,
  };
};
export const deleteANNIVERSERYAiAssistanEMAILError = (data) => {
  return {
    type: types.DELETE_ANNIVERSERY_AIASSISTANT_EMAIL_ERROR,
    payload: data,
  };
};
export const selectedANNIVERSERYAiAssistanEMAILTemplate = (emailtemplate) => {
  return {
    type: types.ADD_ANNIVERSERY_AIASSISTANT_EMAIL_SELECT_REQUEST,
    payload: emailtemplate,
  };
};
export const selectedANNIVERSERYAiAssistantEMAILSuccess = (data) => {
  return {
    type: types.ADD_ANNIVERSERY_AIASSISTANT_EMAIL_SELECT_SUCCESS,
    payload: data,
  };
};
export const selectedANNIVERSERYAiAssistanEMAILError = (data) => {
  return {
    type: types.ADD_ANNIVERSERY_AIASSISTANT_EMAIL_SELECT_ERROR,
    payload: data,
  };
};

export const LoadAllANNIVERSERYAiassistantMMSRequest = (uid) => {
  return {
    type: types.GET_ANNIVERSERY_AIASSISTANT_MMS_REQUEST,
    payload: uid,
  };
};
export const LoadAllANNIVERSERYAiassistantMMSSuccess = (mmslist) => {
  return {
    type: types.GET_ANNIVERSERY_AIASSISTANT_MMS_SUCCESS,
    payload: mmslist,
  };
};
export const LoadAllANNIVERSERYAiassistantMMSError = (error) => {
  return {
    type: types.GET_ANNIVERSERY_AIASSISTANT_MMS_ERROR,
    payload: error,
  };
};
export const addANNIVERSERYAiAssistanMMSTemplate = (mmstemplate) => {
  return {
    type: types.ADD_ANNIVERSERY_AIASSISTANT_MMS_REQUEST,
    payload: mmstemplate,
  };
};
export const addANNIVERSERYAiAssistantMMSSuccess = (data) => {
  return {
    type: types.ADD_ANNIVERSERY_AIASSISTANT_MMS_SUCCESS,
    payload: data,
  };
};
export const addANNIVERSERYAiAssistanMMSError = (data) => {
  return {
    type: types.ADD_ANNIVERSERY_AIASSISTANT_MMS_ERROR,
    payload: data,
  };
};

export const deleteANNIVERSERYAiAssistanMMSTemplate = (smstemplate) => {
  return {
    type: types.DELETE_ANNIVERSERY_AIASSISTANT_MMS_REQUEST,
    payload: smstemplate,
  };
};
export const deleteANNIVERSERYAiAssistantMMSSuccess = (data) => {
  return {
    type: types.DELETE_ANNIVERSERY_AIASSISTANT_MMS_SUCCESS,
    payload: data,
  };
};
export const deleteANNIVERSERYAiAssistanMMSError = (data) => {
  return {
    type: types.DELETE_ANNIVERSERY_AIASSISTANT_MMS_ERROR,
    payload: data,
  };
};
export const selectedANNIVERSERYAiAssistanMMSTemplate = (smstemplate) => {
  return {
    type: types.ADD_ANNIVERSERY_AIASSISTANT_MMS_SELECT_REQUEST,
    payload: smstemplate,
  };
};
export const selectedANNIVERSERYAiAssistantMMSSuccess = (data) => {
  return {
    type: types.ADD_ANNIVERSERY_AIASSISTANT_MMS_SELECT_SUCCESS,
    payload: data,
  };
};
export const selectedANNIVERSERYAiAssistanMMSError = (data) => {
  return {
    type: types.ADD_ANNIVERSERY_AIASSISTANT_MMS_SELECT_ERROR,
    payload: data,
  };
};

export const LoadAllPOSTCLOSENURTUREAiassistantRequest = (uid) => {
  return {
    type: types.GET_POSTCLOSENURTURE_LIST_REQUEST,
    payload: uid,
  };
};
export const LoadAllPOSTCLOSENURTUREAiassistantSuccess = (list) => {
  return {
    type: types.GET_POSTCLOSENURTURE_LIST_SUCCESS,
    payload: list,
  };
};

export const LoadAllPOSTCLOSENURTUREAiassistantError = (error) => {
  return {
    type: types.GET_POSTCLOSENURTURE_LIST_ERROR,
    payload: error,
  };
};
export const addPOSTCLOSENURTUREAiAssistanSMSTemplate = (smstemplate) => {
  return {
    type: types.ADD_POSTCLOSENURTURE_REQUEST,
    payload: smstemplate,
  };
};
export const addPOSTCLOSENURTUREAiAssistantSMSSuccess = (data) => {
  return {
    type: types.ADD_POSTCLOSENURTURE_SUCCESS,
    payload: data,
  };
};
export const addPOSTCLOSENURTUREAiAssistanSMSError = (data) => {
  return {
    type: types.ADD_POSTCLOSENURTURE_ERROR,
    payload: data,
  };
};

export const deletePOSTCLOSENURTUREAiAssistanSMSTemplate = (smstemplate) => {
  return {
    type: types.DELETE_POSTCLOSENURTURE_REQUEST,
    payload: smstemplate,
  };
};
export const deletePOSTCLOSENURTUREAiAssistantSMSSuccess = (data) => {
  return {
    type: types.DELETE_POSTCLOSENURTURE_SUCCESS,
    payload: data,
  };
};
export const deletePOSTCLOSENURTUREAiAssistanSMSError = (data) => {
  return {
    type: types.DELETE_POSTCLOSENURTURE_ERROR,
    payload: data,
  };
};

export const selectedPOSTCLOSENURTUREAiAssistanSMSTemplate = (smstemplate) => {
  return {
    type: types.ADD_POSTCLOSENURTURE_SELECT_REQUEST,
    payload: smstemplate,
  };
};
export const selectedPOSTCLOSENURTUREAiAssistantSMSSuccess = (data) => {
  return {
    type: types.ADD_POSTCLOSENURTURE_SELECT_SUCCESS,
    payload: data,
  };
};
export const selectedPOSTCLOSENURTUREAiAssistanSMSError = (data) => {
  return {
    type: types.ADD_POSTCLOSENURTURE_SELECT_ERROR,
    payload: data,
  };
};

export const LoadAllPOSTCLOSENURTUREAiassistantEmailRequest = (uid) => {
  return {
    type: types.GET_POSTCLOSENURTURE_EMAIL_REQUEST,
    payload: uid,
  };
};
export const LoadAllPOSTCLOSENURTUREAiassistantEmailSuccess = (emaillist) => {
  return {
    type: types.GET_POSTCLOSENURTURE_EMAIL_SUCCESS,
    payload: emaillist,
  };
};
export const LoadAllPOSTCLOSENURTUREAiassistantEmailError = (error) => {
  return {
    type: types.GET_POSTCLOSENURTURE_EMAIL_ERROR,
    payload: error,
  };
};
export const addPOSTCLOSENURTUREAiAssistanEMAILTemplate = (smstemplate) => {
  return {
    type: types.ADD_POSTCLOSENURTURE_EMAIL_REQUEST,
    payload: smstemplate,
  };
};
export const addPOSTCLOSENURTUREAiAssistantEMAILSuccess = (data) => {
  return {
    type: types.ADD_POSTCLOSENURTURE_EMAIL_SUCCESS,
    payload: data,
  };
};
export const addPOSTCLOSENURTUREAiAssistanEMAILError = (data) => {
  return {
    type: types.ADD_POSTCLOSENURTURE_EMAIL_ERROR,
    payload: data,
  };
};

export const deletePOSTCLOSENURTUREAiAssistanEMAILTemplate = (smstemplate) => {
  return {
    type: types.DELETE_POSTCLOSENURTURE_EMAIL_REQUEST,
    payload: smstemplate,
  };
};
export const deletePOSTCLOSENURTUREAiAssistantEMAILSuccess = (data) => {
  return {
    type: types.DELETE_POSTCLOSENURTURE_EMAIL_SUCCESS,
    payload: data,
  };
};
export const deletePOSTCLOSENURTUREAiAssistanEMAILError = (data) => {
  return {
    type: types.DELETE_POSTCLOSENURTURE_EMAIL_ERROR,
    payload: data,
  };
};
export const selectedPOSTCLOSENURTUREAiAssistanEMAILTemplate = (
  emailtemplate
) => {
  return {
    type: types.ADD_POSTCLOSENURTURE_EMAIL_SELECT_REQUEST,
    payload: emailtemplate,
  };
};
export const selectedPOSTCLOSENURTUREAiAssistantEMAILSuccess = (data) => {
  return {
    type: types.ADD_POSTCLOSENURTURE_EMAIL_SELECT_SUCCESS,
    payload: data,
  };
};
export const selectedPOSTCLOSENURTUREAiAssistanEMAILError = (data) => {
  return {
    type: types.ADD_POSTCLOSENURTURE_EMAIL_SELECT_ERROR,
    payload: data,
  };
};

export const LoadAllPOSTCLOSENURTUREAiassistantMMSRequest = (uid) => {
  return {
    type: types.GET_POSTCLOSENURTURE_MMS_REQUEST,
    payload: uid,
  };
};
export const LoadAllPOSTCLOSENURTUREAiassistantMMSSuccess = (mmslist) => {
  return {
    type: types.GET_POSTCLOSENURTURE_MMS_SUCCESS,
    payload: mmslist,
  };
};
export const LoadAllPOSTCLOSENURTUREAiassistantMMSError = (error) => {
  return {
    type: types.GET_POSTCLOSENURTURE_MMS_ERROR,
    payload: error,
  };
};
export const addPOSTCLOSENURTUREAiAssistanMMSTemplate = (mmstemplate) => {
  return {
    type: types.ADD_POSTCLOSENURTURE_MMS_REQUEST,
    payload: mmstemplate,
  };
};
export const addPOSTCLOSENURTUREAiAssistantMMSSuccess = (data) => {
  return {
    type: types.ADD_POSTCLOSENURTURE_MMS_SUCCESS,
    payload: data,
  };
};
export const addPOSTCLOSENURTUREAiAssistanMMSError = (data) => {
  return {
    type: types.ADD_POSTCLOSENURTURE_MMS_ERROR,
    payload: data,
  };
};

export const deletePOSTCLOSENURTUREAiAssistanMMSTemplate = (smstemplate) => {
  return {
    type: types.DELETE_POSTCLOSENURTURE_MMS_REQUEST,
    payload: smstemplate,
  };
};
export const deletePOSTCLOSENURTUREAiAssistantMMSSuccess = (data) => {
  return {
    type: types.DELETE_POSTCLOSENURTURE_MMS_SUCCESS,
    payload: data,
  };
};
export const deletePOSTCLOSENURTUREAiAssistanMMSError = (data) => {
  return {
    type: types.DELETE_POSTCLOSENURTURE_MMS_ERROR,
    payload: data,
  };
};
export const selectedPOSTCLOSENURTUREAiAssistanMMSTemplate = (smstemplate) => {
  return {
    type: types.ADD_POSTCLOSENURTURE_MMS_SELECT_REQUEST,
    payload: smstemplate,
  };
};
export const selectedPOSTCLOSENURTUREAiAssistantMMSSuccess = (data) => {
  return {
    type: types.ADD_POSTCLOSENURTURE_MMS_SELECT_SUCCESS,
    payload: data,
  };
};
export const selectedPOSTCLOSENURTUREAiAssistanMMSError = (data) => {
  return {
    type: types.ADD_POSTCLOSENURTURE_MMS_SELECT_ERROR,
    payload: data,
  };
};

export const removemmsImageRequest = (data) => {
  return {
    type: types.REMOVE_AIASSISTANT_MMS_IMAGE_REQUEST,
    payload: data,
  };
};
export const removemmsImageSuccess = (data) => {
  return {
    type: types.REMOVE_AIASSISTANT_MMS_IMAGE_SUCCESS,
    payload: data,
  };
};
export const removemmsImageError = (data) => {
  return {
    type: types.REMOVE_AIASSISTANT_MMS_IMAGE_ERROR,
    payload: data,
  };
};

export const setPostCloseDate = (data) => {
  return {
    type: types.SET_POSTCLOSENURTURE_DATE,
    payload: data,
  };
};
export const setPostCloseDateSucess = (data) => {
  return {
    type: types.SET_POSTCLOSENURTURE_DATE_SUCCESS,
    payload: data,
  };
};
export const setPostCloseDateError = (data) => {
  return {
    type: types.SET_POSTCLOSENURTURE_DATE_ERROR,
    payload: data,
  };
};
export const getPostCloseDate = (data) => {
  return {
    type: types.GET_POSTCLOSENURTURE_DATE,
    payload: data,
  };
};
export const getPostCloseDateSucess = (data) => {
  return {
    type: types.GET_POSTCLOSENURTURE_DATE_SUCCESS,
    payload: data,
  };
};
export const getPostCloseDateError = (data) => {
  return {
    type: types.GET_POSTCLOSENURTURE_DATE_ERROR,
    payload: data,
  };
};
