import { types } from "./actions";

const initialState = {
  isLoadingList: true,
  error: null,
  list: null,
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_ENCOMPASS_DETAILS_REQUEST:
      return { ...state, isLoadingList: true, list: null };
    case types.GET_ENCOMPASS_DETAILS_SUCCESS:
      return { ...state, isLoadingList: false, list: action.payload };
    case types.GET_ENCOMPASS_DETAILS_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoadingList: false,
        list: null,
      };
    case types.DELETE_SUBSCRIPTION_REQUEST:
      return { ...state, isLoading: true };
    case types.DELETE_SUBSCRIPTION_SUCCESS:
      return { ...state, isLoading: false };
    case types.DELETE_SUBSCRIPTION_ERROR:
      return { ...state, isLoading: false, error: action.payload };
    case types.CREATE_SUBSCRIPTION_REQUEST:
      return { ...state, isLoading: true };
    case types.CREATE_SUBSCRIPTION_SUCCESS:
      return { ...state, isLoading: false };
    case types.CREATE_SUBSCRIPTION_ERROR:
      return { ...state, isLoading: false, error: action.payload };
    default:
      return state;
  }
}
