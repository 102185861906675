import { types } from "./actions";
import { call, put, takeLatest, take } from "redux-saga/effects";
import { mongo } from "../../app/utilities/firebase";
import { db, config, getUserToken } from "../../app/utilities/firebase";
import axios from "axios";
import { eventChannel } from "redux-saga";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function sleep(duration) {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(), duration);
  });
}

let paramsApproved, paramsRequest, paramsRejected;
export function* BankWatcherSaga() {
  yield takeLatest(types.GET_BANK_REQUEST, BankWorkerSaga);
}

function* BankWorkerSaga(action) {
  try {
    paramsRequest = action.data;
    const response = yield call(getBank, action.data);
    if (response) {
      yield put({
        type: types.GET_BANK_REQUEST_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_BANK_REQUEST_ERROR,
      payload: error,
    });
  }
}

const getBank = async (data) => {
  const { data: res, ...rest } = await axios.post(
    `${mongo.mongoUrl}/v0.1/encompass/listApprovals`,
    data,
    {
      headers: {
        Authorization: await getUserToken(),
      },
    }
  );

  const results = res.data || [];
  const promises = [];
  results.forEach((item) => {
    promises.push(new Promise(async (resolve, reject) => {
      try {
        if(item.user && item.user.length > 0) {
          const enterpriseData = (await db.collection('enterprises').doc(item.user[0].enterpriseId).get()).data();
          if(enterpriseData) {
            item['enterpriseName'] = enterpriseData.enterpriseName;
          }
        }
        resolve(item);
      } catch(err) {
        console.log(err);
        reject(err);
      }
    }));
  });
  await Promise.all(promises);

  return {
    ...rest,
    data: {
      ...res,
      data: results,
    },
  };
};

export function* BankApprovedWatcherSaga() {
  yield takeLatest(types.GET_BANK_APPROVED, BankApprovedWorkerSaga);
}

function* BankApprovedWorkerSaga(action) {
  paramsApproved = action.data;
  try {
    const response = yield call(getBank, action.data);
    if (response)
      yield put({
        type: types.GET_BANK_APPROVED_SUCCESS,
        payload: response,
      });
  } catch (error) {
    yield put({
      type: types.GET_BANK_APPROVED_ERROR,
      payload: error,
    });
  }
}

export function* BankGetRejectDataWatcherSaga() {
  yield takeLatest(types.GET_BANK_REJECTED, BankRejectedWorkerSaga);
}

function* BankRejectedWorkerSaga(action) {
  paramsRejected = action.data;
  try {
    const response = yield call(getBank, action.data);
    if (response)
      yield put({
        type: types.GET_BANK_REJECTED_SUCCESS,
        payload: response,
      });
  } catch (error) {
    yield put({
      type: types.GET_BANK_REJECTED_ERROR,
      payload: error,
    });
  }
}

export function* RejectWatcherSaga() {
  yield takeLatest(types.REJECT_REQUEST, RejectWorkerSaga);
}

function* RejectWorkerSaga(action) {
  try {
    let response = yield call(reject, action.payload);
    if (response) {
      toast.success("LOS Request Rejected", {
        position: "bottom-right",
        autoClose: 2000,
      });
    }
    yield put({ type: types.REJECT_REQUEST_SUCCESS, payload: "Rejected" });
    try {
      yield sleep(3000);
      const response = yield call(getBank, paramsApproved);
      const response2 = yield call(getBank, paramsRequest);
      const response3 = yield call(getBank, paramsRejected);
      if (response)
        yield put({
          type: types.GET_BANK_APPROVED_SUCCESS,
          payload: response,
        });
      if (response2)
        yield put({
          type: types.GET_BANK_REQUEST_SUCCESS,
          payload: response2,
        });
      if (response3)
        yield put({
          type: types.GET_BANK_REJECTED_SUCCESS,
          payload: response3,
        });
    } catch (error) {
      yield put({
        type: types.GET_BANK_REQUEST_ERROR,
        payload: error,
      });
    }
  } catch (error) {
    yield put({ type: types.REJECT_REQUEST_ERROR, payload: "Failed" });
    toast.error(error, {
      position: "bottom-right",
      autoClose: 2000,
    });
  }
}

async function reject(data) {
  return await axios.post(`${config.apiUrl}/bankintegrationReject`, data, {
    headers: {
      Authorization: await getUserToken(),
    },
  });
}

export function* approveWatcherSaga() {
  yield takeLatest(types.APPROVE_REQUEST, approveWorkerSaga);
}

function* approveWorkerSaga(action) {
  try {
    let response = yield call(approve, action.payload);
    if (response) {
      toast.success("LOS Request Approved", {
        position: "bottom-right",
        autoClose: 2000,
      });
    }
    yield put({ type: types.APPROVE_REQUEST_SUCCESS });
    try {
      yield sleep(3000);
      const response = yield call(getBank, paramsApproved);
      const response2 = yield call(getBank, paramsRequest);
      const response3 = yield call(getBank, paramsRejected);
      if (response)
        yield put({
          type: types.GET_BANK_APPROVED_SUCCESS,
          payload: response,
        });
      if (response2)
        yield put({
          type: types.GET_BANK_REQUEST_SUCCESS,
          payload: response2,
        });
      if (response3)
        yield put({
          type: types.GET_BANK_REJECTED_SUCCESS,
          payload: response3,
        });
    } catch (error) {
      yield put({
        type: types.GET_BANK_APPROVED_ERROR,
        payload: error,
      });
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: types.APPROVE_REQUEST_ERROR,
      payload: error,
    });
  }
}

async function approve(data) {
  return await axios.post(`${config.apiUrl}/bankintegration`, data, {
    headers: {
      Authorization: await getUserToken(),
    },
  });
}
