export const messages = {
  login: {
    empty: "You can't leave this empty!",
    invalid_email: "Enter a valid email address!",
    error_summury_1: "Error in continue to login.",
    error_summury_2:
      "Invalid credentials. Please try again or reset your password.",
    invalid_logout: "Logout failed",
  },
};
