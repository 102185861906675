export function readCookie(name) {
  var i,
    c,
    ca,
    nameEQ = name + "=";
  ca = document.cookie.split(";");
  for (i = 0; i < ca.length; i++) {
    c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return "";
}
export function validateEmail(email) {
  var re =
    /^(([^<>()\[\]\\.,:\s@"]+(\.[^<>()\[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!email || !re.test(email)) {
    return false;
  }
  return true;
}

export const asyncForEach = async function (a, fn) {
  for (let t of a) {
    await fn(t);
  }
};

export const forEachAsyncParallel = async function (a, fn) {
  await Promise.all(a.map(fn));
};

export const escapeRegex = word => word && typeof word === 'string' ? word.replace(/[-/\\*+?.()^$|[\]{}]/g, '\\$&') : '';
export const  getBankName = (id , banks ) =>   {
  if(!id || !banks) return;
  const bank = banks.find(bank => bank.id === id);
  if(!bank) return '';
  return bank.name;
}
