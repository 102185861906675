import { types } from "./actions";

const initialState = {
  error: null,
  list: null,
  isLoading: false,
};

const strategies = {
  [types.STOP_LICENCE_REQUEST]: stopLicenceRequest,
  [types.STOP_LICENCE_SUCCESS]: stopLicenceSuccess,
  [types.STOP_LICENCE_ERROR]: stopLicenceError,
  __default__: (state) => ({ ...state, error: null }),
};

export default function reducer(state = initialState, action) {
  const transformer = strategies[action.type]
    ? strategies[action.type]
    : strategies.__default__;
  return transformer(state, action);
}

function stopLicenceRequest(state) {
  return { ...state, isLoading: true, error: null };
}

function stopLicenceSuccess(state) {
  return { ...state, isLoading: false, error: null };
}

function stopLicenceError(state, action) {
  return { ...state, isLoading: false, error: action.payload };
}
