import React from "react";
import { Redirect, Route } from "react-router-dom";
import { routes } from "../utilities/routes";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const user = localStorage.getItem("user");
  return (
    <Route
      {...rest}
      render={(props) => {
        return !!user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }}
    />
  );
};

export const PublicRoute = ({ component: Component, ...rest }) => {
  const user = localStorage.getItem("user");
  return (
    <Route
      {...rest}
      render={(props) =>
        !user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: routes.enterprise,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export const Refresh = ({ path = "/" }) => (
  <Route
    path={path}
    component={({ history, location, match }) => {
      history.replace({
        ...location,
        pathname: location.pathname.substring(match.path.length),
      });
      return null;
    }}
  />
);
