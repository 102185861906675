import React from "react";

import "./AiAssistant.scss";
import AiBirthdayView from "./AiBirthday/AiBirthdayView";
import AiAnniversryView from "./AiAnniversry/AiAnniversery";
import PostCloseNurtureView from "./PostCloseNurture/PostCloseNurtureView";

export class AiAssistant extends React.Component {
  constructor(props) {
    super(props);
    this.birthdayHandeler = this.birthdayHandeler.bind(this);
    this.anniversryHandeler = this.anniversryHandeler.bind(this);
    this.postcloseNurtureHandeler = this.postcloseNurtureHandeler.bind(this);
  }
  state = {
    birthdayView: true,
    AnniversryView: false,
    postcloseNurture: false,
    bgColor1: "#EFF5FC",
    bgColor2: "#ffff",
    bgColor3: "#ffff",
  };
  birthdayHandeler() {
    this.setState({
      AnniversryView: false,
      birthdayView: true,
      postcloseNurture: false,
    });
    this.setState({
      bgColor1: "#EFF5FC",
      bgColor2: "#ffff",
      bgColor3: "#ffff",
    });
  }
  anniversryHandeler() {
    this.setState({
      birthdayView: false,
      AnniversryView: true,
      postcloseNurture: false,
    });
    this.setState({
      bgColor1: "#ffff",
      bgColor2: "#EFF5FC",
      bgColor3: "#ffff",
    });
  }
  postcloseNurtureHandeler() {
    this.setState({
      birthdayView: false,
      AnniversryView: false,
      postcloseNurture: true,
    });
    this.setState({
      bgColor1: "#ffff",
      bgColor2: "#ffff",
      bgColor3: "#EFF5FC",
    });
  }
  render() {
    return (
      <div className="">
        <section
          id="aiAssistantSection"
          className="project-tab bg-white partner-layout w-set task-list-layout d-flex flex-column pt-5 px-3"
        >
          <div className="d-flex justify-content-between flex-column flex-md-row ai-View">
            <div className="d-flex align-items-baseline font-hn-medium">
              <button
                className="btn font-lato-bold"
                onClick={this.birthdayHandeler}
                style={{ backgroundColor: this.state.bgColor1 }}
              >
                Birthday
              </button>
              <button
                className="btn font-lato-bold "
                onClick={this.anniversryHandeler}
                style={{ backgroundColor: this.state.bgColor2 }}
              >
                {" "}
                Anniversary
              </button>
              <button
                className="btn font-lato-bold "
                onClick={this.postcloseNurtureHandeler}
                style={{ backgroundColor: this.state.bgColor3 }}
              >
                {" "}
                Post Close Nurture
              </button>
            </div>
          </div>
        </section>
        {this.state.birthdayView ? (
          <AiBirthdayView />
        ) : this.state.AnniversryView ? (
          <AiAnniversryView />
        ) : (
          <PostCloseNurtureView />
        )}
      </div>
    );
  }
}
