import { types } from "./actions";
const initialState = {
  getEnterpriseRequest: false,
  getEnterpriseSuccess: false,
  getEnterpriseError: null,
  addEnterpriseRequest: false,
  addEnterpriseSuccess: false,
  addEnterpriseError: null,
  updateEnterpriseRequest: false,
  updateEnterpriseSuccess: false,
  updateEnterpriseError: null,
  deleteEnterpriseRequest: false,
  deleteEnterpriseSuccess: false,
  deleteEnterpriseError: null,
  enterpriseList: [],
  totalcount: null,
  enterpriserequest: [],
  approveisSuccess: false,
  approveisfailed: false,
  approvesuccess: null,
  rejectsuccess: null,
  isLoadingapproval: false,
  isLoadingreject: false,
  rejectisSuccess: false,
  rejectisfailed: false,
  billingDate: [],
  allBanks: [],
};

const strategies = {
  [types.GET_ENTERPRISE_REQUEST]: getEnterpriseRequest,
  [types.GET_ENTERPRISE_SUCCESS]: getEnterpriseSuccess,
  [types.GET_ENTERPRISE_ERROR]: getEnterpriseError,

  [types.ADD_ENTERPRISE_REQUEST]: addEnterpriseRequest,
  [types.ADD_ENTERPRISE_SUCCESS]: addEnterpriseSuccess,
  [types.ADD_ENTERPRISE_ERROE]: addEnterpriseError,
  [types.ADD_ENTERPRISE_SUCCESSTOAST]: addEnterpriseSuccesstoast,

  [types.UPDATE_ENTERPRISE_REQUEST]: updateEnterpriseRequest,
  [types.UPDATE_ENTERPRISE_SUCCESS]: updateEnterpriseSuccess,
  [types.UPDATE_ENTERPRISE_ERROE]: updateEnterpriseError,

  [types.DELETE_ENTERPRISE_REQUEST]: deleteEnterpriseRequest,
  [types.DELETE_ENTERPRISE_SUCCESS]: deleteEnterpriseSuccess,
  [types.DELETE_ENTERPRISE_ERROR]: deleteEnterpriseError,

  [types.APPROVE]: approve,
  [types.APPROVE_SUCCESS]: onapprove,
  [types.APPROVE_FAILED]: failapprove,

  [types.REJECT]: reject,
  [types.REJECT_SUCCESS]: onreject,
  [types.REJECT_FAILED]: failreject,

  [types.BILLING_DATE_SUCCESS]: billingdate,
  [types.RESET_BILLING_DATE_SUCCESS]: resetBillingDateSuccess,

  [types.GET_ALL_BANKS_REQUEST]: getAllBanksRequest,
  [types.GET_ALL_BANKS_SUCCESS]: getAllBanksSuccess,

  __default__: (state) => ({ ...state, error: null }),
};

export default function reducer(state = initialState, action) {
  const transformer = strategies[action.type]
    ? strategies[action.type]
    : strategies.__default__;
  return transformer(state, action);
}

function getEnterpriseRequest(state) {
  return {
    ...state,
    getEnterpriseRequest: true,
    getEnterpriseSuccess: false,
    getEnterpriseError: null,
  };
}

function getEnterpriseSuccess(state, action) {
  return {
    ...state,
    totalcount: action.payload.data.count,
    enterpriseList: action.payload.data.data,

    getEnterpriseRequest: false,
    getEnterpriseSuccess: true,
    getEnterpriseError: null,
  };
}

function getEnterpriseError(state, action) {
  return {
    ...state,
    getEnterpriseRequest: false,
    getEnterpriseSuccess: false,
    getEnterpriseError: action.payload.message,
  };
}
function addEnterpriseRequest(state, action) {
  return {
    ...state,
    addEnterpriseRequest: true,
    addEnterpriseSuccess: false,
    addEnterpriseError: null,
  };
}
function addEnterpriseSuccess(state, action) {
  return {
    ...state,
    addEnterpriseRequest: false,
    addEnterpriseSuccess: true,
    addEnterpriseError: null,
  };
}
function addEnterpriseError(state, action) {
  return {
    ...state,
    addEnterpriseRequest: false,
    addEnterpriseSuccess: false,
    addEnterpriseError: action,
  };
}

function addEnterpriseSuccesstoast(state, action) {
  return {
    ...state,
    addEnterpriseSuccess: false,
  };
}
function updateEnterpriseRequest(state, action) {
  return {
    ...state,
    updateEnterpriseRequest: true,
    updateEnterpriseSuccess: false,
    updateEnterpriseError: null,
  };
}
function updateEnterpriseSuccess(state, action) {
  return {
    ...state,

    updateEnterpriseRequest: false,
    updateEnterpriseSuccess: true,
    updateEnterpriseError: null,
  };
}
function updateEnterpriseError(state, action) {
  return {
    ...state,
    updateEnterpriseRequest: false,
    updateEnterpriseSuccess: false,
    updateEnterpriseError: action,
  };
}
function deleteEnterpriseRequest(state, action) {
  return {
    ...state,
    deleteEnterpriseRequest: true,
    deleteEnterpriseSuccess: false,
    deleteEnterpriseError: null,
  };
}
function deleteEnterpriseSuccess(state, action) {
  return {
    ...state,

    deleteEnterpriseRequest: false,
    deleteEnterpriseSuccess: true,
    deleteEnterpriseError: null,
  };
}
function deleteEnterpriseError(state, action) {
  return {
    ...state,
    deleteEnterpriseRequest: false,
    deleteEnterpriseSuccess: false,
    deleteEnterpriseError: action,
  };
}

function approve(state, action) {
  return {
    ...state,
    isLoadingapproval: true,
    approveisfailed: false,
    approveisSuccess: false,
    rejectisfailed: false,
    rejectisSuccess: false,
  };
}

function onapprove(state, action) {
  return {
    ...state,
    approveisSuccess: true,
    isLoadingapproval: false,
    approveisfailed: false,
    approvesuccess: action.payload.msg,
  };
}

function failapprove(state, action) {
  return {
    ...state,
    isLoadingapproval: false,
    approveisfailed: true,
    approveisSuccess: false,
  };
}

function reject(state, action) {
  return {
    ...state,
    isLoadingreject: true,
    rejectisfailed: false,
    rejectisSuccess: false,
    approveisfailed: false,
    approveisSuccess: false,
  };
}

function onreject(state, action) {
  return {
    ...state,
    rejectisSuccess: true,
    isLoadingreject: false,
    rejectisfailed: false,
    rejectsuccess: action,
  };
}

function failreject(state, action) {
  return {
    ...state,
    isLoadingreject: false,
    rejectisfailed: true,
    rejectisSuccess: false,
  };
}

function billingdate(state, action) {
  return {
    ...state,
    billingdate: action.payload,
  };
}

function resetBillingDateSuccess(state, action) {
  const billingdate = [...state.billingdate];
  const data = action.payload;
  const fIndex = billingdate.findIndex(item => item.enterpriseId === data.enterpriseId);
  if(fIndex > -1) {
    billingdate.splice(fIndex, 1, data);
  }
  return {
    ...state,
    billingdate,
  };
}

function getAllBanksRequest(state, action) {
  return {
    ...state,
    allBanks: action.payload,
  };
}

function getAllBanksSuccess(state, action) {
  return {
    ...state,
    allBanks: action.payload,
  };
}