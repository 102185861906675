import * as loginSaga from "./auth/saga";
import * as logoutSaga from "./auth/logoutSaga";
import * as dashboardSaga from "./dashboard/saga";
import * as encompassDetailsSaga from "./encompassDetails/saga";
import * as encompassRequestsSaga from "./encompassRequests/saga";
import * as userListSaga from "./userList/saga";
import * as bankIntegrationSaga from "./bankIntegration/saga";
import * as brokerIntegrationSaga from "./brokerIntegration/saga";
import * as licenceStatusSaga from "./licenseStatus/saga";
import * as aiAssistantSaga from "./aiassistant/saga";
import * as freeAccessSaga from "./FreeAccess/saga";
import * as packagesSaga from "./packages/saga";
import * as enterprise from "./Enterprise/saga";
import * as los from "./Los/saga";
import * as nexusBank from "./simpleNexusBankIntegration/saga";

const sagas = {
  ...loginSaga,
  ...dashboardSaga,
  ...logoutSaga,
  ...encompassDetailsSaga,
  ...encompassRequestsSaga,
  ...userListSaga,
  ...bankIntegrationSaga,
  ...brokerIntegrationSaga,
  ...licenceStatusSaga,
  ...aiAssistantSaga,
  ...freeAccessSaga,
  ...packagesSaga,
  ...enterprise,
  ...los,
  ...nexusBank,
};
export function registerWithMiddleware(middleware) {
  for (let name in sagas) {
    middleware.run(sagas[name]);
  }
}
