import React from "react";
import Modal from "react-responsive-modal";
import "./AddBankIntegration.scss";

const formValid = ({ formErrors, ...rest }) => {
  let valid = true;
  console.log("form errors", formErrors);

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });
  console.log("valid", valid);
  return valid;
};
const bankNameRegex = new RegExp(/^([a-zA-Z]+ ?)+$/);
const idRegex = new RegExp(/^\S*$/);

export default class AddBankIntegration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bankName: "",
      instanceID: "",
      clientId: "",
      email: "",
      phoneNo: "",
      secretKey: "",
      formErrors: {
        bankNameError: "",
        instanceIDError: "",
        clientIdError: "",
        secretKeyError: "",
        emailError: "",
        phoneNoError: "",
      },
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.isLoading &&
      prevProps.isLoading !== this.props.isLoading &&
      !this.props.error
    ) {
      this.props.onClose();
    }
  }
  handleInput = ({ target }) => {
    const { name, value } = target;

    let formErrors = this.state.formErrors;
    switch (name) {
      case "bankName":
        formErrors.bankNameError =
          value.length && bankNameRegex.test(value) ? "" : "Invalid Bank Name";
        break;
      case "instanceID":
        formErrors.instanceIDError =
          value.length && idRegex.test(value) ? "" : "Invalid Instance Id";
        break;
      case "clientId":
        formErrors.clientIdError =
          value.length && idRegex.test(value) ? "" : "Invalid Cient Id";
        break;
      case "secretKey":
        formErrors.secretKeyError = value.length ? "" : "Invalid Secret Key";
        break;
      default:
        break;
    }
    this.setState({ [name]: value, formErrors });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (formValid(this.state)) {
      const { formErrors, ...rest } = this.state;

      this.props.addBank(rest);
    }
  };

  render() {
    return (
      <Modal
        open={this.props.shown}
        onClose={this.props.onClose}
        center
        closeOnOverlayClick={false}
      >
        <div className="px-2 pb-2 addBankModel">
          <div id="addBankmodelHeader" className="modal-header pb-0">
            <h5 className="pt-3  mr-auto font-hn-medium">
              Add Bank Integration
            </h5>
          </div>
          <div className="modal-body font-hn-medium">
            <div className="addBankDetails">
              <div className="row pt-1 addBank-in-head">
                <div className="col-4 font-12">Bank Name</div>
                <div className="col-8 font-12 addBank-in-Input">
                  <input
                    className=""
                    placeholder=""
                    type="text"
                    value={this.state.bankName}
                    name="bankName"
                    onChange={this.handleInput}
                  />
                  {this.state.formErrors.bankNameError && (
                    <small className="justify-content-start error addbankfieldError">
                      {this.state.formErrors.bankNameError}
                    </small>
                  )}
                </div>
              </div>
              <div className="row pt-4 addBank-in-head">
                <div className="col-4 font-12">Instance ID</div>
                <div className="col-8 font-12 addBank-in-Input">
                  <input
                    type="text"
                    value={this.state.instanceID}
                    name="instanceID"
                    onChange={this.handleInput}
                  />
                  {this.state.formErrors.instanceIDError && (
                    <small className="justify-content-start error ad-li-fieldError">
                      {this.state.formErrors.instanceIDError}
                    </small>
                  )}
                </div>
              </div>
              <div className="row pt-4 addBank-in-head">
                <div className="col-4 font-12">Client ID</div>
                <div className="col-8 font-12 addBank-in-Input">
                  <input
                    type="text"
                    value={this.state.clientId}
                    name="clientId"
                    onChange={this.handleInput}
                  />
                  {this.state.formErrors.clientIdError && (
                    <small className="justify-content-start error addbankfieldError">
                      {this.state.formErrors.clientIdError}
                    </small>
                  )}
                </div>
              </div>
              <div className="row pt-4 addBank-in-head">
                <div className="col-4 font-12">Secret Key</div>
                <div className="col-8 font-12 addBank-in-Input">
                  <input
                    type="text"
                    value={this.state.secretKey}
                    name="secretKey"
                    onChange={this.handleInput}
                  />
                  {this.state.formErrors.secretKeyError && (
                    <small className="justify-content-start error addbankfieldError">
                      {this.state.formErrors.secretKeyError}
                    </small>
                  )}
                </div>
              </div>

              <div className="row pt-4 addBank-in-head">
                <div className="col-4 font-12">Email</div>
                <div className="col-8 font-12 addBank-in-Input">
                  <input
                    type="text"
                    value={this.state.email}
                    name="email"
                    onChange={this.handleInput}
                  />
                </div>
              </div>
              <div className="row pt-4 addBank-in-head">
                <div className="col-4 font-12">Phone Number</div>
                <div className="col-8 font-12 addBank-in-Input">
                  <input
                    type="text"
                    value={this.state.phoneNo}
                    name="phoneNo"
                    onChange={this.handleInput}
                  />
                </div>
              </div>
            </div>
            {this.props.error ? (
              <div className="addBAnkErrorInvalid">
                <small className="error">{this.props.error.msg}</small>
              </div>
            ) : (
              <small></small>
            )}
            <div className="d-flex justify-content-between mt-5">
              <button
                className="btn btn-outline-secondary shadow font-12 font-hn-medium px-3 AddBankKeyBtn"
                onClick={this.props.onClose}
              >
                Cancel
              </button>

              <button
                disabled={
                  !this.state.bankName ||
                  !this.state.clientId ||
                  !this.state.instanceID ||
                  !this.state.secretKey ||
                  this.state.formErrors.bankNameError ||
                  this.state.formErrors.clientIdError ||
                  this.state.formErrors.instanceIDError ||
                  this.state.formErrors.secretKeyError
                }
                onClick={this.handleSubmit}
                className="btn btn-primary shadow font-12 font-hn-medium px-3 AddBankKeyBtn"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
