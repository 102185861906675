export const types = {
  GET_ENCOMPASS_REQUESTS_REQUEST:
    "[ENCOMPASS REQUESTS] Get all the encompass requests",
  GET_ENCOMPASS_REQUESTS_SUCCESS:
    "[ENCOMPASS REQUESTS] Get all the encompass requests success",
  GET_ENCOMPASS_REQUESTS_ERROR:
    "[ENCOMPASS REQUESTS] Get all the encompass requests error",
  REJECT_ENCOMPASS_REQUEST_REQUEST:
    "[ENCOMPASS REQUESTS] Reject the encompass requests",
  REJECT_ENCOMPASS_REQUESTS_SUCCESS:
    "[ENCOMPASS REQUESTS] Reject the encompass requests success",
  REJECT_ENCOMPASS_REQUESTS_ERROR:
    "[ENCOMPASS REQUESTS] Reject the encompass requests error",
  INTEGRATE_SECRET_KEY_REQUEST:
    "[ENCOMPASS REQUESTS] Integrate secret key request",
  INTEGRATE_SECRET_KEY_SUCCESS:
    "[ENCOMPASS REQUESTS] Integrate secret key success",
  INTEGRATE_SECRET_KEY_ERROR: "[ENCOMPASS REQUESTS] Integrate secret key error",
  SEND_SECRET_KEY_REQUEST: "[ENCOMPASS REQUESTS] Send secret key request",
  SEND_SECRET_KEY_SUCCESS: "[ENCOMPASS REQUESTS] Send secret key success",
  SEND_SECRET_KEY_ERROR: "[ENCOMPASS REQUESTS] Send secret key error",
  DEFAULT_STATE: "[ENCOMPASS REQUESTS] Default State",
};

export function LoadAllEncompassRequestsRequest() {
  return {
    type: types.GET_ENCOMPASS_REQUESTS_REQUEST,
  };
}

export function LoadAllEncompassRequestsSuccess(data) {
  return {
    type: types.GET_ENCOMPASS_REQUESTS_SUCCESS,
    payload: data,
  };
}
export function LoadAllEncompassRequestsError(error) {
  return {
    type: types.GET_ENCOMPASS_REQUESTS_ERROR,
    payload: error,
  };
}

export function RejectEncompassRequestRequest(uid) {
  return {
    type: types.REJECT_ENCOMPASS_REQUEST_REQUEST,
    payload: uid,
  };
}
export function RejectEncompassRequestSuccess() {
  return {
    type: types.REJECT_ENCOMPASS_REQUESTS_SUCCESS,
  };
}

export const RejectEncompassRequestRequestError = (error) => {
  return {
    type: types.REJECT_ENCOMPASS_REQUESTS_ERROR,
    payload: error,
  };
};

export function IntegrateSecretKeyRequest(data) {
  return {
    type: types.INTEGRATE_SECRET_KEY_REQUEST,
    payload: data,
  };
}

export function IntegrateSecretKeySuccess() {
  return {
    type: types.INTEGRATE_SECRET_KEY_SUCCESS,
  };
}
export function IntegrateSecretKeyError(error) {
  return {
    type: types.INTEGRATE_SECRET_KEY_ERROR,
    payload: error.response.data,
  };
}

export function SendSecretKeyRequest(data) {
  return {
    type: types.SEND_SECRET_KEY_REQUEST,
    payload: data,
  };
}

export function SendSecretKeySuccess() {
  return {
    type: types.GET_ENCOMPASS_REQUESTS_SUCCESS,
  };
}
export function SendSecretKeyError(error) {
  return {
    type: types.SEND_SECRET_KEY_ERROR,
    payload: error.response.data,
  };
}

export function DefaultState() {
  return {
    type: types.DEFAULT_STATE,
  };
}
