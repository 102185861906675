import React from "react";
import Modal from "react-responsive-modal";
const defaultData = {
  borrower_name: "applications[0].borrower.fullName",
  rate: "requestedInterestRatePercent",
  term: "loanAmortizationTermMonths",
  loan_purpose: "property.loanPurposeType",
  loan_to_value: "ltv",
  appraised_value: "propertyAppraisedValueAmount",
  loan_number: "loanNumber",
  lock_date: "loanSubmission.lockDate",
  lock_expiration_date: "loanSubmission.lockExpiresDate",
  closing_date: "loanProductData.loanScheduledClosingDate",
  funded_date: "milestoneFundedDate",
  loan_amount: "borrowerRequestedLoanAmount",
  loan_type: "mortgageType",
  down_payment: "downPayment.amount",
  loan_status: "milestoneCurrentName",
  loan_program: "loanProgramName",
  loan_source: "loanSource",
  created_date: "loanCreatedDateUtc",
  created_new_date: "loanCreatedDateUtc",
  credit_score: "applications[0].borrower.hmdaCreditScoreForDecisionMaking",
  credit_score_co_borr:
    "applications[0].coborrower.hmdaCreditScoreForDecisionMaking",
  milestone: "milestoneCurrentName",
  milestone_orginal: "virtualFields.CoreMilestone",
};

export default class ConfirmationDefault extends React.Component {
  onSubmit = () => {
    this.props.submit();
    this.props.onClose();
  };
  render() {
    const { defaultItem } = this.props;

    return (
      <Modal open={this.props.open} onClose={this.props.onClose} center>
        <div className="p-4 confirmDefaultPopUp">
          <div className="mt-4">
            <h5 className="font-hn-medium font-18"> Default Mapping</h5>
          </div>
          <div className="row mt-4 mb-2  mx-0">
            <div className="col-md-4 px-0">
              <span className="font-14 font-lato-bold">FIELD NAME</span>
            </div>
            <div className="col-md-8 pr-0">
              <span className="font-14 font-lato-bold">MAPPING</span>
            </div>
          </div>
          <div className="confirmContent">
            {defaultItem.map((item, index) => (
              <div className="row border-bottom py-1 mx-0 font-14" key={index}>
                <div className="col-md-4 pl-0 align-items-center d-flex">
                  {item === "borrower_name" && <span>Borrower Name</span>}
                  {item === "rate" && <span>Rate</span>}
                  {item === "term" && <span>Term</span>}
                  {item === "loan_purpose" && <span>Loan Purpose</span>}
                  {item === "appraised_value" && <span>Appraised Value</span>}
                  {item === "loan_number" && <span>Loan Number</span>}
                  {item === "lock_date" && <span>Lock Date</span>}
                  {item === "lock_expiration_date" && (
                    <span>Lock Expiration Date</span>
                  )}
                  {item === "closing_date" && <span>Closing Date</span>}
                  {item === "funded_date" && <span>Funded Date</span>}
                  {item === "loan_amount" && <span>Loan Amount</span>}
                  {item === "loan_type" && <span>Loan Type</span>}
                  {item === "down_payment" && <span>Down Payment</span>}
                  {item === "loan_status" && <span>Loan Status</span>}
                  {item === "loan_program" && <span>Loan Program</span>}
                  {item === "loan_source" && <span>Loan Source</span>}
                  {item === "created_date" && <span>Created Date</span>}
                  {item === "created_new_date" && <span>Created New Date</span>}
                  {item === "credit_score" && <span>Credit Score</span>}
                  {item === "credit_score_co_borr" && (
                    <span>Credit Score Co Borrower</span>
                  )}
                  {item === "milestone" && <span>Milestone</span>}
                  {item === "milestone_orginal" && (
                    <span>Milestone Orginal</span>
                  )}
                  {item === "loan_to_value" && <span>Loan To Value</span>}
                </div>
                <div className="col-md-8 pr-0  align-items-center d-flex">
                  <span>{defaultData[item]}</span>
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex flex-end py-3">
            <button
              className="btn btn-normal font-lato-regular mr-3"
              onClick={() => this.props.onClose()}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary font-lato-regular"
              onClick={() => this.onSubmit()}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}
