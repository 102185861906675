export const types = {
  GET_USERS_LIST_REQUEST: "[USER LIST] Load all slos request",
  GET_USERS_LIST_SUCCESS: "[USER LIST] Load all slos success",
  GET_USERS_LIST_ERROR: "[USER LIST] Load all slos error",
  ACTIVATE_USER_REQUEST: "[USER LIST] Activate slo request",
  ACTIVATE_USER_SUCCESS: "[USER LIST] Activate slo success",
  ACTIVATE_USER_ERROR: "[USER LIST] Activate slo error",
  DEACTIVATE_USER_REQUEST: "[USER LIST] Deactivate slo request",
  DEACTIVATE_USER_SUCCESS: "[USER LIST] Deactivate slo success",
  DEACTIVATE_USER_ERROR: "[USER LIST] Deactivate slo error",

  PAID_TO_FREE_USER_REQUEST: "[USER LIST] paid_to_free slo request",
  PAID_TO_FREE_USER_SUCCESS: "[USER LIST] paid_to_free slo success",
  PAID_TO_FREE_USER_ERROR: "[USER LIST] paid_to_free slo error",
  FREE_TO_PAID_USER_REQUEST: "[USER LIST] free_to_paid slo request",
  FREE_TO_PAID_USER_SUCCESS: "[USER LIST] free_to_paid slo success",
  FREE_TO_PAID_USER_ERROR: "[USER LIST] free_to_paid slo error",

  GET_USERS_LIST_SUCCESSJLO: "[USER LIST] Load all jlos success",
};

export const LoadAllUsersRequest = () => {
  return {
    type: types.GET_USERS_LIST_REQUEST,
  };
};

export const LoadAllUsersSuccess = (list) => {
  return {
    type: types.GET_USERS_LIST_SUCCESS,
    payload: list,
  };
};

export const LoadAllUsersError = (error) => {
  return {
    type: types.GET_USERS_LIST_ERROR,
    payload: error,
  };
};

export const ActivateUserRequest = (uid) => {
  return {
    type: types.ACTIVATE_USER_REQUEST,
    payload: uid,
  };
};

export const ActivateUserSuccess = () => {
  return {
    type: types.ACTIVATE_USER_SUCCESS,
  };
};

export const ActivateUserError = (error) => {
  return {
    type: types.ACTIVATE_USER_ERROR,
    payload: error,
  };
};

export const DeactivateUserRequest = (uid) => {
  return {
    type: types.DEACTIVATE_USER_REQUEST,
    payload: uid,
  };
};

export const DeactivateUserSuccess = () => {
  return {
    type: types.DEACTIVATE_USER_SUCCESS,
  };
};

export const DeactivateUserError = (error) => {
  return {
    type: types.DEACTIVATE_USER_ERROR,
    payload: error,
  };
};

export const PaidToFreeUserRequest = (uid) => {
  return {
    type: types.PAID_TO_FREE_USER_REQUEST,
    payload: uid,
  };
};

export const PaidToFreeUserSuccess = () => {
  return {
    type: types.PAID_TO_FREE_USER_SUCCESS,
  };
};

export const PaidToFreeUserError = (error) => {
  return {
    type: types.PAID_TO_FREE_USER_ERROR,
    payload: error,
  };
};

export const FreeToPaidUserRequest = (uid) => {
  return {
    type: types.FREE_TO_PAID_USER_REQUEST,
    payload: uid,
  };
};

export const FreeToPaidUserSuccess = () => {
  return {
    type: types.FREE_TO_PAID_USER_SUCCESS,
  };
};

export const FreeToPaidUserError = (error) => {
  return {
    type: types.FREE_TO_PAID_USER_ERROR,
    payload: error,
  };
};
