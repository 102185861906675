import auth from "./auth/reducer";
import dashboard from "./dashboard/reducer";
import { combineReducers } from "redux";
import encompassDetails from "./encompassDetails/reducer";
import encompassRequests from "./encompassRequests/reducer";
import userList from "./userList/reducer";
import bankIntegration from "./bankIntegration/reducer";
import brokerIntegration from "./brokerIntegration/reducer";
import licenceStatus from "./licenseStatus/reducer";
import aiAssistant from "./aiassistant/reducer";
import freeAccess from "./FreeAccess/reducer";
import packages from "./packages/reducer";
import enterprise from "./Enterprise/reducer";
import los from "./Los/reducer";
import simpleNexus from "./simpleNexusBankIntegration/reducer";

export default combineReducers({
  auth,
  dashboard,
  encompassDetails,
  encompassRequests,
  userList,
  bankIntegration,
  brokerIntegration,
  licenceStatus,
  aiAssistant,
  freeAccess,
  packages,
  enterprise,
  los,
  simpleNexus,
});
