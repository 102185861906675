import { types } from "./actions";

const initialState = {
  isLoadingList: false,
  error: null,
  list: null,
  isLoading: false,
};

const strategies = {
  [types.LOAD_ALL_BROKERS_REQUEST]: loadBrokersListRequest,
  [types.LOAD_ALL_BROKERS_SUCCESS]: loadBrokersListSuccess,
  [types.LOAD_ALL_BROKERS_ERROR]: loadBrokersListError,
  __default__: (state) => ({ ...state, error: null }),
};

export default function reducer(state = initialState, action) {
  const transformer = strategies[action.type]
    ? strategies[action.type]
    : strategies.__default__;
  return transformer(state, action);
}

function loadBrokersListRequest(state) {
  return { ...state, isLoading: true, error: null };
}

function loadBrokersListSuccess(state, action) {
  return { ...state, isLoading: false, error: null, list: action.payload };
}

function loadBrokersListError(state, action) {
  return { ...state, isLoading: false, error: action.payload };
}
