export const defaultPermissions = {
  "addLoanOfficers": {
    "admin": true,
    "managers": true,
    "teamLeads": true
  },
  "addManagers": true,
  "additionalCredit": {
    "admin": false,
    "loanOfficers": false,
    "managers": false,
    "marketingOfficers": false,
    "teamLeads": false
  },
  "aohLaunchpad": false,
  "companyName": {
    "TeamLeaders": true,
    "admins": true,
    "jlos": true,
    "managers": true
  },
  "contactListDownload": {
    "admins": true,
    "loanOfficers": true,
    "managers": true,
    "marketingOfficers": true,
    "teamLeads": true
  },
  "contactMassAction": {
    "delete": true,
    "dripCampaigns": true,
    "email": true,
    "enable": true,
    "group": true,
    "mergeContacts": true,
    "reasign": true,
    "sms": true,
    "tags": true,
    "voiceMail": true
  },
  "createTeams": {
    "admin": true,
    "managers": true
  },
  "generalEmailTemplate": true,
  "managersBuyCredit": false,
  "markettingScreen": {
    "admin": true,
    "enable": true,
    "loanOfficers": true,
    "managers": true,
    "teamLeads": true
  },
  "milestones": {
    "managers": false,
    "teamLeads": false
  },
  "monthlySub": false,
  "nuture": {
    "loanOfficers": true,
    "managers": true,
    "teamLeads": true
  },
  "partnerMessage": {
    "admin": true,
    "enable": true,
    "loanOfficers": true,
    "managers": true,
    "teamLeads": true
  },
  "requireSSO": {
    "admin": false,
    "loanOfficers": false,
    "managers": false,
    "marketingOfficers": false,
    "teamLeads": false
  },
  "websiteLanding": {
    "enable": true,
    "loanOfficers": true,
    "managers": true,
    "teamLeads": true
  },
  "doc_id": "REPLACE_ME!"
}
