import React from "react";
import Modal from "react-responsive-modal";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "react-datepicker/dist/react-datepicker.css";
import "./SetDatePopUp.scss";
import * as aiListActions from "../../../../store/aiassistant/actions";
class SetDatePopUp extends React.Component {
  state = {
    nurtureDate1: this.props.nurtureDates
      ? this.props.nurtureDates[0]
      : new Date(),
    nurtureDate2: this.props.nurtureDates
      ? this.props.nurtureDates[1]
      : new Date(),
    nurtureDate3: this.props.nurtureDates
      ? this.props.nurtureDates[2]
      : new Date(),
    nurtureDate4: this.props.nurtureDates
      ? this.props.nurtureDates[3]
      : new Date(),
  };
  componentWillUnmount() {
    this.props.getPostCloseDate(this.props.uId);
  }
  selectNurtureDate1 = (date) => {
    this.setState({ nurtureDate1: date });
  };
  selectNurtureDate2 = (date) => {
    this.setState({ nurtureDate2: date });
  };
  selectNurtureDate3 = (date) => {
    this.setState({ nurtureDate3: date });
  };
  selectNurtureDate4 = (date) => {
    this.setState({ nurtureDate4: date });
  };
  render() {
    const { show, onClose, nurtureDates, setNurtureDateLoading, savedata } =
      this.props;
    const { nurtureDate1, nurtureDate2, nurtureDate3, nurtureDate4 } =
      this.state;
    return (
      <Modal open={show} onClose={onClose} center>
        <div className="setDatePopUpMain  my-4">
          <div className="set-date-head my-4 font-lato-bold">
            {nurtureDates ? (
              <span className="font-18 ml-3">EDIT NURTURE DATES</span>
            ) : (
              <span className="font-18 ml-3">SET NURTURE DATES</span>
            )}
          </div>
          <div className="set-date-body mx-3">
            <div className="set-date-content">
              <div className="set-nurture form-group my-3">
                <div>
                  <label className="font-lato-medium  mb-0">
                    NURTURE DATE 01
                  </label>
                </div>
                <div className="cursor-pointer d-flex set-datePicket align-items-center px-3 font-14 font-lato-medium">
                  {}
                  <DatePicker className="d-none" />
                  <DatePicker
                    className="font-lato-medium font-14 cursor-pointer"
                    selected={nurtureDate1}
                    onChange={this.selectNurtureDate1}
                    placeholderText={nurtureDates && nurtureDates.nurtureDate1}
                    dateFormat="MMMM d"
                  />
                  <div className="d-flex flex-end w-100 pr-2">
                    <img
                      src={require("../../../../assets/images/calendar.svg")}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="set-nurture form-group my-3">
                <div>
                  <label className="font-lato-medium mb-0">
                    NURTURE DATE 02
                  </label>
                </div>
                <div className="cursor-pointer d-flex set-datePicket align-items-center px-3 font-14 font-lato-medium">
                  <DatePicker
                    className="cursor-pointer"
                    selected={nurtureDate2}
                    onChange={this.selectNurtureDate2}
                    placeholderText={nurtureDates && nurtureDates.nurtureDate2}
                    dateFormat="MMMM d"
                  />
                  <div className="d-flex flex-end w-100 pr-2">
                    <img
                      src={require("../../../../assets/images/calendar.svg")}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="set-nurture form-group my-3">
                <div>
                  <label className="font-lato-medium mb-0">
                    NURTURE DATE 03
                  </label>
                </div>
                <div className="cursor-pointer d-flex set-datePicket align-items-center px-3 font-14 font-lato-medium">
                  <DatePicker
                    className="cursor-pointer"
                    selected={nurtureDate3}
                    onChange={this.selectNurtureDate3}
                    placeholderText={nurtureDates && nurtureDates.nurtureDate3}
                    dateFormat="MMMM d"
                  />
                  <div className="d-flex flex-end w-100 pr-2">
                    <img
                      src={require("../../../../assets/images/calendar.svg")}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="set-nurture form-group my-3">
                <div>
                  <label className="font-lato-medium mb-0">
                    NURTURE DATE 04
                  </label>
                </div>
                <div className="cursor-pointer d-flex set-datePicket align-items-center px-3 font-14 font-lato-medium">
                  <DatePicker
                    className="cursor-pointer"
                    selected={nurtureDate4}
                    onChange={this.selectNurtureDate4}
                    placeholderText={nurtureDates && nurtureDates.nurtureDate4}
                    dateFormat="MMMM d"
                  />
                  <div className="d-flex flex-end w-100 pr-2">
                    <img
                      src={require("../../../../assets/images/calendar.svg")}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="set-date-action d-flex flex-end w-100">
              {setNurtureDateLoading ? (
                <button
                  disabled
                  className="btn btn-primary font-hn-bold font-12"
                >
                  SAVING...
                </button>
              ) : (
                <button
                  onClick={() =>
                    savedata(
                      nurtureDate1,
                      nurtureDate2,
                      nurtureDate3,
                      nurtureDate4
                    )
                  }
                  className="btn btn-primary font-hn-bold font-12"
                >
                  SAVE
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    uId: state.auth.user ? state.auth.user.uid : null,
    nurtureDates: state.aiAssistant.nurtureDates,
    setNurtureDateLoading: state.aiAssistant.setNurtureDateLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(aiListActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SetDatePopUp);
