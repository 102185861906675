import React from "react";
import AdLicenceModal from "../Modal/AdLicenceModal";
import "./LicenseList.scss";
import SelectPlan from "../Modal/SelectPlan";
import { connect } from "react-redux";
import * as dashboardActions from "../../store/dashboard/actions";
import { bindActionCreators } from "redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import YesOrNo from "react-yes-or-no";

class LicenseList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showComponent: false,
      showSelectPlan: false,
      showRejectPopUp: false,
      userData: {},
      planType: "",
      planValue: null,
      planPeriod: null,
      search: "",
      showMessage: false,
    };
    this._onButtonClick = this._onButtonClick.bind(this);
    this.onAcceptClick = this.onAcceptClick.bind(this);
    this.onRejectClick = this.onRejectClick.bind(this);
    this.reject = this.reject.bind(this);
    this.planSelect = this.planSelect.bind(this);
    this.changeText = this.changeText.bind(this);
    this.addLicense = this.addLicense.bind(this);
  }
  addLicense = (data) => {
    data = { ...data };
    if (data.planPeriod) {
      data = { ...data, requestedPlanPeriod: data.planPeriod };
    }
    this.props.AddLicenseRequest(data);
  };

  notify(text) {
    toast(text, {
      position: "bottom-right",
    });
  }

  changeText({ target }) {
    this.setState({
      search: target.value,
    });
  }
  planSelect(planType, planValue, planPeriod) {
    this.props.SetFreePeriodRequest({
      planType,
      planValue,
      planPeriod,
      id: this.state.userData.doc_id,
      status: "accepted",
    });
  }

  _onButtonClick(e) {
    this.setState({
      showComponent: !this.state.showComponent,
    });
  }
  onAcceptClick(item, index) {
    this.setState({
      showSelectPlan: !this.state.showSelectPlan,
      userData: item,
    });
  }
  onRejectClick(item, index) {
    this.setState({
      showRejectPopUp: !this.state.showRejectPopUp,
      userData: item,
    });
  }
  reject = () =>
    this.props.DeleteLicenseRequest({
      id: this.state.userData.doc_id,
      status: "rejected",
    });

  render() {
    let filterData = this.props.dashboard.list
      ? this.props.dashboard.list.filter(
          (item) =>
            item.status === "pending" &&
            (!this.state.search ||
              (item.firstName + item.lastName)
                .replace(/\s/g, "")
                .toLowerCase()
                .indexOf(this.state.search.replace(/\s/g, "").toLowerCase()) >
                -1 ||
              (item.email &&
                item.email
                  .toLowerCase()
                  .indexOf(this.state.search.toLowerCase()) > -1))
        )
      : [];
    return (
      <div className="bg-white partner-layout w-set task-list-layout d-flex flex-column pt-5 px-3">
        <div className="d-flex justify-content-between pb-2 flex-column flex-md-row">
          <div className="d-flex align-items-baseline font-hn-medium">
            <h4 className="licenseText">License Requests</h4>
            <div className="d-flex en-de-searchView">
              <div id="en-de-searchstyle" className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Name"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  onChange={this.changeText}
                  value={this.state.search}
                />
                <div className="input-group-append">
                  <span className="input-group-text" id="basic-addon2">
                    <img
                      src={require("../../assets/images/searchIcon.svg")}
                      alt="search"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="right-btns d-flex">
            <button
              type="button"
              className="btn btn-primary shadow addLicenceBtn"
              onClick={this._onButtonClick}
            >
              ADD REQUEST
            </button>
            {this.state.showComponent ? (
              <AdLicenceModal
                licenseList={this.props.dashboard.list}
                phoneList={this.props.userPhoneList}
                emailList={this.props.userEmailList}
                shown={this.state.showComponent}
                onClose={this._onButtonClick}
                notify={this.notify}
                addLicense={this.addLicense}
                error={
                  this.props.dashboard.error ? this.props.dashboard.error : null
                }
                isLoading={this.props.dashboard.isLoading}
              />
            ) : null}
          </div>
        </div>
        <div className="table-layout mt-3">
          <table className="table table-striped table-hover">
            <thead className="table-header">
              <tr id="tableHeadRow">
                <th className="licenceTabelHeadText font-hn-medium">
                  User Info
                </th>
                <th className="licenceTabelHeadText font-hn-medium">Phone</th>
                <th className="licenceTabelHeadText font-hn-medium">Bank</th>
                <th className="text-center licenceTabelHeadText font-hn-medium">
                  License Requested
                </th>
                <th className="licenceTabelHeadText actionTh font-hn-medium">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="table-body">
              {filterData.length > 0 ? (
                filterData.map((item, index) => (
                  <tr
                    key={index}
                    className="list-tr   pointer pb-2 pt-2 "
                    id="tableBodyRow"
                  >
                    <td className=" outline-none  data-align pt-4">
                      <div className="tableText font-hn-bold">
                        {item.firstName} {item.lastName}
                      </div>
                      <div className="tableEmailText font-hn-medium">
                        {item.email}
                      </div>
                    </td>

                    <td className="  outline-none data-align pt-4">
                      <div className="tableText">{item.phoneNumber}</div>
                    </td>

                    <td className="  text-capitalize data-align outline-none pt-4">
                      <div className="tableText">{item.bankName}</div>
                    </td>
                    <td className=" outline-none text-capitalize data-align pt-4">
                      <div className="text-center tableText">
                        {item.noOfLicense} {item.planPeriod}
                      </div>
                    </td>
                    <td className="  data-align outline-none pt-3 actionTh px-0">
                      {item.status === "pending" ? (
                        <>
                          <div className="row mx-0">
                            <div className="col-md-6 d-flex justify-content-start">
                              <button
                                onClick={() => this.onAcceptClick(item)}
                                id="aprovrbtnId"
                                className="btn approveBtn font-11 font-hn-medium "
                              >
                                APPROVE
                              </button>
                            </div>

                            <div className="col-md-6 d-flex justify-content-end">
                              <button
                                onClick={() => this.onRejectClick(item)}
                                className="btn rejectBtn font-11 font-hn-medium"
                              >
                                REJECT
                              </button>
                            </div>
                          </div>
                        </>
                      ) : item.status === "rejected" ? (
                        <div className=" d-flex justify-content-end font-12 font-hn-medium">
                          <button id="li-li-rejeBtn">Rejected</button>
                        </div>
                      ) : (
                        <div className=" d-flex justify-content-end font-12 font-hn-medium">
                          <button id="li-li-approveBtn">Approved</button>
                        </div>
                      )}
                    </td>
                    {}
                  </tr>
                ))
              ) : (
                <tr
                  id="preventTRBorder"
                  className="d-flex justify-content-center align-items-center font-16 mt-5"
                >
                  <td id="preventTdBorder">Nothing to Show</td>
                </tr>
              )}

              {this.state.showSelectPlan ? (
                <SelectPlan
                  planSelect={this.planSelect}
                  isLoading={this.props.dashboard.isLoading}
                  shown={this.state.showSelectPlan}
                  userData={this.state.userData}
                  onClose={this.onAcceptClick}
                  notify={this.notify}
                />
              ) : null}
              {this.state.showRejectPopUp ? (
                <YesOrNo
                  show={this.state.showRejectPopUp}
                  onClose={this.onRejectClick}
                  message="Are you sure you want to continue?"
                  confirmText="Yes"
                  cancelText="No"
                  confirmHandler={this.reject}
                  isLoading={this.props.dashboard.isLoading}
                  notify={this.notify}
                  notificationText="Rejected"
                />
              ) : null}
            </tbody>
          </table>
        </div>
        <ToastContainer />
        <div className="d-flex justify-content-center align-items-center mb-0"></div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    dashboard: state.dashboard,
    userEmailList: state.userList.list
      ? state.userList.list.map((item) => item.email)
      : null,
    userPhoneList: state.userList.list
      ? state.userList.list.map((item) => item.phoneNo)
      : null,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(dashboardActions, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(LicenseList);
