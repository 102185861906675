import React from "react";
import "./ApprovedFreeAccount.scss";
import YesOrNo from "react-yes-or-no";
import SelectPlan from "../../Modal/SelectPlan";
export default class ApprovedFreeAccount extends React.Component {
  constructor(props) {
    super(props);
    this.onRejectClick = this.onRejectClick.bind(this);
    this.extendsHandeler = this.extendsHandeler.bind(this);
    this.planSelect = this.planSelect.bind(this);
    this.rejectConfirm = this.rejectConfirm.bind(this);
  }
  state = {
    userData: {},
    showRejectPopUp: false,
    showSelectPlan: false,
  };
  onRejectClick = (list) => {
    this.setState({
      showRejectPopUp: !this.state.showRejectPopUp,
      userData: list,
    });
  };
  rejectConfirm = () => {
    this.props.stop(this.state.userData.uid, this.state.userData.planType);
  };
  extendsHandeler(item, index) {
    this.setState({
      showSelectPlan: !this.state.showSelectPlan,
      userData: item,
    });
  }

  planSelect(planPeriod, planValue) {
    this.props.planSelect(
      this.state.userData.planType,
      planValue,
      planPeriod,
      this.state.userData.doc_id,
      this.state.userData.uid
    );
  }
  render() {
    return (
      <div className="table-layout mt-3">
        <table className="table" cellSpacing="0">
          <thead className="table-header">
            <tr id="ap-fa-tableHeadRow">
              <th className="font-hn-medium ap-fa-table-headText">UserInfo</th>
              <th className="font-hn-medium ap-fa-table-headText">Phone</th>
              <th className="font-hn-medium ap-fa-table-headText">Bank</th>
              <th className="font-hn-medium ap-fa-table-headText">Requested</th>
              <th className="font-hn-medium ap-fa-table-headText">Approved</th>
              <th className="font-hn-medium ap-fa-table-headText">Remaining</th>
              <th className="font-hn-medium ap-fa-table-headText ap-fa-actionTh">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.data.map((list) => {
              return (
                <tr id="ap-fa-tableBodyRow" key={list.doc_id}>
                  <td className="font-hn-medium ap-fa-tableText pt-4">
                    <div className="tableText font-hn-bold">
                      {list.firstName} {list.lastName}
                    </div>
                    <div className="ap-fa-tableEmailText font-hn-medium">
                      {list.email}
                    </div>
                  </td>
                  <td className="font-hn-medium ap-fa-tableText pt-4">
                    {list.phoneNumber}
                  </td>
                  <td className="font-hn-medium ap-fa-tableText pt-4">
                    {list.bankName}
                  </td>
                  <td className="font-hn-medium ap-fa-tableText pt-4">
                    {list.noOfLicense}
                  </td>
                  <td className="font-hn-medium ap-fa-tableText pt-4">
                    {list.approved_planValue}
                  </td>
                  <td className="font-hn-medium ap-fa-tableText pt-4">
                    {list.planValue}
                  </td>
                  {list.uid && list.planValue ? (
                    <td className="ap-fa-actionTh">
                      <div className="row mx-0">
                        <div className="col-md-6 d-flex justify-content-start">
                          <button
                            className="ap-fa-addBtn btn font-11 font-hn-medium"
                            onClick={() => this.extendsHandeler(list)}
                          >
                            EXTEND
                          </button>
                        </div>
                        <div className="col-md-6 d-flex justify-content-end">
                          <button
                            className="ap-fa-stopBtn btn  font-11 font-hn-medium"
                            onClick={() => this.onRejectClick(list)}
                          >
                            STOP
                          </button>
                        </div>
                      </div>
                    </td>
                  ) : !list.planValue ? (
                    <td className="ap-fa-actionTh">
                      <div className="row mx-0 d-flex justify-content-end">
                        <div className="col-md-6 ">
                          {}
                          <button
                            id="btnWithOutBorder"
                            className="font-11 font-hn-medium font-weight-bolder text-danger"
                          >
                            Stopped
                          </button>
                        </div>
                      </div>
                    </td>
                  ) : (
                    <td className="ap-fa-actionTh">
                      <div className="row mx-0 d-flex justify-content-end">
                        <div className="col-md-6">
                          {}
                          <button
                            id="btnWithOutBorder"
                            className="font-11 font-hn-medium font-weight-bolder text-primary"
                          >
                            Awaiting Login
                          </button>
                        </div>
                      </div>
                    </td>
                  )}
                </tr>
              );
            })}
            {this.state.showRejectPopUp ? (
              <YesOrNo
                show={this.state.showRejectPopUp}
                onClose={this.onRejectClick}
                message="Are you sure to Stop?"
                confirmText="Yes"
                cancelText="No"
                confirmHandler={this.rejectConfirm}
                isLoading={this.props.isLoading}
                notify={this.props.notify}
                notificationText={
                  this.props.error
                    ? this.props.error.response.data.msg
                    : "Plan is stopped successfully"
                }
              />
            ) : null}
            {this.state.showSelectPlan ? (
              <SelectPlan
                planSelect={this.planSelect}
                plan={"firstFew"}
                isLoading={this.props.isLoading}
                shown={this.state.showSelectPlan}
                userData={this.state.userData}
                onClose={this.extendsHandeler}
                notify={this.props.notify}
                notificationText={
                  this.props.error
                    ? this.props.error.response.data.msg
                    : "Free Period Extended Successfully"
                }
              />
            ) : null}
          </tbody>
        </table>
      </div>
    );
  }
}
