import React, { Component } from "react";
import "./EncompassDetails.scss";
import Avatar from "react-avatar";
import * as CryptoJS from "crypto-js";
import YesOrNo from "react-yes-or-no";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as encompassDetailsAction from "../../store/encompassDetails/actions";

class EncompassSingleView extends Component {
  state = {
    visibility: true,
    clientIdVisible: true,
    instanceVisible: true,
    secretKeyVisible: true,
    toggle: false,
    decryptedUserData: {},
    showLoanconform: false,
    showMessage: false,
  };
  constructor(props) {
    super(props);
    this.visibilityOnOff = this.visibilityOnOff.bind(this);
    this.clientIDView = this.clientIDView.bind(this);
    this.instanceView = this.instanceView.bind(this);
    this.secretKeyView = this.secretKeyView.bind(this);
    this.decrypt = this.decrypt.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onClose = this.onClose.bind(this);
    this.setMessage = this.setMessage.bind(this);
    this.confirmHandler = this.confirmHandler.bind(this);
  }
  setMessage(message, className) {
    this.setState({
      showMessage: true,
      alertText: message,
      alertClass: className,
    });
  }
  visibilityOnOff() {
    this.setState({ visibility: !this.state.visibility });
  }
  clientIDView() {
    this.setState({ clientIdVisible: !this.state.clientIdVisible });
    this.decrypt();
  }
  instanceView() {
    this.setState({ instanceVisible: !this.state.instanceVisible });
    this.decrypt();
  }
  secretKeyView() {
    this.setState({ secretKeyVisible: !this.state.secretKeyVisible });
    this.decrypt();
  }

  confirmHandler() {
    if (this.props.subscription.subscription_id) {
      this.props.DeleteSubscriptionRequest(this.props.data.uid);
    } else {
      this.props.CreateSubscriptionRequest(this.props.data.uid);
    }
  }
  decrypt() {
    const bytes = CryptoJS.AES.decrypt(
      this.props.data.ciphertext,
      this.props.data.topSecret
    );
    const decryptedUserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    this.setState({
      decryptedUserData: decryptedUserData,
    });
  }
  async handleChange() {
    this.setState({ showLoanconform: !this.state.showLoanconform });
  }
  onClose() {
    this.setState({ showLoanconform: !this.state.showLoanconform });
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.subscription &&
      this.props.subscription.subscription_id !==
        prevProps.subscription.subscription_id
    ) {
      if (
        !this.props.encompassDetails.isLoading &&
        this.props.subscription.subscription_id
      ) {
        this.setMessage(
          "Subscription added successfully",
          "alert alert-success"
        );
      } else if (
        !this.props.encompassDetails.isLoading &&
        !this.props.subscription.subscription_id
      ) {
        this.setMessage(
          "Subscription deleted successfully",
          "alert alert-danger"
        );
      } else if (
        !this.props.encompassDetails.isLoading &&
        this.props.encompassDetails.error
      ) {
        this.setMessage("Something Went Wrong", "alert alert-danger");
      }
    }
  }
  render() {
    let fullName = this.props.data.firstName + " " + this.props.data.lastName;
    return (
      <div className="col-4 en-de-clientView">
        <div className="bg-white partner-layout d-flex flex-column pt-5 px-3">
          <div className="en-de-clientHeadView">
            <div className="en-de-clientHead text-center">
              <div className="en-de-profilePic">
                <Avatar
                  round="50px"
                  color={Avatar.getRandomColor("sitebase", [
                    "red",
                    "green",
                    "blue",
                  ])}
                  name={fullName}
                />
              </div>
              <div className="en-de-profileName font-hn-bold pt-2 text-capitalize">
                {this.props.data.firstName} {this.props.data.lastName}
              </div>
              <div className="en-de-profilePhone font-lato-medium pt-1">
                {this.props.data.phoneNo}
              </div>
            </div>
            <hr />

            <div className="en-de-clientdetails">
              <div className="">
                <div className="row py-2">
                  <div className="col-4 en-de-clientText font-lato-medium">
                    Client ID
                  </div>

                  {!this.state.clientIdVisible ? (
                    <div className="col-7 en-de-clientEncText en-de-clientAlign font-lato-medium">
                      {this.state.decryptedUserData.clientId}
                    </div>
                  ) : (
                    <div className="col-7 en-de-clientText en-de-clientAlign font-lato-medium pt-1">
                      ***********
                    </div>
                  )}

                  <div
                    className="col-1 en-de-clientEncText pl-0"
                    onClick={this.clientIDView}
                  >
                    {this.state.clientIdVisible ? (
                      <svg
                        style={{ marginTop: "-3px" }}
                        width="16.47px"
                        height="9.66px"
                        viewBox="0 0 16 10"
                      >
                        <g
                          id="ADMIN"
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <g
                            id="ADMIN_INTEGRATION"
                            transform="translate(-1363.000000, -350.000000)"
                            fill="#8E8E8E"
                            fillRule="nonzero"
                          >
                            <path
                              d="M1371.14428,351.73747 C1369.26073,351.750659 1367.74443,353.22538 1367.75632,355.032522 C1367.76821,356.839664 1369.3038,358.295895 1371.18738,358.28631 C1373.07095,358.276629 1374.59028,356.804774 1374.58212,354.997613 C1374.57815,354.127505 1374.21341,353.294682 1373.5684,352.683009 C1372.9234,352.071337 1372.05117,351.73112 1371.14428,351.73747 Z M1371.14428,357.331742 C1369.81418,357.318588 1368.74494,356.27712 1368.75129,355.00093 C1368.7577,353.724739 1369.8373,352.69316 1371.16747,352.692247 C1372.49765,352.691336 1373.57878,353.721433 1373.58709,354.997613 C1373.58975,355.619106 1373.33292,356.21575 1372.87394,356.654318 C1372.41495,357.092885 1371.79204,357.336852 1371.14428,357.331742 Z M1378.87065,354.77327 C1377.19403,351.799523 1374.25373,350 1371,350 C1367.74627,350 1364.801,351.799523 1363.1393,354.77327 L1363,354.997613 L1363.12935,355.22673 C1364.80597,358.200477 1367.74627,360 1371,360 C1374.25373,360 1377.199,358.224344 1378.87065,355.22673 L1379,354.997613 L1378.87065,354.77327 Z M1371,359.02148 C1368.199,359.02148 1365.62687,357.522673 1364.13433,354.997613 C1365.62687,352.472554 1368.199,350.973747 1371,350.973747 C1373.801,350.973747 1376.34328,352.477327 1377.8607,354.997613 C1376.34328,357.522673 1373.79602,359.02148 1371,359.02148 Z"
                              id="Shape-Copy-4"
                            ></path>
                          </g>
                        </g>
                      </svg>
                    ) : (
                      <svg
                        style={{ marginTop: "-3px" }}
                        width="17.47px"
                        height="10.66px"
                        viewBox="0 0 33 28"
                      >
                        <g
                          id="Symbols"
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <g
                            id="eye-hide-solid"
                            transform="translate(-2.000000, -4.000000)"
                            fill="#000000"
                            fillRule="nonzero"
                          >
                            <path
                              d="M4.87,5.78 L9.33,10.24 C6.51091908,12.0551122 4.20690139,14.5657683 2.64,17.53 L2.38,18 L2.64,18.48 C6.01,24.71 11.92,28.48 18.46,28.48 C21.0126582,28.4794845 23.5321553,27.9017436 25.83,26.79 L30.83,31.79 L32.58,30.29 L6.58,4.29 L4.87,5.78 Z M13.17,14.08 C11.2382875,16.8042364 11.552648,20.5244341 13.914107,22.885893 C16.2755659,25.247352 19.9957636,25.5617125 22.72,23.63 L24.32,25.23 C22.4677809,26.0217359 20.4743389,26.43 18.46,26.43 C12.83,26.43 7.71,23.29 4.66,18 C6.12370762,15.4082183 8.23172427,13.2382012 10.78,11.7 L13.17,14.08 Z M18.47,7.53 C25.01,7.53 30.92,11.3 34.29,17.53 L34.29,17.53 L34.58,18 L34.32,18.48 C33.0653647,20.790192 31.3660743,22.8293405 29.32,24.48 L29.32,24.48 L27.9,23.06 C29.6679436,21.6535847 31.151156,19.9226046 32.27,17.96 C29.22,12.67 24.1,9.53 18.47,9.53 C17.2451997,9.53565308 16.0258515,9.69353012 14.84,10 L14.84,10 L13.23,8.38 C14.9206626,7.82108598 16.6893519,7.53418027 18.47,7.53 Z M18.37,11.17 C22.1469885,11.17 25.2134826,14.2230476 25.2300417,18 C25.2328738,18.8194115 25.0871851,19.6325574 24.8,20.4 L24.8,20.4 L16,11.6 C16.7580606,11.3165696 17.5606868,11.1709454 18.37,11.17 Z"
                              id="Shape"
                            ></path>
                          </g>
                        </g>
                      </svg>
                    )}
                  </div>
                </div>
              </div>
              <div className="">
                <div className="row py-2">
                  <div className="col-4 en-de-clientText font-lato-medium">
                    Instance ID
                  </div>

                  {!this.state.instanceVisible ? (
                    <div className="col-7 en-de-clientEncText en-de-clientAlign font-lato-medium">
                      {this.state.decryptedUserData.instanceID}
                    </div>
                  ) : (
                    <div className="col-7 en-de-clientText en-de-clientAlign font-lato-medium pt-1">
                      ***********
                    </div>
                  )}
                  <div className="col-1 pl-0" onClick={this.instanceView}>
                    {this.state.instanceVisible ? (
                      <svg
                        style={{ marginTop: "-3px" }}
                        width="16.47px"
                        height="9.66px"
                        viewBox="0 0 16 10"
                      >
                        <g
                          id="ADMIN"
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <g
                            id="ADMIN_INTEGRATION"
                            transform="translate(-1363.000000, -350.000000)"
                            fill="#8E8E8E"
                            fillRule="nonzero"
                          >
                            <path
                              d="M1371.14428,351.73747 C1369.26073,351.750659 1367.74443,353.22538 1367.75632,355.032522 C1367.76821,356.839664 1369.3038,358.295895 1371.18738,358.28631 C1373.07095,358.276629 1374.59028,356.804774 1374.58212,354.997613 C1374.57815,354.127505 1374.21341,353.294682 1373.5684,352.683009 C1372.9234,352.071337 1372.05117,351.73112 1371.14428,351.73747 Z M1371.14428,357.331742 C1369.81418,357.318588 1368.74494,356.27712 1368.75129,355.00093 C1368.7577,353.724739 1369.8373,352.69316 1371.16747,352.692247 C1372.49765,352.691336 1373.57878,353.721433 1373.58709,354.997613 C1373.58975,355.619106 1373.33292,356.21575 1372.87394,356.654318 C1372.41495,357.092885 1371.79204,357.336852 1371.14428,357.331742 Z M1378.87065,354.77327 C1377.19403,351.799523 1374.25373,350 1371,350 C1367.74627,350 1364.801,351.799523 1363.1393,354.77327 L1363,354.997613 L1363.12935,355.22673 C1364.80597,358.200477 1367.74627,360 1371,360 C1374.25373,360 1377.199,358.224344 1378.87065,355.22673 L1379,354.997613 L1378.87065,354.77327 Z M1371,359.02148 C1368.199,359.02148 1365.62687,357.522673 1364.13433,354.997613 C1365.62687,352.472554 1368.199,350.973747 1371,350.973747 C1373.801,350.973747 1376.34328,352.477327 1377.8607,354.997613 C1376.34328,357.522673 1373.79602,359.02148 1371,359.02148 Z"
                              id="Shape-Copy-4"
                            ></path>
                          </g>
                        </g>
                      </svg>
                    ) : (
                      <svg width="17.47px" height="10.66px" viewBox="0 0 33 28">
                        <g
                          id="Symbols"
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <g
                            id="eye-hide-solid"
                            transform="translate(-2.000000, -4.000000)"
                            fill="#000000"
                            fillRule="nonzero"
                          >
                            <path
                              d="M4.87,5.78 L9.33,10.24 C6.51091908,12.0551122 4.20690139,14.5657683 2.64,17.53 L2.38,18 L2.64,18.48 C6.01,24.71 11.92,28.48 18.46,28.48 C21.0126582,28.4794845 23.5321553,27.9017436 25.83,26.79 L30.83,31.79 L32.58,30.29 L6.58,4.29 L4.87,5.78 Z M13.17,14.08 C11.2382875,16.8042364 11.552648,20.5244341 13.914107,22.885893 C16.2755659,25.247352 19.9957636,25.5617125 22.72,23.63 L24.32,25.23 C22.4677809,26.0217359 20.4743389,26.43 18.46,26.43 C12.83,26.43 7.71,23.29 4.66,18 C6.12370762,15.4082183 8.23172427,13.2382012 10.78,11.7 L13.17,14.08 Z M18.47,7.53 C25.01,7.53 30.92,11.3 34.29,17.53 L34.29,17.53 L34.58,18 L34.32,18.48 C33.0653647,20.790192 31.3660743,22.8293405 29.32,24.48 L29.32,24.48 L27.9,23.06 C29.6679436,21.6535847 31.151156,19.9226046 32.27,17.96 C29.22,12.67 24.1,9.53 18.47,9.53 C17.2451997,9.53565308 16.0258515,9.69353012 14.84,10 L14.84,10 L13.23,8.38 C14.9206626,7.82108598 16.6893519,7.53418027 18.47,7.53 Z M18.37,11.17 C22.1469885,11.17 25.2134826,14.2230476 25.2300417,18 C25.2328738,18.8194115 25.0871851,19.6325574 24.8,20.4 L24.8,20.4 L16,11.6 C16.7580606,11.3165696 17.5606868,11.1709454 18.37,11.17 Z"
                              id="Shape"
                            ></path>
                          </g>
                        </g>
                      </svg>
                    )}
                  </div>
                </div>
              </div>
              <div className="">
                <div className="row py-2">
                  <div className="col-4 en-de-clientText font-lato-medium">
                    Secret Key
                  </div>
                  {!this.state.secretKeyVisible ? (
                    <div
                      className="col-7 en-de-clientEncText en-de-clientAlign font-lato-medium"
                      id="en-de-secretOver"
                    >
                      {this.state.decryptedUserData.secretKey}
                    </div>
                  ) : (
                    <div className="col-7 en-de-clientText en-de-clientAlign font-lato-medium pt-1">
                      ***********
                    </div>
                  )}
                  <div className="col-1 pl-0" onClick={this.secretKeyView}>
                    {this.state.secretKeyVisible ? (
                      <svg
                        style={{ marginTop: "-3px" }}
                        width="16.47px"
                        height="9.66px"
                        viewBox="0 0 16 10"
                      >
                        <g
                          id="ADMIN"
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <g
                            id="ADMIN_INTEGRATION"
                            transform="translate(-1363.000000, -350.000000)"
                            fill="#8E8E8E"
                            fillRule="nonzero"
                          >
                            <path
                              d="M1371.14428,351.73747 C1369.26073,351.750659 1367.74443,353.22538 1367.75632,355.032522 C1367.76821,356.839664 1369.3038,358.295895 1371.18738,358.28631 C1373.07095,358.276629 1374.59028,356.804774 1374.58212,354.997613 C1374.57815,354.127505 1374.21341,353.294682 1373.5684,352.683009 C1372.9234,352.071337 1372.05117,351.73112 1371.14428,351.73747 Z M1371.14428,357.331742 C1369.81418,357.318588 1368.74494,356.27712 1368.75129,355.00093 C1368.7577,353.724739 1369.8373,352.69316 1371.16747,352.692247 C1372.49765,352.691336 1373.57878,353.721433 1373.58709,354.997613 C1373.58975,355.619106 1373.33292,356.21575 1372.87394,356.654318 C1372.41495,357.092885 1371.79204,357.336852 1371.14428,357.331742 Z M1378.87065,354.77327 C1377.19403,351.799523 1374.25373,350 1371,350 C1367.74627,350 1364.801,351.799523 1363.1393,354.77327 L1363,354.997613 L1363.12935,355.22673 C1364.80597,358.200477 1367.74627,360 1371,360 C1374.25373,360 1377.199,358.224344 1378.87065,355.22673 L1379,354.997613 L1378.87065,354.77327 Z M1371,359.02148 C1368.199,359.02148 1365.62687,357.522673 1364.13433,354.997613 C1365.62687,352.472554 1368.199,350.973747 1371,350.973747 C1373.801,350.973747 1376.34328,352.477327 1377.8607,354.997613 C1376.34328,357.522673 1373.79602,359.02148 1371,359.02148 Z"
                              id="Shape-Copy-4"
                            ></path>
                          </g>
                        </g>
                      </svg>
                    ) : (
                      <svg width="17.47px" height="10.66px" viewBox="0 0 33 28">
                        <g
                          id="Symbols"
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <g
                            id="eye-hide-solid"
                            transform="translate(-2.000000, -4.000000)"
                            fill="#000000"
                            fillRule="nonzero"
                          >
                            <path
                              d="M4.87,5.78 L9.33,10.24 C6.51091908,12.0551122 4.20690139,14.5657683 2.64,17.53 L2.38,18 L2.64,18.48 C6.01,24.71 11.92,28.48 18.46,28.48 C21.0126582,28.4794845 23.5321553,27.9017436 25.83,26.79 L30.83,31.79 L32.58,30.29 L6.58,4.29 L4.87,5.78 Z M13.17,14.08 C11.2382875,16.8042364 11.552648,20.5244341 13.914107,22.885893 C16.2755659,25.247352 19.9957636,25.5617125 22.72,23.63 L24.32,25.23 C22.4677809,26.0217359 20.4743389,26.43 18.46,26.43 C12.83,26.43 7.71,23.29 4.66,18 C6.12370762,15.4082183 8.23172427,13.2382012 10.78,11.7 L13.17,14.08 Z M18.47,7.53 C25.01,7.53 30.92,11.3 34.29,17.53 L34.29,17.53 L34.58,18 L34.32,18.48 C33.0653647,20.790192 31.3660743,22.8293405 29.32,24.48 L29.32,24.48 L27.9,23.06 C29.6679436,21.6535847 31.151156,19.9226046 32.27,17.96 C29.22,12.67 24.1,9.53 18.47,9.53 C17.2451997,9.53565308 16.0258515,9.69353012 14.84,10 L14.84,10 L13.23,8.38 C14.9206626,7.82108598 16.6893519,7.53418027 18.47,7.53 Z M18.37,11.17 C22.1469885,11.17 25.2134826,14.2230476 25.2300417,18 C25.2328738,18.8194115 25.0871851,19.6325574 24.8,20.4 L24.8,20.4 L16,11.6 C16.7580606,11.3165696 17.5606868,11.1709454 18.37,11.17 Z"
                              id="Shape"
                            ></path>
                          </g>
                        </g>
                      </svg>
                    )}
                  </div>
                </div>
              </div>
              <div className="en-de-subscribe py-2 my-2 font-lato-bold font-18">
                Subscription
              </div>
              {this.state.showMessage ? (
                <div className={this.state.alertClass} role="alert">
                  <small>{this.state.alertText}</small>
                  <small
                    style={{ cursor: "pointer" }}
                    className="float-right close"
                    onClick={() => this.setState({ showMessage: false })}
                  >
                    &times;
                  </small>
                </div>
              ) : null}
              <div className="pb-5 pt-2">
                <div className="row">
                  <div className="col-9 en-de-clientText font-lato subscription">
                    {this.props.subscription.subscription_id
                      ? "Delete Subscription"
                      : "Create Subscription"}
                  </div>
                  <div className="col-3">
                    <button
                      onClick={this.handleChange}
                      className="btn btn-primary shadow font-12 font-hn-medium"
                    >
                      {this.props.subscription.subscription_id
                        ? "Delete"
                        : "Create"}
                    </button>
                  </div>
                </div>
              </div>
              {this.state.showLoanconform ? (
                <YesOrNo
                  show={this.state.showLoanconform}
                  onClose={this.onClose}
                  message={
                    this.props.subscription.subscription_id
                      ? "Are you sure you want to Delete Subscription?"
                      : "Are you sure you want to Create Subscription?"
                  }
                  cancelText="No"
                  confirmText="yes"
                  isLoading={this.props.encompassDetails.isLoading}
                  confirmHandler={this.confirmHandler}
                  loadingText={
                    this.props.subscription.subscription_id
                      ? "Deleting..."
                      : "Creating..."
                  }
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    encompassDetails: state.encompassDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(encompassDetailsAction, dispatch);
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EncompassSingleView);
