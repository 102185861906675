import React from "react";
import "./EncompassList.scss";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SecretKey from "../Modal/SecretKey";
import * as encompassRequestsActions from "../../store/encompassRequests/actions";
import YesOrNo from "react-yes-or-no";
class EncompassList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showComponent: false,
      showReject: false,
      showSendKey: false,
      clientSearch: "",
      instanceSearch: "",
      userData: {},
      key: "",
    };
    this.setKey = this.setKey.bind(this);
    this.integrationHandler = this.integrationHandler.bind(this);
    this.rejectHandler = this.rejectHandler.bind(this);
    this.sendHandler = this.sendHandler.bind(this);
    this.delete = this.delete.bind(this);
    this.integrate = this.integrate.bind(this);
    this.sendKey = this.sendKey.bind(this);
  }
  setKey(value) {
    this.setState({ key: value });
  }
  delete = () => {
    this.props.RejectEncompassRequestRequest(this.state.userData.doc_id);
  };
  integrate = () =>
    this.props.IntegrateSecretKeyRequest({
      instanceID: this.state.userData.instanceID,
      clientId: this.state.userData.clientId,
      uid: this.state.userData.uid,
      secretKey: this.state.key,
      reqType: "admin",
    });
  integrationHandler(item, index) {
    this.props.DefaultState();
    this.setState({
      showComponent: !this.state.showComponent,
      key: "",
    });
    this.setState({ userData: item });
  }
  rejectHandler(item, index) {
    this.setState({
      showReject: !this.state.showReject,
    });
    this.setState({ userData: item });
  }
  sendHandler(item, index) {
    this.props.DefaultState();
    this.setState({
      showSendKey: !this.state.showSendKey,
      key: "",
    });
    this.setState({ userData: item });
  }
  sendKey = () =>
    this.props.SendSecretKeyRequest({
      secretKey: this.state.key,
      uid: this.state.userData.doc_id,
    });
  render() {
    let filteredData = this.props.encompassRequests.list
      ? this.props.encompassRequests.list
          .filter(
            (item) =>
              !this.state.clientSearch ||
              item.clientId
                .toLowerCase()
                .indexOf(this.state.clientSearch.toLowerCase()) > -1
          )
          .filter(
            (item) =>
              !this.state.instanceSearch ||
              item.instanceID
                .toLowerCase()
                .indexOf(this.state.instanceSearch.toLowerCase()) > -1
          )
      : [];
    return (
      <div className="bg-white partner-layout w-set encompasstask-list-layout d-flex flex-column pt-5 px-3">
        <div className="d-flex justify-content-between pb-2 flex-column flex-md-row">
          <div className="d-flex align-items-baseline">
            <h4 className="encompassText">Encompass Requests</h4>
          </div>
        </div>
        <div className="table-layout mt-3">
          <div className="margin-top-14 d-flex  align-items-center justify-content-center font-weight-bold text-black-50 text-center">
            <p></p>
          </div>
          <table className="table table-striped table-hover">
            <thead className="table-header">
              <tr id="encompassHeadRow">
                <th className="list-inline en-co-20">
                  <div className="list-inline-item tableHeadText ">
                    Client ID
                  </div>
                  <div className="list-inline-item">
                    <div className="d-flex en-li-searchView">
                      <div id="en-li-searchstyle" className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search"
                          value={this.state.clientSearch}
                          onChange={(event) =>
                            this.setState({ clientSearch: event.target.value })
                          }
                        />
                        <div className="input-group-append">
                          <span className="input-group-text" id="basic-addon2">
                            <img
                              src={require("../../assets/images/searchIcon.svg")}
                              alt="search"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </th>
                <th className="list-inline en-co-0">
                  <div className="list-inline-item tableHeadText ">
                    Instance ID
                  </div>
                  <div className="list-inline-item">
                    <div className="d-flex en-li-searchView">
                      <div id="en-li-searchstyle" className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Seach"
                          aria-label="search"
                          value={this.state.instanceSearch}
                          onChange={(event) =>
                            this.setState({
                              instanceSearch: event.target.value,
                            })
                          }
                        />
                        <div className="input-group-append">
                          <span className="input-group-text" id="basic-addon2">
                            <img
                              src={require("../../assets/images/searchIcon.svg")}
                              alt="search"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </th>
                <th className="tableHeadText p-19">Phone Number</th>
                <th className="tableHeadText p-19">Email</th>
                <th className="tableHeadText en-co-ActionTh p-19">Action</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {filteredData.length > 0 ? (
                filteredData.map((item, index) => (
                  <tr
                    key={index}
                    className="list-tr   pointer pb-2 pt-2 "
                    id="tableBodyRow"
                  >
                    <td className=" outline-none data-align pt-4 en-co-20">
                      <div className="tableText font-hn-medium">
                        {item.clientId}
                      </div>
                    </td>
                    <td className=" outline-none data-align pt-4 pl-0 en-co-20">
                      <div className="tableText font-hn-medium">
                        {item.instanceID}
                      </div>
                    </td>

                    <td className="outline-none data-align pt-4">
                      <div className=" tableText font-hn-medium">
                        {item.phoneNo}
                      </div>
                    </td>
                    <td className="outline-none data-align pt-4">
                      <div className=" tableText font-hn-medium d-flex">
                        {item.email}
                      </div>
                    </td>
                    <td className="data-align outline-none pt-3 en-co-ActionTh ">
                      {!item.status ? (
                        <>
                          <div className="row">
                            <div className="col-md-4 d-flex justify-content-start">
                              <button
                                className="btn sendBtn font-11 font-hn-medium"
                                onClick={() =>
                                  this.integrationHandler(item, index)
                                }
                              >
                                INTEGRATION
                              </button>
                            </div>
                            <div className="col-md-4 d-flex justify-content-center">
                              <button
                                className="btn sendBtn font-11 font-hn-medium"
                                onClick={() => this.sendHandler(item, index)}
                              >
                                SEND KEY
                              </button>
                            </div>
                            <div className="col-md-4 d-flex justify-content-end">
                              <button
                                className="btn rejBtn font-11 font-hn-medium"
                                onClick={() => this.rejectHandler(item, index)}
                              >
                                REJECT
                              </button>
                            </div>
                          </div>
                        </>
                      ) : item.status === "rejected" ? (
                        <div className=" d-flex justify-content-start font-12 font-hn-medium">
                          <button id="enco-Lis-rejeBtn">Reject</button>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-start font-12 font-hn-medium">
                          <button id="enco-Lis-integrateBtn">Integrated</button>
                        </div>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr
                  id="preventTRBorder"
                  className="d-flex justify-content-center align-items-center font-16 mt-5"
                >
                  <td id="preventTdBorder">Nothing to Show</td>
                </tr>
              )}
            </tbody>
          </table>
          {this.state.showComponent ? (
            <SecretKey
              data={this.state.userData}
              shown={this.state.showComponent}
              onClose={this.integrationHandler}
              setKey={this.setKey}
              heading="ASSIGN SECRET KEY"
              secretKey={this.state.key}
              confirmHandler={this.integrate}
              isLoading={this.props.encompassRequests.isLoading}
              error={this.props.encompassRequests.error}
              confirmText="Integrate"
              loadingText="Integrating..."
            />
          ) : null}
          {this.state.showReject ? (
            <YesOrNo
              show={this.state.showReject}
              onClose={this.rejectHandler}
              message="Are you sure you want to remove?"
              confirmText="Yes"
              cancelText="No"
              confirmHandler={this.delete}
              isLoading={this.props.encompassRequests.isLoading}
              loadingText=""
            />
          ) : null}
          {this.state.showSendKey ? (
            <SecretKey
              data={this.state.userData}
              heading="SEND SECRET KEY"
              shown={this.state.showSendKey}
              onClose={this.sendHandler}
              setKey={this.setKey}
              secretKey={this.state.key}
              confirmHandler={this.sendKey}
              isLoading={this.props.encompassRequests.isLoading}
              error={this.props.encompassRequests.error}
              confirmText="Send Key"
              loadingText="Sending..."
            />
          ) : null}
        </div>
        <div className="d-flex justify-content-center align-items-center mb-0"></div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    encompassRequests: state.encompassRequests,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(encompassRequestsActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EncompassList);
