import { types } from "./actions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const initialState = {
  isLoadingList: false,
  error: null,
  list: null,
  isLoading: false,
  bankJsonList: [],
  bankJsonRequest: false,
  bankJsonSuccess: false,
  bankJsonError: null,
  loanFieldMapingRequest: false,
  loanFieldMapingSuccess: false,
  loanFieldMapingError: null,
};

const strategies = {
  [types.ADD_BANK_REQUEST]: addBankRequest,
  [types.ADD_BANK_SUCCESS]: addBankSuccess,
  [types.ADD_BANK_ERROR]: addBankError,
  [types.LOAD_BANK_INTEGRATION_LIST_REQUEST]: loadIntegrationListRequest,
  [types.LOAD_BANK_INTEGRATION_LIST_SUCCESS]: loadIntegrationListSuccess,
  [types.LOAD_BANK_INTEGRATION_LIST_ERROR]: loadIntegrationListError,
  [types.DELETE_BANK_INTEGRATION_REQUEST]: deleteIntegrationRequest,
  [types.DELETE_BANK_INTEGRATION_SUCCESS]: deleteIntegrationSuccess,
  [types.DELETE_BANK_INTEGRATION_ERROR]: deleteIntegrationError,

  [types.GET_BANK_JSON_REQUEST]: getBankJSONRequest,
  [types.GET_BANK_JSON_SUCCESS]: getBankJsonSuccess,
  [types.GET_BANK_JSON_ERROR]: getBankJsonError,

  [types.SET_LOAN_FIELD_MAPPING_REQUEST]: setloanFieldMapingRequest,
  [types.SET_LOAN_FIELD_MAPPING_SUCCESS]: setloanFieldMapingSuccess,
  [types.SET_LOAN_FIELD_MAPPING_ERROR]: setloanFieldMapingError,

  __default__: (state) => ({ ...state, error: null }),
};

export default function reducer(state = initialState, action) {
  const transformer = strategies[action.type]
    ? strategies[action.type]
    : strategies.__default__;
  return transformer(state, action);
}

function addBankRequest(state) {
  return { ...state, isLoading: true, error: null };
}

function addBankSuccess(state) {
  return { ...state, isLoading: false, error: null };
}

function addBankError(state, action) {
  return { ...state, isLoading: false, error: action.payload };
}
function getBankJSONRequest(state, action) {
  return {
    ...state,
    bankJsonRequest: true,
    bankJsonSuccess: false,
    bankJsonError: null,
  };
}
function getBankJsonSuccess(state, action) {
  console.log("bank json success => ", action.payload.data.data);
  return {
    ...state,
    bankJsonList: action.payload.data.data,
    bankJsonRequest: false,
    bankJsonSuccess: true,
    bankJsonError: null,
  };
}
function getBankJsonError(state, action) {
  return {
    ...state,
    bankJsonRequest: false,
    bankJsonSuccess: false,
    bankJsonError: action,
  };
}
function setloanFieldMapingRequest(state, action) {
  return {
    ...state,
    loanFieldMapingRequest: true,
    loanFieldMapingSuccess: false,
    loanFieldMapingError: null,
  };
}
function setloanFieldMapingSuccess(state, action) {
  toast.success("LOS mapped Successfully", {
    position: "bottom-right",
    autoClose: 2000,
  });
  return {
    ...state,
    loanFieldMapingRequest: false,
    loanFieldMapingSuccess: true,
    loanFieldMapingError: null,
  };
}
function setloanFieldMapingError(state) {
  return {
    ...state,
    loanFieldMapingRequest: false,
    loanFieldMapingSuccess: false,
    loanFieldMapingError: null,
  };
}
function loadIntegrationListRequest(state) {
  return { ...state, isLoading: true, error: null };
}

function loadIntegrationListSuccess(state, action) {
  return { ...state, isLoading: false, error: null, list: action.payload };
}

function loadIntegrationListError(state, action) {
  return { ...state, isLoading: false, error: action.payload };
}

function deleteIntegrationRequest(state) {
  return { ...state, isLoading: true, error: null };
}

function deleteIntegrationSuccess(state) {
  return { ...state, isLoading: false, error: null };
}

function deleteIntegrationError(state, action) {
  return { ...state, isLoading: false, error: action.payload };
}