import { types } from "./actions";
import { call, put, takeLatest, take } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { db } from "../../app/utilities/firebase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function* getPackagesWatcherSaga() {
  yield takeLatest(types.GET_PACKAGES_REQUEST, getPackagesRequest);
}
function* getPackagesRequest(action) {
  try {
    const channel = new eventChannel((emitter) => {
      let listener;
      db.collection("packages").onSnapshot(async () => {
        const requests = [];
        const snap = await db
          .collection("packages")
          .where("uId", "==", action.uId)
          .get();
        snap.forEach(function (doc) {
          requests.push(doc.data());
        });
        emitter(requests);
      });
      return () => {
        listener.off();
      };
    });
    while (true) {
      const data = yield take(channel);
      yield put({
        type: types.GET_PACKAGES_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_PACKAGES_ERROR,
      payload: error,
    });
  }
}

export function* AddPackageWatcherSaga() {
  yield takeLatest(types.ADD_PACKAGE_REQUEST, addPackageRequest);
}
function* addPackageRequest(action) {
  try {
    yield call(addPackage, action.pack);
    yield put({ type: types.ADD_PACKAGE_SUCCESS });
  } catch (error) {
    yield put({ type: types.ADD_PACKAGE_ERROE, payload: error });
  }
}
async function addPackage(action) {
  action.monthlyCharge = parseInt(action.monthlyCharge);
  action.thresholdAccounts = parseInt(action.thresholdAccounts);
  action.additionalLicenseCharge = parseInt(action.additionalLicenseCharge);
  if (
    Number.isInteger(action.thresholdAccounts) &&
    Number.isInteger(action.monthlyCharge) &&
    Number.isInteger(action.additionalLicenseCharge)
  ) {
    let docRef = db.collection("packages").doc();
    const package_id = docRef.id;
    const cretedDate = new Date().toISOString();
    let data = { ...action, package_id, cretedDate, toDelete: true };
    toast.success("Package Added", {
      position: "bottom-right",
      autoClose: 2000,
    });
    return docRef.set(data);
  } else {
    toast.error("Package Adding Failed", {
      position: "bottom-right",
      autoClose: 2000,
    });
  }
}
export function* updatePackageWatcherSaga() {
  yield takeLatest(types.UPDATE_PACKAGE_REQUEST, updatePackageRequest);
}
function* updatePackageRequest(action) {
  try {
    yield call(updatePackage, action.pack, action.package_id);
    yield put({ type: types.UPDATE_PACKAGE_SUCCESS });
  } catch (error) {
    yield put({ type: types.UPDATE_PACKAGE_ERROE, payload: error });
  }
}
async function updatePackage(action, package_id) {
  const cretedDate = new Date().toISOString();
  let data = { ...action, package_id, cretedDate };
  toast.success("Package Modified", {
    position: "bottom-right",
    autoClose: 2000,
  });
  return db.collection("packages").doc(package_id).update(data);
}
export function* deletePackageWatcherSaga() {
  yield takeLatest(types.DELETE_PACKAGE_REQUEST, deletePackageRequest);
}
function* deletePackageRequest(action) {
  try {
    yield call(deletePackage, action.package_id);
    yield put({ type: types.DELETE_PACKAGE_SUCCESS });
    toast.success("Package Deleted", {
      position: "bottom-right",
      autoClose: 2000,
    });
  } catch (error) {
    yield put({ type: types.DELETE_PACKAGE_ERROE, payload: error });
  }
}
async function deletePackage(package_id) {
  return db.collection("packages").doc(package_id).delete();
}
