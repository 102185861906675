import React, { Suspense } from "react";
const LOSView = React.lazy(() => import("./LOSView/LOSView"));
export default class LOSContainer extends React.Component {
  render() {
    return (
      <Suspense
        fallback={
          <h4
            style={{
              color: "black",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Loading...
          </h4>
        }
      >
        <LOSView />
      </Suspense>
    );
  }
}
