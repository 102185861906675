export const types = {
  GET_ENTERPRISE_REQUEST: "[ENTERPRISE PAGE] Get ENTERPRISE request",
  GET_ENTERPRISE_SUCCESS: "[ENTERPRISE PAGE] Get ENTERPRISE success",
  GET_ENTERPRISE_ERROR: "[ENTERPRISE PAGE] Get ENTERPRISE error",

  ADD_ENTERPRISE_REQUEST: "[ENTERPRISE PAGE] Add ENTERPRISE request",
  ADD_ENTERPRISE_SUCCESS: "[ENTERPRISE PAGE] Add ENTERPRISE success",
  ADD_ENTERPRISE_ERROE: "[ENTERPRISE PAGE] Add ENTERPRISE error",
  ADD_ENTERPRISE_SUCCESSTOAST: "[ENTERPRISE PAGE] Add ENTERPRISE success Toast",

  UPDATE_ENTERPRISE_REQUEST: "[ENTERPRISE PAGE] UPDATE ENTERPRISE request",
  UPDATE_ENTERPRISE_SUCCESS: "[ENTERPRISE PAGE] UPDATE ENTERPRISE success",
  UPDATE_ENTERPRISE_ERROE: "[ENTERPRISE PAGE] UPDATE ENTERPRISE error",

  DELETE_ENTERPRISE_REQUEST: "[ENTERPRISE PAGE] DELETE ENTERPRISE request",
  DELETE_ENTERPRISE_SUCCESS: "[ENTERPRISE PAGE] DELETE ENTERPRISE success",
  DELETE_ENTERPRISE_ERROR: "[ENTERPRISE PAGE] DELETE ENTERPRISE error",

  GET_PACKAGE_REQUEST: "[ENTERPRISE PAGE] GET ENTEPRISE request",
  GET_PACKAGE_SUCCESS: "[ENTERPRISE PAGE] GET ENTERPRISE successs",
  GET_PACKAGE_ERROE: "[ENTERPRISE PAGE] GET ENTERPRISE error",

  APPROVE: "[ENTERPRISE PAGE] APPROVE",
  APPROVE_SUCCESS: "[ENTERPRISE PAGE] APPROVE SUCCESS",
  APPROVE_FAILED: "[ENTERPRISE PAGE] APPROVE FAILED",

  REJECT: "[ENTERPRISE PAGE] REJECT",
  REJECT_SUCCESS: "[ENTERPRISE PAGE] REJECT SUCCESS",
  REJECT_FAILED: "[ENTERPRISE PAGE] REJECT FAILED",

  BILLING_DATE: "[ENTERPRISE PAGE] GET BILLING DATE ",
  BILLING_DATE_SUCCESS: "[ENTERPRISE PAGE] BILLING DATE SUCCESS ",
  RESET_BILLING_DATE: "[ENTERPRISE PAGE] POST RESET BILLING DATE",
  RESET_BILLING_DATE_SUCCESS: "[ENTERPRISE PAGE] POST RESET BILLING DATE SUCCESS",

  GET_ALL_BANKS_REQUEST: "[ENTERPRISE PAGE] GET ALL BANKS REQUEST",
  GET_ALL_BANKS_SUCCESS: "[ENTERPRISE PAGE] GET ALL BANKS SUCCESS",
  GET_ALL_BANKS_ERROR: "[ENTERPRISE PAGE] GET ALL BANKS ERROR",

  TOGGLE_BILLING: "[ENTERPRISE PAGE] TOGGLE BILLING"
  
};

export const getEnterprise = (data) => {
  return {
    type: types.GET_ENTERPRISE_REQUEST,
    data: data,
  };
};
export const getEnterpriseSuccess = (data) => {
  return {
    type: types.GET_ENTERPRISE_SUCCESS,
    payload: data,
  };
};
export const getEnterpriseError = (error) => {
  return {
    type: types.GET_ENTERPRISE_ERROR,
    payload: error,
  };
};
export const addEnterpriseRequest = (enterprise) => {
  return {
    type: types.ADD_ENTERPRISE_REQUEST,
    enterprise: enterprise,
  };
};
export const addEnterpriseSuccess = (data) => {
  return {
    type: types.ADD_ENTERPRISE_SUCCESS,
    payload: data,
  };
};
export const addEnterpriseError = (error) => {
  return {
    type: types.ADD_ENTERPRISE_ERROE,
    payload: error,
  };
};
export const addEnterpriseSuccesstoast = () => {
  return {
    type: types.ADD_ENTERPRISE_SUCCESSTOAST,
  };
};
export const updateEnterpriseRequest = (enterprise, doc_id) => {
  return {
    type: types.UPDATE_ENTERPRISE_REQUEST,
    enterprise: enterprise,
    doc_id: doc_id,
  };
};
export const updateEnterpriseSuccess = (data) => {
  return {
    type: types.UPDATE_ENTERPRISE_SUCCESS,
    payload: data,
  };
};
export const updateEnterpriseError = (error) => {
  return {
    type: types.UPDATE_ENTERPRISE_ERROE,
    payload: error,
  };
};

export const deleteEnterpriseRequest = (doc_id) => {
  return {
    type: types.DELETE_ENTERPRISE_REQUEST,
    doc_id: doc_id,
  };
};
export const deleteEnterpriseSuccess = (data) => {
  return {
    type: types.DELETE_ENTERPRISE_SUCCESS,
    payload: data,
  };
};
export const deleteEnterpriseError = (error) => {
  return {
    type: types.DELETE_ENTERPRISE_ERROR,
    payload: error,
  };
};
export const resetBillingDate = (data) => {
  return {
    type: types.RESET_BILLING_DATE,
    payload: data,
  }
};
export const resetBillingDateSuccess = (data) => {
  return {
    type: types.RESET_BILLING_DATE_SUCCESS,
    payload: data,
  };
};

export const onapprove = (data) => {
  return {
    type: types.APPROVE,
    payload: data,
  };
};

export const onreject = (data) => {
  return {
    type: types.REJECT,
    payload: data,
  };
};

export const billingDate = () => {
  return {
    type: types.BILLING_DATE,
  };
};

export const getAllBanks = () => {
  return {
    type: types.GET_ALL_BANKS_REQUEST,
  };
}

export const getAllBanksSuccess = (data) => {
  return {
    type: types.GET_ALL_BANKS_SUCCESS,
    payload: data,
  };
}

export const toggleBilling = (data) => {
  return {
    type: types.TOGGLE_BILLING,
    payload: data
  }
}