import { messages } from "../../app/utilities/messages";
import { types } from "./actions";
import { call, put, takeLatest } from "redux-saga/effects";
import { doSignInWithEmailAndPassword, db } from "../../app/utilities/firebase";

export function* watcherSagaLogin() {
  yield takeLatest(types.REQUEST_LOGIN, workerSagaLogin);
}

function requestLogin(payload) {
  return doSignInWithEmailAndPassword(payload.email, payload.password);
}

async function getUserData(id) {
  const response = await db.collection("users").doc(id).get();
  return response.data();
}

function* workerSagaLogin(action) {
  try {
    const response = yield call(requestLogin, action.payload);

    const loggedInData = response || null;
    if (loggedInData) {
      const userData = yield call(getUserData, loggedInData.user.uid);
      localStorage.setItem("user", JSON.stringify(userData));

      yield put({ type: types.LOGIN_SUCCESS, payload: userData });
    } else {
      yield put({
        type: types.LOGIN_ERROR,
        payload: { message: messages.error_summury_2 },
      });
    }
  } catch (error) {
    yield put({ type: types.LOGIN_ERROR, payload: error });
  }
}
