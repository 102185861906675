export const routes = {
  landing_page: "/",
  login: "/login",
  dashboard: "/dashboard",
  licenceRequests: "/dashboard/licence-requests",
  encompassLists: "/dashboard/encompass-lists",
  encompassDetails: "/dashboard/encompass-details",
  usersList: "/dashboard/users-list",
  bankIntegration: "/dashboard/bank-integration",
  brockerIntegration: "/dashboard/broker-list",
  licenseStatus: "/dashboard/license-status",
  aiAssistant: "/dashboard/ai-assistant",
  freeAccess: "/dashboard/free-access",
  packages: "/dashboard/packages",
  enterprise: "/dashboard/enterprise",
  integrationRequests: "/dashboard/integrationRequests",
  simpleNexusBankManagement: "/dashboard/simpleNexusBankManagement",
  simpleNexusIntegrationRequests: "/dashboard/simpleNexusIntegrationRequests",
};
