import React from "react";
import Modal from "react-responsive-modal";
import ReactJson from "react-json-view";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as bankIntegrationActions from "../../../../store/bankIntegration/actions";
import * as Mark from "mark.js";

class JsonPopUp extends React.Component {
  state = {
    instance: null,
    totalMark: null,
    currentCount: 0,
    currentMarkDiv: null,
    serarchLength: 0,
    name: "",
    typing: false,
    typingTimeout: 0,
  };
  itemSelected = (e) => {
    let yy = [];
    e.namespace &&
      e.namespace.map((path) => {
        if (!isNaN(parseInt(path))) {
          yy.push("[" + path + "]");
        } else {
          yy.push("." + path);
        }
      });
    let myString = (yy + "." + e.name).replace(/,/g, "");
    let str = myString.substr(1);
    this.props.selectField(e.name, this.props.currentSelected, str);
    this.props.onClose();
  };
  searchjson(key) {
    const self = this;

    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }

    self.setState({
      name: key,
      typing: false,
      typingTimeout: setTimeout(function () {
        self.goToSearch(self.state.name);
      }, 2000),
    });
  }
  goToSearch(key) {
    this.setState(
      { serarchLength: key.length },
      () => this.state.serarchLength === 0 && this.setState({ currentCount: 0 })
    );
    if (this.state.instance) {
      this.state.instance.unmark();
    }
    this.setState(
      { instance: new Mark(document.getElementById("jsonViewdailyAi")) },
      () => this.state.instance.mark(key)
    );
    setTimeout(() => {
      this.setState({
        totalMark: document.getElementsByTagName("mark").length,
      });
      this.setState({
        currentMarkDiv:
          document.getElementsByTagName("mark")[this.state.currentCount],
      });
    }, 10);
    setTimeout(() => {
      if (this.state.totalMark !== 0) {
        this.setState({ currentCount: 0 }, () => {
          document.getElementsByTagName("mark")[0].classList.add("currentMark");
        });
        this.setState({
          currentMarkDiv: document.getElementsByTagName("mark")[0],
        });
        var elmnt = document.getElementsByTagName("mark")[0];
        elmnt.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 200);
  }
  searchDown = () => {
    if (this.state.currentCount + 1 !== this.state.totalMark) {
      setTimeout(() => {
        document
          .getElementsByTagName("mark")
          [this.state.currentCount].classList.remove("currentMark");
        this.setState({ currentCount: this.state.currentCount + 1 }, () => {
          document
            .getElementsByTagName("mark")
            [this.state.currentCount].classList.add("currentMark");
        });
        this.setState({
          currentMarkDiv:
            document.getElementsByTagName("mark")[this.state.currentCount],
        });
        var elmnt =
          document.getElementsByTagName("mark")[this.state.currentCount];
        elmnt.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 100);
    }
  };
  searchUp = () => {
    if (this.state.currentCount !== 0) {
      setTimeout(() => {
        document
          .getElementsByTagName("mark")
          [this.state.currentCount].classList.remove("currentMark");
        this.setState({ currentCount: this.state.currentCount - 1 }, () => {
          document
            .getElementsByTagName("mark")
            [this.state.currentCount].classList.add("currentMark");
        });
        this.setState({
          currentMarkDiv:
            document.getElementsByTagName("mark")[this.state.currentCount],
        });
        var elmnt =
          document.getElementsByTagName("mark")[this.state.currentCount];
        elmnt.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 100);
    }
  };
  render() {
    const { getListLoading, jsonList } = this.props;
    const { totalMark, currentCount, serarchLength } = this.state;
    return (
      <Modal open={this.props.shown} onClose={this.props.onClose} center>
        <div className="jsonPopUp">
          <div className="d-flex flex-end jsonPopClose">
            <img
              className="closebtn mt-3 mr-3 cursor-pointer"
              onClick={() => this.props.onClose()}
              src={require("../../../../assets/images/close.svg")}
              alt="x"
            />
          </div>
          <div className="jsonPopUpHead d-flex align-items-center flex-end">
            <div className="input-group mr-4 w-50">
              <div className="d-flex align-items-center ">
                <img
                  src={require("../../../../assets/images/searchIcon.svg")}
                  alt=""
                />
              </div>
              <input
                className="form-control px-0"
                type="text"
                onChange={(event) => this.searchjson(event.target.value)}
                placeholder="Search"
              />
              {serarchLength !== 0 && (
                <div className="d-flex align-items-center">
                  <span className="font-12">
                    {totalMark === 0 ? 0 : currentCount + 1}
                  </span>
                  <span className="font-12">/</span>
                  <span className="font-12">{totalMark}</span>
                  <img
                    className="cursor-pointer"
                    onClick={() => this.searchUp()}
                    src={require("../../../../assets/images/uparrow.svg")}
                    alt=""
                  />
                  <img
                    className="cursor-pointer"
                    onClick={() => this.searchDown()}
                    src={require("../../../../assets/images/downarrow.svg")}
                    alt=""
                  />
                </div>
              )}
            </div>
          </div>

          <div id="jsonViewdailyAi" className="jsonPoupContent">
            {getListLoading ? (
              <div>
                <ReactJson
                  onSelect={(e) => this.itemSelected(e)}
                  displayObjectSize={false}
                  enableClipboard={false}
                  displayDataTypes={false}
                  src={jsonList}
                  name={false}
                />
              </div>
            ) : (
              <div>
                <span>Loading....</span>
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}
function mapStateToProps(state) {
  return {
    getListLoading: state.bankIntegration.bankJsonSuccess,
    jsonList: state.bankIntegration.bankJsonList,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(bankIntegrationActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(JsonPopUp);
