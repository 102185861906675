import React from "react";
import "./UsersList.scss";
import { connect } from "react-redux";
import YesOrNo from "react-yes-or-no";
import * as userListActions from "../../store/userList/actions";
import { bindActionCreators } from "redux";
import { Badge } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class UsersList extends React.Component {
  constructor(props) {
    super(props);
    this.popupHandler = this.popupHandler.bind(this);
    this.makefreeorpaidHandler = this.makefreeorpaidHandler.bind(this);
    this.deactivate = this.deactivate.bind(this);
    this.activate = this.activate.bind(this);
    this.paidtofree = this.paidtofree.bind(this);
    this.freetopaid = this.freetopaid.bind(this);
    this.notify = this.notify.bind(this);
    this.changeText = this.changeText.bind(this);

    this.state = {
      show: false,
      search: "",
      userData: null,
      paidflag: false,
    };
  }

  notify(text) {
    toast(text, {
      position: "bottom-right",
    });
  }

  popupHandler(item, index) {
    this.setState({
      show: !this.state.show,
      userData: item,
    });
  }
  changeText({ target }) {
    this.setState({
      search: target.value,
    });
  }
  makefreeorpaidHandler(item, index) {
    this.setState({
      paidflag: !this.state.paidflag,
      userData: item,
    });
  }

  deactivate() {
    this.props.DeactivateUserRequest(this.state.userData.uid);
  }
  activate() {
    this.props.ActivateUserRequest(this.state.userData.uid);
  }

  paidtofree() {
    this.props.PaidToFreeUserRequest(this.state.userData.uid);
  }
  freetopaid() {
    this.props.FreeToPaidUserRequest(this.state.userData.uid);
  }

  render() {
    let filteredData = this.props.userList.list
      ? this.props.userList.list.filter(
          (item) =>
            !item.isSmallEnterprise &&
            (!this.state.search ||
              (item.firstName + item.lastName)
                .replace(/\s/g, "")
                .toLowerCase()
                .indexOf(this.state.search.replace(/\s/g, "").toLowerCase()) >
                -1 ||
              (item.email &&
                item.email
                  .toLowerCase()
                  .indexOf(this.state.search.toLowerCase()) > -1))
        )
      : [];
    return (
      <div className="us-li-section bg-white partner-layout w-set task-list-layout d-flex flex-column pt-5 px-3">
        <div className="d-flex  pb-2 flex-column flex-md-row">
          <div className="pr-4 pt-1 d-flex align-items-baseline font-hn-medium">
            <h4 className="us-li-headText">List of SLOs</h4>
          </div>

          <div className="d-flex us-li-searchView">
            <div id="us-li-searchstyle" className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search Name"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                value={this.state.search}
                onChange={this.changeText}
              />
              <div className="input-group-append">
                <span className="input-group-text" id="basic-addon2">
                  <img
                    src={require("../../assets/images/searchIcon.svg")}
                    alt="search"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="table-layout mt-3">
          <table className="table table-striped table-hover">
            <thead className="table-header">
              <tr id="us-li-tableHeadRow">
                <th className="font-hn-medium us-li-table-headText">Name s</th>
                <th className="font-hn-medium us-li-table-headText pl-0">
                  Email s
                </th>
                <th className="font-hn-medium us-li-table-headText pl-0">
                  Phone s
                </th>
                <th className="font-hn-medium us-li-table-headText pl-0">
                  Time Zone
                </th>
                <th className="font-hn-medium us-li-table-headText pl-0">
                  {" "}
                  planType
                </th>
                <th className="font-hn-medium us-li-table-headText pl-0">
                  {" "}
                  Status
                </th>
                {}
                <th className="font-hn-medium us-li-table-headText pl-0">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="table-body">
              {filteredData.length > 0 ? (
                filteredData.map((item, index) => {
                  return (
                    <tr key={index} id="us-li-tableBodyRow">
                      <td className="font-hn-medium us-li-tableText pt-4 text-capitalize">
                        {item.firstName} {item.lastName}
                      </td>
                      <td className="font-hn-medium us-li-tableText pt-4 pl-1">
                        {item.email}
                      </td>
                      <td className="font-hn-medium us-li-tableText pt-4 pl-1">
                        {item.phoneNo}
                      </td>
                      <td className="font-hn-medium us-li-tableText pt-4 pl-1">
                        {item.timezone.split(/[\+\-]/)[0]}
                      </td>
                      <td className="font-hn-medium us-li-tableText pt-4 pl-1">
                        {item.planType ? item.planType : "N/A"}
                      </td>
                      <td className="font-hn-medium us-li-tableText pt-4 pl-2">
                        {item.status === "Active" ? (
                          <Badge color="success">Active</Badge>
                        ) : (
                          <Badge color="danger">Inactive</Badge>
                        )}
                      </td>
                      <td className="font-hn-medium pl-0">
                        {item.planType === "paid_user" ? (
                          <button
                            className="btn us-li-deActiveBtn"
                            onClick={() =>
                              this.makefreeorpaidHandler(item, index)
                            }
                          >
                            Make free
                          </button>
                        ) : item.planType === "free_user" ? (
                          <button
                            className="btn us-li-activeBtn"
                            onClick={() =>
                              this.makefreeorpaidHandler(item, index)
                            }
                          >
                            Make paid
                          </button>
                        ) : (
                          <div></div>
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr
                  id="preventTRBorder"
                  className="d-flex justify-content-center align-items-center font-16 mt-5"
                >
                  <td id="preventTdBorder">Nothing to Show</td>
                </tr>
              )}
            </tbody>
          </table>
          {this.state.show ? (
            <YesOrNo
              show={this.state.show}
              onClose={this.popupHandler}
              message={
                this.state.userData.status === "Active"
                  ? "Are you sure you want to deactivate?"
                  : "Are you sure you want to activate?"
              }
              confirmText="Yes"
              cancelText="No"
              confirmHandler={
                this.state.userData.status === "Active"
                  ? this.deactivate
                  : this.activate
              }
              notify={this.notify}
              notificationText={
                this.props.userList.error
                  ? this.props.userList.error.response.data.msg
                  : "Done"
              }
              isLoading={this.props.userList.isLoading}
            />
          ) : null}

          {this.state.paidflag ? (
            <YesOrNo
              show={this.state.paidflag}
              onClose={this.makefreeorpaidHandler}
              message={
                this.state.userData.planType === "paid_user"
                  ? "Are you sure you want to make free?"
                  : "Are you sure you want to make paid?"
              }
              confirmText="Yes"
              cancelText="No"
              confirmHandler={
                this.state.userData.planType === "paid_user"
                  ? this.paidtofree
                  : this.freetopaid
              }
              notify={this.notify}
              notificationText={
                this.props.userList.error
                  ? this.props.userList.error.response.data.msg
                  : "Done"
              }
              isLoading={this.props.userList.isLoading}
            />
          ) : null}
        </div>
        <ToastContainer />
        <div className="d-flex justify-content-center align-items-center mb-0"></div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userList: state.userList,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(userListActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
