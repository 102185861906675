export const types = {
  GET_ENCOMPASS_DETAILS_REQUEST:
    "[ENCOMPASS DETAILS] Get all the encompass details",
  GET_ENCOMPASS_DETAILS_SUCCESS:
    "[ENCOMPASS DETAILS] Get all the encompass details success",
  GET_ENCOMPASS_DETAILS_ERROR:
    "[ENCOMPASS DETAILS] Get all the encompass details error",
  DELETE_SUBSCRIPTION_REQUEST: "[LOAN CONFIRM] Delete subscription request",
  DELETE_SUBSCRIPTION_SUCCESS: "[LOAN CONFIRM] Delete subscription success",
  DELETE_SUBSCRIPTION_ERROR: "[LOAN CONFIRM] Delete subscription error",
  CREATE_SUBSCRIPTION_REQUEST: "[LOAN CONFIRM] create subscription request",
  CREATE_SUBSCRIPTION_SUCCESS: "[LOAN CONFIRM] create subscription success",
  CREATE_SUBSCRIPTION_ERROR: "[LOAN CONFIRM] create subscription error",
};

export function LoadAllEncompassDetails() {
  return {
    type: types.GET_ENCOMPASS_DETAILS_REQUEST,
  };
}

export function LoadAllEncompassDetailsSuccess(data) {
  return {
    type: types.GET_ENCOMPASS_DETAILS_SUCCESS,
    payload: data,
  };
}
export const LoadAllEncompassDetailsError = (error) => {
  return {
    type: types.GET_LICENCE_REQUESTS_ERROR,
    payload: error,
  };
};

export function DeleteSubscriptionRequest(uid) {
  return {
    type: types.DELETE_SUBSCRIPTION_REQUEST,
    payload: uid,
  };
}

export function DeleteSubscriptionSuccess(data) {
  return {
    type: types.DELETE_SUBSCRIPTION_SUCCESS,
  };
}

export const DeleteSubscriptionError = (error) => {
  return {
    type: types.DELETE_SUBSCRIPTION_ERROR,
    payload: error,
  };
};

export function CreateSubscriptionRequest(uid) {
  return {
    type: types.CREATE_SUBSCRIPTION_REQUEST,
    payload: uid,
  };
}

export function CreateSubscriptionSuccess(data) {
  return {
    type: types.CREATE_SUBSCRIPTION_SUCCESS,
  };
}

export const CreateSubscriptionError = (error) => {
  return {
    type: types.CREATE_SUBSCRIPTION_ERROR,
    payload: error,
  };
};
