import { types } from "./actions";
const initialState = {
  freeAccessListLoading: false,
  freeAccessFirstRequest: true,
  freeAccessSucess: false,
  freeAccessError: null,
  freeAccessList: null,
  addedAccesListLoading: false,
  addedAccessSuccess: false,
  addedAccessError: null,
  addedAccessList: null,
  otherAdminsList: null,
  otherAdminsListError: null,
};

const strategies = {
  [types.GET_FREEACCESS_LIST_REQUEST]: getFreeAcessListRequest,
  [types.GET_FREEACCESS_LIST_SUCCESS]: getFreeAcessListSuccess,
  [types.GET_FREEACCESS_LIST_ERROR]: getFreeAcessListError,
  [types.ADD_USER_FREEACCESS_REQUEST]: addAccessRequest,
  [types.ADD_USER_FREEACCESS_SUCCESS]: addedAccessSuccess,
  [types.GET_FREEACCESS_LIST_ERROR]: addedAccessError,
  [types.GET_OTHER_ADMINS_REQUEST]: getOtherAdminsRequest,
  [types.GET_OTHER_ADMINS_SUCCESS]: getOtherAdminsSuccess,
  [types.GET_OTHER_ADMINS_ERROR]: getOtherAdminsError,
  __default__: (state) => state,
};

export default function reducer(state = initialState, action) {
  const transformer = strategies[action.type]
    ? strategies[action.type]
    : strategies.__default__;
  return transformer(state, action);
}

function getFreeAcessListRequest(state) {
  return {
    ...state,
    freeAccessListLoading: true,
    freeAccessError: null,
  };
}
function getFreeAcessListSuccess(state, action) {
  return {
    ...state,
    freeAccessFirstRequest: false,
    freeAccessListLoading: false,
    freeAccessSucess: true,
    freeAccessError: null,
    freeAccessList: action.payload,
  };
}
function getFreeAcessListError(state, action) {
  return {
    ...state,
    freeAccessListLoading: false,
    freeAccessError: action.payload,
  };
}

function addAccessRequest(state) {
  return {
    ...state,
    addedAccesListLoading: true,
    addedAccessSuccess: false,
    addedAccessError: null,
    addedAccessList: null,
  };
}
function addedAccessSuccess(state, action) {
  return {
    ...state,
    addedAccessList: action.payload,
    addedAccessSuccess: true,
    addedAccessError: null,
    addedAccesListLoading: false,
  };
}
function addedAccessError(state, action) {
  return {
    ...state,
    addedAccesListLoading: false,
    addedAccessSuccess: false,
    addedAccessError: action.payload,
  };
}

function getOtherAdminsRequest(state) {
    return { ...state, otherAdminsList: null, otherAdminsListError: null };
}
function getOtherAdminsSuccess(state, action) {
  return {
    ...state,
    otherAdminsList: action.payload,
    otherAdminsListError: null,
  };
}
function getOtherAdminsError(state, action) {
  return {
    ...state,
    otherAdminsList: null,
    otherAdminsListError: action.payload,
  };
}
