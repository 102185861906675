import React from "react";
import Modal from "react-responsive-modal";
import "./AdLicenceModal.scss";

const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });
  return valid;
};

const emailRegex = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const phoneNumberRegex = new RegExp(/^[1-9]{1}[0-9]{9}$/);
const noOfLicenseRegex = new RegExp(/^[0-9]*$/);
const firstNameRegex = new RegExp(/^([a-zA-Z]+ ?)+$/);
const lastNameRegex = new RegExp(/^([a-zA-Z]+ ?)+$/);
const numberOnly = new RegExp(/^[0-9]*$/);

class AdLicenceModal extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.isLoading &&
      prevProps.isLoading !== this.props.isLoading &&
      !this.props.error
    ) {
      this.props.onClose();
      this.props.notify("Added Successfully");
    }
  }
  state = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    noOfLicense: "",
    bankName: "",
    phoneNumberExists: false,
    emailExists: false,
    planSelect: null,
    planPeriod: null,
    formErrors: {
      firstNameError: "",
      lastNameError: "",
      emailError: "",
      phoneNumberError: "",
      noOfLicenseError: "",
      bankNameError: "",
      commonError: "",
    },
  };

  handleInputChange = ({ target }) => {
    const { name, value } = target;
    let formErrors = this.state.formErrors;

    switch (name) {
      case "firstName":
        formErrors.firstNameError = !value.length
          ? "please enter the First Name"
          : value.length && numberOnly.test(value)
          ? "number not allowed"
          : value.length && firstNameRegex.test(value)
          ? ""
          : "invalid";
        break;
      case "lastName":
        formErrors.lastNameError = !value.length
          ? "please enter the Last Name"
          : value.length && numberOnly.test(value)
          ? "number not allowed"
          : value.length && lastNameRegex.test(value)
          ? ""
          : "invalid";
        break;
      case "email":
        formErrors.emailError = !value.length
          ? "please enter the Email"
          : value.length && emailRegex.test(value)
          ? ""
          : "invalid";
        break;
      case "phoneNumber":
        formErrors.phoneNumberError = !value.length
          ? "please enter the phone Number"
          : value.length && !numberOnly.test(value)
          ? "charater not allowed"
          : value.length && phoneNumberRegex.test(value)
          ? ""
          : "invalid";
        break;
      case "noOfLicense":
        formErrors.noOfLicenseError = !value.length
          ? "please enter the No of License"
          : value.length && !numberOnly.test(value)
          ? "charater not allowed"
          : noOfLicenseRegex.test(value) && value.length
          ? ""
          : "Invalid";
        break;
      case "bankName":
        formErrors.bankNameError = value.length
          ? ""
          : "please enter the Bank Name";
        break;
      default:
        break;
    }

    this.setState({
      [name]: value,
      formErrors,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (formValid(this.state)) {
      const { formErrors, phoneNumberExists, emailExists, ...rest } =
        this.state;
      let error = false;
      for (let i = 0; i < this.props.licenseList.length; i++) {
        if (
          !this.props.licenseList[i].status ||
          this.props.licenseList[i].status === "pending" ||
          this.props.licenseList[i].status === ("accepted" || "approved")
        ) {
          if (
            this.props.licenseList[i].email === rest.email ||
            this.props.licenseList[i].phoneNumber.slice(-10) ===
              rest.phoneNumber
          ) {
            error = true;
            this.props.licenseList[i].email === rest.email
              ? this.setState({ emailExists: true })
              : this.setState({ phoneNumberExists: true });
          }
        }
      }
      if (
        this.props.emailList
          ? this.props.emailList.includes(rest.email)
          : null || this.props.phoneList
          ? this.props.phoneList.includes(rest.phoneNumber.slice(-10))
          : null
      ) {
        this.props.emailList.includes(rest.email)
          ? this.setState({ emailExists: true })
          : this.setState({ phoneNumberExists: true });
      } else if (!error) {
        this.setState({ emailExists: false, phoneNumberExists: false });
        this.props.addLicense({ status: "pending", ...rest });
      }
    } else {
      this.setState({
        formErrors: { commonError: "Please check the fields again" },
      });
    }
  };

  render() {
    return (
      <div>
        <Modal
          open={this.props.shown}
          onClose={this.props.onClose}
          center
          closeOnOverlayClick={false}
        >
          <div className="px-2 pb-2 ad-li-maindiv">
            <div id="modelHeader" className="modal-header pb-0">
              <h5 className="pt-3 font-18 font-hn-medium adLicenceHeadText mr-auto ">
                {" "}
                ADD LICENSE REQUEST
              </h5>
            </div>
            <div className="modal-body input-layout">
              <form onSubmit={this.handleSubmit} autoComplete="off">
                <div className="row mx-0 px-0">
                  <div className="col-6 pl-0 ad-li-field">
                    <label className="font-lato-bold font-14">FIRST NAME</label>
                    <input
                      className="form-control input-form-popup inputPopUp"
                      type="text"
                      placeholder="First Name"
                      value={this.state.firstName}
                      onChange={this.handleInputChange}
                      name="firstName"
                      autoComplete="off"
                    />
                    {this.state.formErrors.firstNameError && (
                      <small className="justify-content-start error ad-li-fieldError">
                        {this.state.formErrors.firstNameError}
                      </small>
                    )}
                  </div>
                  <div className="col-6 px-0 ad-li-field">
                    <label className="font-lato-bold font-14">LAST NAME</label>
                    <input
                      className="form-control input-form-popup inputPopUp"
                      type="text"
                      placeholder="Last Name"
                      value={this.state.lastName}
                      onChange={this.handleInputChange}
                      name="lastName"
                      autoComplete="off"
                    />
                    {this.state.formErrors.lastNameError && (
                      <small className="justify-content-start error ad-li-fieldError">
                        {this.state.formErrors.lastNameError}
                      </small>
                    )}
                  </div>
                </div>

                <div className="row mx-0 mt-4 px-0 pt-1">
                  <div className="col-6 pl-0 ad-li-field">
                    <label className="font-lato-bold font-14">EMAIL</label>
                    <input
                      className="form-control input-form-popup inputPopUp"
                      type="email"
                      placeholder="Email"
                      name="email"
                      autoComplete="off"
                      value={this.state.email.toLowerCase()}
                      onChange={this.handleInputChange}
                    />
                    {this.state.formErrors.emailError && (
                      <small className="justify-content-start error ad-li-fieldError">
                        {this.state.formErrors.emailError}
                      </small>
                    )}
                  </div>
                  <div className="col-6 px-0 ad-li-field">
                    <label className="font-lato-bold font-14">
                      PHONE NUMBER
                    </label>
                    <input
                      className="form-control input-form-popup inputPopUp"
                      type="text"
                      maxLength="10"
                      placeholder="Phone Number"
                      name="phoneNumber"
                      autoComplete="off"
                      value={this.state.phoneNumber}
                      onChange={this.handleInputChange}
                    />
                    {this.state.formErrors.phoneNumberError && (
                      <small className="justify-content-start error ad-li-fieldError">
                        {this.state.formErrors.phoneNumberError}
                      </small>
                    )}
                  </div>
                </div>

                <div className="row mx-0 mt-4 px-0 pt-1">
                  <div className="col-6 pl-0 ad-li-field">
                    <label className="font-lato-bold font-14">BANK NAME</label>
                    <input
                      className="form-control input-form-popup inputPopUp"
                      type="text"
                      placeholder="Bank Name"
                      name="bankName"
                      autoComplete="off"
                      value={this.state.bankName}
                      onChange={this.handleInputChange}
                    />
                    {this.state.formErrors.bankNameError && (
                      <small className="justify-content-start error ad-li-fieldError">
                        {this.state.formErrors.bankNameError}
                      </small>
                    )}
                  </div>
                  {}

                  <div className="col-6 px-0 ad-li-field">
                    <label className="font-lato-bold font-14">
                      SELECT PLAN
                    </label>
                    <select
                      className="custom-select"
                      defaultValue={"DEFAULT"}
                      name="planSelect"
                      onChange={this.handleInputChange}
                    >
                      <option
                        className="font-lato-bold selectplanOption"
                        value="DEFAULT"
                        disabled
                      >
                        Select Plan
                      </option>
                      <option
                        className="font-lato-bold selectplanOption"
                        value="freeAccount"
                      >
                        Free Account
                      </option>
                      <option
                        className="font-lato-bold selectplanOption"
                        value="freePeriod"
                      >
                        Free Period
                      </option>
                    </select>
                  </div>
                </div>

                {this.state.planSelect === "freeAccount" ? (
                  <div className="row mx-0 mt-4 px-0 pt-1">
                    <div className="col-6 pl-0 ad-li-field">
                      <label className="font-lato-bold font-14">LICENSE</label>
                      <input
                        className="form-control input-form-popup inputPopUp"
                        type="text"
                        placeholder="No.of License"
                        name="noOfLicense"
                        autoComplete="off"
                        maxLength="10"
                        value={this.state.noOfLicense}
                        onChange={this.handleInputChange}
                      />
                      {this.state.formErrors.noOfLicenseError && (
                        <small className="justify-content-start ad-li-fieldError error">
                          {this.state.formErrors.noOfLicenseError}
                        </small>
                      )}
                    </div>
                  </div>
                ) : this.state.planSelect === "freePeriod" ? (
                  <div className="row mx-0 mt-4 px-0 pt-1">
                    <div className="col-12 pl-0 ad-li-field">
                      <label className="font-lato-bold font-14">DURATION</label>
                      <div className="d-flex">
                        <input
                          id="ad-li-PeriodInput"
                          className="form-control input-form-popup inputPopUp"
                          type="text"
                          placeholder="Duration"
                          name="noOfLicense"
                          autoComplete="off"
                          maxLength="10"
                          value={this.state.noOfLicense}
                          onChange={this.handleInputChange}
                        />
                        {this.state.formErrors.noOfLicenseError && (
                          <small className="justify-content-start ad-li-fieldError error">
                            {this.state.formErrors.noOfLicenseError}
                          </small>
                        )}
                        <div>
                          <select
                            className="custom-select"
                            defaultValue={"DEFAULT"}
                            name="planPeriod"
                            onChange={this.handleInputChange}
                          >
                            <option
                              className="font-lato-bold"
                              value="DEFAULT"
                              disabled
                            >
                              Select
                            </option>
                            <option className="font-lato-bold" value="days">
                              DAYS
                            </option>
                            <option className="font-lato-bold" value="weeks">
                              WEEK
                            </option>
                            <option className="font-lato-bold" value="months">
                              MONTHS
                            </option>
                            <option className="font-lato-bold" value="years">
                              YEARS
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div>
                  {this.state.emailExists || this.props.error ? (
                    <small className="justify-content-start  error ad-li-already">
                      Email already exists!
                    </small>
                  ) : this.state.phoneNumberExists ? (
                    <small className="justify-content-start error ad-li-already">
                      Phone number already exists!
                    </small>
                  ) : null}
                </div>
                <div
                  id="footer"
                  className="mt-4 pt-2 d-flex justify-content-end"
                >
                  <button
                    type="button"
                    className="btn btn-outline-secondary font-12 font-hn-medium px-3 mr-4"
                    onClick={this.props.onClose}
                    disabled={this.props.isLoading}
                  >
                    CANCEL
                  </button>
                  {!this.props.isLoading ? (
                    <button
                      type="submit"
                      className="btn btn-primary shadow font-12 font-hn-medium px-3"
                      disabled={
                        !this.state.bankName ||
                        !this.state.email ||
                        !this.state.firstName ||
                        !this.state.lastName ||
                        !this.state.noOfLicense ||
                        !this.state.phoneNumber ||
                        this.state.formErrors.bankNameError ||
                        this.state.formErrors.emailError ||
                        this.state.formErrors.firstNameError ||
                        this.state.formErrors.lastNameError ||
                        this.state.formErrors.phoneNumberError ||
                        this.props.isLoading
                      }
                    >
                      ADD
                    </button>
                  ) : (
                    <button className="btn btn-primary shadow font-12 font-hn-medium px-3">
                      Adding..
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default AdLicenceModal;
