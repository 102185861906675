import { types } from "./actions";
import { call, put, takeLatest, take } from "redux-saga/effects";
import { mongo } from "../../app/utilities/firebase";
import { db, config, getUserToken } from "../../app/utilities/firebase";
import axios from "axios";
import { eventChannel } from "redux-saga";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { defaultPermissions } from "../../shared/defaults";

let params;
function sleep(duration) {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(), duration);
  });
}
export function* getEnterpriseRequestWatcherSaga() {
  yield takeLatest(
    types.GET_ENTERPRISE_REQUEST,
    getEnterpriseRequestWorkerSaga
  );
}

function* getEnterpriseRequestWorkerSaga(action) {
  try {
    params = action.data;
    const response = yield call(getEnterprise, action.data);
    if (response)
      yield put({
        type: types.GET_ENTERPRISE_SUCCESS,
        payload: response,
      });
  } catch (error) {
    yield put({
      type: types.GET_ENTERPRISE_ERROR,
      payload: error,
    });
  }
}

const getEnterprise = async (data) => {
  return await axios.post(
    `${mongo.mongoUrl}/v0.1/enterprises/listEnterprises`,
    data,
    {
      headers: {
        Authorization: await getUserToken()
      },
    }
  );
};

export function* AddEnterpriseWatcherSaga() {
  yield takeLatest(types.ADD_ENTERPRISE_REQUEST, addEnterpriseRequest);
}

function* addEnterpriseRequest(action) {
  try {
    yield call(addEnterprise, action.enterprise);

    yield put({ type: types.ADD_ENTERPRISE_SUCCESS });

    try {
      const response = yield call(getEnterprise, params);
      if (response)
        yield put({
          type: types.GET_ENTERPRISE_SUCCESS,
          payload: response,
        });
    } catch (error) {
      yield put({
        type: types.GET_ENTERPRISE_ERROR,
        payload: error,
      });
    }
  } catch (error) {
    yield put({ type: types.ADD_ENTERPRISE_ERROE, payload: error });
  }
}

async function checkEmail(action) {
  const requests = [];
  const snap = await db
    .collection("enterprises")
    .where("enterpriseAdminEmail", "==", action)
    .get();
  snap.forEach(function (doc) {
    requests.push(doc.data());
  });
  return requests;
}

async function* addEnterprise(action) {
  let loop = 1;
  let docRef = db.collection("enterprises").doc();
  const doc_id = docRef.id;
  const created_date = new Date().toISOString();
  let data = {
    ...action,
    doc_id,
    created_date,
    updated_date: created_date,
  };

  try {
    const userRef = await checkEmail(action.enterpriseAdminEmail);

    if (!userRef.length) {
      docRef.set(data);
      let permissions = defaultPermissions
      permissions.doc_id = data.doc_id;
      await db.collection('permissions').doc(data.doc_id).set(permissions);
      toast.success("Enterprise Added ", {
        position: "bottom-right",
        autoClose: 2000,
      });
    } else {
      toast.error("Enterprise Adding Failed, Email already exist ", {
        position: "bottom-right",
        autoClose: 2000,
      });
    }
  } catch (e) {
    toast.error("Enterprise Adding Failed ", {
      position: "bottom-right",
      autoClose: 2000,
    });
  }
}
export function* updateEnterpriseWatcherSaga() {
  yield takeLatest(types.UPDATE_ENTERPRISE_REQUEST, updateEnterpriseRequest);
}
function* updateEnterpriseRequest(action) {
  try {
    yield call(updateEnterprise, action.enterprise, action.doc_id);

    yield put({ type: types.UPDATE_ENTERPRISE_SUCCESS });
    toast.success("Enterprise Modified", {
      position: "bottom-right",
      autoClose: 2000,
    });
    try {
      yield sleep(3000);
      const response = yield call(getEnterprise, params);
      if (response)
        yield put({
          type: types.GET_ENTERPRISE_SUCCESS,
          payload: response,
        });
    } catch (error) {
      yield put({
        type: types.GET_ENTERPRISE_ERROR,
        payload: error,
      });
    }
  } catch (error) {
    yield put({ type: types.UPDATE_ENTERPRISE_ERROE, payload: error });
  }
}

async function updateEnterprise(action, doc_id) {
  const updated_date = new Date().toISOString();
  let data = { ...action, doc_id, updated_date };

  return db.collection("enterprises").doc(doc_id).set(data, { merge: true });
}

export function* deleteEnterpriseWatcherSaga() {
  yield takeLatest(types.DELETE_ENTERPRISE_REQUEST, deleteEnterpriseRequest);
}
function* deleteEnterpriseRequest(action) {
  try {
    yield call(deleteEnterprise, action.doc_id);
    yield call(getEnterprise, params);
    yield put({ type: types.DELETE_ENTERPRISE_SUCCESS });

    toast.success("Enterprise Deleted", {
      position: "bottom-right",
      autoClose: 2000,
    });
  } catch (error) {
    toast.error(`Enterprise Delete Failed: \n${error}`, {
      position: "bottom-right",
      autoClose: 2000,
    });
    yield put({ type: types.DELETE_ENTERPRISE_ERROR, payload: error });
  }
}

const deleteEnterprise = async (doc_id) => {
  return await axios.post(
    `${config.apiUrl}/deleteEnterpriseCompletely`,
    { enterpriseId: doc_id },
  );
};

export function* actionWatcher() {
  yield takeLatest(types.APPROVE, WorkerSaga);
}

function* WorkerSaga(action) {
  try {
    const response = yield call(approveEnterprise, action.payload);
    if (response) {
      toast.success(
        "Enterprise Request Approved. Account Activation Mail Sent",
        {
          position: "bottom-right",
          autoClose: 2000,
        }
      );
      yield put({ type: types.APPROVE_SUCCESS, payload: response.data });
    }
    try {
      yield sleep(3000);
      const response = yield call(getEnterprise, params);
      if (response)
        yield put({
          type: types.GET_ENTERPRISE_SUCCESS,
          payload: response,
        });
    } catch (error) {
      yield put({
        type: types.GET_ENTERPRISE_ERROR,
        payload: error,
      });
    }
  } catch (error) {
    yield put({ type: types.APPROVE_FAILED, payload: error });
    toast.error("Approve Failed", {
      position: "bottom-right",
      autoClose: 2000,
    });
  }
}

const approveEnterprise = async (data) => {
  return await axios.post(
    `${config.apiUrl}/approve_enterprise_request`,
    {
      enterpriseId: data,
    },
    {
      headers: {
        Authorization: await getUserToken()
      },
    }
  );
};

export function* rejectWatcher() {
  yield takeLatest(types.REJECT, rejectWorkerSaga);
}

function* rejectWorkerSaga(action) {
  try {
    let data = { status: "Rejected" };
    db.collection("enterprises").doc(action.payload).update(data);
    toast.success("Enterprise Request Rejected", {
      position: "bottom-right",
      autoClose: 2000,
    });
    yield put({ type: types.REJECT_SUCCESS, payload: "Rejected" });
    try {
      yield sleep(3000);
      const response = yield call(getEnterprise, params);
      if (response)
        yield put({
          type: types.GET_ENTERPRISE_SUCCESS,
          payload: response,
        });
    } catch (error) {
      yield put({
        type: types.GET_ENTERPRISE_ERROR,
        payload: error,
      });
    }
  } catch (error) {
    yield put({ type: types.REJECT_FAILED, payload: "Failed" });
    toast.error(error, {
      position: "bottom-right",
      autoClose: 2000,
    });
  }
}

export function* BillWatcherSaga() {
  yield takeLatest(types.BILLING_DATE, BillWorkerSaga);
}

function* BillWorkerSaga() {
  const response = yield call(Getdate);
  if (response) {
    yield put({ type: types.BILLING_DATE_SUCCESS, payload: response });
  }
}
async function Getdate() {
  const citiesRef = db.collection("billing_admins");
  const snapshot = await citiesRef.get();
  let billData = [];
  if (snapshot.empty) {
    return;
  }
  snapshot.forEach(function (doc) {
    billData.push(doc.data());
  });
  return billData;
}

export function* ResetBillingWatcherSaga() {
  yield takeLatest(types.RESET_BILLING_DATE, resetBillingDate);
}
function* resetBillingDate(action) {
  try {
    const params = {
      enterpriseId: action.payload
    };
    const { data } = yield call(resetBillingDateApi, params);
    if(data) {
      const { data: updatedData } = data;
      if(updatedData) {
        yield put({
          type: types.RESET_BILLING_DATE_SUCCESS,
          payload: updatedData
        });
        toast.success("Billing has been reset", {
          position: "bottom-right",
          autoClose: 2000,
        });
      }
    }
  } catch (error) {
    toast.error("Billing has failed to reset", {
      position: "bottom-right",
      autoClose: 2000,
    });
  }
}
async function resetBillingDateApi(params) {
  const response = await axios.post(`${config.apiUrl}/reset_billing_date`, params, {
    headers: {
      Authorization: await getUserToken(),
    },
  });
  return response;
}


export function* getAllBankWatcherSaga() {
  yield takeLatest(types.GET_ALL_BANKS_REQUEST, getAllBanksWorkerSaga);
}

function* getAllBanksWorkerSaga() {
  try {
    const response = yield call(getAllBanks);
    if (response) {
      yield put({ type: types.GET_ALL_BANKS_SUCCESS, payload: response });
    }
  } catch (error) {
    yield put({ type: types.GET_ALL_BANKS_ERROR, payload: error });
  }
} 

async function getAllBanks() {
  const citiesRef = db.collection("bank_integration");
  const snapshot = await citiesRef.get();
  let bankData = [];
  if (snapshot.empty) {
    return;
  }
  snapshot.forEach(function (doc) {
    bankData.push(doc.data());
  });

  const bankDataOnlyIdAndName = bankData.map((bank) => {
    return {
      id: bank.bankId,
      name: bank.bankName,
    };
  });
  return bankDataOnlyIdAndName;
}

async function toggleBillingApi(params) {
  const response = await axios.post(`${config.apiUrl}/toggleBillingActivation`, params, {
    headers: {
      Authorization: await getUserToken(),
    },
  });
  return response;
}

function* toggleBilling(action) {
  const tId = toast.info('Please Wait...', {
    position: "bottom-right",
    autoClose: 2000,
  });
  const { payload } = action;
  const { data } = yield call(toggleBillingApi, payload);
  yield sleep(2000);
  yield put({
    type:types.GET_ENTERPRISE_REQUEST,
    data: payload.pageFetchParams
  })
  toast.dismiss(tId);
  toast.info(data.message, {
    position: "bottom-right",
    autoClose: 5000,
  });
}

export function* ToggleBillingWatcherSaga(){
  yield takeLatest(types.TOGGLE_BILLING,toggleBilling)
}

