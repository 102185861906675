import { types } from "./actions";
import { call, put, takeLatest, take } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { db, config, getUserToken } from "../../app/utilities/firebase";
import axios from "axios";

export function* LoadUserListWatcherSaga() {
  yield takeLatest(types.GET_USERS_LIST_REQUEST, LoadUserListWorkerSaga);
}

function* LoadUserListWorkerSaga() {
  try {
    const channel = new eventChannel((emitter) => {
      let listener;
      db.collection("users").onSnapshot(async () => {
        const requests = [];
        const snap = await db
          .collection("users")
          .where("userType", "==", "SLO")
          .get();
        snap.forEach(function (doc) {
          requests.push(doc.data());
        });
        emitter(
          requests
            .sort((a, b) => {
              if (a.firstName == undefined) {
                console.log(a.uid);
              }
              if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
                return 1;
              }
              if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
                return -1;
              }
              return 0;
            })
            .sort((item) => (item.status ? -1 : 1))
        );
      });
      return () => {
        listener.off();
      };
    });
    const channeljlo = new eventChannel((emitter) => {
      let listenerjlo;
      db.collection("users").onSnapshot(async () => {
        const requests = [];
        const snap = await db
          .collection("users")
          .where("userType", "==", "JLO")
          .get();
        snap.forEach(function (doc) {
          requests.push(doc.data());
        });
        emitter(
          requests
            .sort((a, b) => {
              if (a.firstName == undefined) {
                console.log(a.uid);
              }
              if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
                return 1;
              }
              if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
                return -1;
              }
              return 0;
            })
            .sort((item) => (item.status ? -1 : 1))
        );
      });
      return () => {
        listenerjlo.off();
      };
    });
    while (true) {
      const data = yield take(channel);
      const datajlo = yield take(channeljlo);
      yield put({ type: types.GET_USERS_LIST_SUCCESS, payload: data });
      yield put({ type: types.GET_USERS_LIST_SUCCESSJLO, payload: datajlo });
    }
  } catch (error) {
    yield put({
      type: types.GET_USERS_LIST_ERROR,
      payload: error,
    });
  }
}

export function* ActivateUserRequestWatcherSaga() {
  yield takeLatest(types.ACTIVATE_USER_REQUEST, ActivateUserRequestWorkerSaga);
}

function* ActivateUserRequestWorkerSaga(action) {
  try {
    const response = yield call(ActivateUser, action.payload);
    if (response)
      yield put({
        type: types.ACTIVATE_USER_SUCCESS,
      });
  } catch (error) {
    yield put({
      type: types.ACTIVATE_USER_ERROR,
      payload: error,
    });
  }
}

const ActivateUser = async (uid) => {
  return await axios.post(
    `${config.apiUrl}/activateuser`,
    {
      uid,
    },
    {
      headers: {
        Authorization: await getUserToken(),
      },
    }
  );
};

export function* DectivateUserRequestWatcherSaga() {
  yield takeLatest(
    types.DEACTIVATE_USER_REQUEST,
    DeactivateUserRequestWorkerSaga
  );
}

function* DeactivateUserRequestWorkerSaga(action) {
  try {
    const response = yield call(DeactivateUser, action.payload);
    if (response)
      yield put({
        type: types.DEACTIVATE_USER_SUCCESS,
      });
  } catch (error) {
    yield put({
      type: types.DEACTIVATE_USER_ERROR,
      payload: error,
    });
  }
}

const DeactivateUser = async (uid) => {
  console.log("inside async function");
  return await axios.post(
    `${config.apiUrl}/deactivateuser`,
    {
      uid,
    },
    {
      headers: {
        Authorization: await getUserToken(),
      },
    }
  );
};

export function* PaidToFreeUserRequestWatcherSaga() {
  yield takeLatest(
    types.PAID_TO_FREE_USER_REQUEST,
    PaidToFreeUserRequestWorkerSaga
  );
}

function* PaidToFreeUserRequestWorkerSaga(action) {
  try {
    const response = yield call(PaidToFreeUser, action.payload);
    if (response)
      yield put({
        type: types.PAID_TO_FREE_USER_SUCCESS,
      });
  } catch (error) {
    yield put({
      type: types.PAID_TO_FREE_USER_ERROR,
      payload: error,
    });
  }
}

const PaidToFreeUser = async (uid) => {
  return await axios.post(
    `${config.apiUrl}/paid_to_free_user`,
    {
      uid,
    },
    {
      headers: {
        Authorization: await getUserToken(),
      },
    }
  );
};

export function* FreetoPaidUserRequestWatcherSaga() {
  yield takeLatest(
    types.FREE_TO_PAID_USER_REQUEST,
    FreetoPaidUserRequestWorkerSaga
  );
}

function* FreetoPaidUserRequestWorkerSaga(action) {
  try {
    const response = yield call(FreetoPaidUser, action.payload);
    if (response)
      yield put({
        type: types.FREE_TO_PAID_USER_SUCCESS,
      });
  } catch (error) {
    yield put({
      type: types.FREE_TO_PAID_USER_ERROR,
      payload: error,
    });
  }
}

const FreetoPaidUser = async (uid) => {
  console.log("inside async function");
  return await axios.post(
    `${config.apiUrl}/free_to_paid_user`,
    {
      uid,
    },
    {
      headers: {
        Authorization: await getUserToken(),
      },
    }
  );
};
