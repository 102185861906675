import { types } from "./actions";
import { call, put, takeLatest, take } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { db, config } from "../../app/utilities/firebase";

export function* loadAllBrokersWatcherSaga() {
  yield takeLatest(types.LOAD_ALL_BROKERS_REQUEST, loadAllBrokersWorkerSaga);
}

function* loadAllBrokersWorkerSaga() {
  try {
    const channel = new eventChannel((emitter) => {
      let listener;
      db.collection("broker_integration").onSnapshot(async () => {
        let requests = [];
        const snap = await db.collection("broker_integration").get();
        requests = snap.docs.map((d) => d.data());
        emitter(requests);
      });
      return () => {
        listener.off();
      };
    });
    while (true) {
      const data = yield take(channel);
      yield put({
        type: types.LOAD_ALL_BROKERS_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    console.log("error", error.response.data);
    yield put({
      type: types.LOAD_ALL_BROKERS_ERROR,
      payload: error,
    });
  }
}
