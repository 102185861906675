import Dashboard from "../Dashboard/DashboardContainer";
import Login from "../Login/LoginContainer";
import { routes } from "../utilities/routes";
import { PrivateRoute, PublicRoute, Refresh } from "./RouteComponents";
import React from "react";
import { Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as authActions from "../../store/auth/actions";

class Main extends React.Component {
  componentDidMount() {
    console.log("09/30/2021");
    const user = localStorage.getItem("user");
    if (user) {
      const userData = JSON.parse(user);
      this.props.LoginSuccess(userData);
    }
  }
  render() {
    return (
      <Switch>
        <PublicRoute exact path="/" component={Login} />
        <PublicRoute path={routes.login} component={Login} />
        <PrivateRoute path={routes.dashboard} component={Dashboard} />
        <Refresh path="/refresh" />
      </Switch>
    );
  }
}

function mapStateToProps(state) {
  return { auth: state.auth };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(authActions, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
