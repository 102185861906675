import { types } from "./actions";

const initialState = {
  isLoadingList: true,
  error: null,
  list: null,
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_ENCOMPASS_REQUESTS_REQUEST:
      return { ...state, isLoadingList: true, list: null };
    case types.GET_ENCOMPASS_REQUESTS_SUCCESS:
      return { ...state, isLoadingList: false, list: action.payload };
    case types.GET_ENCOMPASS_REQUESTS_ERROR:
      return {
        ...state,
        isLoadingList: false,
        list: null,
        error: action.payload,
      };
    case types.REJECT_ENCOMPASS_REQUEST_REQUEST:
      return { ...state, isLoading: true, error: null };

    case types.REJECT_ENCOMPASS_REQUESTS_SUCCESS:
      return { ...state, isLoading: false, error: null };
    case types.REJECT_ENCOMPASS_REQUESTS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case types.INTEGRATE_SECRET_KEY_REQUEST:
      return { ...state, isLoading: true, error: null };
    case types.INTEGRATE_SECRET_KEY_SUCCESS:
      return { ...state, isLoading: false, error: null };
    case types.INTEGRATE_SECRET_KEY_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case types.SEND_SECRET_KEY_REQUEST:
      return { ...state, isLoading: true, error: null };
    case types.SEND_SECRET_KEY_SUCCESS:
      return { ...state, isLoading: false, error: null };
    case types.SEND_SECRET_KEY_ERROR:
      return { ...state, isLoading: false, error: action.payload };
    case types.DEFAULT_STATE:
      return { ...state, error: null };
    default:
      return state;
  }
}
