import { types } from "./actions";
import { takeLatest, take, put, call } from "redux-saga/effects";
import { db, getUserToken } from "../../app/utilities/firebase";
import { eventChannel } from "redux-saga";
import axios from "axios";
import { config } from "../../app/utilities/firebase";

export function* EncompassRequestWatcherSaga() {
  yield takeLatest(
    types.GET_ENCOMPASS_REQUESTS_REQUEST,
    EncompassRequestWorkerSaga
  );
}

function* EncompassRequestWorkerSaga() {
  try {
    const channel = new eventChannel((emitter) => {
      let listener;
      db.collection("encompassRequests").onSnapshot(async () => {
        const requests = [];
        const snap = await db.collection("encompassRequests").get();

        for (let i = 0; i < snap.docs.length; i++) {
          const info = await db
            .collection("users")
            .doc(snap.docs[i].data().doc_id)
            .get();
          const data = {
            ...snap.docs[i].data(),
            ...info.data(),
          };
          requests.push(data);
        }

        emitter(requests.sort((item) => (item.status ? 1 : -1)));
      });
      return () => listener.off();
    });
    while (true) {
      const data = yield take(channel);
      yield put({ type: types.GET_ENCOMPASS_REQUESTS_SUCCESS, payload: data });
    }
  } catch (error) {
    yield put({ type: types.GET_ENCOMPASS_REQUESTS_ERROR, payload: error });
  }
}

export function* RejectEncompassRequestWatcherSaga() {
  yield takeLatest(
    types.REJECT_ENCOMPASS_REQUEST_REQUEST,
    RejectEncompassRequestWorkerSaga
  );
}

function* RejectEncompassRequestWorkerSaga(action) {
  try {
    yield call(RejectEncompassRequest, action.payload);
    yield put({
      type: types.REJECT_ENCOMPASS_REQUESTS_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: types.REJECT_ENCOMPASS_REQUESTS_ERROR,
      payload: error,
    });
  }
}

async function RejectEncompassRequest(doc_id) {
  await db
    .collection("encompassRequests")
    .doc(doc_id)
    .update({ status: "rejected" });
}

export function* IntegrateSecretKeyRequestWatcherSaga() {
  yield takeLatest(
    types.INTEGRATE_SECRET_KEY_REQUEST,
    IntegrateSecretKeyWorkerSaga
  );
}

function* IntegrateSecretKeyWorkerSaga(action) {
  console.log("action==>", action);
  try {
    const response = yield call(IntegrateSecretKey, action.payload);
    console.log("response==>", response);
    if (response) {
      yield put({
        type: types.INTEGRATE_SECRET_KEY_SUCCESS,
      });
    }
  } catch (error) {
    yield put({
      type: types.INTEGRATE_SECRET_KEY_ERROR,
      payload: error.response.data ? error.response.data : null,
    });
  }
}

async function IntegrateSecretKey(data) {
  return await axios.post(
    `${config.apiUrl}/validateEncompass`,
    {
      instanceID: data.instanceID,
      clientId: data.clientId,
      uid: data.uid,
      secretKey: data.secretKey,
      reqType: data.reqType,
    },

    {
      headers: {
        Authorization: await getUserToken(),
      },
    }
  );
}

export function* SendSecretKeyRequestWatcherSaga() {
  yield takeLatest(
    types.SEND_SECRET_KEY_REQUEST,
    SendSecretKeyRequestWorkerSaga
  );
}
function* SendSecretKeyRequestWorkerSaga(action) {
  try {
    const response = yield call(SendSecretKey, action.payload);
    if (response)
      yield put({
        type: types.SEND_SECRET_KEY_SUCCESS,
      });
  } catch (error) {
    yield put({
      type: types.SEND_SECRET_KEY_ERROR,
      payload: error.response.data,
    });
  }
}

const SendSecretKey = async (data) =>
  await axios.post(
    `${config.apiUrl}/sendKey`,
    {
      userId: data.uid,
      secretKey: data.secretKey,
    },
    {
      headers: {
        Authorization: await getUserToken(),
      },
    }
  );
