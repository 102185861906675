export const types = {
  GET_BANK_REQUEST: "[LOS PAGE] GET BANK DATA REQUEST ",
  GET_BANK_REQUEST_SUCCESS: "[LOS PAGE] GET BANK DATA REQUEST SUCCESS ",
  GET_BANK_REQUEST_ERROR: "[LOS PAGE] GET BANK DATA REQUEST ERROR",
  GET_BANK_APPROVED: "[LOS PAGE] GET BANK DATA APPROVED ",
  GET_BANK_APPROVED_SUCCESS: "[LOS PAGE] GET BANK DATA APPROVED SUCCESS ",
  GET_BANK_APPROVED_ERROR: "[LOS PAGE] GET BANK DATA APPROVED ERROR",

  GET_BANK_REJECTED: "[LOS PAGE] GET BANK DATA REJECTED ",
  GET_BANK_REJECTED_SUCCESS: "[LOS PAGE] GET BANK DATA REJECTED SUCCESS ",
  GET_BANK_REJECTED_ERROR: "[LOS PAGE] GET BANK DATA REJECTED ERROR",
  REJECT_REQUEST: "[LOS PAGE] REJECT REQUEST",
  REJECT_REQUEST_SUCCESS: "[LOS PAGE] REJECT REQUEST SUCCESS",
  REJECT_REQUEST_ERROR: "[LOS PAGE] REJECT REQUEST ERROR",
  APPROVE_REQUEST: "[LOS PAGE] APPROVE REQUEST",
  APPROVE_REQUEST_SUCCESS: "[LOS PAGE] APPROVE REQUEST SUCCESS",
  APPROVE_REQUEST_ERROR: "[LOS PAGE] APPROVE REQUEST ERROR",
};

export const getBankDataRequest = (data) => {
  return {
    type: types.GET_BANK_REQUEST,
    data: data,
  };
};

export const getBankDataApproved = (data) => {
  return {
    type: types.GET_BANK_APPROVED,
    data: data,
  };
};

export const getBankDataRejected = (data) => {
  return {
    type: types.GET_BANK_REJECTED,
    data: data,
  };
};

export const onreject = (data, uid) => {
  let payloadData = {
    reqId: data,
  };
  return {
    type: types.REJECT_REQUEST,
    payload: payloadData,
    uid: uid,
  };
};

export const onApprove = (data) => {
  let payloadData = {
    uid: data.uid,
    bankId: data.bankId,
    encompass_userId: data.encompass_userId,
  };
  return {
    type: types.APPROVE_REQUEST,
    payload: payloadData,
  };
};
