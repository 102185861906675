import React from "react";
import "./SelectablePlan.scss";

export default class SelectablePlan extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="selectplan7section mt-5 pl-4">
        <div className="selectplanHeadSection">
          <h5 className="selectplanHead font-hn-bold">Select Plan</h5>
        </div>
        <div className="selectPlanFormSection pt-3">
          <form
            onSubmit={this.selectPlanHandleSubmit}
            className="justify-content-around align-items-center "
          >
            <label className="checkboxlabel font-hn-medium ">
              Free Period
              <input
                type="radio"
                name="radio"
                onChange={this.props.selectItem}
                value="freePeriod"
              />
              <span className="checkmark"></span>
            </label>
            <label className="checkboxlabel font-hn-medium mt-3">
              Free Account(s)
              <input
                type="radio"
                name="radio"
                onChange={this.props.selectItem}
                value="firstFew"
              />
              <span className="checkmark"></span>
            </label>
            {}
          </form>
        </div>
      </div>
    );
  }
}
