import React from "react";
import Modal from "react-responsive-modal";
import "./SecretKey.scss";

class SecretKey extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidUpdate(prevProps) {
    if (
      !this.props.isLoading &&
      prevProps.isLoading !== this.props.isLoading &&
      !this.props.error
    ) {
      this.props.onClose();
    }
  }
  render() {
    return (
      <div>
        <Modal
          open={this.props.shown}
          onClose={
            !this.props.isLoading
              ? () => {
                  this.props.onClose();
                }
              : () => false
          }
          center
        >
          <div className="px-2 pb-2 secretModel">
            <div id="SecretmodelHeader" className="modal-header pb-0">
              <h5 className="pt-3  mr-auto font-hn-medium">
                {this.props.heading}
              </h5>
            </div>
            <div className="modal-body font-hn-medium">
              <div className="secretKeyDetails">
                <div className="row pt-1">
                  <div className="col-4 font-12">Name</div>
                  <div className="col-8 font-12">
                    {this.props.data.firstName} {this.props.data.lastName}
                  </div>
                </div>
                <div className="row pt-1">
                  <div className="col-4 font-12">Instance ID</div>
                  <div className="col-8 font-12">
                    {this.props.data.instanceID}
                  </div>
                </div>
                <div className="row pt-1">
                  <div className="col-4 font-12">Client ID</div>
                  <div className="col-8 font-12">
                    {this.props.data.clientId}
                  </div>
                </div>
                <div className="row pt-4 secretKeyPossition">
                  <div className="col-12">
                    <div className="secretKeyInput">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="SECRET KEY"
                        value={this.props.secretKey}
                        onChange={({ target }) =>
                          this.props.setKey(target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="errormesaa ">
                    {this.props.error ? (
                      <small className="error">{this.props.error.msg}</small>
                    ) : (
                      <small></small>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between mt-5">
                <button
                  disabled={this.props.isLoading}
                  className="btn btn-outline-secondary shadow font-12 font-hn-medium px-3 secreteKeyBtn"
                  onClick={this.props.onClose}
                >
                  Cancel
                </button>
                {!this.props.isLoading ? (
                  <button
                    disabled={this.props.secretKey.length < 10}
                    className="btn btn-primary shadow font-12 font-hn-medium px-3 secreteKeyBtn"
                    onClick={this.props.confirmHandler}
                  >
                    {this.props.confirmText}
                  </button>
                ) : (
                  <button className="btn btn-primary  font-12 font-hn-medium px-3 secreteKeyBtn">
                    {this.props.loadingText}
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Loading...</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default SecretKey;
