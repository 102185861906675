export const types = {
  ADD_BANK_REQUEST: "[BANK INTEGRATION] Add bank request",
  ADD_BANK_SUCCESS: "[BANK INTEGRATION] Add bank success",
  ADD_BANK_ERROR: "[BANK INTEGRATION] Add bank error",
  LOAD_BANK_INTEGRATION_LIST_REQUEST:
    "[BANK INTEGRATION] Load all bank integration list request",
  LOAD_BANK_INTEGRATION_LIST_SUCCESS:
    "[BANK INTEGRATION] Load all bank integration list success",
  LOAD_BANK_INTEGRATION_LIST_ERROR:
    "[BANK INTEGRATION] Load all bank integration list error",
  DELETE_BANK_INTEGRATION_REQUEST:
    "[BANK INTEGRATION] Delete bank integration request",
  DELETE_BANK_INTEGRATION_SUCCESS:
    "[BANK INTEGRATION] Delete bank integration success",
  DELETE_BANK_INTEGRATION_ERROR:
    "[BANK INTEGRATION] Delete bank integration error",
  GET_BANK_JSON_REQUEST: "[BANK INTEGRATION] Get bank Json request",
  GET_BANK_JSON_SUCCESS: "[BANK INTEGRATION] Get bank Json success",
  GET_BANK_JSON_ERROR: "[BANK INTEGRATION] Get bank Json error",

  SET_LOAN_FIELD_MAPPING_REQUEST: "[BANK INTEGRATION] Set loan field request",
  SET_LOAN_FIELD_MAPPING_SUCCESS: "[BANK INTEGRATION] Set loan field success",
  SET_LOAN_FIELD_MAPPING_ERROR: "[BANK INTEGRATION] Set loan field error",
};

export const addBankRequest = (data) => {
  return {
    type: types.ADD_BANK_REQUEST,
    payload: data,
  };
};

export const addBankSuccess = () => {
  return { type: types.ADD_BANK_SUCCESS };
};

export const addBankError = (error) => {
  return {
    type: types.ADD_BANK_ERROR,
    payload: error,
  };
};
export const getBankJsonRequest = (bankId) => {
  return {
    type: types.GET_BANK_JSON_REQUEST,
    payload: bankId,
  };
};
export const getBankJsonSuccess = (data) => {
  return {
    type: types.GET_BANK_JSON_SUCCESS,
    payload: data,
  };
};
export const getBankJsonError = (error) => {
  return {
    type: types.GET_BANK_JSON_ERROR,
    payload: error,
  };
};
export const setloanFieldMaping = (bankId, loanFieldMaping) => {
  return {
    type: types.SET_LOAN_FIELD_MAPPING_REQUEST,
    bankId: bankId,
    loanFieldMaping: loanFieldMaping,
  };
};
export const loanFieldMapingSuccess = (data) => {
  return {
    type: types.SET_LOAN_FIELD_MAPPING_SUCCESS,
    payload: data,
  };
};
export const loanFieldMapingError = (error) => {
  return {
    type: types.SET_LOAN_FIELD_MAPPING_ERROR,
    payload: error,
  };
};

export const loadIntegrationBankListRequest = () => {
  return {
    type: types.LOAD_BANK_INTEGRATION_LIST_REQUEST,
  };
};

export const loadIntegrationBankListSuccess = (list) => {
  return {
    type: types.LOAD_BANK_INTEGRATION_LIST_SUCCESS,
    payload: list,
  };
};

export const loadIntegrationBankListError = (error) => {
  return {
    type: types.LOAD_BANK_INTEGRATION_LIST_ERROR,
    payload: error,
  };
};

export const deleteBankIntegrationRequest = (id) => {
  return {
    type: types.DELETE_BANK_INTEGRATION_REQUEST,
    payload: id,
  };
};

export const deleteBankIntegrationSuccess = () => {
  return {
    type: types.DELETE_BANK_INTEGRATION_SUCCESS,
  };
};

export const deleteBankIntegrationError = (error) => {
  return {
    type: types.DELETE_BANK_INTEGRATION_ERROR,
    payload: error,
  };
};

export const defaultAction = () => {
  return {
    type: "default",
  };
};
