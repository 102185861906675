import { types } from "./actions";
import axios from "axios";
import { call, put, takeLatest, take, select } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { db, config } from "../../app/utilities/firebase";
import "react-toastify/dist/ReactToastify.css";
import { getUserToken } from "../../app/utilities/firebase";

const allEnterpriseName = {};

export function* LoadFreeAccessListWatcherSaga() {
  yield takeLatest(types.GET_FREEACCESS_LIST_REQUEST, LoadFreeListWorkerSaga);
}

function* LoadFreeListWorkerSaga() {
  try {
    let firstRequest = yield select(state => state.freeAccess && state.freeAccess.freeAccessFirstRequest);
    if (!firstRequest) {
      return;
    }
    const channel = new eventChannel((emitter) => {
      let listener;
      db.collection("users").onSnapshot(async (snap) => {
        const users = snap.docs.map(doc => doc.data());
        const enterpriseIds = [...new Set(users.map(user => user.enterpriseId).filter(id => !!id))];
        await Promise.all(
          enterpriseIds.map(async id => {
            if (!allEnterpriseName[id]) {
              const data = (await db.collection("enterprises").doc(id).get()).data()
              allEnterpriseName[id] = data ? data.enterpriseName : null;
            }
          })
        );
        users.forEach(userData => {
          const enterpriseName = allEnterpriseName[userData.enterpriseId];
          if (enterpriseName) {
            userData['enterpriseName'] = enterpriseName;
          }
        });
        emitter(users);
      });
      return () => {
        listener.off();
      };
    });
    while (true) {
      const data = yield take(channel);
      yield put({ type: types.GET_FREEACCESS_LIST_SUCCESS, payload: data });
    }
  } catch (error) {
    yield put({
      type: types.GET_FREEACCESS_LIST_ERROR,
      payload: error,
    });
  }
}

export function* addFreeAccessWatcherSaga() {
  yield takeLatest(types.ADD_USER_FREEACCESS_REQUEST, addfreeAccessWorkerSaga);
}
function* addfreeAccessWorkerSaga(action) {
  try {
    const urlGet = yield call(addFreeAccess, action.payload);
    yield put({ type: types.ADD_USER_FREEACCESS_SUCCESS, payload: urlGet });
  } catch (error) {
    yield put({ type: types.ADD_USER_FREEACCESS_ERROR, payload: error });
  }
}

const addFreeAccess = async (uid) => {
  const getFree = await axios.post(
    `${config.apiUrl}/getUserTokenForAdmin`,
    {
      userId: uid,
    },
    {
      headers: {
        Authorization: await getUserToken(),
      },
    }
  );
  if (getFree) {
    if (config.env === "prod") {
      return `https://mortgage.dailyai.io/login?tokenId=${getFree.data.data.token_id}`;
    } else if (config.env === "staging") {
      return `https://staging.dailyai.io/login?tokenId=${getFree.data.data.token_id}`;
    } else {
      return `https://testapp.dailycrm.ai/login?tokenId=${getFree.data.data.token_id}`;
    }
  }
};

export function* getOtherAdminsSaga() {
  yield takeLatest(types.GET_OTHER_ADMINS_REQUEST, getOtherAdminsGenerator);
}

function* getOtherAdminsGenerator(action) {
  try {
    const { data: list } = yield call(getOtherAdmins, action.payload);
    yield put({ type: types.GET_OTHER_ADMINS_SUCCESS, payload: list });
  } catch (ex) {
    console.log(ex);
    yield put({ type: types.GET_OTHER_ADMINS_ERROR, payload: ex });
  }
}

const getOtherAdmins = async ({ adminId, enterpriseId }) => {
  return await axios.post(
    `${config.apiUrl}/getAdminAccountsOfEnterprise`,
    { enterpriseId, adminId },
    {
      headers: {
        Authorization: await getUserToken(),
      },
    }
  )
}