export const types = {
  GET_FREEACCESS_LIST_REQUEST: "[FREEACCESS] Load all request",
  GET_FREEACCESS_LIST_SUCCESS: "[FREEACCESS] Load all success",
  GET_FREEACCESS_LIST_ERROR: "[FREEACCESS] Load all  error",

  ADD_USER_FREEACCESS_REQUEST: "[FREE ACCESS]add user request",
  ADD_USER_FREEACCESS_SUCCESS: "[FREE ACCESS]add user success",
  ADD_USER_FREEACCESS_ERROR: "[FREE ACCESS]add user error",

  GET_OTHER_ADMINS_REQUEST: "[FREE ACCESS]get other admins request",
  GET_OTHER_ADMINS_SUCCESS: "[FREE ACCESS]get other admins success",
  GET_OTHER_ADMINS_ERROR: "[FREE ACCESS]get other admins error",
};

export const LoadAllFreeAccessRequest = (uid) => {
  return {
    type: types.GET_FREEACCESS_LIST_REQUEST,
    payload: uid,
  };
};
export const LoadAllFreeAccessSuccess = (list) => {
  return {
    type: types.GET_FREEACCESS_LIST_SUCCESS,
    payload: list,
  };
};

export const LoadAllFreeAccessError = (error) => {
  return {
    type: types.GET_FREEACCESS_LIST_ERROR,
    payload: error,
  };
};

export const AddUserFreeRequest = (uid) => {
  return {
    type: types.ADD_USER_FREEACCESS_REQUEST,
    payload: uid,
  };
};
export const AddUserFreeSuccess = (list) => {
  return {
    type: types.ADD_USER_FREEACCESS_SUCCESS,
    payload: list,
  };
};

export const AddUserFreeError = (error) => {
  return {
    type: types.ADD_USER_FREEACCESS_ERROR,
    payload: error,
  };
};

export const GetOtherAdminsRequest = (payload) => {
  return {
    type: types.GET_OTHER_ADMINS_REQUEST,
    payload,
  };
}

export const GetOtherAdminsSuccess = (adminsList) => {
  return {
    type: types.GET_OTHER_ADMINS_SUCCESS,
    payload: adminsList
  };
}

export const GetOtherAdminsError = (error) => {
  return {
    type: types.GET_OTHER_ADMINS_ERROR,
    payload: error
  };
}