import React from "react";
import Modal from "react-responsive-modal";
import "./SelectPlan.scss";
import SelectablePlan from "./SelectablePlan";
import SetFreePeriod from "./SetFreePeriod";
import SetFreeLimits from "./SetFreeLimits";
import Discount from "./Discount";

export default class SelectPlan extends React.Component {
  state = {
    selectedOption: "",
  };
  constructor(props) {
    super(props);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.backButton = this.backButton.bind(this);
    this.planSelect = this.planSelect.bind(this);
  }

  planSelect(x, y) {}
  componentDidUpdate(prevProps) {
    if (
      !this.props.isLoading &&
      prevProps.isLoading !== this.props.isLoading &&
      !this.props.error
    ) {
      this.props.onClose();
      this.props.notify(
        this.props.notificationText ? this.props.notificationText : "Aproved"
      );
    }
  }

  selectPlanHandleSubmit(event) {
    event.preventDefault();
  }
  handleOptionChange({ target }) {
    this.setState({
      selectedOption: target.value,
    });
  }
  backButton() {
    this.setState({ selectedOption: null });
  }
  render() {
    return (
      <Modal open={this.props.shown} onClose={this.props.onClose} center>
        <div className="selectPlanAll ">
          <div
            id="selectPlanBody"
            className="modal-body input-layout name-tag "
          >
            <div className="row mx-0 px-0 ">
              <div className="col-5 px-0 selectPlanUserDetails">
                <ul className="mt-5">
                  <li className="username text-uppercase font-lato-bold">
                    {this.props.userData.firstName}{" "}
                    {this.props.userData.lastName}
                  </li>
                  <li className="userDataShow pt-2 font-lato-regular">
                    {this.props.userData.email}
                  </li>
                  <li className="userDataShow font-lato-regular">
                    {this.props.userData.phoneNumber}
                  </li>
                  <li className="userDataShow font-lato-regular">
                    {this.props.userData.bankName}
                  </li>
                </ul>
                <div className="selectPlanLicenseAmt font-lato-regular pl-3">
                  License Requested: {this.props.userData.noOfLicense}{" "}
                  {this.props.userData.planPeriod}
                </div>
              </div>
              <div className="col-7 px-0">
                {this.props.userData.planSelect === "freePeriod" ||
                this.props.plan === "freePeriod" ? (
                  <SetFreePeriod
                    planType={this.props.plan ? this.props.plan : "freePeriod"}
                    planSelect={this.props.planSelect}
                    user_id={this.props.userData.doc_id}
                    isLoading={this.props.isLoading}
                    backButton={this.props.plan ? null : this.backButton}
                  />
                ) : this.props.userData.planSelect === "freeAccount" ||
                  this.props.plan === "firstFew" ? (
                  <SetFreeLimits
                    planType={this.props.plan ? this.props.plan : "firstFew"}
                    planSelect={this.props.planSelect}
                    isLoading={this.props.isLoading}
                    user_id={this.props.userData.doc_id}
                    backButton={this.props.plan ? null : this.backButton}
                  />
                ) : this.props.userData.planSelect === "discount" ? (
                  <Discount
                    planType={this.state.selectedOption}
                    user_id={this.props.userData.doc_id}
                    backButton={this.backButton}
                  />
                ) : this.state.selectedOption === null ||
                  this.state.selectedOption === "" ? (
                  <SelectablePlan
                    selectItem={this.handleOptionChange}
                    user_id={this.props.userData.doc_id}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
