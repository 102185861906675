import * as authActions from "../../store/auth/actions";
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { bindActionCreators } from "redux";
import "./Logout.scss";

class LogoutContainer extends React.Component {
  render() {
    if (this.props.auth && !this.props.auth.isLoggedIn) {
      return (
        <Redirect
          to={{ pathname: "/login", state: { from: this.props.location } }}
        />
      );
    }
    return (
      <div onClick={this.props.RequestLogout}>
        <div className="input-group-append">
          <span
            className="cursor-pointer input-group-text text-uppercase font-hn-medium"
            id="basic-addon2"
          >
            Logout
            <img
              className="cursor-pointer pl-2"
              src={require("../../assets/images/logout.svg")}
              alt="logo"
            />
          </span>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { auth: state.auth };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(authActions, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(LogoutContainer);
