import { types } from "./actions";
import { call, put, takeLatest, take } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { db, config } from "../../app/utilities/firebase";
import axios from "axios";
import { getUserToken } from "../../app/utilities/firebase";

export function* addBankWatcherSaga() {
  yield takeLatest(types.ADD_BANK_REQUEST, addBankWorkerSaga);
}

function* addBankWorkerSaga(action) {
  try {
    yield call(addBank, action.payload);
    yield put({ type: types.ADD_BANK_SUCCESS });
  } catch (error) {
    console.log(error);
    yield put({
      type: types.ADD_BANK_ERROR,
      payload: error,
    });
  }
}

async function addBank(data) {
  return await axios.post(
    `${config.apiUrl}/validateEncompass`,
    {
      ...data,
    },

    {
      headers: {
        Authorization: await getUserToken(),
      },
    }
  );
}

export function* bankJSONWatcherSaga() {
  yield takeLatest(types.GET_BANK_JSON_REQUEST, getBankJsonRequest);
}
function* getBankJsonRequest(bankId) {
  try {
    const response = yield call(jsonBankData, bankId.payload);
    if (response)
      yield put({
        type: types.GET_BANK_JSON_SUCCESS,
        payload: response,
      });
  } catch (error) {
    yield put({
      type: types.GET_BANK_JSON_ERROR,
      payload: error,
    });
  }
}

const jsonBankData = async (bankId) => {
  return await axios.post(
    `${config.apiUrl}/get_bank_loan `,
    {
      bankId: bankId,
    },
    {
      headers: {
        Authorization: await getUserToken(),
      },
    }
  );
};

export function* LoadSetLoanWatcherSaga() {
  yield takeLatest(types.SET_LOAN_FIELD_MAPPING_REQUEST, setloanMaping);
}
function* setloanMaping(action) {
  try {
    yield call(setloanMapingRequest, action);
    yield put({ type: types.SET_LOAN_FIELD_MAPPING_SUCCESS });
  } catch (error) {
    console.log(error);
    yield put({ type: types.SET_LOAN_FIELD_MAPPING_ERROR, payload: error });
  }
}
async function setloanMapingRequest(action) {
  return await db
    .collection("bank_integration")
    .doc(action.bankId)
    .update({ loanFieldMaping: action.loanFieldMaping });
}

export function* loadIntegrationBankWatcherSaga() {
  yield takeLatest(
    types.LOAD_BANK_INTEGRATION_LIST_REQUEST,
    loadIntegrationBankWorkerSaga
  );
}

function* loadIntegrationBankWorkerSaga() {
  try {
    const channel = new eventChannel((emitter) => {
      let listener;
      db.collection("bank_integration").onSnapshot(async () => {
        const requests = [];
        const snap = await db.collection("bank_integration").get();
        snap.forEach(function (doc) {
          requests.push(doc.data());
        });
        emitter(
          requests.sort((a, b) => {
            if (a.bankName.toLowerCase() < b.bankName.toLowerCase()) {
              return 1;
            }
            if (a.bankName.toLowerCase() > b.bankName.toLowerCase()) {
              return -1;
            }
            return 0;
          })
        );
      });
      return () => {
        listener.off();
      };
    });
    while (true) {
      const data = yield take(channel);
      yield put({
        type: types.LOAD_BANK_INTEGRATION_LIST_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    console.log("error==>", error.response.data);
    yield put({
      type: types.LOAD_BANK_INTEGRATION_LIST_ERROR,
      payload: error,
    });
  }
}

export function* deleteIntegrationWatcherSaga() {
  yield takeLatest(
    types.DELETE_BANK_INTEGRATION_REQUEST,
    deleteIntegrationWorkerSaga
  );
}

function* deleteIntegrationWorkerSaga(action) {
  try {
    yield call(deleteIntegration, action.payload);
    yield put({
      type: types.DELETE_BANK_INTEGRATION_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: types.DELETE_BANK_INTEGRATION_ERROR,
      payload: error,
    });
    console.log(error);
  }
}

async function deleteIntegration(id) {
  return await axios.post(
    `${config.apiUrl}/deletesubscriptionEncompass`,
    {
      uid: id,
    },

    {
      headers: {
        Authorization: await getUserToken(),
      },
    }
  );
}
