import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { UncontrolledPopover } from "reactstrap";

import * as dashboardActions from "../../store/dashboard/actions";
import * as authActions from "../../store/auth/actions";
import "./Dashbord.scss";
import SideMenuBar from "../SideMenuBar/SideMenuBar";
import LicenseList from "../LicenseList/LicenseList";
import EncompassList from "../EncompassList/EncompassList";
import LogoutContainer from "../Logout/LogoutContainer";
import LicenseStatus from "../LicenseStatus/LicenseStatus";
import { Route } from "react-router-dom";
import { routes } from "../utilities/routes";
import * as encompassActions from "../../store/encompassDetails/actions";
import EncompassDetails from "../EncompassDetails/EncompassDetails";
import UsersList from "../UsersList/UsersList";

import * as EncompassRequestsActions from "../../store/encompassRequests/actions";
import * as userListActions from "../../store/userList/actions";
import * as bankIntegrationActions from "../../store/bankIntegration/actions";
import * as brokerIntegrationActions from "../../store/brokerIntegration/actions";
import BankIntegration from "../BankIntegration/BankIntegration";
import BrockerIntegration from "../BrockerIntegration/BrockerIntegration";
import { AiAssistant } from "../AiAssistant/AiAssistant";
import * as aiAssistantListActions from "../../store/aiassistant/actions";
import * as enterpriseAction from "../../store/Enterprise/actions";
import FreeAccess from "../FreeAccess/FreeAccess";
import PackageContainer from "../Packages/PackagesContainer";
import EnterpriseContainer from "../Enterprise/Enterprisecontainer";
import LOSContainer from "../LOS/LOScontainer";
import * as los from "../../store/Los/actions";
import * as nexus from "../../store/simpleNexusBankIntegration/actions";

class Dashboard extends React.Component {
  state = {
    userId: "",
  };
  componentDidMount(prevpros) {
    this.props.LoadAllLicenceRequests();
    this.props.LoadAllEncompassDetails();
    this.props.LoadAllEncompassRequestsRequest();
    this.props.LoadAllUsersRequest();
    this.props.loadIntegrationBankListRequest();
    this.props.loadAllBrokersRequest();
    this.props.loadIntegrationNexusBankListRequest();

    if (
      this.props.history.location.pathname === "/dashboard" ||
      this.props.history.location.pathname === "/dashboard/"
    ) {
      this.props.history.push("/dashboard/licence-requests");
    }
  }

  titleChange = () => {
    if (this.props.history.location.pathname === "/dashboard/licence-requests")
      return "dashboard";
    else if (
      this.props.history.location.pathname === "/dashboard/encompass-details"
    )
      return "encompass details";
    else if (
      this.props.history.location.pathname === "/dashboard/encompass-lists"
    )
      return "Encompass Lists";
    else if (this.props.history.location.pathname === "/dashboard/users-list")
      return "List of SLO";
    else if (
      this.props.history.location.pathname === "/dashboard/license-status"
    )
      return "License List";
    else if (this.props.history.location.pathname === "/dashboard/broker-list")
      return "Broker List";
    else if (
      this.props.history.location.pathname === "/dashboard/bank-integration"
    )
      return "Bank Integration";
    else if (this.props.history.location.pathname === "/dashboard/ai-assistant")
      return "Ai Assistant";
    else if (this.props.history.location.pathname === "/dashboard/free-access")
      return "User List";
    else if (this.props.history.location.pathname === "/dashboard/packages")
      return "Packages";
    else if (this.props.history.location.pathname === "/dashboard/enterprise")
      return "Enterprise";
    else if (
      this.props.history.location.pathname ===
      "/dashboard/simpleNexusBankManagement"
    )
      return "Simple Nexus Account Integration";
    else if (
      this.props.history.location.pathname ===
      "/dashboard/simpleNexusIntegrationRequests"
    )
      return "Simple Nexus Integration Requests";
    else if (
      this.props.history.location.pathname === "/dashboard/integrationRequests"
    )
      return "LOS Integration Requests";
    else return "dashboard";
  };
  render() {
    return (
      <div className="container-fluid dashboard ">
        <div className="row">
          <div className="col-2 col-sm-1 sidenavbar px-0">
            <SideMenuBar />
          </div>
          <div className="col-10 col-sm-11 px-0 bg-color pb-2 dashboard-layout">
            <div className="column">
              <div className="row justify-content-between header-bg px-5 py-4 ml-0 mr-0">
                <div className="col-sm-6 text-uppercase pl-0 pt-2 router-text">
                  <h6>{this.titleChange()}</h6>
                </div>
                <div className="col-sm-6 pr-0">
                  <div className="row justify-content-end mr-1">
                    <div className="d-flex flex-column pr-3">
                      <h6 className="text-uppercase text-right mb-0 pt-3 usr-text">
                        {this.props.user ? this.props.user.firstName : null}
                      </h6>
                    </div>
                    <div>
                      <img
                        id="UncontrolledPopover"
                        className="rounded-circle img-fluid profile-pic cursor-pointer"
                        src={require("../../assets/images/person.svg")}
                        alt="logo"
                      />
                      <div>
                        <UncontrolledPopover
                          trigger="legacy"
                          placement="bottom"
                          target="UncontrolledPopover"
                        >
                          <LogoutContainer />
                        </UncontrolledPopover>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wrapper">
                <Route
                  exact
                  path={routes.licenceRequests}
                  component={LicenseList}
                />
                <Route
                  exact
                  path={routes.encompassDetails}
                  component={EncompassDetails}
                />
                <Route
                  exact
                  path={routes.encompassLists}
                  component={EncompassList}
                />
                <Route exact path={routes.usersList} component={UsersList} />
                {}
                <Route
                  exact
                  path={routes.aiAssistant}
                  component={AiAssistant}
                />
                <Route
                  exact
                  path={routes.bankIntegration}
                  component={BankIntegration}
                />
                <Route
                  exact
                  path={routes.brockerIntegration}
                  component={BrockerIntegration}
                />
                <Route
                  exact
                  path={routes.licenseStatus}
                  component={LicenseStatus}
                />
                <Route exact path={routes.freeAccess} component={FreeAccess} />
                <Route
                  exact
                  path={routes.packages}
                  component={PackageContainer}
                />
                <Route
                  exact
                  path={routes.enterprise}
                  component={EnterpriseContainer}
                />
                <Route
                  exact
                  path={routes.integrationRequests}
                  component={LOSContainer}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...dashboardActions,
      ...authActions,
      ...encompassActions,
      ...EncompassRequestsActions,
      ...userListActions,
      ...bankIntegrationActions,
      ...brokerIntegrationActions,
      ...aiAssistantListActions,
      ...enterpriseAction,
      ...los,
      ...nexus,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
