export const types = {
  STOP_LICENCE_REQUEST: "[LICENCE STATUS] Stop licence request",
  STOP_LICENCE_SUCCESS: "[LICENCE STATUS] Stop licence success",
  STOP_LICENCE_ERROR: "[LICENCE STATUS] Stop licence error",
};

export const stopLicenceRequest = (data) => ({
  type: types.STOP_LICENCE_REQUEST,
  payload: data,
});

export const stopLicenceSuccess = () => ({
  type: types.STOP_LICENCE_SUCCESS,
});
export const stopLicenceError = (error) => ({
  type: types.STOP_LICENCE_ERROR,
  payload: error,
});
