import React from "react";
import "./BrockerIntegration.scss";
import { connect } from "react-redux";

class BrockerIntegration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
    };
  }

  render() {
    let filteredData = this.props.brokerIntegration.list
      ? this.props.brokerIntegration.list.filter(
          (item) =>
            !this.state.search ||
            item.brokerName
              .toLowerCase()
              .indexOf(this.state.search.toLowerCase()) > -1
        )
      : [];
    return (
      <div className="bg-white partner-layout w-set brockerIntegration d-flex flex-column pt-5 px-3">
        <div className="d-flex pb-2 flex-column flex-md-row">
          <div className="d-flex align-items-baseline font-hn-medium pr-4 ">
            <h4 className="brocker-in-headText">Broker List</h4>
          </div>

          <div className="d-flex bro-in-searchView">
            <div id="bro-in-searchstyle" className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search Name"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                value={this.state.search}
                onChange={(e) => this.setState({ search: e.target.value })}
              />
              <div className="input-group-append">
                <span className="input-group-text" id="basic-addon2">
                  <img
                    src={require("../../assets/images/searchIcon.svg")}
                    alt="search"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="table-layout mt-3">
          <table className="table table-striped table-hover">
            <thead className="table-header">
              <tr id="brockerHeadRow">
                <th className="brocker-in-HeadText p-19 font-hn-medium">
                  Name
                </th>
                <th className="brocker-in-HeadText p-19 font-hn-medium">
                  Email
                </th>
                <th className="brocker-in-HeadText p-19 font-hn-medium">
                  User Id
                </th>
                <th className="brocker-in-HeadText p-19 font-hn-medium">
                  Instance ID
                </th>
                <th className="brocker-in-HeadText p-19 font-hn-medium">
                  Unique Key
                </th>
              </tr>
            </thead>
            <tbody className="table-body">
              {filteredData.length > 0 ? (
                filteredData.map((item, index) => (
                  <tr key={index} className="list-tr   pointer pb-2 pt-2 ">
                    <td className=" outline-none data-align pt-4">
                      <div className="brocker-in-bodyText font-hn-medium">
                        {item.brokerName}
                      </div>
                    </td>
                    <td className=" outline-none data-align pt-4">
                      <div className="brocker-in-bodyText font-hn-medium">
                        {item.email}
                      </div>
                    </td>
                    <td className=" outline-none data-align pt-4">
                      <div className="brocker-in-bodyText font-hn-medium">
                        {item.encompass_userId}
                      </div>
                    </td>
                    <td className=" outline-none data-align pt-4">
                      <div className="brocker-in-bodyText font-hn-medium">
                        {item.instanceID}
                      </div>
                    </td>
                    <td className=" outline-none data-align pt-4">
                      <div className="brocker-in-bodyText font-hn-medium">
                        {item.uniquekey ? item.uniquekey : "--"}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr
                  id="preventTRBorder"
                  className="d-flex justify-content-center align-items-center font-16 mt-5"
                >
                  <td id="preventTdBorder">Nothing to Show</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    brokerIntegration: state.brokerIntegration,
  };
}

export default connect(mapStateToProps)(BrockerIntegration);
