import { types } from "./actions";
import { call, put, takeLatest, take, cps } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { db } from "../../app/utilities/firebase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function* LoadAiassistantListWatcherSaga() {
  yield takeLatest(
    types.GET_AIASSISTANT_LIST_REQUEST,
    LoadAiassistantListWorkerSaga
  );
}

let userId;
function* LoadAiassistantListWorkerSaga(action) {
  userId = action.payload && action.payload;
  if (userId) {
    try {
      const channel = new eventChannel((emitter) => {
        let listener;
        db.collection("ai_sms_templates").onSnapshot(async () => {
          const requests = [];
          const snap = await db
            .collection("ai_sms_templates")
            .where("uId", "==", userId)
            .where("type", "==", "birthday")
            .get();
          snap.forEach(function (doc) {
            requests.push(doc.data());
          });

          emitter(
            requests
              .sort((a, b) => {
                if (a.created_at > b.created_at) {
                  return 1;
                }
                if (a.created_at < b.created_at) {
                  return -1;
                }
                return 0;
              })
              .sort((item) => (item.status ? -1 : 1))
          );
        });
        return () => {
          listener.off();
        };
      });
      while (true) {
        const data = yield take(channel);
        yield put({ type: types.GET_AIASSISTANT_LIST_SUCCESS, payload: data });
      }
    } catch (error) {
      console.log(error);
      yield put({ type: types.GET_AIASSISTANT_LIST_ERROR, payload: error });
    }
  }
}

export function* LoadAiassistantAddWatcherSaga() {
  yield takeLatest(types.ADD_AIASSISTANT_REQUEST, addAiAssistantRequest);
}

function* addAiAssistantRequest(action) {
  try {
    yield call(addAiAssistantBirtdaySms, action);
    yield put({ type: types.ADD_AIASSISTANT_SUCCESS });
  } catch (error) {
    yield put({ type: types.ADD_AIASSISTANT_ERROR, payload: error });
  }
}
async function addAiAssistantBirtdaySms(action) {
  if (action.payload.doc_id) {
    try {
      if (action.payload.template_header) {
        let smsHeadData = {
          template_header: action.payload.template_header,
          type: "birthday",
        };
        db.collection("ai_sms_templates")
          .doc(action.payload.doc_id)
          .update(smsHeadData);
      }
      if (action.payload.content) {
        let smscontentData = {
          content: action.payload.content,
          type: "birthday",
        };
        db.collection("ai_sms_templates")
          .doc(action.payload.doc_id)
          .update(smscontentData);
      }
      toast.success("Updated", {
        position: "bottom-right",
        autoClose: 2000,
      });
    } catch (error) {
      toast.error(error, {
        position: "bottom-right",
        autoClose: 2000,
      });
    }
  } else {
    try {
      let smsData = {
        created_at: new Date().getTime(),
        content: action.payload.content ? action.payload.content : "",
        uId: userId,
        template_header: action.payload.template_header
          ? action.payload.template_header
          : "",
        type: "birthday",
        selected: false,
      };
      let docRef = db.collection("ai_sms_templates").doc();
      const doc_id = docRef.id;
      smsData = { ...smsData, doc_id };
      docRef.set(smsData);
      toast.success("Saved", {
        position: "bottom-right",
        autoClose: 2000,
      });
    } catch (error) {
      toast.error(error, {
        position: "bottom-right",
        autoClose: 2000,
      });
    }
  }
}

export function* LoadAiassistantDELETEWatcherSaga() {
  yield takeLatest(types.DELETE_AIASSISTANT_REQUEST, deleteAiAssistantRequest);
}

function* deleteAiAssistantRequest(action) {
  try {
    yield call(deletesmsTemplate, action);
    yield put({ type: types.DELETE_AIASSISTANT_SUCCESS, payload: "sucessss" });
  } catch (error) {
    yield put({ type: types.DELETE_AIASSISTANT_ERROR, payload: error });
  }
}
async function deletesmsTemplate(action) {
  if (action.payload.doc_id) {
    db.collection("ai_sms_templates").doc(action.payload.doc_id).delete();
    toast.success("Deleted", {
      position: "bottom-right",
      autoClose: 2000,
    });
  }
}

export function* LoadAiassistanSelectedWatcherSaga() {
  yield takeLatest(
    types.ADD_AIASSISTANT_SELECT_REQUEST,
    LoadAiassistantSelectedWorkerSaga
  );
}
function* LoadAiassistantSelectedWorkerSaga(action) {
  try {
    const temp = action.payload;
    let xx = Object.values(temp).map((item) => {
      let data = { selected: item.selected };
      db.collection("ai_sms_templates").doc(item.doc_id).update(data);
    });
    toast.success("Saved And Updated", {
      position: "bottom-right",
      autoClose: 2000,
    });
  } catch (error) {
    toast.error(error, {
      position: "bottom-right",
      autoClose: 2000,
    });
  }
}

export function* LoadAiassistanEmailListWatcherSaga() {
  yield takeLatest(
    types.GET_AIASSISTANT_EMAIL_REQUEST,
    LoadAiassistantemailListWorkerSaga
  );
}
function* LoadAiassistantemailListWorkerSaga(action) {
  try {
    const channel = new eventChannel((emitter) => {
      let listener;
      db.collection("ai_email_templates").onSnapshot(async () => {
        const requests = [];
        const snap = await db
          .collection("ai_email_templates")
          .where("uId", "==", userId)
          .where("type", "==", "birthday")
          .get();
        snap.forEach(function (doc) {
          requests.push(doc.data());
        });

        emitter(
          requests
            .sort((a, b) => {
              if (a.created_at > b.created_at) {
                return 1;
              }
              if (a.created_at < b.created_at) {
                return -1;
              }
              return 0;
            })
            .sort((item) => (item.status ? -1 : 1))
        );
      });
      return () => {
        listener.off();
      };
    });
    while (true) {
      const data = yield take(channel);
      yield put({ type: types.GET_AIASSISTANT_EMAIL_SUCCESS, payload: data });
    }
  } catch (error) {
    console.log(error);
    yield put({ type: types.GET_AIASSISTANT_EMAIL_ERROR, payload: error });
  }
}

export function* LoadAiassistanEmailTemplateAddWatcherSaga() {
  yield takeLatest(types.ADD_AIASSISTANT_EMAIL_REQUEST, addAiEmailTemplate);
}
function* addAiEmailTemplate(action) {
  try {
    yield call(addBirthdayEmail, action);
    yield put({ type: types.ADD_AIASSISTANT_EMAIL_SUCCESS });
  } catch (error) {
    yield put({ type: types.ADD_AIASSISTANT_EMAIL_ERROR, payload: error });
  }
}
async function addBirthdayEmail(action) {
  if (action.payload.doc_id) {
    try {
      if (action.payload.template_header) {
        let emailHeadData = {
          template_header: action.payload.template_header,
          type: "birthday",
        };
        db.collection("ai_email_templates")
          .doc(action.payload.doc_id)
          .update(emailHeadData);
      }
      if (action.payload.subject) {
        let emailSubjectData = {
          subject: action.payload.subject,
          type: "birthday",
        };
        db.collection("ai_email_templates")
          .doc(action.payload.doc_id)
          .update(emailSubjectData);
      }
      if (action.payload.content) {
        let emailContentdata = {
          content: action.payload.content,
          type: "birthday",
        };
        db.collection("ai_email_templates")
          .doc(action.payload.doc_id)
          .update(emailContentdata);
      }
      toast.success("Updated", {
        position: "bottom-right",
        autoClose: 2000,
      });
    } catch (error) {
      toast.error(error, {
        position: "bottom-right",
        autoClose: 2000,
      });
    }
  } else {
    try {
      let emailData = {
        created_at: new Date().getTime(),
        subject: action.payload.subject ? action.payload.subject : "",
        uId: userId,
        template_header: action.payload.template_header
          ? action.payload.template_header
          : "",
        type: "birthday",
        content: action.payload.content ? action.payload.content : "",
      };
      let docRef = db.collection("ai_email_templates").doc();
      const doc_id = docRef.id;
      emailData = { ...emailData, doc_id };
      docRef.set(emailData);
      toast.success("Saved", {
        position: "bottom-right",
        autoClose: 2000,
      });
    } catch (error) {
      toast.error(error, {
        position: "bottom-right",
        autoClose: 2000,
      });
    }
  }
}

export function* LoadAiassistanSelectedEMAILWatcherSaga() {
  yield takeLatest(
    types.ADD_AIASSISTANT_EMAIL_SELECT_REQUEST,
    addAiAssistantEmailRequest
  );
}
function* addAiAssistantEmailRequest(action) {
  try {
    const temp = action.payload;
    let xx = Object.values(temp).map((item) => {
      let data = { selected: item.selected };
      db.collection("ai_email_templates").doc(item.doc_id).update(data);
    });
    toast.success("Save and Updated", {
      position: "bottom-right",
      autoClose: 2000,
    });
  } catch (error) {
    toast.error(error, {
      position: "bottom-right",
      autoClose: 2000,
    });
  }
}

export function* LoadAiassistantDELETEEmailWatcherSaga() {
  yield takeLatest(
    types.DELETE_AIASSISTANT_EMAIL_REQUEST,
    deleteAiAssistantEmailRequest
  );
}
function* deleteAiAssistantEmailRequest(action) {
  if (action.payload.doc_id) {
    db.collection("ai_email_templates").doc(action.payload.doc_id).delete();
    toast.success("Deleted", {
      position: "bottom-right",
      autoClose: 2000,
    });
  }
}

export function* LoadAiassistanMmsListWatcherSaga() {
  yield takeLatest(
    types.GET_AIASSISTANT_MMS_REQUEST,
    LoadAiassistantmmsListWorkerSaga
  );
}
function* LoadAiassistantmmsListWorkerSaga(action) {
  try {
    const channel = new eventChannel((emitter) => {
      let listener;
      db.collection("ai_mms_templates").onSnapshot(async () => {
        const requests = [];
        const snap = await db
          .collection("ai_mms_templates")
          .where("uId", "==", userId)
          .where("type", "==", "birthday")
          .get();
        snap.forEach(function (doc) {
          requests.push(doc.data());
        });

        emitter(
          requests
            .sort((a, b) => {
              if (a.created_at > b.created_at) {
                return 1;
              }
              if (a.created_at < b.created_at) {
                return -1;
              }
              return 0;
            })
            .sort((item) => (item.status ? -1 : 1))
        );
      });
      return () => {
        listener.off();
      };
    });
    while (true) {
      const data = yield take(channel);
      yield put({ type: types.GET_AIASSISTANT_MMS_SUCCESS, payload: data });
    }
  } catch (error) {
    yield put({ type: types.GET_AIASSISTANT_MMS_ERROR, payload: error });
  }
}

export function* LoadAiassistanMmsTemplateAddWatcherSaga() {
  yield takeLatest(types.ADD_AIASSISTANT_MMS_REQUEST, addAiMmsTemplate);
}
function* addAiMmsTemplate(action) {
  try {
    yield call(addbirthdayMMS, action);
    yield put({ type: types.ADD_AIASSISTANT_MMS_SUCCESS });
  } catch (error) {
    yield put({ type: types.ADD_AIASSISTANT_MMS_ERROR, payload: error });
  }
}
async function addbirthdayMMS(action) {
  if (action.payload.doc_id) {
    try {
      if (action.payload.template_header) {
        let mmsHeadData = {
          template_header: action.payload.template_header,
          type: "birthday",
        };
        db.collection("ai_mms_templates")
          .doc(action.payload.doc_id)
          .update(mmsHeadData);
      }
      if (action.payload.content) {
        let mmscontentData = {
          content: action.payload.content,
          type: "birthday",
        };
        db.collection("ai_mms_templates")
          .doc(action.payload.doc_id)
          .update(mmscontentData);
      }
      if (action.payload.imageUrl) {
        let imageUrlData = {
          image_url: action.payload.imageUrl,
          type: "birthday",
        };
        db.collection("ai_mms_templates")
          .doc(action.payload.doc_id)
          .update(imageUrlData);
      }
      if (action.payload.videoUrl) {
        let videoUrlData = {
          video_url: action.payload.videoUrl,
          type: "birthday",
        };
        db.collection("ai_mms_templates")
          .doc(action.payload.doc_id)
          .update(videoUrlData);
      }
      toast.success("Updated", {
        position: "bottom-right",
        autoClose: 2000,
      });
    } catch (error) {
      toast.error(error, {
        position: "bottom-right",
        autoClose: 2000,
      });
    }
  } else {
    try {
      let mmsData = {
        image_url: action.payload.imageUrl ? action.payload.imageUrl : "",
        created_at: new Date().getTime(),
        content: action.payload.content ? action.payload.content : "",
        uId: userId,
        template_header: action.payload.template_header
          ? action.payload.template_header
          : "",
        type: "birthday",
        video_url: action.payload.videoUrl ? action.payload.videoUrl : "",
      };
      let docRef = db.collection("ai_mms_templates").doc();
      const doc_id = docRef.id;
      mmsData = { ...mmsData, doc_id };
      docRef.set(mmsData);
      toast.success("Saved", {
        position: "bottom-right",
        autoClose: 2000,
      });
    } catch (error) {
      toast.error(error, {
        position: "bottom-right",
        autoClose: 2000,
      });
    }
  }
}

export function* LoadAiassistanSelectedMMSWatcherSaga() {
  yield takeLatest(
    types.ADD_AIASSISTANT_MMS_SELECT_REQUEST,
    addAiAssistantMMSRequest
  );
}

function* addAiAssistantMMSRequest(action) {
  try {
    const temp = action.payload;
    let xx = Object.values(temp).map((item) => {
      let data = { selected: item.selected };
      db.collection("ai_mms_templates").doc(item.doc_id).update(data);
    });
    toast.success("Saved", {
      position: "bottom-right",
      autoClose: 2000,
    });
  } catch (error) {
    toast.error(error, {
      position: "bottom-right",
      autoClose: 2000,
    });
  }
}

export function* LoadAiassistantDELETEMMSlWatcherSaga() {
  yield takeLatest(
    types.DELETE_AIASSISTANT_MMS_REQUEST,
    deleteAiAssistantMMSRequest
  );
}

function* deleteAiAssistantMMSRequest(action) {
  if (action.payload.doc_id) {
    db.collection("ai_mms_templates").doc(action.payload.doc_id).delete();
    toast.success("Deleted", {
      position: "bottom-right",
      autoClose: 2000,
    });
  }
}

export function* LoadANNIVERSERYAiassistantListWatcherSaga() {
  yield takeLatest(
    types.GET_ANNIVERSERY_AIASSISTANT_LIST_REQUEST,
    LoadANNIVERSERYAiassistantListWorkerSaga
  );
}

function* LoadANNIVERSERYAiassistantListWorkerSaga(action) {
  userId = action.payload && action.payload;
  if (userId) {
    try {
      const channel = new eventChannel((emitter) => {
        let listener;
        db.collection("ai_sms_templates").onSnapshot(async () => {
          const requests = [];
          const snap = await db
            .collection("ai_sms_templates")
            .where("uId", "==", userId)
            .where("type", "==", "anniversary")
            .get();
          snap.forEach(function (doc) {
            requests.push(doc.data());
          });

          emitter(
            requests
              .sort((a, b) => {
                if (a.created_at > b.created_at) {
                  return 1;
                }
                if (a.created_at < b.created_at) {
                  return -1;
                }
                return 0;
              })
              .sort((item) => (item.status ? -1 : 1))
          );
        });
        return () => {
          listener.off();
        };
      });
      while (true) {
        const data = yield take(channel);
        yield put({
          type: types.GET_ANNIVERSERY_AIASSISTANT_LIST_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      yield put({
        type: types.GET_ANNIVERSERY_AIASSISTANT_LIST_ERROR,
        payload: error,
      });
    }
  }
}

export function* LoadANNIVERSERYAiassistantAddWatcherSaga() {
  yield takeLatest(
    types.ADD_ANNIVERSERY_AIASSISTANT_REQUEST,
    addANNIVERSERYAiAssistantRequest
  );
}
function* addANNIVERSERYAiAssistantRequest(action) {
  try {
    yield call(addaiAnniveserySms, action);
    yield put({ type: types.ADD_ANNIVERSERY_AIASSISTANT_SUCCESS });
  } catch (error) {
    yield put({
      type: types.ADD_ANNIVERSERY_AIASSISTANT_ERROR,
      payload: error,
    });
  }
}
async function addaiAnniveserySms(action) {
  if (action.payload.doc_id) {
    try {
      if (action.payload.template_header) {
        let smsHeadData = {
          template_header: action.payload.template_header,
          type: "anniversary",
        };
        db.collection("ai_sms_templates")
          .doc(action.payload.doc_id)
          .update(smsHeadData);
      }
      if (action.payload.content) {
        let smscontentData = {
          content: action.payload.content,
          type: "anniversary",
        };
        db.collection("ai_sms_templates")
          .doc(action.payload.doc_id)
          .update(smscontentData);
      }
      toast.success("Updated", {
        position: "bottom-right",
        autoClose: 2000,
      });
    } catch (error) {
      toast.error(error, {
        position: "bottom-right",
        autoClose: 2000,
      });
    }
  } else {
    try {
      let smsData = {
        created_at: new Date().getTime(),
        content: action.payload.content ? action.payload.content : "",
        uId: userId,
        template_header: action.payload.template_header
          ? action.payload.template_header
          : "",
        type: "anniversary",
      };
      let docRef = db.collection("ai_sms_templates").doc();
      const doc_id = docRef.id;
      smsData = { ...smsData, doc_id };
      docRef.set(smsData);
      toast.success("Saved", {
        position: "bottom-right",
        autoClose: 2000,
      });
    } catch (error) {
      toast.error(error, {
        position: "bottom-right",
        autoClose: 2000,
      });
    }
  }
}

export function* LoadANNIVERSERYAiassistantDELETEWatcherSaga() {
  yield takeLatest(
    types.DELETE_ANNIVERSERY_AIASSISTANT_REQUEST,
    deleteANNIVERSERYAiAssistantRequest
  );
}
function* deleteANNIVERSERYAiAssistantRequest(action) {
  if (action.payload.doc_id) {
    db.collection("ai_sms_templates").doc(action.payload.doc_id).delete();
    toast.success("Deleted", {
      position: "bottom-right",
      autoClose: 2000,
    });
  }
}

export function* LoadANNIVERSERYAiassistanSelectedWatcherSaga() {
  yield takeLatest(
    types.ADD_ANNIVERSERY_AIASSISTANT_SELECT_REQUEST,
    LoadANNIVERSERYAiassistantSelectedWorkerSaga
  );
}
function* LoadANNIVERSERYAiassistantSelectedWorkerSaga(action) {
  try {
    const temp = action.payload;
    let xx = Object.values(temp).map((item) => {
      let data = { selected: item.selected };
      db.collection("ai_sms_templates").doc(item.doc_id).update(data);
    });
    toast.success("Save and Updated", {
      position: "bottom-right",
      autoClose: 2000,
    });
  } catch (error) {
    toast.error(error, {
      position: "bottom-right",
      autoClose: 2000,
    });
  }
}

export function* LoadANNIVERSERYAiassistanEmailListWatcherSaga() {
  yield takeLatest(
    types.GET_ANNIVERSERY_AIASSISTANT_EMAIL_REQUEST,
    LoadANNIVERSERYAiassistantemailListWorkerSaga
  );
}
function* LoadANNIVERSERYAiassistantemailListWorkerSaga(action) {
  try {
    const channel = new eventChannel((emitter) => {
      let listener;
      db.collection("ai_email_templates").onSnapshot(async () => {
        const requests = [];
        const snap = await db
          .collection("ai_email_templates")
          .where("uId", "==", userId)
          .where("type", "==", "anniversary")
          .get();
        snap.forEach(function (doc) {
          requests.push(doc.data());
        });

        emitter(
          requests
            .sort((a, b) => {
              if (a.created_at > b.created_at) {
                return 1;
              }
              if (a.created_at < b.created_at) {
                return -1;
              }
              return 0;
            })
            .sort((item) => (item.status ? -1 : 1))
        );
      });
      return () => {
        listener.off();
      };
    });
    while (true) {
      const data = yield take(channel);
      yield put({
        type: types.GET_ANNIVERSERY_AIASSISTANT_EMAIL_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    yield put({
      type: types.GET_ANNIVERSERY_AIASSISTANT_EMAIL_ERROR,
      payload: error,
    });
  }
}

export function* LoadANNIVERSERYAiassistanEmailTemplateAddWatcherSaga() {
  yield takeLatest(
    types.ADD_ANNIVERSERY_AIASSISTANT_EMAIL_REQUEST,
    addANNIVERSERYAiEmailTemplate
  );
}
function* addANNIVERSERYAiEmailTemplate(action) {
  try {
    yield call(addAnniceseryEmail, action);
    yield put({ type: types.ADD_ANNIVERSERY_AIASSISTANT_EMAIL_SUCCESS });
  } catch (error) {
    yield put({
      type: types.ADD_ANNIVERSERY_AIASSISTANT_EMAIL_ERROR,
      payload: error,
    });
  }
}
async function addAnniceseryEmail(action) {
  if (action.payload.doc_id) {
    try {
      if (action.payload.template_header) {
        let emailHeadData = {
          template_header: action.payload.template_header,
          type: "anniversary",
        };
        db.collection("ai_email_templates")
          .doc(action.payload.doc_id)
          .update(emailHeadData);
      }
      if (action.payload.subject) {
        let emailSubjectData = {
          subject: action.payload.subject,
          type: "anniversary",
        };
        db.collection("ai_email_templates")
          .doc(action.payload.doc_id)
          .update(emailSubjectData);
      }
      if (action.payload.content) {
        let emailContentdata = {
          content: action.payload.content,
          type: "anniversary",
        };
        db.collection("ai_email_templates")
          .doc(action.payload.doc_id)
          .update(emailContentdata);
      }
      toast.success("Updated", {
        position: "bottom-right",
        autoClose: 2000,
      });
    } catch (error) {
      toast.error(error, {
        position: "bottom-right",
        autoClose: 2000,
      });
    }
  } else {
    try {
      let emailData = {
        created_at: new Date().getTime(),
        subject: action.payload.subject ? action.payload.subject : "",
        uId: userId,
        template_header: action.payload.template_header
          ? action.payload.template_header
          : "",
        type: "anniversary",
        content: action.payload.content ? action.payload.content : "",
      };
      let docRef = db.collection("ai_email_templates").doc();
      const doc_id = docRef.id;
      emailData = { ...emailData, doc_id };
      docRef.set(emailData);
      toast.success("Saved", {
        position: "bottom-right",
        autoClose: 2000,
      });
    } catch (error) {
      toast.error(error, {
        position: "bottom-right",
        autoClose: 2000,
      });
    }
  }
}

export function* LoadANNIVERSERYAiassistanSelectedEMAILWatcherSaga() {
  yield takeLatest(
    types.ADD_ANNIVERSERY_AIASSISTANT_EMAIL_SELECT_REQUEST,
    addANNIVERSERYAiAssistantEmailRequest
  );
}
function* addANNIVERSERYAiAssistantEmailRequest(action) {
  try {
    const temp = action.payload;
    let xx = Object.values(temp).map((item) => {
      let data = { selected: item.selected };
      db.collection("ai_email_templates").doc(item.doc_id).update(data);
    });
    toast.success("Save and Updated", {
      position: "bottom-right",
      autoClose: 2000,
    });
  } catch (error) {
    toast.error(error, {
      position: "bottom-right",
      autoClose: 2000,
    });
  }
}

export function* LoadANNIVERSERYAiassistantDELETEEmailWatcherSaga() {
  yield takeLatest(
    types.DELETE_ANNIVERSERY_AIASSISTANT_EMAIL_REQUEST,
    deleteANNIVERSERYAiAssistantEmailRequest
  );
}
function* deleteANNIVERSERYAiAssistantEmailRequest(action) {
  if (action.payload.doc_id) {
    db.collection("ai_email_templates").doc(action.payload.doc_id).delete();
    toast.success("Deleted", {
      position: "bottom-right",
      autoClose: 2000,
    });
  }
}

export function* LoadANNIVERSERYAiassistanMmsListWatcherSaga() {
  yield takeLatest(
    types.GET_ANNIVERSERY_AIASSISTANT_MMS_REQUEST,
    LoadANNIVERSERYAiassistantmmsListWorkerSaga
  );
}
function* LoadANNIVERSERYAiassistantmmsListWorkerSaga(action) {
  try {
    const channel = new eventChannel((emitter) => {
      let listener;
      db.collection("ai_mms_templates").onSnapshot(async () => {
        const requests = [];
        const snap = await db
          .collection("ai_mms_templates")
          .where("uId", "==", userId)
          .where("type", "==", "anniversary")
          .get();
        snap.forEach(function (doc) {
          requests.push(doc.data());
        });

        emitter(
          requests
            .sort((a, b) => {
              if (a.created_at > b.created_at) {
                return 1;
              }
              if (a.created_at < b.created_at) {
                return -1;
              }
              return 0;
            })
            .sort((item) => (item.status ? -1 : 1))
        );
      });
      return () => {
        listener.off();
      };
    });
    while (true) {
      const data = yield take(channel);
      yield put({
        type: types.GET_ANNIVERSERY_AIASSISTANT_MMS_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: types.GET_ANNIVERSERY_AIASSISTANT_MMS_ERROR,
      payload: error,
    });
  }
}

export function* LoadANNIVERSERYAiassistanMmsTemplateAddWatcherSaga() {
  yield takeLatest(
    types.ADD_ANNIVERSERY_AIASSISTANT_MMS_REQUEST,
    addANNIVERSERYAiMmsTemplate
  );
}
function* addANNIVERSERYAiMmsTemplate(action) {
  try {
    yield call(addanniceserymms, action);
    yield put({ type: types.ADD_ANNIVERSERY_AIASSISTANT_MMS_SUCCESS });
  } catch (error) {
    yield put({
      type: types.ADD_ANNIVERSERY_AIASSISTANT_MMS_ERROR,
      payload: error,
    });
  }
}
async function addanniceserymms(action) {
  if (action.payload.doc_id) {
    try {
      if (action.payload.template_header) {
        let mmsHeadData = {
          template_header: action.payload.template_header,
          type: "anniversary",
        };
        db.collection("ai_mms_templates")
          .doc(action.payload.doc_id)
          .update(mmsHeadData);
      }
      if (action.payload.content) {
        let mmscontentData = {
          content: action.payload.content,
          type: "anniversary",
        };
        db.collection("ai_mms_templates")
          .doc(action.payload.doc_id)
          .update(mmscontentData);
      }
      if (action.payload.imageUrl) {
        let imageUrlData = {
          image_url: action.payload.imageUrl,
          type: "anniversary",
        };
        db.collection("ai_mms_templates")
          .doc(action.payload.doc_id)
          .update(imageUrlData);
      }
      if (action.payload.videoUrl) {
        let videoUrlData = {
          video_url: action.payload.videoUrl,
          type: "anniversary",
        };
        db.collection("ai_mms_templates")
          .doc(action.payload.doc_id)
          .update(videoUrlData);
      }
      toast.success("Updated", {
        position: "bottom-right",
        autoClose: 2000,
      });
    } catch (error) {
      toast.error(error, {
        position: "bottom-right",
        autoClose: 2000,
      });
    }
  } else {
    try {
      let mmsData = {
        created_at: new Date().getTime(),
        content: action.payload.content ? action.payload.content : "",
        uId: userId,
        template_header: action.payload.template_header
          ? action.payload.template_header
          : "",
        type: "anniversary",
        image_url: action.payload.imageUrl ? action.payload.imageUrl : "",
        video_url: action.payload.videoUrl ? action.payload.videoUrl : "",
      };
      let docRef = db.collection("ai_mms_templates").doc();
      const doc_id = docRef.id;
      mmsData = { ...mmsData, doc_id };
      docRef.set(mmsData);
      toast.success("Saved", {
        position: "bottom-right",
        autoClose: 2000,
      });
    } catch (error) {
      toast.error(error, {
        position: "bottom-right",
        autoClose: 2000,
      });
    }
  }
}

export function* LoadANNIVERSERYAiassistanSelectedMMSWatcherSaga() {
  yield takeLatest(
    types.ADD_ANNIVERSERY_AIASSISTANT_MMS_SELECT_REQUEST,
    addANNIVERSERYAiAssistantMMSRequest
  );
}
function* addANNIVERSERYAiAssistantMMSRequest(action) {
  try {
    const temp = action.payload;
    let xx = Object.values(temp).map((item) => {
      let data = { selected: item.selected };
      db.collection("ai_mms_templates").doc(item.doc_id).update(data);
    });
    toast.success("Save And Updated", {
      position: "bottom-right",
      autoClose: 2000,
    });
  } catch (error) {
    toast.error(error, {
      position: "bottom-right",
      autoClose: 2000,
    });
  }
}

export function* LoadANNIVERSERYAiassistantDELETEMMSlWatcherSaga() {
  yield takeLatest(
    types.DELETE_ANNIVERSERY_AIASSISTANT_MMS_REQUEST,
    deleteANNIVERSERYAiAssistantMMSRequest
  );
}
function* deleteANNIVERSERYAiAssistantMMSRequest(action) {
  if (action.payload.doc_id) {
    db.collection("ai_mms_templates").doc(action.payload.doc_id).delete();
    toast.success("Deleted", {
      position: "bottom-right",
      autoClose: 2000,
    });
  }
}

export function* LoadPostCloseListWatcherSaga() {
  yield takeLatest(
    types.GET_POSTCLOSENURTURE_LIST_REQUEST,
    LoadPostCloseListWorkerSaga
  );
}
function* LoadPostCloseListWorkerSaga(action) {
  userId = action.payload && action.payload;
  if (userId) {
    try {
      const channel = new eventChannel((emitter) => {
        let listener;
        db.collection("ai_sms_templates").onSnapshot(async () => {
          const requests = [];
          const snap = await db
            .collection("ai_sms_templates")
            .where("uId", "==", userId)
            .where("type", "==", "post_nurture")
            .get();
          snap.forEach(function (doc) {
            requests.push(doc.data());
          });

          emitter(
            requests
              .sort((a, b) => {
                if (a.created_at > b.created_at) {
                  return 1;
                }
                if (a.created_at < b.created_at) {
                  return -1;
                }
                return 0;
              })
              .sort((item) => (item.status ? -1 : 1))
          );
        });
        return () => {
          listener.off();
        };
      });
      while (true) {
        const data = yield take(channel);
        yield put({
          type: types.GET_POSTCLOSENURTURE_LIST_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      console.log(error);
      yield put({
        type: types.GET_POSTCLOSENURTURE_LIST_ERROR,
        payload: error,
      });
    }
  }
}

export function* LoadPostCloseAddWatcherSaga() {
  yield takeLatest(types.ADD_POSTCLOSENURTURE_REQUEST, addPostCloseRequest);
}

function* addPostCloseRequest(action) {
  try {
    yield call(addPostCloseSms, action);
    yield put({ type: types.ADD_POSTCLOSENURTURE_SUCCESS });
  } catch (error) {
    yield put({ type: types.ADD_POSTCLOSENURTURE_ERROR, payload: error });
  }
}
async function addPostCloseSms(action) {
  if (action.payload.doc_id) {
    try {
      if (action.payload.template_header) {
        let smsHeadData = {
          template_header: action.payload.template_header,
          type: "post_nurture",
        };
        db.collection("ai_sms_templates")
          .doc(action.payload.doc_id)
          .update(smsHeadData);
      }
      if (action.payload.content) {
        let smscontentData = {
          content: action.payload.content,
          type: "post_nurture",
        };
        db.collection("ai_sms_templates")
          .doc(action.payload.doc_id)
          .update(smscontentData);
      }
      toast.success("Updated", {
        position: "bottom-right",
        autoClose: 2000,
      });
    } catch (error) {
      toast.error(error, {
        position: "bottom-right",
        autoClose: 2000,
      });
    }
  } else {
    try {
      let smsData = {
        created_at: new Date().getTime(),
        content: action.payload.content ? action.payload.content : "",
        uId: userId,
        template_header: action.payload.template_header
          ? action.payload.template_header
          : "",
        type: "post_nurture",
        selected: false,
      };
      let docRef = db.collection("ai_sms_templates").doc();
      const doc_id = docRef.id;
      smsData = { ...smsData, doc_id };
      docRef.set(smsData);
      toast.success("Saved", {
        position: "bottom-right",
        autoClose: 2000,
      });
    } catch (error) {
      toast.error(error, {
        position: "bottom-right",
        autoClose: 2000,
      });
    }
  }
}

export function* LoadPostCloseDELETEWatcherSaga() {
  yield takeLatest(
    types.DELETE_POSTCLOSENURTURE_REQUEST,
    deletePostCloseRequest
  );
}

function* deletePostCloseRequest(action) {
  try {
    yield call(deletePostClosesmsTemplate, action);
    yield put({
      type: types.DELETE_POSTCLOSENURTURE_SUCCESS,
      payload: "sucessss",
    });
  } catch (error) {
    yield put({ type: types.DELETE_POSTCLOSENURTURE_ERROR, payload: error });
  }
}
async function deletePostClosesmsTemplate(action) {
  if (action.payload.doc_id) {
    db.collection("ai_sms_templates").doc(action.payload.doc_id).delete();
    toast.success("Deleted", {
      position: "bottom-right",
      autoClose: 2000,
    });
  }
}

export function* LoadPostCloseSelectedWatcherSaga() {
  yield takeLatest(
    types.ADD_POSTCLOSENURTURE_SELECT_REQUEST,
    LoadPostCloseSelectedWorkerSaga
  );
}
function* LoadPostCloseSelectedWorkerSaga(action) {
  try {
    const temp = action.payload;
    let xx = Object.values(temp).map((item) => {
      let data = { selected: item.selected };
      db.collection("ai_sms_templates").doc(item.doc_id).update(data);
    });
    toast.success("Saved And Updated", {
      position: "bottom-right",
      autoClose: 2000,
    });
  } catch (error) {
    toast.error(error, {
      position: "bottom-right",
      autoClose: 2000,
    });
  }
}

export function* LoadPostCloseEmailListWatcherSaga() {
  yield takeLatest(
    types.GET_POSTCLOSENURTURE_EMAIL_REQUEST,
    LoadPostCloseemailListWorkerSaga
  );
}
function* LoadPostCloseemailListWorkerSaga(action) {
  try {
    const channel = new eventChannel((emitter) => {
      let listener;
      db.collection("ai_email_templates").onSnapshot(async () => {
        const requests = [];
        const snap = await db
          .collection("ai_email_templates")
          .where("uId", "==", userId)
          .where("type", "==", "post_nurture")
          .get();
        snap.forEach(function (doc) {
          requests.push(doc.data());
        });

        emitter(
          requests
            .sort((a, b) => {
              if (a.created_at > b.created_at) {
                return 1;
              }
              if (a.created_at < b.created_at) {
                return -1;
              }
              return 0;
            })
            .sort((item) => (item.status ? -1 : 1))
        );
      });
      return () => {
        listener.off();
      };
    });
    while (true) {
      const data = yield take(channel);
      yield put({
        type: types.GET_POSTCLOSENURTURE_EMAIL_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    console.log(error);
    yield put({ type: types.GET_POSTCLOSENURTURE_EMAIL_ERROR, payload: error });
  }
}

export function* LoadPostCloseEmailTemplateAddWatcherSaga() {
  yield takeLatest(
    types.ADD_POSTCLOSENURTURE_EMAIL_REQUEST,
    addPostCloseEmailTemplate
  );
}
function* addPostCloseEmailTemplate(action) {
  try {
    yield call(addPostCloseEmail, action);
    yield put({ type: types.ADD_POSTCLOSENURTURE_EMAIL_SUCCESS });
  } catch (error) {
    yield put({ type: types.ADD_POSTCLOSENURTURE_EMAIL_ERROR, payload: error });
  }
}
async function addPostCloseEmail(action) {
  if (action.payload.doc_id) {
    try {
      if (action.payload.template_header) {
        let emailHeadData = {
          template_header: action.payload.template_header,
          type: "post_nurture",
        };
        db.collection("ai_email_templates")
          .doc(action.payload.doc_id)
          .update(emailHeadData);
      }
      if (action.payload.subject) {
        let emailSubjectData = {
          subject: action.payload.subject,
          type: "post_nurture",
        };
        db.collection("ai_email_templates")
          .doc(action.payload.doc_id)
          .update(emailSubjectData);
      }
      if (action.payload.content) {
        let emailContentdata = {
          content: action.payload.content,
          type: "post_nurture",
        };
        db.collection("ai_email_templates")
          .doc(action.payload.doc_id)
          .update(emailContentdata);
      }
      toast.success("Updated", {
        position: "bottom-right",
        autoClose: 2000,
      });
    } catch (error) {
      toast.error(error, {
        position: "bottom-right",
        autoClose: 2000,
      });
    }
  } else {
    try {
      let emailData = {
        created_at: new Date().getTime(),
        subject: action.payload.subject ? action.payload.subject : "",
        uId: userId,
        template_header: action.payload.template_header
          ? action.payload.template_header
          : "",
        type: "post_nurture",
        content: action.payload.content ? action.payload.content : "",
      };
      let docRef = db.collection("ai_email_templates").doc();
      const doc_id = docRef.id;
      emailData = { ...emailData, doc_id };
      docRef.set(emailData);
      toast.success("Saved", {
        position: "bottom-right",
        autoClose: 2000,
      });
    } catch (error) {
      toast.error(error, {
        position: "bottom-right",
        autoClose: 2000,
      });
    }
  }
}

export function* LoadPostCloseSelectedEMAILWatcherSaga() {
  yield takeLatest(
    types.ADD_POSTCLOSENURTURE_EMAIL_SELECT_REQUEST,
    addPostCloseEmailRequest
  );
}
function* addPostCloseEmailRequest(action) {
  try {
    const temp = action.payload;
    let xx = Object.values(temp).map((item) => {
      let data = { selected: item.selected };
      db.collection("ai_email_templates").doc(item.doc_id).update(data);
    });
    toast.success("Save and Updated", {
      position: "bottom-right",
      autoClose: 2000,
    });
  } catch (error) {
    toast.error(error, {
      position: "bottom-right",
      autoClose: 2000,
    });
  }
}

export function* LoadPostCloseDELETEEmailWatcherSaga() {
  yield takeLatest(
    types.DELETE_POSTCLOSENURTURE_EMAIL_REQUEST,
    deletePostCloseEmailRequest
  );
}
function* deletePostCloseEmailRequest(action) {
  if (action.payload.doc_id) {
    db.collection("ai_email_templates").doc(action.payload.doc_id).delete();
    toast.success("Deleted", {
      position: "bottom-right",
      autoClose: 2000,
    });
  }
}

export function* LoadPostCloseMmsListWatcherSaga() {
  yield takeLatest(
    types.GET_POSTCLOSENURTURE_MMS_REQUEST,
    LoadPostClosemmsListWorkerSaga
  );
}
function* LoadPostClosemmsListWorkerSaga(action) {
  try {
    const channel = new eventChannel((emitter) => {
      let listener;
      db.collection("ai_mms_templates").onSnapshot(async () => {
        const requests = [];
        const snap = await db
          .collection("ai_mms_templates")
          .where("uId", "==", userId)
          .where("type", "==", "post_nurture")
          .get();
        snap.forEach(function (doc) {
          requests.push(doc.data());
        });

        emitter(
          requests
            .sort((a, b) => {
              if (a.created_at > b.created_at) {
                return 1;
              }
              if (a.created_at < b.created_at) {
                return -1;
              }
              return 0;
            })
            .sort((item) => (item.status ? -1 : 1))
        );
      });
      return () => {
        listener.off();
      };
    });
    while (true) {
      const data = yield take(channel);
      yield put({
        type: types.GET_POSTCLOSENURTURE_MMS_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    yield put({ type: types.GET_POSTCLOSENURTURE_MMS_ERROR, payload: error });
  }
}

export function* LoadPostCloseMmsTemplateAddWatcherSaga() {
  yield takeLatest(
    types.ADD_POSTCLOSENURTURE_MMS_REQUEST,
    addPostCloseMmsTemplate
  );
}
function* addPostCloseMmsTemplate(action) {
  try {
    yield call(addPostCloseMMS, action);
    yield put({ type: types.ADD_POSTCLOSENURTURE_MMS_SUCCESS });
  } catch (error) {
    yield put({ type: types.ADD_POSTCLOSENURTURE_MMS_ERROR, payload: error });
  }
}
async function addPostCloseMMS(action) {
  if (action.payload.doc_id) {
    try {
      if (action.payload.template_header) {
        let mmsHeadData = {
          template_header: action.payload.template_header,
          type: "post_nurture",
        };
        db.collection("ai_mms_templates")
          .doc(action.payload.doc_id)
          .update(mmsHeadData);
      }
      if (action.payload.content) {
        let mmscontentData = {
          content: action.payload.content,
          type: "post_nurture",
        };
        db.collection("ai_mms_templates")
          .doc(action.payload.doc_id)
          .update(mmscontentData);
      }
      if (action.payload.imageUrl) {
        let imageUrlData = {
          image_url: action.payload.imageUrl,
          type: "post_nurture",
        };
        db.collection("ai_mms_templates")
          .doc(action.payload.doc_id)
          .update(imageUrlData);
      }
      if (action.payload.videoUrl) {
        let videoUrlData = {
          video_url: action.payload.videoUrl,
          type: "post_nurture",
        };
        db.collection("ai_mms_templates")
          .doc(action.payload.doc_id)
          .update(videoUrlData);
      }
      toast.success("Updated", {
        position: "bottom-right",
        autoClose: 2000,
      });
    } catch (error) {
      toast.error(error, {
        position: "bottom-right",
        autoClose: 2000,
      });
    }
  } else {
    try {
      let mmsData = {
        image_url: action.payload.imageUrl ? action.payload.imageUrl : "",
        created_at: new Date().getTime(),
        content: action.payload.content ? action.payload.content : "",
        uId: userId,
        template_header: action.payload.template_header
          ? action.payload.template_header
          : "",
        type: "post_nurture",
        video_url: action.payload.videoUrl ? action.payload.videoUrl : "",
      };
      let docRef = db.collection("ai_mms_templates").doc();
      const doc_id = docRef.id;
      mmsData = { ...mmsData, doc_id };
      docRef.set(mmsData);
      toast.success("Saved", {
        position: "bottom-right",
        autoClose: 2000,
      });
    } catch (error) {
      toast.error(error, {
        position: "bottom-right",
        autoClose: 2000,
      });
    }
  }
}

export function* LoadPostCloseSelectedMMSWatcherSaga() {
  yield takeLatest(
    types.ADD_POSTCLOSENURTURE_MMS_SELECT_REQUEST,
    addPostCloseMMSRequest
  );
}

function* addPostCloseMMSRequest(action) {
  try {
    const temp = action.payload;
    let xx = Object.values(temp).map((item) => {
      let data = { selected: item.selected };
      db.collection("ai_mms_templates").doc(item.doc_id).update(data);
    });
    toast.success("Saved", {
      position: "bottom-right",
      autoClose: 2000,
    });
  } catch (error) {
    toast.error(error, {
      position: "bottom-right",
      autoClose: 2000,
    });
  }
}

export function* LoadPostCloseDELETEMMSlWatcherSaga() {
  yield takeLatest(
    types.DELETE_POSTCLOSENURTURE_MMS_REQUEST,
    deletePostCloseMMSRequest
  );
}

function* deletePostCloseMMSRequest(action) {
  if (action.payload.doc_id) {
    db.collection("ai_mms_templates").doc(action.payload.doc_id).delete();
    toast.success("Deleted", {
      position: "bottom-right",
      autoClose: 2000,
    });
  }
}

export function* LoadRemoveImageWatcherSaga() {
  try {
    yield takeLatest(
      types.REMOVE_AIASSISTANT_MMS_IMAGE_REQUEST,
      removeImageMMS
    );
  } catch (e) {}
}

function* removeImageMMS(action) {
  const channel = new eventChannel((emitter) => {
    let listener;
    db.collection("ai_mms_templates").onSnapshot(async () => {
      const requests = [];
      const snap = await db
        .collection("ai_mms_templates")
        .where("doc_id", "==", action.payload.doc_id)
        .get();
      snap.forEach(function (doc) {
        requests.push(doc.data());
      });
      emitter(requests);
    });
    return () => {
      listener.off();
    };
  });
  while (true) {
    const data = yield take(channel);
    try {
      if (action.payload.type === "image") {
        let urls = data[0].image_url;
        const indexofremove = urls.indexOf(action.payload.removeUrl);
        let result = urls.filter((data, idx) => idx !== indexofremove);
        let uploadedurl = { image_url: result };
        db.collection("ai_mms_templates")
          .doc(action.payload.doc_id)
          .update(uploadedurl);
        yield put({ type: types.REMOVE_AIASSISTANT_MMS_IMAGE_SUCCESS });
      } else if (action.payload.type === "video") {
        let urls = data[0].video_url;
        const indexofremove = urls.indexOf(action.payload.removeUrl);
        let result = urls.filter((data, idx) => idx !== indexofremove);
        let uploadedurl = { video_url: result };
        db.collection("ai_mms_templates")
          .doc(action.payload.doc_id)
          .update(uploadedurl);
        yield put({ type: types.REMOVE_AIASSISTANT_MMS_IMAGE_SUCCESS });
      }
    } catch (e) {
      yield put({ type: types.REMOVE_AIASSISTANT_MMS_IMAGE_ERROR, payload: e });
    }
  }
}

export function* LoadSETNURTUREDATEWatcherSaga() {
  yield takeLatest(types.SET_POSTCLOSENURTURE_DATE, setPostcloseDate);
}

function* setPostcloseDate(action) {
  try {
    yield call(setNurtureDate, action);
    yield put({ type: types.SET_POSTCLOSENURTURE_DATE_SUCCESS });
  } catch (error) {
    yield put({ type: types.SET_POSTCLOSENURTURE_DATE_ERROR, payload: error });
  }
}

function setNurtureDate(action) {
  if (action.payload.uId) {
    db.collection("users")
      .doc(action.payload.uId)
      .update({
        post_close_nurture_dates: action.payload.post_close_nurture_dates,
      });
    db.collection("users")
      .doc(action.payload.uId)
      .update({
        post_close_nurture_details: action.payload.post_close_nurture_details,
      });
    toast.success("Dates Saved", {
      position: "bottom-right",
      autoClose: 2000,
    });
  }
}

export function* LoadNurtureDateListWatcherSaga() {
  yield takeLatest(
    types.GET_POSTCLOSENURTURE_DATE,
    LoadNurtureDateListWorkerSaga
  );
}

function* LoadNurtureDateListWorkerSaga(action) {
  try {
    const data = yield call(getPostCloseDate, action);
    yield put({ type: types.GET_POSTCLOSENURTURE_DATE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: types.GET_POSTCLOSENURTURE_DATE_ERROR, payload: error });
  }
}

async function getPostCloseDate(action) {
  let val = await db.collection("users").doc(action.payload).get();
  let nurture = val.data().post_close_nurture_details
    ? val.data().post_close_nurture_details
    : [];
  var a = nurture.nurtureDate1.split(/\D+/);
  let aa = new Date(Date.UTC(a[0], --a[1], a[2], a[3], a[4], a[5], a[6]));
  var b = nurture.nurtureDate2.split(/\D+/);
  let bb = new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
  var c = nurture.nurtureDate3.split(/\D+/);
  let cc = new Date(Date.UTC(c[0], --c[1], c[2], c[3], c[4], c[5], c[6]));
  var d = nurture.nurtureDate4.split(/\D+/);
  let dd = new Date(Date.UTC(d[0], --d[1], d[2], d[3], d[4], d[5], d[6]));
  return [aa, bb, cc, dd];
}