import React from "react";
import YesOrNo from "react-yes-or-no";
import SelectPlan from "../../Modal/SelectPlan";
import "./ApprovedFreePeriod.scss";
import moment from "moment";
export default class ApprovedFreePeriod extends React.Component {
  constructor(props) {
    super(props);
    this.onRejectClick = this.onRejectClick.bind(this);
    this.extendsHandeler = this.extendsHandeler.bind(this);
    this.planSelect = this.planSelect.bind(this);
    this.rejectConfirm = this.rejectConfirm.bind(this);
  }
  state = {
    userData: {},
    showRejectPopUp: false,
    showSelectPlan: false,
  };
  onRejectClick = (list) => {
    this.setState({
      showRejectPopUp: !this.state.showRejectPopUp,
      userData: list,
    });
  };
  extendsHandeler(item, index) {
    this.setState({
      showSelectPlan: !this.state.showSelectPlan,
      userData: item,
    });
  }

  rejectConfirm() {
    this.props.stop(this.state.userData.uid, this.state.userData.planType);
  }

  planSelect(planType, planValue, planPeriod) {
    this.props.planSelect(
      planType,
      planValue,
      planPeriod,
      this.state.userData.doc_id,
      this.state.userData.uid
    );
  }
  render() {
    return (
      <div className="table-layout mt-3">
        <table className="table" cellSpacing="0">
          <thead className="table-header">
            <tr id="ap-fp-tableHeadRow">
              <th className="font-hn-medium ap-fp-table-headText">UserInfo</th>
              <th className="font-hn-medium ap-fp-table-headText pl-0">
                Phone
              </th>
              <th className="font-hn-medium ap-fp-table-headText pl-0">Bank</th>
              <th className="font-hn-medium ap-fp-table-headText pl-0">
                Requested
              </th>
              <th className="font-hn-medium ap-fp-table-headText pl-0">
                Approved
              </th>
              <th className="font-hn-medium ap-fp-table-headText pl-0">
                Remaining
              </th>
              <th className="font-hn-medium ap-fp-table-headText ap-fp-actionTh pl-0">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.data.map((list, index) => {
              let currentDate = new Date().toLocaleDateString();
              if (list.approved_planValue) {
                let a = moment(currentDate, "DD/MM/YYYY");
                let b = moment(list.planValue, "DD/MM/YYYY");
                let diff = b.diff(a, "days");
                if (diff <= 0) {
                  diff = 0;
                }
                list = { ...list, remainingDays: diff };
              }
              return (
                <tr id="ap-fp-tableBodyRow" key={list.doc_id}>
                  <td className="font-hn-medium ap-fp-tableText pt-4">
                    <div className="tableText font-hn-bold">
                      {list.firstName} {list.lastName}
                    </div>
                    <div className="ap-fp-tableEmailText font-hn-medium">
                      {list.email}
                    </div>
                  </td>
                  <td className="font-hn-medium ap-fp-tableText pt-4 pl-1">
                    {list.phoneNumber}
                  </td>
                  <td className="font-hn-medium ap-fp-tableText pt-4 pl-1">
                    {list.bankName}
                  </td>
                  <td className="font-hn-medium ap-fp-tableText pt-4 pl-1">
                    {list.approved_planValue
                      ? list.approved_planValue
                      : list.noOfLicense}{" "}
                    {list.approved_planValue ? "" : list.requestedPlanPeriod}
                  </td>
                  <td className="font-hn-medium ap-fp-tableText pt-4 pl-1">
                    {list.approved_planValue && list.planValue}{" "}
                    {!list.approved_planValue && list.planPeriod}
                  </td>
                  <td className="font-hn-medium ap-fp-tableText pt-4 pl-1">
                    {list.remainingDays ? list.remainingDays + "Days" : "--"}
                  </td>
                  {list.uid && list.planValue ? (
                    <td className="ap-fp-actionTh pl-0">
                      <div className="row mx-0">
                        <div className="col-md-6 d-flex justify-content-start pl-0">
                          <button
                            className="ap-fp-addBtn btn font-11 font-hn-medium"
                            onClick={() => this.extendsHandeler(list)}
                          >
                            EXTEND
                          </button>
                        </div>
                        <div className="col-md-6 d-flex justify-content-end">
                          <button
                            className="ap-fp-stopBtn btn  font-11 font-hn-medium"
                            onClick={() => this.onRejectClick(list)}
                          >
                            STOP
                          </button>
                        </div>
                      </div>
                    </td>
                  ) : !list.planValue ? (
                    <td className="ap-fa-actionTh">
                      <div className="row mx-0 d-flex justify-content-end">
                        <div className="col-md-6">
                          <button
                            id="btnWithOutBorder"
                            className="font-11 font-hn-medium font-weight-bolder text-danger "
                          >
                            Stopped
                          </button>
                        </div>
                      </div>
                    </td>
                  ) : (
                    <td className="ap-fa-actionTh">
                      <div className="row mx-0 d-flex justify-content-end">
                        <div className="col-md-6  ">
                          <button
                            id="btnWithOutBorder"
                            className="font-11 font-hn-medium font-weight-bolder text-primary "
                          >
                            Awaiting Login
                          </button>
                        </div>
                      </div>
                    </td>
                  )}
                </tr>
              );
            })}
            {this.state.showRejectPopUp && (
              <YesOrNo
                show={this.state.showRejectPopUp}
                onClose={this.onRejectClick}
                message="Are you sure to Stop?"
                confirmText="Yes"
                cancelText="No"
                confirmHandler={this.rejectConfirm}
                isLoading={this.props.isLoading}
                notify={this.notify}
                notificationText={
                  this.props.error
                    ? this.props.error.response.data.msg
                    : "Plan is stopped successfully"
                }
              />
            )}
            {this.state.showSelectPlan && (
              <SelectPlan
                planSelect={this.planSelect}
                plan={"freePeriod"}
                isLoading={this.props.isLoading}
                shown={this.state.showSelectPlan}
                userData={this.state.userData}
                onClose={this.extendsHandeler}
                notify={this.props.notify}
                notificationText={
                  this.props.error
                    ? this.props.error.response.data.msg
                    : "Free Period Extended Successfully"
                }
              />
            )}
          </tbody>
        </table>
      </div>
    );
  }
}
