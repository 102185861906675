import { types } from "./actions";
const initialState = {
  getPackageRequest: false,
  getPackageSuccess: false,
  getPackageError: null,
  addPackageRequest: false,
  addPackageSuccess: false,
  addPackageError: null,
  updatePackageRequest: false,
  updatePackageSuccess: false,
  updatePackageError: null,
  deletePackageRequest: false,
  deletePackageSuccess: false,
  deletePackageError: null,
  packageList: [],
};

const strategies = {
  [types.GET_PACKAGES_REQUEST]: getPackageRequest,
  [types.GET_PACKAGES_SUCCESS]: getPackageSuccess,
  [types.GET_PACKAGES_ERROR]: getPackageError,

  [types.ADD_PACKAGE_REQUEST]: addPackageRequest,
  [types.ADD_PACKAGE_SUCCESS]: addPackageSuccess,
  [types.ADD_PACKAGE_ERROE]: addPackageError,

  [types.UPDATE_PACKAGE_REQUEST]: updatePackageRequest,
  [types.UPDATE_PACKAGE_SUCCESS]: updatePackageSuccess,
  [types.UPDATE_PACKAGE_ERROE]: updatePackageError,

  [types.DELETE_PACKAGE_REQUEST]: deletePackageRequest,
  [types.DELETE_PACKAGE_SUCCESS]: deletePackageSuccess,
  [types.DELETE_PACKAGE_ERROE]: deletePackageError,

  __default__: (state) => ({ ...state, error: null }),
};

export default function reducer(state = initialState, action) {
  const transformer = strategies[action.type]
    ? strategies[action.type]
    : strategies.__default__;
  return transformer(state, action);
}

function getPackageRequest(state) {
  return {
    ...state,
    getPackageRequest: true,
    getPackageSuccess: false,
    getPackageError: null,
  };
}

function getPackageSuccess(state, action) {
  return {
    ...state,
    packageList: action.payload,
    getPackageRequest: false,
    getPackageSuccess: true,
    getPackageError: null,
  };
}

function getPackageError(state, action) {
  return {
    ...state,
    getPackageRequest: false,
    getPackageSuccess: false,
    getPackageError: action,
  };
}
function addPackageRequest(state, action) {
  return {
    ...state,
    addPackageRequest: true,
    addPackageSuccess: false,
    addPackageError: null,
  };
}
function addPackageSuccess(state, action) {
  return {
    ...state,

    addPackageRequest: false,
    addPackageSuccess: true,
    addPackageError: null,
  };
}
function addPackageError(state, action) {
  return {
    ...state,
    addPackageRequest: false,
    addPackageSuccess: false,
    addPackageError: action,
  };
}
function updatePackageRequest(state, action) {
  return {
    ...state,
    updatePackageRequest: true,
    updatePackageSuccess: false,
    updatePackageError: null,
  };
}
function updatePackageSuccess(state, action) {
  return {
    ...state,

    updatePackageRequest: false,
    updatePackageSuccess: true,
    updatePackageError: null,
  };
}
function updatePackageError(state, action) {
  return {
    ...state,
    updatePackageRequest: false,
    updatePackageSuccess: false,
    updatePackageError: action,
  };
}
function deletePackageRequest(state, action) {
  return {
    ...state,
    deletePackageRequest: true,
    deletePackageSuccess: false,
    deletePackageError: null,
  };
}
function deletePackageSuccess(state, action) {
  return {
    ...state,

    deletePackageRequest: false,
    deletePackageSuccess: true,
    deletePackageError: null,
  };
}
function deletePackageError(state, action) {
  return {
    ...state,
    deletePackageRequest: false,
    deletePackageSuccess: false,
    deletePackageError: action,
  };
}
