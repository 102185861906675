import { types } from "./actions";
import { takeLatest, put, take, call } from "redux-saga/effects";
import { db } from "../../app/utilities/firebase";
import { eventChannel } from "redux-saga";
import axios from "axios";
import { getUserToken, config } from "../../app/utilities/firebase";

export function* EncompassDetailsWatcherSaga() {
  yield takeLatest(
    types.GET_ENCOMPASS_DETAILS_REQUEST,
    EncompassDetailsWorkerSaga
  );
}
function* EncompassDetailsWorkerSaga() {
  try {
    const channel = new eventChannel((emitter) => {
      let listener;
      db.collection("encompassDetails").onSnapshot(async () => {
        const requests = [];
        const snap = await db.collection("encompassDetails").get();
        for (let i = 0; i < snap.docs.length; i++) {
          const info = await db
            .collection("users")
            .doc(snap.docs[i].data().uid)
            .get();
          let data = {
            ...snap.docs[i].data(),
            ...info.data(),
          };
          requests.push(data);
        }

        emitter(
          requests.sort((a, b) => {
            if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
              return -1;
            }
            if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
              return 1;
            }
            return 0;
          })
        );
      });
      return () => listener.off();
    });
    while (true) {
      const data = yield take(channel);
      yield put({ type: types.GET_ENCOMPASS_DETAILS_SUCCESS, payload: data });
    }
  } catch (error) {
    yield put({ type: types.GET_ENCOMPASS_DETAILS_ERROR, payload: error });
  }
}

export function* DeleteSubscriptionWatcherSaga() {
  yield takeLatest(
    types.DELETE_SUBSCRIPTION_REQUEST,
    deleteSubscriptionWorkerSaga
  );
}
function* deleteSubscriptionWorkerSaga(action) {
  try {
    const response = yield call(deleteSubscription, action.payload);
    if (response) {
      yield put({ type: types.DELETE_SUBSCRIPTION_SUCCESS });
    } else {
      yield put({
        type: types.DELETE_SUBSCRIPTION_ERROR,
        payload: action.payload,
      });
    }
  } catch (error) {
    yield put({ type: types.DELETE_SUBSCRIPTION_ERROR });
  }
}

async function deleteSubscription(uid) {
  return await axios.delete(`${config.apiUrl}/deletesubscriptionEncompass`, {
    headers: {
      Authorization: await getUserToken(),
    },
    data: {
      uid: uid,
    },
  });
}

export function* CreateSubscriptionWatcherSaga() {
  yield takeLatest(
    types.CREATE_SUBSCRIPTION_REQUEST,
    CreateSubscriptionWorkerSaga
  );
}
function* CreateSubscriptionWorkerSaga(action) {
  try {
    const response = yield call(CreateSubscription, action.payload);
    if (response) {
      yield put({ type: types.CREATE_SUBSCRIPTION_SUCCESS });
    } else {
      yield put({
        type: types.CREATE_SUBSCRIPTION_ERROR,
      });
    }
  } catch (error) {
    yield put({
      type: types.CREATE_SUBSCRIPTION_ERROR,
    });
  }
}

async function CreateSubscription(uid) {
  return await axios.post(
    `${config.apiUrl}/createsubscriptionEncompass`,
    {
      uid: uid,
    },

    {
      headers: {
        Authorization: await getUserToken(),
      },
    }
  );
}
