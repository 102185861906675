import Modal from "react-responsive-modal";
import "./confirmationModalGlobal.scss";
import React, { Component } from "react";
import { UserType } from "../../constants/commonEnums";

export class ConfirmationModalGlobal extends Component {
  constructor(props) {
    super(props);
    this.returnback = this.returnback.bind(this);
  }

  componentDidMount() {
    if (
      this.props.deleteUserFlag
      && this.props.deleteUserDetails.userType === UserType.ADMIN
      && this.props.otherAdminsList
      && this.props.otherAdminsList[0]
      && !this.state.assignToAdminId
    ) {
      this.setState({ assignToAdminId: this.props.otherAdminsList[0].uid });
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.deleteUserFlag && !this.props.isLoading) {
      this.props.onClose();
    }
  }

  state = {
    confirmationText: "",
    assignToAdminId: '',
  };

  nothing() {
    return null;
  }

  returnback(e) {
    this.props.confirmHandler(this.state.assignToAdminId);
    if (this.props.deleteUserFlag) {
      this.props.onClose();
    }
  }

  render() {
    return (
      <Modal
        open={this.props.show}
        onClose={!this.props.isLoading ? this.props.onClose : this.nothing}
        center
      >
        <div className="confirmationConfirm">
          <div id="confirmationConfirmBody" className="mt-5 pt-3">
            <div className="d-flex justify-content-center">
              <h6 className="text-center">
                {this.props.message
                  ? this.props.message
                  : "Are you sure you want to continue?"}
              </h6>
            </div>
            {this.props.deleteUserFlag ? (
              <div>
                <div className="justify-content-center">
                  <span className="text-center">
                    Enter "delete" to delete account
                  </span>
                  <div className="d-flex text-center justify-content-center">
                    <span className="text-capitalize">
                      {this.props.deleteUserDetails.firstName +
                        " " +
                        this.props.deleteUserDetails.lastName}
                    </span>
                    <span className="pl-1">
                      { this.props.deleteUserDetails.email && 
                      <>
                        ({this.props.deleteUserDetails.email})
                      </>
                      }
                    </span>
                  </div>
                </div>
                <input
                  type="text"
                  className="form-control justify-content-center w-50 mx-auto"
                  placeholder='Type "delete" here'
                  value={this.state.confirmationText}
                  onChange={(e) =>
                    this.setState({ confirmationText: e.target.value })
                  }
                />
                {this.props.deleteUserDetails.userType === UserType.ADMIN && (
                  <div className="w-75 mx-auto mt-4">
                    <label htmlFor="assignAdminId">Select another admin to transfer data to</label>
                    <select name="assignAdminId" className="form-control" value={this.state.assignToAdminId} onChange={(eve) => this.setState({ assignToAdminId: eve.target.value })}>
                      <option value={''} hidden>Select</option>
                      {
                        !!this.props.otherAdminsList && this.props.otherAdminsList.map(admin => (<option key={admin.uid} value={admin.uid}>{admin.firstName} {admin.lastName}</option>))
                      }
                    </select>
                  </div>
                )}
              </div>
            ) : (
              <div></div>
            )}
            <div className="mt-2 pt-4 d-flex justify-content-between">
              <button
                disabled={this.props.isLoading}
                onClick={this.props.onClose}
                className="ml-5 btn  btn-outline-secondary font-12 font-hn-medium px-3 confirmationNoBtn"
              >
                {this.props.cancelText ? this.props.cancelText : "No"}
              </button>
              {!this.props.isLoading ? (
                <button
                  disabled={
                    this.props.isLoading ||
                    (this.props.deleteUserFlag
                      ? (
                        this.state.confirmationText.toLowerCase() !== "delete"
                        || (
                          this.props.deleteUserDetails.userType === UserType.ADMIN
                          && !this.state.assignToAdminId
                        )
                      )
                      : false)
                  }
                  onClick={this.returnback}
                  className="mr-5 btn  btn btn-primary shadow font-12 font-hn-medium px-3 confirmationYesBtn"
                >
                  {this.props.confirmText ? this.props.confirmText : "Yes"}
                </button>
              ) : (
                <button className="mr-5 btn  btn btn-primary shadow font-12 font-hn-medium px-3 confirmationYesBtn">
                  {this.props.loadingText}
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ConfirmationModalGlobal;